import { Select } from '@pidedirecto/ui';
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

/**
 * This is an implementation of Select component from library, this is temporary so try to use it always `FormSelect` from library if possible
 */
export function FormSelect({ name, label, placeholder, helperText, defaultValue = null, options, disabled, required, multiselectable, preventClose, tooltip, classes }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLSelectElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <Select
                    value={value}
                    label={required && !!label ? `${label}*` : label}
                    placeholder={placeholder}
                    options={options}
                    multiselectable={multiselectable}
                    preventClose={preventClose}
                    onChange={(value: any) => {
                        onChange(value);
                    }}
                    name={name}
                    disabled={isSubmitting || disabled}
                    tooltip={tooltip}
                    helperText={getError(errors, name)?.message ?? helperText}
                    error={!!getError(errors, name)}
                    classes={classes}
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    label?: string;
    placeholder?: string;
    helperText?: string;
    name: string;
    options: Array<{
        value: any;
        label: string;
        content?: React.ReactNode;
    }>;
    classes?: {
        item?: string;
        button?: string;
    };
    multiselectable?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    preventClose?: boolean;
    error?: boolean;
    defaultValue?: any | Array<any>;
    tooltip?: string;
};
