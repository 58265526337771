import type { SubModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';

export function subModifierGroupsToString(subModifierGroups?: Array<SubModifierGroupVm> | null): string {
    const texts = subModifierGroups
        ? subModifierGroups
              .flatMap((subModifierGroup) => subModifierGroup.subModifiers)
              .filter((subModifier) => subModifier.quantity > 0)
              .map((subModifier) => `${subModifier.name} x ${subModifier.quantity}`)
        : [];

    return texts.length > 0 ? `(${texts.join(', ')})` : '';
}
