import * as React from 'react';

export function DeliveryIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='20' height='14' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M10.7031 0C11.1625 0 11.5844 0.251562 11.8 0.655625L12.2406 1.46656L13.7219 0.231875C13.9031 0.0818749 14.1281 0 14.3625 0H15C15.5531 0 16 0.447812 16 1V2C16 2.55313 15.5531 3 15 3H13.0688L14.7906 6.15938C15.1719 6.06563 15.5781 6 16 6C18.2094 6 20 7.79063 20 10C20 12.2094 18.2094 14 16 14C13.7906 14 12 12.2094 12 10C12 8.72187 12.575 7.63438 13.4719 6.9L12.9906 6.00625C11.7531 6.91875 11 8.36875 11 10C11 10.3156 11.0344 10.6781 11.1 11H8.9C8.96562 10.6781 8.97188 10.3156 8.97188 10C8.97188 7.2375 6.7625 5 3.97187 5H0.971875V4C0.971875 3.44687 1.44781 3 1.97187 3H5.17188C5.70312 3 6.20937 3.20937 6.58437 3.58437L7.97188 5L11.0656 2.44688L10.5531 1.5H8.72188C8.33438 1.5 7.97188 1.16406 7.97188 0.75C7.97188 0.335938 8.33438 0 8.72188 0H10.7031ZM14 10C14 11.1031 14.8969 12 16 12C17.1031 12 18 11.1031 18 10C18 8.89688 17.1031 8 16 8C15.925 8 15.85 8.00312 15.7781 8.0125L16.6594 9.64375C16.8562 10.0063 16.7219 10.4625 16.3563 10.6594C15.9938 10.8563 15.5375 10.7219 15.3406 10.3562L14.4594 8.725C14.1719 9.07188 14 9.51562 14 10ZM7.93125 10.75C7.57813 12.6 5.95312 14 4 14C1.79094 14 0 12.2094 0 10C0 7.79063 1.79094 6 4 6C5.95312 6 7.57813 7.4 7.93125 9.25H5.85313C5.55938 8.51562 4.84062 8 4 8C2.89531 8 2 8.89688 2 10C2 11.1031 2.89531 12 4 12C4.84062 12 5.55938 11.4844 5.85313 10.75H7.93125ZM3 10C3 9.44687 3.44687 9 4 9C4.55313 9 5 9.44687 5 10C5 10.5531 4.55313 11 4 11C3.44687 11 3 10.5531 3 10Z'
                fill={color}
            />
        </svg>
    );
}
type Props = {
    color: string;
};
