import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import * as React from 'react';
import { OrderDetailsItem } from 'src/components/OrderDetailsItem';
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import type { TimeZone } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { ClockIcon } from 'src/icons/ClockIcon';
import { isAsapPickupTime } from 'src/utils/order/isAsapPickupTime';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPickUpStationOrder } from 'src/utils/order/isPickUpStationOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { formatPickupStationTimes } from 'src/utils/transform/formatPickupStationTimes';
import { formatPickupTime } from 'src/utils/transform/formatPickupTime';

export function OrderDetailsItemPickupTime(): React.ReactElement {
    const classes = useStyles();

    const orderStatus = useSelector((state) => state.app.orderStatus);
    const orderType = useSelector((state) => state.app.orderType);
    const order = useSelector((state) => state.app.order);
    const externalDeliveryEstimatedTime = useSelector((state) => state.app.restaurant?.externalDeliveryEstimatedTime);
    const hideDeliveryEstimatedTimeEnabled = useSelector((state) => state.app.restaurant?.hideDeliveryEstimatedTimeEnabled);

    const orderItems = order.orderItems;
    const pickupTime = order.pickupTime;
    const deliveryTime = order.deliveryTime;
    const timeZone = order.timeZone;

    const driverArrivesAtStoreTime = useSelector((state) => state.app.driverArrivesAtStoreTime);

    useSelector((state) => state.app.time); // Triggers minutesToPickupTime update

    const getDeliveryOrderText = () => {
        if (hideDeliveryEstimatedTimeEnabled) return translate('Delivery time');
        if (externalDeliveryEstimatedTime) return translate('Estimated delivery (@minutes min)', { minutes: externalDeliveryEstimatedTime });
        return translate('Estimated delivery');
    };

    const getDeliveryOrderSubText = () => {
        if (hideDeliveryEstimatedTimeEnabled) return translate('Now');
        if (externalDeliveryEstimatedTime) return minutesToDelivery(deliveryTime, timeZone, orderStatus);
        return translate('Between @minutes minutes', { minutes: translate(`DriverArrivesAtStoreTimes.${driverArrivesAtStoreTime ?? 'DEFAULT'}`) });
    };

    if (isPickUpStationOrder(orderType)) {
        return (
            <OrderDetailsItem
                icon={<ClockIcon color={'#C1C3C8'} />}
                text={translate('Estimated time')}
                subtext={formatPickupStationTimes(
                    orderItems.map((cartItem) => cartItem.pickupTime),
                    timeZone,
                ).join('\n')}
                classes={{ container: classes.container }}
            />
        );
    }

    if (isDeliveryOrder(orderType)) {
        if (isAsapPickupTime(order.pickupTimeType)) {
            return <OrderDetailsItem icon={<ClockIcon color={'#C1C3C8'} />} text={getDeliveryOrderText()} subtext={getDeliveryOrderSubText()} classes={{ container: classes.container }} />;
        }

        return (
            <OrderDetailsItem
                icon={<ClockIcon color={'#C1C3C8'} />}
                text={externalDeliveryEstimatedTime ? translate('Estimated delivery (@minutes min)', { minutes: externalDeliveryEstimatedTime }) : translate('Estimated delivery')}
                subtext={minutesToDelivery(deliveryTime, timeZone, orderStatus)}
            />
        );
    }

    return (
        <OrderDetailsItem
            icon={<ClockIcon color={'#C1C3C8'} />}
            text={isRoomServiceOrder(orderType) ? translate('Estimated delivery') : translate('Ready for pickup')}
            subtext={formatPickupTime(pickupTime, timeZone)}
            subtext2={minutesToPickupTime(pickupTime, timeZone, orderStatus)}
            classes={{ container: isTakeAwayOrder(orderType) ? classes.containerOne : classes.container }}
        />
    );
}

function minutesToDelivery(pickupTime: Date | undefined, timeZone: TimeZone, orderStatus: OrderStatus): string {
    const minutes = moment.tz(pickupTime, timeZone).diff(moment.tz(timeZone), 'minutes') + 1 + 5;
    if (!([OrderStatuses.NEW, OrderStatuses.ACCEPTED] as Array<OrderStatus>).includes(orderStatus)) {
        return '';
    }
    if (minutes > 5 && minutes < 60) {
        return `\n${translate('@minutes min left', { minutes: `${minutes - 5}-${minutes}` })}`;
    }
    if (minutes > 0 && minutes <= 5) {
        return `\n${translate('@minutes min left', { minutes: `${0}-${minutes}` })}`;
    }
    if (minutes <= 0) {
        return `\n${translate('@minutes min left', { minutes: '0' })}`;
    }
    return '';
}

function minutesToPickupTime(pickupTime: Date | undefined, timeZone: TimeZone, orderStatus: OrderStatus): string {
    const minutes = moment.tz(pickupTime, timeZone).diff(moment.tz(timeZone), 'minutes') + 1 + 5;
    if (!([OrderStatuses.NEW, OrderStatuses.ACCEPTED] as Array<OrderStatus>).includes(orderStatus)) {
        return '';
    }
    if (minutes > 5 && minutes < 60) {
        return `\n${translate('@minutes min left', { minutes: `${minutes - 5}-${minutes}` })}`;
    }
    if (minutes > 0 && minutes <= 5) {
        return `\n${translate('@minutes min left', { minutes: `${0}-${minutes}` })}`;
    }
    if (minutes <= 0 && orderStatus === OrderStatuses.ACCEPTED) {
        return `\n${translate('Ready for pickup')}`;
    }
    return '';
}

const useStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'flex-end',
        paddingRight: 12,
    },
    containerOne: {
        alignItems: 'flex-start',
        paddingRight: 12,
    },
}));
