export function ascendingBy<T extends any>(property: keyof T): (arg1: T, arg2: T) => number {
    return (c1: T, c2: T) => {
        if (c1[property] > c2[property]) {
            return 1;
        }
        if (c1[property] < c2[property]) {
            return -1;
        }
        return 0;
    };
}
