import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { findValidPromoCodeApi } from 'src/api/pidedirecto/promoCode/findValidPromoCodeApi';
import { BottomDialogCenteredButton } from 'src/components/BottomDialogCenteredButton';
import { Dialog } from 'src/components/Dialog';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUserAddedInvalidPromoCodeLogEvent } from 'src/services/logEvent/createUserAddedInvalidPromoCodeLogEvent';
import { createUserAddedValidPromoCodeLogEvent } from 'src/services/logEvent/createUserAddedValidPromoCodeLogEvent';
import { AppTheme } from 'src/styles/AppTheme';
import { CartItemVm } from 'src/types/CartItemVm';
import { logError } from 'src/utils/log/logError';
import { isPromoCodeApplicable } from 'src/utils/promoCode/isPromoCodeApplicable';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useGetInvalidPromoCodeMessage } from 'src/utils/react/useGetInvalidPromoCodeMessage';
import { useSelector } from 'src/utils/react/useSelector';
import { upperCase } from 'src/utils/string/upperCase';
import { wait } from 'src/utils/wait';

let timeout: any = null;

export function AddPromoCodeDialog(): React.ReactElement {
    const classes = useStyles();

    const input: any = useRef(null);

    const [code, setCode] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [failure, setFailure] = useState<string>();
    const [success, setSuccess] = useState<boolean>();

    const customerId = useSelector((state) => state.app.customerId);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const open = useSelector((state) => state.app.addPromoCodeDialog.open);
    const cartItems = useSelector((state) => state.app.cartItems);
    const closeAddPromoCodeDialog = useAction(actions.closeAddPromoCodeDialog);
    const addPromoCode = useAction(actions.addPromoCode);
    const orderType = useSelector((state) => state.app.orderType);
    const numberOfOrders = useSelector((state) => state.app.numberOfOrders);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const subtotal = useSelector((state) => state.app.payment?.subtotal);
    const total = useSelector((state) => state.app.payment?.total);
    const customerDeliveryCost = useSelector((state) => state.app.payment?.customerDeliveryCost);

    const menuItemIds = cartItems?.map((cartItem: CartItemVm) => cartItem?.menuItemId);
    const getInvalidPromoCodeMessage = useGetInvalidPromoCodeMessage();

    useEffect(() => {
        if (open) {
            clearTimeout(timeout);
            setCode('');
            setInProgress(false);
            setFailure(undefined);
            setSuccess(undefined);
            setTimeout(() => {
                input?.current?.focus();
            }, 300);
        }
    }, [open]);

    const handleOk = async () => {
        if (inProgress) {
            return;
        }
        setInProgress(true);
        const request = {
            restaurantId: restaurantId,
            customerId: customerId,
            code: upperCase(code),
            menuItemIds: menuItemIds,
        } as const;
        const response = await findValidPromoCodeApi(request);
        await wait(1000);
        if (!response.ok) {
            logError('Failed to download promotion', { request, response });
            setInProgress(false);
            setFailure(translate('Failed to download promotion'));
            timeout = setTimeout(() => {
                closeAddPromoCodeDialog();
            }, 2000);
            return;
        }

        console.info('Successfully downloaded promo', { request, response });
        const promoCode = response.data;
        if (!promoCode) {
            console.info('No valid promotion found', { request, response });
            setInProgress(false);
            setFailure(translate('No valid promotion found'));
            createUserAddedInvalidPromoCodeLogEvent({
                promoCode: code,
            });
            timeout = setTimeout(() => {
                closeAddPromoCodeDialog();
            }, 2000);
            return;
        }
        if (!isPromoCodeApplicable({ promoCode, orderType, paymentMethod, amount: total, customerDeliveryCost, menuItemIds, numberOfOrders })) {
            const message = getInvalidPromoCodeMessage(promoCode, menuItemIds);
            setFailure(translate(message));
            setInProgress(false);
            return;
        }
        addPromoCode(promoCode);
        setInProgress(false);
        createUserAddedValidPromoCodeLogEvent({
            promoCode: code,
        });
        setSuccess(true);
        timeout = setTimeout(() => {
            closeAddPromoCodeDialog();
        }, 1000);
    };

    return (
        <Dialog open={open} onClose={!inProgress && closeAddPromoCodeDialog} title={translate('Enter your promo code')}>
            <div>
                <div className={classes.inputContainer}>
                    <TextField
                        ref={input}
                        autoFocus
                        value={code}
                        disabled={inProgress}
                        classes={{ root: classes.inputRoot }}
                        inputProps={{
                            autoCapitalize: 'characters',
                            autoComplete: 'off',
                            className: classNames(classes.input, getInputStyle(inProgress, success, failure, classes)),
                        }}
                        onKeyDown={(e) => {
                            if ((e.key || e.code) === 'Enter') handleOk();
                        }}
                        onChange={async (event) => {
                            setCode(event.target.value);
                        }}
                    />
                </div>

                {(success || !!failure) && (
                    <div className={classes.resultContainer}>
                        {inProgress && <CircularProgress size={40} />}
                        <Slide direction='up' in={success || !!failure} mountOnEnter unmountOnExit>
                            <div className={classes.resultTextContainer}>
                                <div className={classNames(classes.resultText, getResultTextStyle(failure, classes))}>{getResultText(failure)}</div>
                            </div>
                        </Slide>
                    </div>
                )}
            </div>
            <BottomDialogCenteredButton text={translate('Add')} onClick={handleOk} disabled={!code || inProgress || !!failure || success} loading={inProgress} />
        </Dialog>
    );
}

function getInputStyle(inProgress: boolean | null | undefined, success: boolean | null | undefined, failure: string | null | undefined, classes: any) {
    if (inProgress) {
        return classes.inputInProgress;
    }
    if (success) {
        return classes.inputSuccess;
    }
    if (failure) {
        return classes.inputFailure;
    }
    return {};
}

function getResultText(failure?: string) {
    if (failure) {
        return failure;
    }
    return translate('Verified!');
}

function getResultTextStyle(failure: string | null | undefined, classes: any) {
    if (failure) {
        return classes.resultTextFailure;
    }
    return classes.resultTextSuccess;
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '0 16px',
    },
    inputRoot: {
        maxWidth: 300,
        marginTop: 16,
    },
    input: {
        textAlign: 'center',
        fontSize: 36,
        height: 40,
        margin: 0,
        color: AppTheme.text.light,
        textTransform: 'uppercase',
    },
    inputInProgress: {
        color: AppTheme.input.colorDisabled,
    },
    inputSuccess: {
        color: theme.palette.primary.main,
    },
    inputFailure: {
        color: theme.palette.secondary.main,
    },
    resultContainer: {
        position: 'relative',
        height: 56,
        marginTop: 8,
        overflow: 'hidden',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inProgressContainer: {
        position: 'absolute',
        width: '100%',
    },
    resultTextContainer: {
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
    },
    resultText: {
        textAlign: 'center',
        width: '100%',
    },
    resultTextSuccess: {
        color: theme.palette.primary.main,
        fontSize: 26,
    },
    resultTextFailure: {
        color: AppTheme.text.colorFailure,
        fontSize: 18,
    },
}));
