export const PaymentRejectReasons = Object.freeze({
    UNKNOWN: 'UNKNOWN',
    UNCATEGORIZED: 'UNCATEGORIZED',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    COMMUNICATION_ERROR: 'COMMUNICATION_ERROR',
    RESTRICTED_CARD: 'RESTRICTED_CARD',
    INVALID_SECURITY_CODE: 'INVALID_SECURITY_CODE',
    CARD_PAYMENT_NOT_ALLOWED: 'CARD_PAYMENT_NOT_ALLOWED',
});

export type PaymentRejectReason = (typeof PaymentRejectReasons)[keyof typeof PaymentRejectReasons];
