import { isMexico } from 'src/utils/country/isMexico';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useSelector } from 'src/utils/react/useSelector';

export function useCanRestaurantInvoice(): boolean {
    const csdCertificateRestaurantId = useSelector((state) => state.app.restaurant?.csdCertificateRestaurantId);
    const csdCertificateUploaded = useSelector((state) => state.app.restaurant?.csdCertificateUploaded);
    const uruwareClave = useSelector((state) => state.app.restaurant?.uruwareClave);
    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);

    if (isMexico(restaurantCountry)) return !!csdCertificateRestaurantId || !!csdCertificateUploaded;
    if (isUruguay(restaurantCountry)) return !!uruwareClave;

    return false;
}
