import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

const defaultFontSize = 40;

export type IconProps = {
    className?: any;
    size?: number;
};

export function Icon({ character, className, size }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <span className={classNames(classes.icon, className)} style={sizeToStyle(size)}>
            {character}
        </span>
    );
}

function sizeToStyle(size: undefined | number) {
    if (!size) {
        return {};
    }
    return {
        fontSize: size,
        // lineHeight: size / 2, // android fix for centering icon, needs to be half og fontSize
        // paddingTop: size / 2, // android fix for centering icon, needs to be half og fontSize
    };
}

const useStyles = makeStyles((theme) => ({
    icon: {
        fontFamily: 'LetsEatIcons',
        fontSize: defaultFontSize,
        // lineHeight: `${defaultFontSize / 2}px`, // android fix for centering icon, needs to be half og fontSize
        // paddingTop: defaultFontSize / 2, // android fix for centering icon, needs to be half og fontSize
    },
}));

type Props = {
    character: string;
} & IconProps;
