import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useEffect } from 'react';
import * as React from 'react';
import { RestaurantChainBottomDrawer } from 'src/components/bottomDrawers/RestaurantChainBottomDrawer';
import { Page } from 'src/components/Page';
import { RestaurantSelectStoreBanner } from 'src/components/RestaurantSelectStoreBanner';
import { SelectRestaurantDialog } from 'src/components/SelectRestaurantDialog';
import { Pages } from 'src/constants/Page';
import { actions } from 'src/reducers';
import { useRestaurantTheme } from 'src/services/restaurant/useRestaurantTheme';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantChainPage(): React.ReactElement | null {
    const classes = useStyles();

    const restaurantChain = useSelector((state) => state.app.restaurantChain);

    const setPage = useAction(actions.setPage);

    const restaurantTheme = useRestaurantTheme(restaurantChain);

    useEffect(() => {
        setPage(Pages.RESTAURANT_CHAIN_PAGE);
    }, []);

    if (!restaurantChain) return null;

    return (
        <ThemeProvider theme={restaurantTheme}>
            <Page title={'RestaurantChainPage'} className={classes.container}>
                <RestaurantSelectStoreBanner />
                <RestaurantChainBottomDrawer />
                <SelectRestaurantDialog />
            </Page>
        </ThemeProvider>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        height: '100vh',
    },
}));
