import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardVm } from 'src/types/CardVm';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function getCardsApi(request: GetCardsApiRequest): ApiSauceResponse<Array<CardVm>> {
    return pidedirectoApiMethod('card/getCardsApi', request);
}

export type GetCardsApiRequest = {
    customerId: CustomerId;
    restaurantId: RestaurantId;
};
