import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { BannerVm, findRestaurantBannersApi } from 'src/api/pidedirecto/promotionalBanner/findRestaurantBannersApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { CopyIcon } from 'src/icons/CopyIcon';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PromotionalBannerDialog({ open, onClose }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);

    const [loading, banners] = useLoadApi<Array<BannerVm>>(findRestaurantBannersApi, { restaurantId }, { requiredValues: [restaurantId], dependencies: [restaurantId], onError: () => {} });

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentImageIndex < banners?.length - 1) {
                const nextIndex = currentImageIndex + 1;
                setCurrentImageIndex(nextIndex);
            } else {
                setCurrentImageIndex(0);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentImageIndex]);

    const goToNextImage = () => {
        setCurrentImageIndex(currentImageIndex + 1);
    };

    const goToPrevImage = () => {
        setCurrentImageIndex(currentImageIndex - 1);
    };

    const handleCopyPromoCode = async (value: undefined | string) => {
        if (!value) return;
        await navigator.clipboard.writeText(`${value}`);
    };

    if (banners?.length <= 0 || !banners) return null;

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialog: classes.dialog, content: classes.dialogContent }} loading={loading}>
            <div className={classes.imageSliderContainer}>
                <div className={classes.backgroundGradient} />
                {currentImageIndex > 0 && (
                    <Button onClick={goToPrevImage} classes={{ button: classes.buttonLeft }}>
                        &lt;
                    </Button>
                )}
                {banners[currentImageIndex]?.imageUrl && <img className={classes.imageItem} alt={banners[currentImageIndex]?.imageUrl} src={banners[currentImageIndex]?.imageUrl} />}
                {currentImageIndex < banners.length - 1 && (
                    <Button onClick={goToNextImage} classes={{ button: classes.buttonRight }}>
                        &gt;
                    </Button>
                )}
                {banners[currentImageIndex]?.promoCode && (
                    <Input
                        classes={{ inputContainer: classes.input, label: classes.label }}
                        name={'promoCode'}
                        label={translate('Your discount code')}
                        value={banners[currentImageIndex]?.promoCode}
                        disabled
                        rightIcon={
                            <Button classes={{ button: classes.button }} onClick={() => handleCopyPromoCode(banners[currentImageIndex]?.promoCode)}>
                                <CopyIcon />
                                {translate('Copy')}
                            </Button>
                        }
                    />
                )}
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 300,
        height: 600,
        backgroundColor: 'transparent',
    },
    dialogContent: {
        minWidth: 300,
        minHeight: 600,
    },
    imageSliderContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: '100%',
    },
    imageItem: {
        width: 300,
        height: 600,
        objectFit: 'cover',
        background: '#f1f1f1',
        backgroundColor: 'transparent',
        padding: 12,
    },
    buttonLeft: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        left: '10px',
        borderRadius: '50%',
        height: 42,
        width: 42,
        color: '#06B7A2',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    buttonRight: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        right: '10px',
        borderRadius: '50%',
        height: 42,
        width: 42,
        color: '#06B7A2',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    backgroundGradient: {
        position: 'absolute',
        top: '0',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
        border: 'none',
        cursor: 'pointer',
        width: 278,
        height: 560,
    },
    container: {
        backgroundColor: 'black',
    },
    input: {
        position: 'absolute',
        top: '83%',
        left: '2%',
        width: '95%',
        backgroundColor: 'white',
    },
    label: {
        position: 'absolute',
        top: '78%',
        left: '4%',
        width: '95%',
        color: 'white',
    },
    button: {
        position: 'absolute',
        left: '60%',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
};
