import { BigNumber } from 'bignumber.js';
import type { SubModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';
import { sum } from 'src/utils/reduce/sum';

export function calculateOrderItemSubModifierGroupsPrice(subModifierGroups?: Array<SubModifierGroupVm>): BigNumber {
    if (subModifierGroups === undefined || subModifierGroups.length === 0) return BigNumber(0);
    return subModifierGroups
        .flatMap((subModifierGroup) => subModifierGroup.subModifiers)
        .map((subModifier) => BigNumber(subModifier.price).multipliedBy(subModifier.quantity))
        .reduce(sum, BigNumber(0));
}
