import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import type { MenuItemVm, ModifierGroupVm, ModifierVm, RestaurantChainVm, RestaurantMenuVm, RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { GetAppContextSignedInApiResponse } from 'src/api/pidedirecto/getAppContextSignedInApi';
import type { AddressVm } from 'src/api/pidedirecto/types/AddressVm';
import { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { ModifierGroupVm as OrderModifierGroupVm, OrderPaymentVm, OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import type { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import type { AuthenticationType } from 'src/constants/AuthenticationType';
import type { DriverArrivesAtStoreTime } from 'src/constants/DriverArrivesAtStoreTime';
import { MomentFormats } from 'src/constants/MomentFormat';
import type { OrderType } from 'src/constants/OrderType';
import type { Page } from 'src/constants/Page';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { PickupTimeType, PickupTimeTypes } from 'src/constants/PickupTimeType';
import { AwsFacade } from 'src/facade/aws/AwsFacade';
import type { CardVm } from 'src/types/CardVm';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { DeliveryEstimateVm } from 'src/types/DeliveryEstimateVm';
import type { GiftVm } from 'src/types/GiftVm';
import { MenuCategoryId, type AddressId, type CustomerId, type MenuItemId, type PaymentId } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';
import type { AppVm } from 'src/types/StateVm';
import { emptyArrayToUndefined } from 'src/utils/array/emptyArrayToUndefined';
import { createCartItemKey } from 'src/utils/cart/createCartItemKey';
import { refreshPickupTimes } from 'src/utils/cart/refreshPickupTimes';
import { sortGiftsByCreatedAtDesc } from 'src/utils/gift/sortGiftsByCreatedAtDesc';
import { setTitle } from 'src/utils/html/setTitle';
import { refreshCreditsWithGifts } from 'src/utils/payment/refreshCreditsWithGifts';
import { refreshPaymentState } from 'src/utils/payment/refreshPaymentState';
import { requireValue } from 'src/utils/require/requireValue';
import { getDefaultOrderType } from 'src/utils/restaurant/getDefaultOrderType';
import { getDefaultPaymentMethod } from 'src/utils/restaurant/getDefaultPaymentMethod';
import { getDefaultPickupTimeAndType } from 'src/utils/restaurant/getDefaultPickupTimeAndType';

const initialState: AppVm = {
    time: moment().format(MomentFormats.ISO_TIME),
    inProgress: { fetchAppContext: true },
    errors: {},
    selectRestaurantDialog: { open: false },
    restaurantClosedDialog: { open: false },
    restaurantOpeningHoursDialog: { open: false },
    addMenuItemDialog: { open: false },
    addMenuItemSubModifierGroupDialog: { open: false, selectedModifierGroups: [] },
    signInDialog: { open: false },
    signUpDialog: { open: false },
    enterVerificationCodeDialog: { open: false, isSignUp: false },
    enterPasswordDialog: { open: false },
    enterNameDialog: { open: false, onSuccess: undefined },
    enterEmailDialog: { open: false, onSuccess: undefined },
    createCustomerAccountDialog: { open: false, mobileNumber: undefined, firstName: undefined, lastName: undefined, email: undefined, onSuccess: undefined },
    enterCvcDialog: { open: false },
    selectOrderTypeDialog: { open: false },
    selectAddressDialog: { open: false },
    addNewAddressDialog: { open: false },
    addNewNonGeoLocatedAddressDialog: { open: false },
    editAddressDialog: { open: false, address: undefined },
    editNonGeoLocatedAddressDialog: { open: false, address: undefined },
    removeAddressDialog: { open: false, addressId: undefined },
    selectPaymentMethodDialog: { open: false },
    addNewCardDialog: { open: false },
    validateCardDialog: { open: false },
    selectPickupTimeDialog: { open: false },
    selectTableDialog: { open: false },
    addPromoCodeDialog: { open: false },
    cartDialog: { open: false },
    warningAlert: { open: false },
    checkoutDialog: { open: false },
    orderStatusScreenDialog: { open: false },
    otherDriverTipDialog: { open: false },
    stripe3dSecureDialog: { open: false, onUnauthorized: undefined, onAuthorized: undefined },
    restaurant: undefined,
    restaurantMenu: undefined,
    openCategoriesMemory: {},
    openCategory: { category: undefined, animationToCategory: false },
    customerId: undefined,
    numberOfOrders: 0,
    mobileNumber: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    manualOrder: false,
    credits: undefined,
    creditsWithGifts: undefined,
    gifts: undefined,
    paymentMethod: undefined,
    customPaymentMethod: undefined,
    cartItems: [],
    promotions: [],
    cartItemsByMenuItem: {},
    useLetsEatCredits: false,
    payment: undefined,
    paymentId: undefined,
    plexoPaymentSecurityId: undefined,
    stripe3dsClientSecret: undefined,
    paymentFailsCount: 0,
    signedIn: false,
    hasPassword: false,
    order: undefined,
    orderId: undefined,
    driverId: undefined,
    orderStatus: undefined,
    customerAddress: undefined,
    orderPaymentMethod: undefined,
    orderType: undefined,
    driver: undefined,
    menuItemsFound: undefined,
    centerMapToCustomerLocation: false,
    addressLocationMap: undefined,
    googleMapsReady: false,
    restaurantLocation: undefined,
    customerLocation: undefined,
    scrollContainerToPosition: undefined,
    showMap: true,
    quickActionMessage: '',
    loadingDeliveryEstimate: false,
    roomServiceFixedDeliveryCost: undefined,
    restaurantMenuPageIsScrollable: false,
    isCartLimited: false,
    page: undefined,
};

export const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        initApp: (
            app: AppVm,
            {
                payload,
            }: {
                payload: any;
            },
        ) => {},

        setTime: (
            app: AppVm,
            {
                payload: time,
            }: {
                payload: string;
            },
        ) => {
            app.time = time;
        },

        refreshPaymentState: (app: AppVm) => {
            refreshPickupTimes(app);
            refreshCreditsWithGifts(app);
            refreshPaymentState(app);
        },

        setLoadingAppContext: (
            app: AppVm,
            {
                payload: loading,
            }: {
                payload: boolean;
            },
        ) => {
            app.inProgress.fetchAppContext = loading;
        },

        setContentNotFound: (
            app: AppVm,
            {
                payload: contentNotFound,
            }: {
                payload: boolean;
            },
        ) => {
            app.contentNotFound = contentNotFound;
        },

        setRedirectUrl: (
            app: AppVm,
            {
                payload: redirectUrl,
            }: {
                payload: string;
            },
        ) => {
            // TODO: Remove redirectUrl when old url names is no longer in use
            app.redirectUrl = redirectUrl;
        },

        openRestaurantChain: (
            app: AppVm,
            {
                payload: { restaurantChain, restaurants },
            }: {
                payload: {
                    restaurantChain: RestaurantChainVm;
                    restaurants: Array<RestaurantVm>;
                };
            },
        ) => {
            setTitle(restaurantChain.name);
            app.restaurants = restaurants;
            app.restaurantChain = restaurantChain;
            delete app.urlPathname;
            delete app.restaurant;
            delete app.restaurantMenu;
            refreshPaymentState(app);
        },

        refreshRestaurant: (
            app: AppVm,
            {
                payload: { restaurants, urlPathname, restaurantMenu },
            }: {
                payload: {
                    restaurants: Array<RestaurantVm>;
                    urlPathname?: string;
                    restaurantMenu: RestaurantMenuVm;
                };
            },
        ) => {
            // TODO: Remove redirectUrl when old url names is no longer in use
            const restaurant = restaurants.find((r) => r.urlPathname === urlPathname);
            setTitle(restaurant?.name ?? '');
            app.restaurants = restaurants;
            app.urlPathname = urlPathname;
            app.restaurant = restaurant;
            app.restaurantMenu = restaurantMenu;
            refreshPickupTimes(app);
            refreshCreditsWithGifts(app);
            refreshPaymentState(app);
        },

        openRestaurant: (
            app: AppVm,
            {
                payload: { restaurants, urlPathname, restaurantMenu },
            }: {
                payload: {
                    restaurants: Array<RestaurantVm>;
                    urlPathname?: string;
                    restaurantMenu: RestaurantMenuVm;
                };
            },
        ) => {
            // TODO: Remove redirectUrl when old url names is no longer in use
            const restaurant = requireValue(restaurants.find((r) => r.urlPathname === urlPathname));
            setTitle(restaurant.name);
            app.urlPathname = urlPathname;
            app.restaurants = restaurants;
            app.restaurant = restaurant;
            app.restaurantMenu = restaurantMenu;
            app.orderType = getDefaultOrderType(restaurant, app.rememberedOrderType);
            app.paymentMethod = getDefaultPaymentMethod(restaurant, app.rememberedPaymentMethod);
            refreshPickupTimes(app);
            const pickupTimeAndType = getDefaultPickupTimeAndType(restaurant, app.pickupTimes, app.rememberedPickupTimeType, app.rememberedPickupTime);
            app.pickupTimeType = pickupTimeAndType.pickupTimeType;
            app.pickupTime = pickupTimeAndType.pickupTime;
            refreshCreditsWithGifts(app);
            refreshPaymentState(app);
        },

        setPromotions: (app: AppVm, { payload: promotions }: { payload: Array<PromotionVm> }) => {
            app.promotions = promotions;
        },

        openCategory: (app: AppVm, { payload: menuCategoryId }: { payload: MenuCategoryId }) => {
            app.openCategory.category = menuCategoryId;
            app.openCategory.animationToCategory = true;
        },

        endAnimationToCategory: (app: AppVm) => {
            app.openCategory.animationToCategory = false;
        },

        categoryVisible: (app: AppVm, { payload: menuCategoryId }: { payload: MenuCategoryId }) => {
            if (!app.openCategory.animationToCategory) app.openCategory.category = menuCategoryId;
        },

        closeCategory: (app: AppVm, { payload: menuCategoryId }: { payload: MenuCategoryId }) => {
            app.openCategoriesMemory[menuCategoryId] = false;
        },

        addNote: (
            app: AppVm,
            {
                payload: { cartItem, note },
            }: {
                payload: {
                    cartItem: CartItemVm;
                    note: string;
                };
            },
        ) => {
            const existingCartItemIndex = app.cartItems.findIndex((current) => current.key === cartItem.key);
            if (existingCartItemIndex === -1) {
                return;
            }
            const existingCartItem = app.cartItems[existingCartItemIndex];
            const newQuantity = existingCartItem.quantity - 1;
            if (newQuantity > 0) {
                existingCartItem.quantity = newQuantity;
            } else {
                app.cartItems.splice(existingCartItemIndex, 1);
            }

            const cartItemsWithMenuItem = app.cartItemsByMenuItem[cartItem.menuItemId] ?? [];
            const reversedExistingCartItemByMenuItemIndex = [...cartItemsWithMenuItem].reverse().findIndex((current) => current.key === cartItem.key);
            const existingCartItemByMenuItemIndex = cartItemsWithMenuItem.length - 1 - reversedExistingCartItemByMenuItemIndex;
            const existingCartItemByMenuItem = cartItemsWithMenuItem[existingCartItemByMenuItemIndex];
            const newQuantityByMenuItem = existingCartItemByMenuItem.quantity - 1;
            if (newQuantityByMenuItem > 0) {
                existingCartItemByMenuItem.quantity = newQuantityByMenuItem;
            } else {
                cartItemsWithMenuItem.splice(existingCartItemByMenuItemIndex, 1);
                if (cartItemsWithMenuItem.length === 0) {
                    delete app.cartItemsByMenuItem[cartItem.menuItemId];
                }
            }

            let newCartItem = { ...cartItem, note };
            newCartItem = { ...newCartItem, key: createCartItemKey(newCartItem) };

            const existingCartItem2 = app.cartItems.find((current) => current.key === newCartItem.key);
            if (existingCartItem2) {
                existingCartItem2.quantity = existingCartItem2.quantity + newCartItem.quantity;
            } else {
                app.cartItems.push(newCartItem);
            }

            if (!app.cartItemsByMenuItem[newCartItem.menuItemId]) {
                app.cartItemsByMenuItem[newCartItem.menuItemId] = [];
            }
            app.cartItemsByMenuItem[newCartItem.menuItemId]?.push(newCartItem);
        },

        removeNote: (
            app: AppVm,
            {
                payload: cartItem,
            }: {
                payload: CartItemVm;
            },
        ) => {
            const existingCartItemIndex = app.cartItems.findIndex((current) => current.key === cartItem.key);
            if (existingCartItemIndex === -1) {
                return;
            }
            const existingCartItem = app.cartItems[existingCartItemIndex];
            const newQuantity = existingCartItem.quantity - 1;
            if (newQuantity > 0) {
                existingCartItem.quantity = newQuantity;
            } else {
                app.cartItems.splice(existingCartItemIndex, 1);
            }

            const cartItemsWithMenuItem = app.cartItemsByMenuItem[cartItem.menuItemId] ?? [];
            const reversedExistingCartItemByMenuItemIndex = [...cartItemsWithMenuItem].reverse().findIndex((current) => current.key === cartItem.key);
            const existingCartItemByMenuItemIndex = cartItemsWithMenuItem.length - 1 - reversedExistingCartItemByMenuItemIndex;
            const existingCartItemByMenuItem = cartItemsWithMenuItem[existingCartItemByMenuItemIndex];
            const newQuantityByMenuItem = existingCartItemByMenuItem.quantity - 1;
            if (newQuantityByMenuItem > 0) {
                existingCartItemByMenuItem.quantity = newQuantityByMenuItem;
            } else {
                cartItemsWithMenuItem.splice(existingCartItemByMenuItemIndex, 1);
                if (cartItemsWithMenuItem.length === 0) {
                    delete app.cartItemsByMenuItem[cartItem.menuItemId];
                }
            }

            let newCartItem = { ...cartItem };
            delete newCartItem.note;
            newCartItem = { ...newCartItem, key: createCartItemKey(newCartItem) };

            const existingCartItem2 = app.cartItems.find((current) => current.key === newCartItem.key);
            if (existingCartItem2) {
                existingCartItem2.quantity = existingCartItem2.quantity + newCartItem.quantity;
            } else {
                app.cartItems.push(newCartItem);
            }

            if (!app.cartItemsByMenuItem[newCartItem.menuItemId]) {
                app.cartItemsByMenuItem[newCartItem.menuItemId] = [];
            }
            app.cartItemsByMenuItem[newCartItem.menuItemId]?.push(newCartItem);
        },

        selectOrderType: (
            app: AppVm,
            {
                payload: orderType,
            }: {
                payload?: OrderType;
            },
        ) => {
            app.orderType = orderType;
            app.rememberedOrderType = orderType;
            refreshPaymentState(app);
            refreshPickupTimes(app);
        },

        selectPaymentMethod: (
            app: AppVm,
            {
                payload: { paymentMethod, card, customPaymentMethod },
            }: {
                payload: {
                    paymentMethod?: PaymentMethod;
                    card?: CardVm;
                    customPaymentMethod?: OrderPaymentVm;
                };
            },
        ) => {
            app.paymentMethod = paymentMethod;
            app.rememberedPaymentMethod = paymentMethod;
            app.customPaymentMethod = customPaymentMethod;
            if (card) app.card = card;
            refreshPaymentState(app);
        },

        selectAddress: (
            app: AppVm,
            {
                payload: address,
            }: {
                payload?: AddressVm;
            },
        ) => {
            app.address = address;
        },

        selectPickupTime: (
            app: AppVm,
            {
                payload: { pickupTimeType, pickupTime },
            }: {
                payload: {
                    pickupTimeType?: PickupTimeType;
                    pickupTime?: string;
                };
            },
        ) => {
            app.pickupTimeType = pickupTimeType;
            app.pickupTime = pickupTime;
            app.rememberedPickupTimeType = pickupTimeType;
            app.rememberedPickupTime = pickupTimeType === PickupTimeTypes.PLANNED ? pickupTime : undefined;
            refreshPaymentState(app);
        },

        selectTable: (
            app: AppVm,
            {
                payload: table,
            }: {
                payload?: string;
            },
        ) => {
            app.table = table;
        },

        setAddresses: (
            app: AppVm,
            {
                payload: addresses,
            }: {
                payload: Array<AddressVm>;
            },
        ) => {
            app.addresses = emptyArrayToUndefined(addresses);
        },

        addAddress: (
            app: AppVm,
            {
                payload: address,
            }: {
                payload: AddressVm;
            },
        ) => {
            app.addresses = app.addresses ? [...app.addresses, address] : [address];
        },

        changeAddress: (
            app: AppVm,
            {
                payload: address,
            }: {
                payload: AddressVm;
            },
        ) => {
            app.addresses = emptyArrayToUndefined(app.addresses?.filter((a: AddressVm) => a.addressId !== address.addressId));
            app.addresses = app.addresses ? [...app.addresses, address] : [address];
            if (app.address && app.address.addressId === address.addressId) {
                app.address = address;
            }
        },

        removeAddress: (
            app: AppVm,
            {
                payload: address,
            }: {
                payload: AddressVm;
            },
        ) => {
            app.addresses = emptyArrayToUndefined(app.addresses?.filter((a: AddressVm) => a.addressId !== address.addressId));
            if (app.address && app.address.addressId === address.addressId) {
                if (app.addresses?.length) {
                    app.address = app.addresses[app.addresses.length - 1];
                } else {
                    delete app.address;
                }
            }
        },

        setCards: (
            app: AppVm,
            {
                payload: cards,
            }: {
                payload: Array<CardVm>;
            },
        ) => {
            app.cards = emptyArrayToUndefined(cards);
        },

        addCard: (
            app: AppVm,
            {
                payload: card,
            }: {
                payload: CardVm;
            },
        ) => {
            app.cards = app.cards ? [...app.cards, card] : [card];
        },

        updateCard: (
            app: AppVm,
            {
                payload: card,
            }: {
                payload: CardVm;
            },
        ) => {
            app.cards = app.cards?.map((_card: CardVm) => (_card.cardId === card.cardId ? card : _card));
            if (app.card && app.card.cardId === card.cardId) {
                app.card = card;
            }
        },

        removeCard: (
            app: AppVm,
            {
                payload: card,
            }: {
                payload: CardVm;
            },
        ) => {
            app.cards = emptyArrayToUndefined(app.cards?.filter((c: CardVm) => c.cardId !== card.cardId));
            if (app.card && app.card.cardId === card.cardId) {
                if (app.cards?.length) {
                    app.card = app.cards[app.cards.length - 1];
                } else {
                    delete app.card;
                }
            }
        },

        addPromoCode: (
            app: AppVm,
            {
                payload: promoCode,
            }: {
                payload: PromoCodeVm;
            },
        ) => {
            app.promoCode = promoCode;
            refreshPaymentState(app);
        },

        removePromoCode: (app: AppVm) => {
            delete app.promoCode;
            refreshPaymentState(app);
        },

        setUseLetsEatCredits: (
            app: AppVm,
            {
                payload: useLetsEatCredits,
            }: {
                payload: boolean;
            },
        ) => {
            app.useLetsEatCredits = useLetsEatCredits;
            refreshPaymentState(app);
        },

        setCreditsAndGifts(
            app: AppVm,
            {
                payload: { credits, gifts },
            }: {
                payload: {
                    credits?: string;
                    gifts: Array<GiftVm>;
                };
            },
        ) {
            app.credits = credits;
            app.gifts = sortGiftsByCreatedAtDesc(gifts);
            refreshCreditsWithGifts(app);
            refreshPaymentState(app);
        },

        setCustomerId: (
            app: AppVm,
            {
                payload: customerId,
            }: {
                payload?: CustomerId;
            },
        ) => {
            app.customerId = customerId;
        },

        setNumberOfOrders: (
            app: AppVm,
            {
                payload: numberOfOrders,
            }: {
                payload: number;
            },
        ) => {
            app.numberOfOrders = numberOfOrders;
        },

        setMobileNumber: (
            app: AppVm,
            {
                payload: mobileNumber,
            }: {
                payload?: string;
            },
        ) => {
            app.mobileNumber = mobileNumber;
        },

        setEmail: (
            app: AppVm,
            {
                payload: email,
            }: {
                payload?: string;
            },
        ) => {
            app.email = email;
        },

        setFirstName: (
            app: AppVm,
            {
                payload: firstName,
            }: {
                payload?: string;
            },
        ) => {
            app.firstName = firstName;
        },

        setLastName: (
            app: AppVm,
            {
                payload: lastName,
            }: {
                payload?: string;
            },
        ) => {
            app.lastName = lastName;
        },

        setPaymentFailsCount: (
            app: AppVm,
            {
                payload: paymentFailsCount,
            }: {
                payload?: number;
            },
        ) => {
            app.paymentFailsCount = paymentFailsCount;
        },

        openSignInDialog: (app: AppVm) => {
            app.signInDialog.open = true;
        },

        closeSignInDialog: (app: AppVm) => {
            app.signInDialog.open = false;
        },

        openEnterVerificationCodeDialog: (
            app: AppVm,
            {
                payload: { mobileNumber, isSignUp, authenticationType, onSuccess },
            }: {
                payload: {
                    mobileNumber: string;
                    isSignUp: boolean;
                    authenticationType: AuthenticationType;
                    onSuccess: Function;
                };
            },
        ) => {
            app.enterVerificationCodeDialog.open = true;
            app.enterVerificationCodeDialog.isSignUp = isSignUp;
            app.enterVerificationCodeDialog.mobileNumber = mobileNumber;
            app.enterVerificationCodeDialog.authenticationType = authenticationType;
            app.enterVerificationCodeDialog.onSuccess = onSuccess;
        },

        closeEnterVerificationCodeDialog: (app: AppVm) => {
            app.enterVerificationCodeDialog.open = false;
            app.enterVerificationCodeDialog.isSignUp = false;
            app.enterVerificationCodeDialog.authenticationType = undefined;
            app.enterVerificationCodeDialog.mobileNumber = undefined;
            app.enterVerificationCodeDialog.onSuccess = undefined;
        },

        openEnterPasswordDialog: (
            app: AppVm,
            {
                payload: { mobileNumber, onForgotPassword, onSuccess },
            }: {
                payload: {
                    mobileNumber: string;
                    onForgotPassword: Function;
                    onSuccess: Function;
                };
            },
        ) => {
            app.enterPasswordDialog.open = true;
            app.enterPasswordDialog.mobileNumber = mobileNumber;
            app.enterPasswordDialog.onForgotPassword = onForgotPassword;
            app.enterPasswordDialog.onSuccess = onSuccess;
        },

        closeEnterPasswordDialog: (app: AppVm) => {
            app.enterPasswordDialog.open = false;
            app.enterPasswordDialog.mobileNumber = undefined;
            app.enterPasswordDialog.onForgotPassword = undefined;
            app.enterPasswordDialog.onSuccess = undefined;
        },

        openEnterNameDialog: (app: AppVm, { payload: { onSuccess } }: { payload: { onSuccess: () => void | Promise<void> } }) => {
            app.enterNameDialog.open = true;
            app.enterNameDialog.onSuccess = onSuccess;
        },

        closeEnterNameDialog: (app: AppVm) => {
            app.enterNameDialog.open = false;
            app.enterNameDialog.onSuccess = undefined;
        },

        openEnterEmailDialog: (app: AppVm, { payload: { onSuccess } }: { payload: { onSuccess: () => void | Promise<void> } }) => {
            app.enterEmailDialog.open = true;
            app.enterEmailDialog.onSuccess = onSuccess;
        },

        closeEnterEmailDialog: (app: AppVm) => {
            app.enterEmailDialog.open = false;
            app.enterEmailDialog.onSuccess = undefined;
        },

        openSignUpDialog: (app: AppVm) => {
            app.signUpDialog.open = true;
        },

        closeSignUpDialog: (app: AppVm) => {
            app.signUpDialog.open = false;
        },

        openCreateCustomerAccountDialog: (
            app: AppVm,
            {
                payload: { mobileNumber, firstName, lastName, email, onSuccess },
            }: { payload: { mobileNumber: string; firstName: string; lastName: string; email: string; onSuccess: (appContext: GetAppContextSignedInApiResponse) => void | Promise<void> } },
        ) => {
            app.createCustomerAccountDialog.open = true;
            app.createCustomerAccountDialog.mobileNumber = mobileNumber;
            app.createCustomerAccountDialog.firstName = firstName;
            app.createCustomerAccountDialog.lastName = lastName;
            app.createCustomerAccountDialog.email = email;
            app.createCustomerAccountDialog.onSuccess = onSuccess;
        },

        closeCreateCustomerAccountDialog: (app: AppVm) => {
            app.createCustomerAccountDialog.open = false;
            app.createCustomerAccountDialog.mobileNumber = undefined;
            app.createCustomerAccountDialog.firstName = undefined;
            app.createCustomerAccountDialog.lastName = undefined;
            app.createCustomerAccountDialog.email = undefined;
            app.createCustomerAccountDialog.onSuccess = undefined;
        },

        openEnterCvcDialog: (app: AppVm) => {
            app.enterCvcDialog.open = true;
        },

        closeEnterCvcDialog: (app: AppVm) => {
            app.enterCvcDialog.open = false;
        },
        openRemoveAddressDialog: (
            app: AppVm,
            {
                payload: addressId,
            }: {
                payload: AddressId;
            },
        ) => {
            app.removeAddressDialog.addressId = addressId;
            app.removeAddressDialog.open = true;
        },

        closeRemoveAddressDialog: (app: AppVm) => {
            app.removeAddressDialog.open = false;
        },

        openSelectRestaurantDialog: (app: AppVm) => {
            app.selectRestaurantDialog.open = true;
        },

        closeSelectRestaurantDialog: (app: AppVm) => {
            app.selectRestaurantDialog.open = false;
        },

        openRestaurantOpeningHoursDialog: (app: AppVm) => {
            app.restaurantOpeningHoursDialog.open = true;
        },

        closeRestaurantOpeningHoursDialog: (app: AppVm) => {
            app.restaurantOpeningHoursDialog.open = false;
        },

        closeRestaurantClosedDialog: (app: AppVm) => {
            app.restaurantClosedDialog.open = false;
        },

        openRestaurantClosedDialog: (app: AppVm) => {
            app.restaurantClosedDialog.open = true;
        },

        openSelectOrderTypeDialog: (app: AppVm) => {
            app.selectOrderTypeDialog.open = true;
        },

        closeSelectOrderTypeDialog: (app: AppVm) => {
            app.selectOrderTypeDialog.open = false;
        },

        openSelectAddressDialog: (app: AppVm) => {
            app.selectAddressDialog.open = true;
        },

        closeSelectAddressDialog: (app: AppVm) => {
            app.selectAddressDialog.open = false;
        },

        openStripe3dSecureDialog: (
            app: AppVm,
            {
                payload: { onAuthorized, onUnauthorized },
            }: {
                payload: {
                    onAuthorized: any;
                    onUnauthorized: any;
                };
            },
        ) => {
            app.stripe3dSecureDialog.open = true;
            app.stripe3dSecureDialog.onAuthorized = onAuthorized;
            app.stripe3dSecureDialog.onUnauthorized = onUnauthorized;
        },

        closeStripe3dSecureDialog: (app: AppVm) => {
            app.stripe3dSecureDialog.open = false;
        },

        setStripe3dsClientSecret: (app: AppVm, { payload: stripe3dsClientSecret }: { payload: string }) => {
            app.stripe3dsClientSecret = stripe3dsClientSecret;
        },

        setPaymentId: (
            app: AppVm,
            {
                payload: paymentId,
            }: {
                payload: PaymentId;
            },
        ) => {
            app.paymentId = paymentId;
        },

        setOrder: (
            app: AppVm,
            {
                payload: order,
            }: {
                payload: OrderVm;
            },
        ) => {
            app.order = order;
            app.orderId = order.orderId;
            app.orderStatus = order.orderStatus;
            app.orderType = order.orderType;
            app.orderPaymentMethod = order.paymentMethod;
            app.manualOrder = !!order.manualOrder;
        },

        setDriver: (
            app: AppVm,
            {
                payload: order,
            }: {
                payload: OrderVm;
            },
        ) => {
            app.driverId = order.driverId;
            app.driver = order.driver;
            app.deliveryStatus = order.deliveryStatus;
        },

        setShowMap: (
            app: AppVm,
            {
                payload: showMap,
            }: {
                payload: boolean;
            },
        ) => {
            app.showMap = showMap;
        },

        setCustomer: (
            app: AppVm,
            {
                payload: order,
            }: {
                payload: OrderVm;
            },
        ) => {
            app.customerAddress = order.address;
            if (!app.firstName) app.firstName = order.firstName;
            if (!app.customerLocation) app.customerLocation = order.address?.location;
        },

        setRestaurant: (
            app: AppVm,
            {
                payload: restaurant,
            }: {
                payload: RestaurantVm;
            },
        ) => {
            if (!app.restaurant) app.restaurant = restaurant;
            if (!app.restaurantLocation) app.restaurantLocation = restaurant.location;
        },

        setDriverArrivesAtStoreTime: (
            app: AppVm,
            {
                payload: driverArrivesAtStoreTime,
            }: {
                payload: DriverArrivesAtStoreTime;
            },
        ) => {
            if (!app.driverArrivesAtStoreTime) app.driverArrivesAtStoreTime = driverArrivesAtStoreTime;
        },

        setTrackInApp: (
            app: AppVm,
            {
                payload: { trackInAppBannerImageUrl, trackInAppBannerColor, trackInAppBannerBrandName },
            }: {
                payload: {
                    trackInAppBannerImageUrl: string;
                    trackInAppBannerColor: string;
                    trackInAppBannerBrandName: string;
                };
            },
        ) => {
            app.trackInAppBannerImageUrl = trackInAppBannerImageUrl;
            app.trackInAppBannerColor = trackInAppBannerColor;
            app.trackInAppBannerBrandName = trackInAppBannerBrandName;
        },

        openAddNewAddressDialog: (app: AppVm) => {
            app.addNewAddressDialog.open = true;
        },

        closeAddNewAddressDialog: (app: AppVm) => {
            app.addNewAddressDialog.open = false;
        },

        openAddNewNonGeoLocatedAddressDialog: (app: AppVm) => {
            app.addNewNonGeoLocatedAddressDialog.open = true;
        },

        closeAddNewNonGeoLocatedAddressDialog: (app: AppVm) => {
            app.addNewNonGeoLocatedAddressDialog.open = false;
        },

        openEditAddressDialog: (
            app: AppVm,
            {
                payload: editAddress,
            }: {
                payload: AddressVm;
            },
        ) => {
            app.editAddressDialog.open = true;
            app.editAddressDialog.address = editAddress;
        },

        closeEditAddressDialog: (app: AppVm) => {
            app.editAddressDialog.open = false;
        },

        openEditNonGeoLocatedAddressDialog: (
            app: AppVm,
            {
                payload: editAddress,
            }: {
                payload: AddressVm;
            },
        ) => {
            app.editNonGeoLocatedAddressDialog.open = true;
            app.editNonGeoLocatedAddressDialog.address = editAddress;
        },

        closeEditNonGeoLocatedAddressDialog: (app: AppVm) => {
            app.editNonGeoLocatedAddressDialog.open = false;
        },

        openSelectPaymentMethodDialog: (app: AppVm) => {
            app.selectPaymentMethodDialog.open = true;
        },

        closeSelectPaymentMethodDialog: (app: AppVm) => {
            app.selectPaymentMethodDialog.open = false;
        },

        openAddNewCardDialog: (app: AppVm) => {
            app.addNewCardDialog.open = true;
        },

        closeAddNewCardDialog: (app: AppVm) => {
            app.addNewCardDialog.open = false;
        },

        openValidateCardDialog: (
            app: AppVm,
            {
                payload: { card, onValidatedCard },
            }: {
                payload: {
                    card: CardVm;
                    onValidatedCard?: (arg1: CardVm) => any;
                };
            },
        ) => {
            app.validateCardDialog.open = true;
            app.validateCardDialog.card = card;
            app.validateCardDialog.onValidatedCard = onValidatedCard;
        },

        closeValidateCardDialog: (app: AppVm) => {
            app.validateCardDialog.open = false;
        },

        openSelectPickupTimeDialog: (app: AppVm) => {
            app.selectPickupTimeDialog.open = true;
        },

        closeSelectPickupTimeDialog: (app: AppVm) => {
            app.selectPickupTimeDialog.open = false;
        },

        openSelectTableDialog: (app: AppVm) => {
            app.selectTableDialog.open = true;
        },

        closeSelectTableDialog: (app: AppVm) => {
            app.selectTableDialog.open = false;
        },

        openAddPromoCodeDialog: (app: AppVm) => {
            app.addPromoCodeDialog.open = true;
        },

        closeAddPromoCodeDialog: (app: AppVm) => {
            app.addPromoCodeDialog.open = false;
        },

        openAddMenuItemDialog: (
            app: AppVm,
            {
                payload: menuItem,
            }: {
                payload: MenuItemVm;
            },
        ) => {
            app.addMenuItemDialog.open = true;
            app.addMenuItemDialog.menuItem = menuItem;
        },

        closeAddMenuItemDialog: (app: AppVm) => {
            app.addMenuItemDialog.open = false;
        },

        openAddMenuItemSubModifierGroupDialog: (
            app: AppVm,
            {
                payload: { modifierGroup, modifier, selectedModifierGroups },
            }: {
                payload: {
                    modifierGroup: ModifierGroupVm;
                    modifier: ModifierVm;
                    selectedModifierGroups: Array<OrderModifierGroupVm>;
                };
            },
        ) => {
            app.addMenuItemSubModifierGroupDialog.open = true;
            app.addMenuItemSubModifierGroupDialog.modifierGroup = modifierGroup;
            app.addMenuItemSubModifierGroupDialog.modifier = modifier;
            app.addMenuItemSubModifierGroupDialog.selectedModifierGroups = selectedModifierGroups;
        },

        closeAddMenuItemSubModifierGroupDialog: (app: AppVm) => {
            app.addMenuItemSubModifierGroupDialog.open = false;
        },

        openCartDialog: (app: AppVm) => {
            app.cartDialog.open = true;
        },

        closeCartDialog: (app: AppVm) => {
            app.cartDialog.open = false;
        },

        openWarningAlert: (
            app: AppVm,
            {
                payload: { message, title },
            }: {
                payload: {
                    message: string;
                    title: string;
                };
            },
        ) => {
            app.warningAlert.open = true;
            app.warningAlert.message = message;
            app.warningAlert.title = title;
        },

        closeWarningAlert: (app: AppVm) => {
            app.warningAlert.open = false;
            app.warningAlert.message = undefined;
            app.warningAlert.title = undefined;
        },

        openCheckoutDialog: (app: AppVm) => {
            app.checkoutDialog.open = true;
        },

        closeCheckoutDialog: (app: AppVm) => {
            app.checkoutDialog.open = false;
        },

        openOrderStatusScreenDialog: (app: AppVm) => {
            app.orderStatusScreenDialog.open = true;
        },

        closeOrderStatusScreenDialog: (app: AppVm) => {
            app.orderStatusScreenDialog.open = false;
        },

        createOrder: (app: AppVm) => {},

        signOut: (app: AppVm) => {
            AwsFacade.signOut();
            app.useLetsEatCredits = false;
            app.customerId = undefined;
            app.mobileNumber = undefined;
            app.email = undefined;
            app.firstName = undefined;
            app.lastName = undefined;
            refreshPickupTimes(app);
            refreshCreditsWithGifts(app);
            refreshPaymentState(app);
        },

        searchMenuItems: (
            app: AppVm,
            {
                payload: menuItem,
            }: {
                payload: string;
            },
        ) => {
            menuItem = menuItem.trim();
            const regExp = new RegExp(menuItem, 'i');
            const sections = app.restaurantMenu?.menus[0]?.categories;

            if (!sections) return;

            let menuItemsFound: Array<MenuItemVm> = [];

            for (const section of sections) {
                menuItemsFound = [...menuItemsFound, ...section.menuItems.filter((item) => regExp.test(item.name))];
            }

            app.menuItemsFound = menuItemsFound;
        },

        clearSearchMenuItems: (app: AppVm) => {
            app.menuItemsFound = undefined;
        },

        centerMapToCustomerLocation: (app: AppVm) => {
            app.centerMapToCustomerLocation = true;
            app.addressLocationMap = undefined;
        },

        removeCenterMapToCustomerLocation: (app: AppVm) => {
            app.centerMapToCustomerLocation = false;
        },

        setAddressLocationMap: (app: AppVm, { payload: addressLocation }: { payload: { street: string; googlePlaceId: string; name: string; formattedAddress: string; location: GeoJsonPointVm } }) => {
            app.addressLocationMap = addressLocation;
        },

        setGoogleMapsReady: (app: AppVm) => {
            app.googleMapsReady = true;
        },

        setDeliveryEstimate: (app: AppVm, { payload: deliveryEstimate }: { payload: DeliveryEstimateVm }) => {
            app.deliveryEstimate = {
                deliveryEstimateId: deliveryEstimate.deliveryEstimateId,
                restaurantId: deliveryEstimate.restaurantId,
                deliveryLocation: deliveryEstimate.deliveryLocation,
                drivingDistance: deliveryEstimate.drivingDistance,
                drivingDuration: deliveryEstimate.drivingDuration,
                deliveryCost: deliveryEstimate.deliveryCost,
                customerDeliveryCost: deliveryEstimate.customerDeliveryCost,
                restaurantDeliveryCost: deliveryEstimate.restaurantDeliveryCost,
                isWithinDeliveryRadius: deliveryEstimate.isWithinDeliveryRadius,
            };
            app.driverArrivesAtStoreTime = deliveryEstimate.driverArrivesAtStoreTime;
            refreshPaymentState(app);
            refreshPickupTimes(app);
        },

        setLoadingDeliveryEstimate: (app: AppVm, { payload: loadingDeliveryEstimate }: { payload: boolean }) => {
            app.loadingDeliveryEstimate = loadingDeliveryEstimate;
        },

        setRoomServiceDeliveryCost: (app: AppVm, { payload: roomServiceFixedDeliveryCost }: { payload: string | undefined }) => {
            app.roomServiceFixedDeliveryCost = roomServiceFixedDeliveryCost;
            refreshPaymentState(app);
        },

        setDriverTip: (app: AppVm, { payload: driverTip }: { payload: string | undefined }) => {
            app.driverTip = driverTip;
            refreshPaymentState(app);
        },

        openOtherDriverTipDialog: (app: AppVm) => {
            app.otherDriverTipDialog.open = true;
        },

        closeOtherDriverTipDialog: (app: AppVm) => {
            app.otherDriverTipDialog.open = false;
        },

        setQuickActionMessage: (app: AppVm, { payload: quickActionMessage }: { payload: string | undefined }) => {
            app.quickActionMessage = quickActionMessage;
        },

        setSettings: (app: AppVm, { payload: settings }: { payload: { whatsAppAuthenticationEnabled: boolean } | undefined }) => {
            app.settings = settings;
        },
        setRestaurantMenuPageIsScrollable: (app: AppVm, { payload: restaurantMenuPageIsScrollable }: { payload: boolean }) => {
            app.restaurantMenuPageIsScrollable = restaurantMenuPageIsScrollable;
        },

        scrollContainerTo: (app: AppVm, { payload: scrollTo }: { payload: number | undefined }) => {
            app.scrollContainerToPosition = scrollTo;
        },

        clearScrollContainerToPosition: (app: AppVm) => {
            app.scrollContainerToPosition = undefined;
        },

        addNotes: (app: AppVm, { payload: note }: { payload: string | undefined }) => {
            app.notes = note;
        },

        setMandatoryCashAmount: (app: AppVm, { payload: cashAmount }: { payload: string | undefined }) => {
            app.mandatoryCashAmount = cashAmount;
        },

        addRoomServiceNumber: (app: AppVm, { payload: roomServiceNumber }: { payload: string | undefined }) => {
            app.roomServiceNumber = roomServiceNumber;
        },

        setCartItems: (
            app: AppVm,
            {
                payload: { cartItems, cartItemsByMenuItem },
            }: {
                payload: {
                    cartItems: Array<CartItemVm>;
                    cartItemsByMenuItem: Partial<Record<MenuItemId, Array<CartItemVm>>>;
                };
            },
        ) => {
            app.cartItems = cartItems;
            app.cartItemsByMenuItem = cartItemsByMenuItem;

            refreshPaymentState(app);
        },

        setIsCartLimited: (app: AppVm, { payload: isCartLimited }: { payload: boolean }) => {
            app.isCartLimited = isCartLimited;
        },

        setPage: (
            app: AppVm,
            {
                payload: page,
            }: {
                payload: Page;
            },
        ) => {
            app.page = page;
        },

        setPlexoPaymentSecurityId: (
            app: AppVm,
            {
                payload: plexoPaymentSecurityId,
            }: {
                payload: string;
            },
        ) => {
            app.plexoPaymentSecurityId = plexoPaymentSecurityId;
        },

        setHasPassword: (
            app: AppVm,
            {
                payload: hasPassword,
            }: {
                payload: boolean;
            },
        ) => {
            app.hasPassword = hasPassword;
        },
    },
});

export const actions = slice.actions;
