import { isString } from 'src/utils/string/isString';

export function trim<T extends string | null | undefined>(value: T): T {
    if (value === undefined || value === null) {
        return value as any;
    }
    if (!isString(value)) {
        return value;
    }
    return (value as any).trim();
}
