import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

export function PlusIcon({ color }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <svg className={classes.icon} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9 1.5V16.5M16.5 9H1.5' stroke={color || 'currentColor'} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 12,
        height: 12,
    },
}));

type Props = {
    color?: string;
};
