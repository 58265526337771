import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { memo } from 'react';
import { Button } from 'src/components/Button';
import { CartQuantitySpinner } from 'src/components/cart/CartQuantitySpinner';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { useRemoveItem } from 'src/services/order/useRemoveItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import type { CartItemVm } from 'src/types/CartItemVm';
import { modifierGroupsAndNoteToString } from 'src/utils/cart/modifierGroupsAndNoteToString';
import { transformImageUrlMenuItem } from 'src/utils/image/transformImageUrlMenuItem';
import { calculateOrderItemProductDiscountPrice } from 'src/utils/order/calculateOrderItemProductDiscountPrice';
import { calculateOrderItemSubtotalPrice } from 'src/utils/order/calculateOrderItemSubtotalPrice';
import { isItemSoldByWeight } from 'src/utils/order/isItemSoldByWeight';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export const CartItem: React.ComponentType<Props> = memo<Props>(function CartItem({ cartItem, leftPad }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const theme = useTheme();

    const { removeItem } = useRemoveItem();

    const promotions = useSelector((state) => state.app.promotions);
    const usedPromotions = useSelector((state) => state.app.payment?.usedPromotions) ?? [];
    const menuItemCurrency = useSelector((state) => state.app.addMenuItemDialog.menuItem?.currency);

    const { name, imageUrl, quantity } = cartItem;
    const modifiersAndNote = modifierGroupsAndNoteToString(cartItem.modifierGroups, cartItem.note);
    const usedOrderItemPromotion = usedPromotions.find((usedPromotion) => usedPromotion.cartItemKey === cartItem.key);
    const promotion = promotions.find((promotion) => usedOrderItemPromotion?.promotionId === promotion.promotionId);

    const quantityLabel = () => {
        if (cartItem.salesUnit === 'CURRENCY') return `${menuItemCurrency}`;
        return translate(`UnitsOfMeasurement.${cartItem.salesUnit}`);
    };
    return (
        <div className={classes.container}>
            {(!!imageUrl || leftPad) && (
                <div className={classes.imageOrSpacesContainer}>
                    {!imageUrl && <div className={classes.imagePlaceholder} />}
                    {!!imageUrl && (
                        <img className={classNames(classes.image, { [classes.imagePng]: imageUrl.endsWith('png') })} src={transformImageUrlMenuItem(imageUrl)} alt='image' draggable='false' />
                    )}
                </div>
            )}
            <div className={classes.nonImageContainer}>
                <div className={classes.textContainer}>
                    <div className={classes.nameContainer}>
                        <Text className={classes.name}>{name}</Text>
                        {!!promotion && <Text style={{ color: theme.palette?.text?.brand }}>{promotion.name}</Text>}
                    </div>
                    <div>
                        <div className={classes.priceContainer}>
                            {!!cartItem.promoUnitPrice && <Text className={classes.price}>{formatAsCurrencyNumber(calculateOrderItemProductDiscountPrice(cartItem).toString())}</Text>}
                            <Text className={classNames(classes.price, { [classes.nonPromoPrice]: cartItem.promoUnitPrice })}>
                                {formatAsCurrencyNumber(calculateOrderItemSubtotalPrice(cartItem).toString())}
                            </Text>
                            {!!cartItem.promoText && <Text className={classes.promoText}>{cartItem.promoText}</Text>}
                        </div>
                        <Text className={classes.modifierGroupsAndNote}>{modifiersAndNote}</Text>
                    </div>
                </div>
                {!isItemSoldByWeight(cartItem) && <CartQuantitySpinner cartItem={cartItem} quantity={quantity} />}
                {isItemSoldByWeight(cartItem) && (
                    <div className={classes.separatedLineText}>
                        <Button onClick={() => removeItem(cartItem)} text>
                            <TrashcanIcon />
                        </Button>
                        <div className={classes.quantityItemContainer}>
                            <span>{quantityLabel()}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    imagePlaceholder: {
        width: 60,
    },
    image: {
        width: 60,
        height: 60,
        borderRadius: 4,
        backgroundColor: AppTheme.imagePlaceholder.backgroundColor,
        objectFit: 'cover',
    },
    imagePng: {
        objectFit: 'contain',
    },
    nonImageContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 18,
        paddingLeft: 12,
        // paddingRight: 8,
    },
    textContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
    },
    name: {
        flex: 1,
        fontSize: 16,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 4,
        marginRight: -theme.spacing(1),
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    promoText: {
        fontSize: 10,
        lineHeight: '20px',
        color: '#FF7F00',
        fontWeight: 600,
        marginLeft: 8,
    },
    price: {
        fontSize: 13,
    },
    nonPromoPrice: {
        color: '#CCCCCC',
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid',
        marginLeft: 8,
    },
    modifierGroupsAndNote: {
        fontSize: 13,
        color: '#B1B1B1',
        paddingTop: 2,
    },
    backgroundIcon: {
        color: 'white',
    },
    button: {
        zIndex: 1,
    },
    buttonIcon: {
        color: theme.palette.secondary.main,
    },
    menuItemQuantity: {
        fontSize: 16,
        width: 24,
        textAlign: 'center',
    },
    separatedLineText: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    quantityItemContainer: {
        display: 'flex',
        width: 56,
        height: 28,
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#2E3748',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        border: '1px solid #D9D9D9',
        borderRadius: 4,
    },
    imageOrSpacesContainer: {},
}));

type Props = {
    cartItem: CartItemVm;
    leftPad: boolean;
};
