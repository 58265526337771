import * as React from 'react';

export function ChevronDownIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M11 1L6 6L1 1' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    color: string;
};
