import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { ModifierGroupVm, ModifierVm, SubModifierGroupVm, SubModifierVm } from 'src/api/pidedirecto/getAppContextApi';
import type { ModifierGroupVm as OrderModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';
import { QuantitySpinner } from 'src/components/QuantitySpinner';
import { Text } from 'src/components/Text';
import { ModifierTypes } from 'src/constants/ModifierType';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { deepClone } from 'src/utils/object/deepClone';
import { sum } from 'src/utils/reduce/sum';
import { requireValue } from 'src/utils/require/requireValue';

export function SubModifier({ modifierGroup, modifier, subModifierGroup, subModifier, selectedModifierGroups, onMissingSectionName, onChangeSelectedModifierGroups }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const selectedSubModifiers =
        selectedModifierGroups
            .find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)
            ?.modifiers?.find((selectedModifier) => selectedModifier.name === modifier.name)
            ?.subModifierGroups?.find((selectedSubModifierGroup) => selectedSubModifierGroup.name === subModifierGroup.name)?.subModifiers ?? [];
    const selectedSubModifiersQuantity =
        selectedSubModifiers
            .map((selectedSubModifier) => selectedSubModifier.quantity)
            .reduce(sum, BigNumber(0))
            .toNumber() ?? 0;
    const quantity = selectedSubModifiers.find((selectedSubModifier) => selectedSubModifier.name === subModifier.name)?.quantity ?? 0;

    const handleChangeQuantity = (newQuantity: number) => {
        if (newQuantity < 0) {
            return;
        }
        if (subModifierGroup.requiredMax !== undefined) {
            const max = subModifierGroup.requiredMax - (selectedSubModifiersQuantity - quantity);
            if (max < newQuantity) {
                onMissingSectionName();
                return;
            }
        }
        const updatedSelectedModifierGroups = deepClone(selectedModifierGroups); // Deep clone since we should not modify selectedModifierGroups state passes as prop
        const _subModifier = requireValue(
            updatedSelectedModifierGroups
                .find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)
                ?.modifiers?.find((selectedModifier) => selectedModifier.name === modifier.name)
                ?.subModifierGroups?.find((selectedSubModifierGroup) => selectedSubModifierGroup.name === subModifierGroup.name)
                ?.subModifiers?.find((selectedSubModifier) => selectedSubModifier.name === subModifier.name),
        );
        _subModifier.quantity = newQuantity;
        onChangeSelectedModifierGroups(updatedSelectedModifierGroups);
    };

    return (
        <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={1}>
            <Grid item style={{ flex: 1 }}>
                {subModifier.type === ModifierTypes.MULTIPLE && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: 100 }}>
                            <QuantitySpinner
                                quantity={quantity}
                                onSubtract={() => handleChangeQuantity(quantity - 1)}
                                onAdd={() => handleChangeQuantity(quantity + 1)}
                                variant='secondary'
                                size='small'
                            />
                        </div>
                        <div style={{ fontSize: '1rem', paddingLeft: 8 }}>{subModifier.name}</div>
                    </div>
                )}
                {subModifier.type !== ModifierTypes.MULTIPLE && (
                    <FormControlLabel
                        control={<Checkbox checked={quantity >= 1} disabled={subModifierGroup.requiredMax === selectedModifierGroups.length && quantity === 0} color='primary' />}
                        label={subModifier.name}
                        style={{ width: '100%' }}
                        onChange={(event: React.ChangeEvent<any>) => handleChangeQuantity(event.target.checked ? 1 : 0)}
                    />
                )}
            </Grid>
            <Grid item style={{ flex: 0, paddingTop: 12 }}>
                {BigNumber(subModifier.price ?? 0).isGreaterThan(0) && <Text style={{ color: '#222' }}>+{formatAsCurrencyNumber(subModifier.price)}</Text>}
            </Grid>
        </Grid>
    );
}

type Props = {
    modifierGroup: ModifierGroupVm;
    modifier: ModifierVm;
    subModifierGroup: SubModifierGroupVm;
    subModifier: SubModifierVm;
    selectedModifierGroups: Array<OrderModifierGroupVm>;
    onMissingSectionName: () => any;
    onChangeSelectedModifierGroups: (modifierGroups: Array<OrderModifierGroupVm>) => any;
};
