import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/core/styles';
import { useEffect } from 'react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { AddNewCardDialog } from 'src/components/cart/AddNewCardDialog';
import { EnterEmailDialog } from 'src/components/cart/EnterEmailDialog';
import { EnterNameDialog } from 'src/components/cart/EnterNameDialog';
import { SelectPaymentMethodDialog } from 'src/components/cart/SelectPaymentMethodDialog';
import { ValidateCardDialog } from 'src/components/cart/ValidateCardDialog';
import { CreateCustomerAccountDialog } from 'src/components/dialog/customer/CreateCustomerAccountDialog';
import { Page } from 'src/components/Page';
import { Pages } from 'src/constants/Page';
import { actions } from 'src/reducers';
import { PaymentLinkCheckout } from 'src/scenes/paymentLink/PaymentLinkCheckout';
import { useRestaurantTheme } from 'src/services/restaurant/useRestaurantTheme';
import { PaymentLinkId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentLinkPage(): React.ReactElement | null {
    const { paymentLinkId } = useParams<{
        paymentLinkId?: PaymentLinkId;
    }>();

    const restaurant = useSelector((state) => state.app.restaurant);

    const setPage = useAction(actions.setPage);

    const restaurantTheme = useRestaurantTheme(restaurant);

    useEffect(() => {
        setPage(Pages.PAYMENT_LINK_PAGE);
    }, []);

    if (!paymentLinkId) return null;

    return (
        <ThemeProvider theme={restaurantTheme}>
            <Page title={'PaymentLinkPage'}>
                <Grid container spacing={0}>
                    <EnterEmailDialog />
                    <CreateCustomerAccountDialog />
                    <EnterNameDialog />
                    <SelectPaymentMethodDialog isPaymentLink={!!paymentLinkId} />
                    <AddNewCardDialog />
                    <ValidateCardDialog />
                    <PaymentLinkCheckout paymentLinkId={paymentLinkId} />
                </Grid>
            </Page>
        </ThemeProvider>
    );
}
