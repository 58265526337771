import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { UiLogEvent } from 'src/services/logEvent/types/UiLogEvent';
import { isCognitoCurrentSession } from 'src/utils/cognito/isCognitoCurrentSession';

/**
 * calling the api method from the service layer is against conventions,
 * but we want to avoid asynchronous api calls for Ui logEvents
 */
export function createUiInteractionLogEvent(request: UiLogEvent): void {
    createUiInteractionLogEventAsynchronous({ ...request, occurredAt: new Date() });
}

async function createUiInteractionLogEventAsynchronous(request: UiLogEvent): Promise<void> {
    if (await isCognitoCurrentSession()) {
        pidedirectoApiMethod('logEvent/createUiInteractionLogEventApi', request).catch((e) => console.log(e));
    } else {
        pidedirectoApiMethodPublic('logEvent/createUiInteractionLogEventApi', request).catch((e) => console.log(e));
    }
}
