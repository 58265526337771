import * as React from 'react';
import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { Countries } from 'src/constants/Country';
import { MexicanInvoiceForm } from 'src/scenes/invoicePage/MexicanInvoiceForm';
import { UruguayanInvoiceForm } from 'src/scenes/invoicePage/UruguayanInvoiceForm';
import { useSelector } from 'src/utils/react/useSelector';

export function InvoiceInfoForm({ order }: Props): React.ReactElement | null {
    const isUruguayanRestaurant = useSelector((state) => state.app.restaurant?.country === Countries.UY);

    if (!order) return null;
    if (isUruguayanRestaurant) return <UruguayanInvoiceForm order={order} />;
    return <MexicanInvoiceForm order={order} />;
}

type Props = {
    order?: OrderVm;
};
