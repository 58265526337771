import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { cancelOrderApi } from 'src/api/pidedirecto/order/cancelOrderApi';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import type { TimeZone } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport } from 'src/utils/alert/alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { orderIsInternalDeliveryOrder } from 'src/utils/order/orderIsInternalDeliveryOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { formatDateTimeStringReadable } from 'src/utils/transform/formatDateTimeStringReadable';
import { lowerCaseFirstLetterInString } from 'src/utils/transform/lowerCaseFirstLetterInString';

export function OrderCancelButton(): React.ReactElement | null {
    const classes = useStyles();

    const [cancelling, setCancelling] = useState<boolean>(false);

    const order = useSelector((state) => state.app.order);
    const orderId = useSelector((state) => state.app.orderId);

    const setOrder = useAction(actions.setOrder);
    const setDriver = useAction(actions.setDriver);
    const setCustomer = useAction(actions.setCustomer);
    const setShowMap = useAction(actions.setShowMap);
    const setMandatoryCashAmount = useAction(actions.setMandatoryCashAmount);

    const cancelOrder = async () => {
        if (cancelling) {
            return;
        }
        if (!window.confirm(translate('Are you sure you want to cancel your order?'))) {
            return;
        }
        setCancelling(true);
        const response = await cancelOrderApi({
            customerId: order.customerId,
            orderId,
            // refundMethod: refundMethod,
        });
        if (!response.ok) {
            alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport();
        }
        setOrder(response.data);
        setMandatoryCashAmount(response.data.mandatoryCashAmount);
        setDriver(response.data);
        setCustomer(response.data);
        setShowMap(orderIsInternalDeliveryOrder(response.data) && (isNewOrder(response.data.orderStatus) || isAcceptedOrder(response.data.orderStatus) || isCompletedOrder(response.data.orderStatus)));
        setCancelling(false);
    };

    if (!order || order.driverId) return null;

    return (
        <div>
            <Button text onClick={cancelOrder}>
                <Text className={classes.text}>{cancelling ? translate('Cancelling') : translate('Cancel Order')}</Text>
            </Button>
            {order.closingTime && <Text className={classes.subtext}>{`${translate('Until')} ${lowerCaseFirstLetterInString(formatDateTimeStringReadable(order.closingTime, order.timeZone))}`}</Text>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        color: '#F35865',
        fontSize: 15,
        lineHeight: '20px',
        fontFamily: AppTheme.typography.medium,
    },
    subtext: {
        color: AppTheme.text.light,
        fontSize: 11,
        lineHeight: '14px',
    },
}));

type Props = {
    closingTime?: Date | string;
    restaurantTimeZone: TimeZone;
    cancelling?: boolean;
};
