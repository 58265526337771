import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function createUserSelectedOrderTypeLogEvent(params: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_SELECTED_ORDER_TYPE,
        message: `OrderType: ${params.orderType}`,
        data: params,
    });
}

type Params = {
    orderType: string;
};
