import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SurveyAnswer } from 'src/constants/SurveyAnswer';
import type { SurveyId } from 'src/types/Id';

export async function answerSurveyApi(request: AnswerSurveyApiRequest): ApiSauceResponse<void> {
    return pidedirectoApiMethodPublic('survey/answerSurveyApi', request);
}

export type AnswerSurveyApiRequest = {
    surveyId: SurveyId;
    rating: number;
    recommendationRating: number;
    surveyAnswers?: Array<SurveyAnswer>;
    otherAnswer?: string;
};
