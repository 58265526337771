import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import amex from 'payment-icons/min/flat/amex.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import visa from 'payment-icons/min/flat/visa.svg';
import unknown from 'payment-icons/min/outline/default.svg';
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { Input } from 'src/components/Input';
import { CardBrands } from 'src/constants/CardBrand';
import { translate } from 'src/i18n/translate';
import { theme } from 'src/styles/theme';
import { isBlankString } from 'src/utils/string/isBlankString';

export function FormCardNumberField({
    name,
    label,
    placeholder,
    helperText,
    value,
    onChange,
    variant = 'outlined',
    required,
    disabled,
    InputProps,
    rules,
    defaultValue = null,
}: Props): React.ReactElement {
    const classes = useStyles();

    const {
        errors,
        control,
        formState: { isSubmitting },
        watch,
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();
    const brand = watch(`${name}.brand`);
    const expiry = watch(`${name}.expiry`);
    const cvc = watch(`${name}.cvc`);

    const minLength = 13;
    const maxLength = 19;

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const { ref, ...restProps } = props;
                return (
                    <Input
                        {...restProps}
                        value={transformStringInput(value)}
                        onChange={(value: string) => onChange(transformStringOutput(value))}
                        label={label}
                        placeholder={'1234 1234 1234 1234'}
                        error={!!getError(errors, name)?.['cardNumber']}
                        helperText={getError(errors, name)?.['cardNumber']?.message}
                        inputRef={inputRef}
                        InputComponent={NumberFormatCustom}
                        rightIcon={
                            <>
                                {brand === CardBrands.VISA && <img src={visa} alt='' height={theme.spacing(2.5)} />}
                                {brand === CardBrands.MASTERCARD && <img src={mastercard} alt='' height={theme.spacing(2.5)} />}
                                {brand === CardBrands.AMERICAN_EXPRESS && <img src={amex} alt='' height={theme.spacing(2.5)} />}
                                {!isBlankString(brand) && brand !== CardBrands.VISA && brand !== CardBrands.MASTERCARD && brand !== CardBrands.AMERICAN_EXPRESS && (
                                    <img src={unknown} alt='' height={theme.spacing(2.5)} style={{ opacity: 0.7 }} />
                                )}
                                {isBlankString(brand) && (
                                    <>
                                        <img src={visa} alt='' height={theme.spacing(2.5)} />
                                        <Box p={0.25} />
                                        <img src={mastercard} alt='' height={theme.spacing(2.5)} />
                                    </>
                                )}
                            </>
                        }
                        autoComplete='cc-number'
                        required={required}
                        disabled={isSubmitting || disabled}
                    />
                );
            }}
            control={control}
            name={`${name}.cardNumber`}
            defaultValue={defaultValue}
            rules={
                disabled
                    ? undefined
                    : {
                          required: {
                              value: rules?.required || !isBlankString(expiry) || !isBlankString(cvc),
                              message: translate('Card number is required'),
                          },
                          minLength: {
                              value: minLength,
                              message: translate('Invalid card number'),
                          },
                          maxLength: {
                              value: maxLength,
                              message: translate('Invalid card number'),
                          },
                      }
            }
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat {...other} getInputRef={inputRef} onValueChange={(values) => onChange(values.value)} thousandSeparator isNumericString format='#### #### #### #### ####' decimalScale={0} />;
}

const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderBottomColor: 'white',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
            '&:hover fieldset': {
                // borderBottomColor: 'white',
            },
            '&.Mui-focused fieldset': {
                // borderBottomColor: 'white',
            },
        },
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    value?: string;
    onChange?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    required?: boolean;
    disabled?: boolean;
    rules?: {
        required?: boolean;
    };
    InputProps?: any;
    defaultValue?: string | null;
};
