import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { getError } from 'src/components/form/utils/getError';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { ShieldIconOutlined } from 'src/icons/ShieldIconOutlined';

export function FormVerificationCodeField({ name, label, placeholder, helperText, defaultValue = null, disabled, required, autoFocus, autoComplete }: Props): React.ReactElement {
    const classes = useStyles();

    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const { ref, ...restProps } = props;
                return (
                    <Input
                        {...restProps}
                        inputRef={ref}
                        value={value}
                        onChange={onChange}
                        label={label}
                        placeholder={placeholder}
                        helperText={getError(errors, name)?.message ?? helperText}
                        error={!!getError(errors, name)}
                        disabled={isSubmitting || disabled}
                        autoFocus={autoFocus}
                        rightIcon={<ShieldIconOutlined color={'#4F586E'} className={classes.shieldIcon} />}
                        inputMode={'code' as any}
                        InputComponent={NumberFormatCustom}
                    />
                );
            }}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => onChange(values.value)}
            thousandSeparator={false}
            isNumericString
            format='## ## ##'
            // allowEmptyFormatting
            // mask="_"
            decimalScale={0}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null;
    disabled?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
};

const useStyles = makeStyles((theme) => ({
    shieldIcon: {
        width: 24,
        height: 24,
    },
}));
