import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Text } from 'src/components/Text';
import { LogEventTypes } from 'src/constants/LogEventType';
import { translate } from 'src/i18n/translate';
import { ClockIcon } from 'src/icons/ClockIcon';
import { actions } from 'src/reducers';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { distanceInKm } from 'src/utils/geojson/distanceInKm';
import { isWithinHours } from 'src/utils/hours/isWithinHours';
import { useAction } from 'src/utils/react/useAction';
import { useCurrentLocation } from 'src/utils/react/useCurrentLocation';
import { useSelector } from 'src/utils/react/useSelector';
import { isRestaurantWithinDeliveryDistance } from 'src/utils/restaurant/isRestaurantWithinDeliveryDistance';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

export function SelectRestaurantDialog(): React.ReactElement | null {
    const classes = useStyles();
    const { location } = useCurrentLocation();

    const open = useSelector((state) => state.app.selectRestaurantDialog.open);
    const restaurants = useSelector((state) => state.app.restaurants);
    const restaurantIsSelected = useSelector((state) => !!state.app.urlPathname);
    const addressLocationMap = useSelector((state) => state.app.addressLocationMap);

    const closeSelectRestaurantDialog = useAction(actions.closeSelectRestaurantDialog);
    const { getAppContext } = useGetAppContext();

    const locationToMeasure = addressLocationMap?.location || location;
    const restaurantsSortedByDistance = [...restaurants]
        ?.sort((a: RestaurantVm, b: RestaurantVm) => (distanceInKm(a.location, locationToMeasure) ?? 0) - (distanceInKm(b.location, locationToMeasure) ?? 0))
        .filter((restaurant: RestaurantVm) => restaurant.urlPathname && restaurant.webOrdersEnabled);

    const selectRestaurant = (restaurant: RestaurantVm) => {
        if (!restaurant.urlPathname) {
            alert('Something went wrong. Please contact support!');
            return;
        }
        createUiInteractionLogEvent({
            logEventType: LogEventTypes.USER_SELECTED_RESTAURANT_FROM_SELECT_RESTAURANT_DIALOG,
        });

        getAppContext({ urlSubdomain: getUrlSubdomain(), urlPathname: restaurant.urlPathname ?? '' });
        closeSelectRestaurantDialog();
    };

    if (!restaurants) return null;

    return (
        <Dialog open={open} title={translate('Select Store')} onClose={restaurantIsSelected ? closeSelectRestaurantDialog : undefined}>
            <ul className={classes.restaurantContainer}>
                {restaurantsSortedByDistance.map((restaurant) => {
                    const closed = !isWithinHours(restaurant.hours, restaurant.timeZone);
                    const tooFarAway = !isRestaurantWithinDeliveryDistance(restaurant, locationToMeasure);

                    return (
                        <li key={restaurant.restaurantId}>
                            <Button classes={{ button: classes.button }} text onClick={() => selectRestaurant(restaurant)}>
                                <div className={classes.restaurant}>
                                    <Text style={{ color: '#0D3037' }} variant={'semiBold'} className={classes.restaurantInfo}>
                                        {restaurant.name}
                                    </Text>
                                    <Text className={classes.restaurantInfo}>{restaurant.street}</Text>
                                </div>
                                <div className={classes.infoContent}>
                                    {closed && (
                                        <div className={classes.clockContainer}>
                                            <ClockIcon color={'#B40707'} />
                                            <h4 style={{ color: '#B40707' }} className={classes.timeText}>
                                                {translate('Closed')}
                                            </h4>
                                        </div>
                                    )}
                                    {!closed && (
                                        <div className={classes.clockContainer}>
                                            <ClockIcon color={'#0CAB4C'} />
                                            <h4 style={{ color: '#0CAB4C' }} className={classes.timeText}>
                                                {translate('Open')}
                                            </h4>
                                        </div>
                                    )}
                                    {locationToMeasure && distanceInKm(restaurant.location, locationToMeasure) && (
                                        <span className={classes.distanceText} style={{ color: tooFarAway ? '#f84b4b' : '#848484' }}>
                                            {distanceInKm(restaurant.location, locationToMeasure)?.toFixed(1)} km
                                        </span>
                                    )}
                                </div>
                            </Button>
                        </li>
                    );
                })}
            </ul>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    restaurantContainer: {
        width: '100%',
        maxHeight: '80vh',
        flexDirection: 'column',
        display: 'flex',
        overflowY: 'auto',
        listStyle: 'none',
        paddingLeft: 0,
    },
    button: {
        width: '100%',
        paddingTop: 14,
        paddingBottom: 14,
        '&:focus': {
            outline: 0,
        },
    },
    restaurant: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    restaurantInfo: {
        textAlign: 'left',
    },
    distanceText: {
        color: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontSize: 14,
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {},
    },
    timeText: {
        fontSize: 12,
        fontWeight: 400,
        margin: 0,
        marginLeft: 4,
        color: '#4F525E',
    },
    infoContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            gap: 12,
            width: '50%',
        },
    },
    clockContainer: {
        display: 'flex',
    },
}));
