import { CardBrand, CardBrands } from 'src/constants/CardBrand';
import { upperCase } from 'src/utils/string/upperCase';

export function isKnownCard(cardBrand?: CardBrand): boolean {
    if (!cardBrand) {
        return false;
    }

    return ([CardBrands.VISA, CardBrands.MASTERCARD, CardBrands.AMERICAN_EXPRESS, CardBrands.SODEXO] as Array<CardBrand>).includes(upperCase(cardBrand) as CardBrand);
}
