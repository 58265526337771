import { useState } from 'react';
import * as React from 'react';
import { startCardValidationApi } from 'src/api/pidedirecto/card/startCardValidationApi';
import { payPaymentLinkApi } from 'src/api/pidedirecto/paymentLink/payPaymentLinkApi';
import { Button, ButtonProps } from 'src/components/Button';
import { useConfirmDialog } from 'src/components/cart/ConfirmDialog';
import { PaymentStatuses } from 'src/constants/PaymentStatus';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { CardVm } from 'src/types/CardVm';
import type { PaymentLinkVm } from 'src/types/PaymentLinkVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isMexico } from 'src/utils/country/isMexico';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PayPaymentLinkButton({ children, paymentLink, onFailed, onSuccess, onPaymentStarted, ...props }: Props): React.ReactElement {
    const confirm = useConfirmDialog();

    const [loading, setLoading] = useState(false);

    const card = useSelector((state) => state.app.card);
    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const customerId = useSelector((state) => state.app.customerId);

    const openSelectPaymentMethodDialog = useAction(actions.openSelectPaymentMethodDialog);
    const openValidateCardDialog = useAction(actions.openValidateCardDialog);
    const openAddNewCardDialog = useAction(actions.openAddNewCardDialog);
    const updateCard = useAction(actions.updateCard);

    const tryToPay = async () => {
        if (!paymentMethod) return openSelectPaymentMethodDialog();
        if (isCardPayment(paymentMethod) && !card) return openAddNewCardDialog();

        if (isMexico(restaurantCountry) && !card.validated) {
            let cardToValidate: CardVm | undefined = card;
            if (!card.needsValidation) cardToValidate = await startCardValidation();
            openValidateCardDialog({
                card: cardToValidate,
                onValidatedCard: (card: CardVm) => {
                    pay(card);
                },
            });
            return;
        }
        await pay(card);
    };

    const pay = async (card: CardVm) => {
        onPaymentStarted?.();
        setLoading(true);
        const request = { paymentLinkId: paymentLink.paymentLinkId, customerId, cardId: card.cardId } as const;
        const response = await payPaymentLinkApi(request);
        setLoading(false);
        if (!response.ok) {
            onFailed?.();
            return alertKnownErrorOrSomethingWentWrong(translate('Something went wrong'));
        }
        if (response.data.paymentStatus !== PaymentStatuses.PAYED) {
            console.info('Payment link got rejected', { request });
            onFailed?.(response.data);
            // alert(translate('Payment link rejected') + '\n' + translatePaymentRejectReason(response.data.paymentRejectReason));
            return;
        }
        onSuccess?.();
    };

    const startCardValidation = async (): Promise<CardVm | undefined> => {
        const startCardValidationConfirmed = await confirm({
            title: translate('Are you sure you want to validate this card?'),
            content: translate(
                'For your own safety we need to verify this card belongs to you.\nWe will charge a random amount less than $20 on your card that you will need to send to us to verify that you are the owner of this card. The amount will be refunded and only used for verification.',
            ),
        });
        if (startCardValidationConfirmed !== true) return;
        setLoading(true);
        const response = await startCardValidationApi({ cardId: card.cardId });
        setLoading(false);
        if (!response.ok) {
            alert(
                translate(
                    'Failed to create payment needed for the card validation. Make sure you have at least $20 on your cards and try again. Also make sure you have internet connection. If the problem persists try another card.',
                ),
            );
            return;
        }
        var updatedCard = response.data;
        updateCard(updatedCard);
        return updatedCard;
    };

    return (
        <Button loading={loading} onClick={tryToPay} {...props}>
            {children}
        </Button>
    );
}

type Props = ButtonProps & {
    children: React.ReactNode;
    paymentLink: PaymentLinkVm;
    onPaymentStarted?: any;
    onSuccess?: any;
    onFailed?: any;
    onClick?: any;
};
