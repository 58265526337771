import * as React from 'react';

export function PhoneIcon(): React.ReactElement {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 2C0 1.46957 0.210714 0.960859 0.585787 0.585787C0.960859 0.210714 1.46957 0 2 0H2.91467C3.488 0 3.988 0.390667 4.12733 0.946667L4.864 3.89533C4.9237 4.134 4.91164 4.38497 4.82934 4.61682C4.74704 4.84866 4.59815 5.05106 4.40133 5.19867L3.53933 5.84533C3.44933 5.91267 3.43 6.01133 3.45533 6.08C3.83159 7.10323 4.42573 8.03247 5.19663 8.80337C5.96753 9.57427 6.89677 10.1684 7.92 10.5447C7.98867 10.57 8.08667 10.5507 8.15467 10.4607L8.80133 9.59867C8.94894 9.40185 9.15134 9.25296 9.38318 9.17066C9.61503 9.08836 9.866 9.0763 10.1047 9.136L13.0533 9.87267C13.6093 10.012 14 10.512 14 11.086V12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H10.5C4.70133 14 0 9.29867 0 3.5V2Z'
                fill='#4F586E'
            />
        </svg>
    );
}
