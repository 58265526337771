import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Text } from '@pidedirecto/ui';
import * as React from 'react';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { BottomDrawerTopBar } from 'src/components/BottomDrawerTopBar';
import { Footer } from 'src/components/Footer';
import { Order } from 'src/components/Order';
import { translate } from 'src/i18n/translate';
import { AppTheme } from 'src/styles/AppTheme';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderBottomDrawer(): React.ReactElement | null {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);
    const orderId = useSelector((state) => state.app.orderId);
    const manualOrder = useSelector((state) => state.app.manualOrder);

    if (!restaurant) return null;

    return (
        <BottomDrawer className={classes.bottomDrawer}>
            <div className={classes.stickyContainer}>
                <BottomDrawerTopBar />
            </div>
            {manualOrder && restaurant.webOrdersEnabled && !restaurant.hideFollowUpBannerEnabled && (
                <a className={classes.pidedirectoAdContainer} href='/'>
                    <span className={classes.pidedirectoAdText}>{translate('Make your next order easier @url', { url: window.location.origin })}</span>
                    <ArrowForwardIcon className={classes.pidedirectoAdIcon} />
                </a>
            )}
            {orderId && <Order />}
            <Footer>
                <Text className={classes.footerText}>{translate('With technology from')}</Text>
                <a className={classes.footerText} href={'https://www.ambit.la/'}>
                    Ambit.la
                </a>
            </Footer>
        </BottomDrawer>
    );
}
const useStyles = makeStyles((theme) => ({
    stickyContainer: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 99,
        [theme.breakpoints.up('sm')]: {
            marginTop: 32,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    pidedirectoAdContainer: {
        width: '100%',
        minHeight: 50,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 40px',
        textDecoration: ' none',
        borderRadius: 8,
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            padding: '15px 40px',
        },
    },
    pidedirectoAdText: {
        color: 'white',
        fontSize: 12,
        fontFamily: AppTheme.typography.medium,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            fontSize: 15,
        },
    },
    pidedirectoAdIcon: {
        animation: '$movingIcon 5s ease',
        color: 'white',
        animationIterationCount: 'infinite',
        marginLeft: 12,
    },
    bottomDrawer: {},
    footerText: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 14,
    },
}));
