import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { transformToOutput } from 'src/components/form/materialui/TransformedTextField';
import { getError } from 'src/components/form/utils/getError';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';

export function FormTextField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    trim = true,
    upperCase,
    capitalize,
    autoComplete = 'off',
    multiline,
    rowsMax,
    disabled,
    required,
    minLength,
    maxLength,
    pattern,
    validate,
    variant = 'outlined',
    autoFocus,
    InputProps,
    inputProps,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <Input
                    {...props}
                    label={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    helperText={getError(errors, name)?.message ?? helperText}
                    disabled={isSubmitting || disabled}
                    required={(required as any)?.value ?? required}
                    error={!!getError(errors, name)}
                    autoFocus={autoFocus}
                    inputRef={inputRef}
                    autoComplete={autoComplete}
                />
            )}
            control={control}
            name={name}
            defaultValue={transformToOutput(defaultValue, trim, upperCase)}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                minLength: {
                    value: (minLength as any)?.value ?? minLength,
                    message: (minLength as any)?.message ?? translate('Must be at least @number characters', { number: (minLength as any)?.value ?? minLength }),
                },
                maxLength: {
                    value: (maxLength as any)?.value ?? maxLength,
                    message: (maxLength as any)?.message ?? translate('Must be at most @number characters', { number: (maxLength as any)?.value ?? maxLength }),
                },
                pattern: {
                    value: (pattern as any)?.value ?? pattern,
                    message: (pattern as any)?.message ?? translate('This field is in incorrect format'),
                },
                validate,
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null;
    trim?: boolean;
    upperCase?: boolean;
    capitalize?: boolean;
    autoComplete?: AutoCompleteInputProp;
    multiline?: boolean;
    rowsMax?: number;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    pattern?:
        | RegExp
        | {
              value: RegExp;
              message: string;
          };
    validate?: any;
    variant?: Variant;
    autoFocus?: boolean;
    InputProps?: any;
    inputProps?: any;
};
