import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

export function useAction(action: any, deps?: Array<any>): any {
    const dispatch = useDispatch();
    return useMemo(
        () => {
            return bindActionCreators(action, dispatch);
        },
        deps ? [dispatch, ...deps] : [dispatch],
    );
}
