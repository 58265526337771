export function clipString({ string, max }: Props): string {
    if (string?.length <= max) return string;

    return string.slice(0, max).concat('...');
}

type Props = {
    string: string;
    max: number;
};
