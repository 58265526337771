import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { setEmailApi } from 'src/api/pidedirecto/customer/setEmailApi';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

export function EnterEmailDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const [error, setError] = useState<{ message: string }>();

    const open = useSelector((state) => state.app.enterEmailDialog.open);
    const onSuccess = useSelector((state) => state.app.enterEmailDialog.onSuccess);
    const customerId = useSelector((state) => state.app.customerId);
    const country = useSelector((state) => state.app.restaurant?.country);

    const closeEnterEmailDialog = useAction(actions.closeEnterEmailDialog);
    const openEnterNameDialog = useAction(actions.openEnterNameDialog);
    const setEmail = useAction(actions.setEmail);

    useEffect(() => {
        if (open) {
            setError(undefined);
        }
    }, [open]);

    const onSubmit = async (form: any) => {
        setError(undefined);
        const response = await setEmailApi({ customerId, email: form.email, urlSubdomain: getUrlSubdomain() });
        if (!response.ok) {
            setError({ message: translate('Failed to save, please try again.') });
            return;
        }
        setEmail(form.email);
        closeEnterEmailDialog();
        onSuccess?.();
    };

    return (
        <Dialog open={open} title={translate('Enter your email')} actionButtonFormId={'form'} actionText={translate('Next')}>
            <Form form={form} onSubmit={onSubmit} className={classes.form} id={'form'}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} autoFocus required autoComplete='email' />
                        </Grid>
                        <Grid item xs={12}>
                            {!error && (
                                <Text variant='medium' style={{ textAlign: 'center' }}>
                                    {translate('We need your email to be able to verify your identity and give you support')}
                                </Text>
                            )}
                            {!!error && (
                                <Text variant='medium' error style={{ textAlign: 'center' }}>
                                    {translate(error.message)}
                                </Text>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContent: {
        padding: theme.spacing(2),
    },
}));
