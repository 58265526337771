import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { DeleteIcon } from 'src/icons/DeleteIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SearchBar(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [query, setQuery] = useState('');

    const restaurantMenuPageIsScrollable = useSelector((state) => state.app.restaurantMenuPageIsScrollable);

    const searchMenuItems = useAction(actions.searchMenuItems);
    const clearSearchMenuItems = useAction(actions.clearSearchMenuItems);
    const specialCharactersRegExp = /[^a-zA-Z0-9\s]+/;

    useEffect(() => {
        if (query === '') clearSearchMenuItems();
        if (specialCharactersRegExp.test(query)) return;
        if (query) searchMenuItems(query);
    }, [query, searchMenuItems]);

    const searchProduct = (value: any) => {
        setQuery(value);
    };

    const clearSearchBar = () => {
        setQuery('');
    };

    const getLeftPosition = () => {
        if (!restaurantMenuPageIsScrollable) return '50%';
        if (largeScreen) return SEARCHBAR_WIDTH / 2 + LARGE_SCREEN_PADDING;
        if (mediumScreen) return SEARCHBAR_WIDTH / 2 + MEDIUM_SCREEN_PADDING;
        if (smallScreen) return '50%';
        return '50%';
    };

    return (
        <div className={classes.container} style={{ left: getLeftPosition() }}>
            <Input
                classes={{ inputContainer: classes.inputContent, input: classes.input }}
                placeholder={translate('Search...')}
                onChange={searchProduct}
                name={'query'}
                value={query}
                type={'text'}
                leftIcon={<SearchIcon color={theme.palette.primary.main} />}
            />
            {query !== '' && (
                <div className={classes.deleteIcon} onClick={clearSearchBar}>
                    <DeleteIcon color={'#97979c'} />
                </div>
            )}
        </div>
    );
}

const SEARCHBAR_WIDTH = 500;
const LARGE_SCREEN_PADDING = 180;
const MEDIUM_SCREEN_PADDING = 90;

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -130%)',
        width: SEARCHBAR_WIDTH,
        height: 75,
        padding: 32,
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        border: '1px solid #DDDEE2',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: 16,
        transition: '350ms',
        zIndex: 97,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    deleteIcon: {
        margin: 0,
        display: 'grid',
        placeItems: 'center',
    },
    inputContent: {
        marginLeft: 12,
        width: '100%',
        minWidth: 400,
        maxWidth: 600,
        height: 60,
        border: 'none',
    },
    input: {},
}));
