import moment from 'moment';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import type { TimeZone } from 'src/constants/TimeZone';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { GiftVm } from 'src/types/GiftVm';
import { isDateWithinHours } from 'src/utils/hours/isDateWithinHours';

export function validGiftsForOrderTypeAndCartItems(
    orderType: OrderType | null | undefined,
    pickupTime: Date | string | undefined,
    cartItems: Array<CartItemVm>,
    restaurantTimeZone: TimeZone,
): (arg1: GiftVm) => boolean {
    return (gift: GiftVm) => {
        if (gift.restrictedToDelivery && orderType !== OrderTypes.PICKUP_STATION_ORDER) {
            return false;
        }
        const now = new Date();
        if (gift.validFrom && moment(gift.validFrom).isAfter(now)) {
            return false;
        }
        if (gift.validTo && moment(gift.validTo).isBefore(now)) {
            return false;
        }
        if (orderType === OrderTypes.PICKUP_STATION_ORDER) {
            return cartItems.some((cartItem) => {
                if (!cartItem.pickupTime) {
                    return false;
                }
                return isDateWithinHours(gift.hours, cartItem.pickupTime, restaurantTimeZone);
            });
        } else {
            if (!pickupTime) {
                return false;
            }
            return isDateWithinHours(gift.hours, pickupTime, restaurantTimeZone);
        }
    };
}
