import type { MenuItemVm } from 'src/api/pidedirecto/getAppContextApi';
import type { ModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function createUserAddedItemToCartLogEvent(params: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_ADDED_ITEM_TO_CART,
        message: params.cartItem.name && `${params.cartItem.name} added to cart`,
        data: { cartItem: params },
    });
}

type Params = {
    cartItem: MenuItemVm;
    quantity: any;
    modifierGroups: Array<ModifierGroupVm>;
};
