import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { useTheme } from '@pidedirecto/ui/hooks';
import { useEffect, useState } from 'react';
import { RestaurantChainVm, RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { getRestaurantColorsInLocalStorage } from 'src/localStorage/getRestaurantColorsInLocalStorage';

export function useRestaurantTheme(restaurant?: RestaurantVm | RestaurantChainVm): MuiTheme {
    const [theme, setTheme] = useState({});

    useTheme(theme);

    useEffect(() => {
        buildRestaurantTheme();
    }, [restaurant]);

    const deprecatedCreateMuiTheme: CreateMuiThemeFunction = () => {
        let colors: Array<string> | undefined;
        if (getRestaurantColorsInLocalStorage()) {
            try {
                colors = getRestaurantColorsInLocalStorage();
            } catch (e: any) {
                // use default colors, this is here just for safety since we did save strange values in localstorage for a while
            }
        } else if ((restaurant as RestaurantVm)?.restaurantColors) {
            colors = (restaurant as RestaurantVm).restaurantColors;
        } else if ((restaurant as RestaurantChainVm)?.chainColors) {
            colors = (restaurant as RestaurantChainVm).chainColors;
        }
        return createMuiTheme({
            palette: {
                primary: {
                    main: colors?.[0] ?? '#1a9aa0',
                },
                secondary: {
                    main: colors?.[1] ?? '#68CCCA',
                },
            },
        } as ThemeOptions);
    };

    const buildRestaurantTheme = () => {
        let colors: Array<string> | undefined;
        if (getRestaurantColorsInLocalStorage()) {
            try {
                colors = getRestaurantColorsInLocalStorage();
            } catch (e: any) {
                // use default colors, this is here just for safety since we did save strange values in localstorage for a while
            }
        } else if ((restaurant as RestaurantVm)?.restaurantColors) {
            colors = (restaurant as RestaurantVm).restaurantColors;
        } else if ((restaurant as RestaurantChainVm)?.chainColors) {
            colors = (restaurant as RestaurantChainVm).chainColors;
        }
        setTheme({
            palette: {
                surface: {
                    brand: colors?.[0] ? `${colors?.[0]}33` : '#1a9aa033',
                    brandContrast: colors?.[0] ? `${colors?.[0]}aa` : '#1a9aa0aa',
                    brandHeavyContrast: colors?.[0] ?? '#1a9aa0',
                },
                text: {
                    brand: colors?.[0] ?? '#1a9aa0',
                },
                border: {
                    brand: colors?.[0] ?? '#1a9aa0',
                    brandContrast: colors?.[0] ?? '#1a9aa0',
                },
            },
        });
    };

    return deprecatedCreateMuiTheme();
}

export type MuiTheme = {
    palette: {
        primary: {
            main: string;
            dark: string;
        };
        text: {
            disabled: string;
        };
    };
    spacing: (arg1: number) => number;
    breakpoints: {
        down: (arg1: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => string;
    };
};

type CreateMuiThemeFunction = (restaurant?: RestaurantVm | RestaurantChainVm) => MuiTheme;
