import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AmbitIcon, CircleCheckIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DeliveryStatus, DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { CancelIcon } from 'src/icons/CancelIcon';
import { AppTheme } from 'src/styles/AppTheme';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderStepper(): React.ReactElement {
    const classes = useStyles();

    const [previousStep, setPreviousStep] = useState<OrderStatus>();

    const orderStatus = useSelector((state) => state.app.orderStatus);
    const deliveryStatus = useSelector((state) => state.app.deliveryStatus);
    const orderType = useSelector((state) => state.app.orderType);
    const driver = useSelector((state) => state.app.driver);
    const order = useSelector((state) => state.app.order);
    const showDriverArriveAtStoreStatusOnEcommerceEnabled = useSelector((state) => state.app.restaurant?.showDriverArriveAtStoreStatusOnEcommerceEnabled);

    const isDeliveryOrder = orderType === OrderTypes.DELIVERY_ORDER;
    const isPickupOrder = isAcceptedOrder(order.orderStatus) && deliveryStatus === DeliveryStatuses.PICKED_UP;
    const orderCancelled = orderStatus === OrderStatuses.REJECTED || orderStatus === OrderStatuses.CANCELLED;
    const isAcceptedButNotPickedUp =
        isAcceptedOrder(order.orderStatus) && (!deliveryStatus || ([DeliveryStatuses.ACCEPTED, DeliveryStatuses.REQUESTED] as Array<DeliveryStatus>).includes(deliveryStatus));
    const isDriverArrivedAtStore = isAcceptedOrder(order.orderStatus) && !!order.arrivedAtStoreAt && deliveryStatus === DeliveryStatuses.ACCEPTED;

    useEffect(() => {
        if (isAcceptedOrder(order.orderStatus)) setPreviousStep(OrderStatuses.NEW);
    }, [orderStatus]);

    const getOrderSteps = () => {
        if (orderStatus === OrderStatuses.REJECTED) {
            if (!previousStep) return REJECTED_BEFORE_ACCEPTED_ORDER_STEPS;
            return REJECTED_ORDER_STEPS;
        }
        if (orderStatus === OrderStatuses.CANCELLED) {
            if (!previousStep) return CANCELLED_BEFORE_ACCEPTED_ORDER_STEPS;
            return CANCELLED_ORDER_STEPS;
        }
        if (isDeliveryOrder) return DELIVERY_ORDER_STEPS;
        return TAKE_AWAY_ORDER_STEPS;
    };

    const getOrderCancelledSteps = () => {
        if (previousStep === null) {
            return ORDER_STATUS_STEP.CANCELLED_BEFORE_ACCEPTED;
        }
        return ORDER_STATUS_STEP.CANCELLED;
    };

    const getOrderRejectedSteps = () => {
        if (previousStep === null) {
            return ORDER_STATUS_STEP.REJECTED_BEFORE_ACCEPTED;
        }
        return ORDER_STATUS_STEP.REJECTED;
    };

    const getActiveStep = () => {
        if (orderStatus === OrderStatuses.NEW) return ORDER_STATUS_STEP.NEW;
        if (orderStatus === OrderStatuses.CANCELLED) return getOrderCancelledSteps();
        if (orderStatus === OrderStatuses.REJECTED) return getOrderRejectedSteps();
        if (isPickupOrder) return ORDER_STATUS_STEP.PICKED_UP;
        if (orderStatus === OrderStatuses.COMPLETE || orderStatus === OrderStatuses.COMPLETED) return ORDER_STATUS_STEP.COMPLETED;
        if (isDriverArrivedAtStore) return ORDER_STATUS_STEP.ARRIVE_AT_STORE;
        if (driver) return ORDER_STATUS_STEP.READY_TO_PICK_UP;
        if (isAcceptedButNotPickedUp) return ORDER_STATUS_STEP.ACCEPTED;
    };

    function StepIcon(props: any) {
        const { active, completed } = props;

        if (completed)
            return (
                <div className={classes.circleCheckedStepContainer}>
                    <CircleCheckIcon color={'#DAB7FF'} size={35} />
                </div>
            );
        if (orderCancelled) return <CancelIcon color={'#EE184B'} />;
        if (active) return <AmbitIcon className={classes.ambitStepperIcon} />;

        return <div className={classes.circle} />;
    }

    return (
        <Stepper
            activeStep={getActiveStep()}
            orientation='vertical'
            style={{ padding: '16px 0px 24px', paddingLeft: '10px' }}
            connector={
                <StepConnector
                    classes={{
                        active: classes.stepConnectorActive,
                        completed: classes.stepConnectorActive,
                        lineVertical: classes.line,
                    }}
                />
            }
        >
            {getOrderSteps().map((step, index) => {
                if (step.label === orderDriverArrivedAtStoreStepInfo.label && !showDriverArriveAtStoreStatusOnEcommerceEnabled && isDeliveryOrder) return <></>;

                return (
                    <Step key={step.label}>
                        <StepLabel StepIconComponent={StepIcon}>
                            <p className={getActiveStep() === index ? classes.label : classes.inactiveLabel}>{step.label}</p>
                            {getActiveStep() === index && <p className={classes.description}>{step.description}</p>}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}

const orderCreatedStepInfo = {
    label: translate('Sent to the restaurant'),
    description: translate('Your order was created and will be accepted shortly'),
    orderStatusStep: 0,
} as const;
const orderAcceptedStepInfo = {
    label: translate('Accepted'),
    description: translate('Your order was accepted and is being prepared'),
    orderStatusStep: 1,
} as const;
const orderReadyStepInfo = {
    label: translate('Delivery man on the way'),
    description: translate('A delivery person has been assigned to your order'),
    orderStatusStep: 2,
} as const;
const orderDriverArrivedAtStoreStepInfo = {
    label: translate('Delivery person arrived at store'),
    description: translate('Your delivery person has arrived at the store and is waiting for your order'),
    orderStatusStep: 3,
};
const orderSentStepInfo = {
    label: translate('Sent'),
    description: translate('Prepare the table, your order is on its way'),
    orderStatusStep: 4,
} as const;
const orderDeliveredStepInfo = {
    label: translate('Delivered'),
    description: translate(''),
    orderStatusStep: 5,
} as const;
const orderRejectedStepInfo = {
    label: translate('Rejected'),
    description: translate('The restaurant refused your order'),
} as const;
const orderCancelledStepInfo = {
    label: translate('Cancelled'),
    description: translate('Your order was cancelled'),
} as const;

const DELIVERY_ORDER_STEPS = [orderCreatedStepInfo, orderAcceptedStepInfo, orderReadyStepInfo, orderDriverArrivedAtStoreStepInfo, orderSentStepInfo, orderDeliveredStepInfo];
const TAKE_AWAY_ORDER_STEPS = [orderCreatedStepInfo, orderAcceptedStepInfo, orderDeliveredStepInfo];
const REJECTED_BEFORE_ACCEPTED_ORDER_STEPS = [orderCreatedStepInfo, orderRejectedStepInfo];
const REJECTED_ORDER_STEPS = [orderCreatedStepInfo, orderAcceptedStepInfo, orderRejectedStepInfo];
const CANCELLED_BEFORE_ACCEPTED_ORDER_STEPS = [orderCreatedStepInfo, orderCancelledStepInfo];
const CANCELLED_ORDER_STEPS = [orderCreatedStepInfo, orderAcceptedStepInfo, orderCancelledStepInfo];

const ORDER_STATUS_STEP = {
    NEW: 0,
    REJECTED_BEFORE_ACCEPTED: 1,
    CANCELLED_BEFORE_ACCEPTED: 1,
    ACCEPTED: 1,
    CANCELLED: 1,
    REJECTED: 2,
    READY_TO_PICK_UP: 2,
    ARRIVE_AT_STORE: 3,
    PICKED_UP: 4,
    COMPLETED: 5,
} as const;

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#393B41',
        fontSize: 16,
        fontWeight: 600,
        margin: 0,
    },
    inactiveLabel: {
        color: '#C5C7CC',
        fontSize: 16,
        fontWeight: 600,
        margin: 0,
    },
    description: {
        color: '#818696',
        fontSize: 12,
        fontWeight: 400,
        margin: 0,
    },

    circle: {
        border: '1px solid #C5C7CC',
        width: 35,
        height: 35,
        background: 'transparent',
        borderRadius: '70%',
        position: 'relative',
        right: '3px',
    },
    circleCheckedStepContainer: {
        width: 35,
        height: 35,
        background: 'transparent',
        borderRadius: '70%',
        position: 'relative',
        right: '3px',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            gap: 42,
            flexDirection: 'row',
        },
    },
    title: {
        fontFamily: AppTheme.typography.bold,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
    },
    subTitle: {
        fontFamily: AppTheme.typography.semiBold,
        margin: 0,
    },
    infoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 42,
        },
    },
    mapRestaurantInfoContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            marginTop: 50,
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    stepperContainer: {
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    },
    step: {
        '& > span': {
            '& svg': {
                color: '#bdbdbd',
                backgroundColor: 'white',
            },
        },
    },
    stepLabel: {
        fontFamily: AppTheme.typography.medium,
    },
    stepConnectorActive: {
        '& $line': {
            borderColor: '#DAB7FF',
        },
    },
    line: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '2px',
        height: '5px',
        position: 'relative',
        marginBottom: '-8px',
        right: '-2px',
    },
    introOrder: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '18px 0',
    },
    orderTypeInfo: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        color: '#4F586E',
        fontSize: 15,
        fontFamily: AppTheme.typography.medium,
        height: 'fit-content',
        '& p': {
            margin: 0,
        },
    },
    ambitStepperIcon: {
        position: 'relative',
        right: '4px',
        animation: '$grow 1s infinite ease-in-out',
    },
    '@keyframes grow': {
        '0%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.3)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
}));
