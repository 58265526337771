import { makeStyles } from '@material-ui/core/styles';
import { ExchangeArrowsIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { CardIcon } from 'src/icons/CardIcon';
import { CashIcon } from 'src/icons/CashIcon';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isTransferPayment } from 'src/utils/paymentMethod/isTransferPayment';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentMethodIcon(): React.ReactElement {
    const classes = useStyles();

    const paymentMethod = useSelector((state) => state.app.paymentMethod);

    if (isCardPayment(paymentMethod)) {
        return (
            <div className={classes.container}>
                <CardIcon className={classes.icon} />
            </div>
        );
    }

    if (isTransferPayment(paymentMethod)) {
        return (
            <div className={classes.container}>
                <ExchangeArrowsIcon />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <CashIcon className={classes.icon} />
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        placeItems: 'center',
        minWidth: 32,
        minHeight: 32,
        borderRadius: '50%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    icon: {
        width: 24,
    },
}));
