export function validatePassword(password: string | undefined): PasswordValidation {
    if (!password) {
        return {
            numberValidator: false,
            lengthValidator: false,
        };
    }

    return {
        numberValidator: doesPasswordHasNumber(password),
        lengthValidator: doesPasswordHasValidLength(password),
    };
}

function doesPasswordHasNumber(password: string): boolean {
    return /\d/.test(password);
}

function doesPasswordHasValidLength(password: string): boolean {
    return password.length >= 8;
}

type PasswordValidation = { numberValidator: boolean; lengthValidator: boolean };
