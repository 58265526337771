import { BigNumber } from 'bignumber.js';
import type { MenuItemVm } from 'src/api/pidedirecto/getAppContextApi';
import type { CartItemVm } from 'src/types/CartItemVm';

export function checkProductLimit(quantity: number, cartItems: Array<CartItemVm>, menuItem: MenuItemVm | CartItemVm): boolean {
    const existingProductCart = cartItems.find((item) => item.menuItemId === menuItem.menuItemId);

    if (existingProductCart) {
        if (!menuItem.orderItemsMaximumQuantity) return false;
        const isBiggerThanLimitProduct = BigNumber(quantity + existingProductCart.quantity).isGreaterThan(parseInt(menuItem.orderItemsMaximumQuantity));

        return isBiggerThanLimitProduct;
    }
    if (!menuItem.orderItemsMaximumQuantity) return false;
    const isQuantityBiggerThanLimit = BigNumber(quantity).isGreaterThan(parseInt(menuItem.orderItemsMaximumQuantity));

    return isQuantityBiggerThanLimit;
}
