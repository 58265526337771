import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { isBlankString } from 'src/utils/string/isBlankString';

export function FormExpiryField({ name, label, required, disabled, rules, defaultValue = null }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
        watch,
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();
    const cardNumber = watch(`${name}.cardNumber`);
    const cvc = watch(`${name}.cvc`);

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const { ref, ...restProps } = props;
                return (
                    <Input
                        {...restProps}
                        value={transformStringInput(value)}
                        onChange={(value: string) => onChange(transformStringOutput(value))}
                        label={label}
                        placeholder={translate('MM/YY')}
                        error={!!getError(errors, name)?.['expiry']}
                        helperText={getError(errors, name)?.['expiry']?.message}
                        inputRef={inputRef}
                        InputComponent={NumberFormatCustom}
                        autoComplete={'cc-exp'}
                        required={required}
                        disabled={isSubmitting || disabled}
                    />
                );
            }}
            control={control}
            name={`${name}.expiry`}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: rules?.required || !isBlankString(cardNumber) || !isBlankString(cvc),
                    message: translate('Expiry is required'),
                },
                pattern: {
                    value: /(00|01|02|03|04|05|06|07|08|09|10|11|12)\d\d/,
                    message: translate('Expiry must be in format MM/YY'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat {...other} getInputRef={inputRef} onValueChange={(values) => onChange(values.value)} isNumericString format='##/##' mask='' decimalScale={0} />;
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    value?: string;
    onChange?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    required?: boolean;
    disabled?: boolean;
    rules?: {
        required?: boolean;
    };
    InputProps?: any;
    defaultValue?: string | null;
};
