import { ThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getDeliveryEstimateApi } from 'src/api/pidedirecto/deliveryEstimate/getDeliveryEstimateApi';
import { AddMenuItemDialog } from 'src/components/AddMenuItemDialog';
import { WarningAlert } from 'src/components/alerts/WarningAlert';
import { CartButton } from 'src/components/buttons/CartButton';
import { AddNewAddressDialog } from 'src/components/cart/AddNewAddressDialog';
import { AddNewCardDialog } from 'src/components/cart/AddNewCardDialog';
import { AddNewNonGeoLocatedAddressDialog } from 'src/components/cart/AddNewNonGeoLocatedAddressDialog';
import { AddPromoCodeDialog } from 'src/components/cart/AddPromoCodeDialog';
import { CartDialog } from 'src/components/cart/CartDialog';
import { EditAddressDialog } from 'src/components/cart/EditAddressDialog';
import { EditNonGeoLocatedAddressDialog } from 'src/components/cart/EditNonGeoLocatedAddressDialog';
import { EnterEmailDialog } from 'src/components/cart/EnterEmailDialog';
import { EnterNameDialog } from 'src/components/cart/EnterNameDialog';
import { EnterPasswordDialog } from 'src/components/cart/EnterPasswordDialog';
import { EnterVerificationCodeDialog } from 'src/components/cart/EnterVerificationCodeDialog';
import { SelectAddressDialog } from 'src/components/cart/SelectAddressDialog';
import { SelectOrderTypeDialog } from 'src/components/cart/SelectOrderTypeDialog';
import { SelectPaymentMethodDialog } from 'src/components/cart/SelectPaymentMethodDialog';
import { SelectPickupTimeDialog } from 'src/components/cart/SelectPickupTimeDialog';
import { SelectTableDialog } from 'src/components/cart/SelectTableDialog';
import { SignInDialog } from 'src/components/cart/SignInDialog';
import { SignUpDialog } from 'src/components/cart/SignUpDialog';
import { Stripe3dSecureDialog } from 'src/components/cart/Stripe3dSecureDialog';
import { ValidateCardDialog } from 'src/components/cart/ValidateCardDialog';
import { CheckoutDialog } from 'src/components/checkout/CheckoutDialog';
import { OrderStatusScreen } from 'src/components/checkout/OrderStatusScreen';
import { CreateCustomerAccountDialog } from 'src/components/dialog/customer/CreateCustomerAccountDialog';
import { Page } from 'src/components/Page';
import { PromotionalBannerDialog } from 'src/components/PromotionalBannerDialog';
import { RemoveAddressDialog } from 'src/components/RemoveAddressDialog';
import { RestaurantOpeningHoursDialog } from 'src/components/RestaurantOpeningHoursDialog';
import { SelectRestaurantDialog } from 'src/components/SelectRestaurantDialog';
import { Pages } from 'src/constants/Page';
import { actions } from 'src/reducers';
import { RestaurantMenu } from 'src/scenes/restaurantMenuPage/RestaurantMenu';
import { useRestaurantTheme } from 'src/services/restaurant/useRestaurantTheme';
import { resetCartItemsInSessionStorage } from 'src/utils/cart/resetCartItemsInSessionStorage';
import { setCartItemsInSessionStorage } from 'src/utils/cart/setCartItemsInSessionStorage';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMenuPage(): React.ReactElement | null {
    const [promotionalBannerDialogState, setPromotionalBannerDialogState] = useState(false);

    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const address = useSelector((state) => state.app.address);
    const setCartItems = useAction(actions.setCartItems);
    const cartItemsByMenuItem = useSelector((state) => state.app.cartItemsByMenuItem);
    const cartItems = useSelector((state) => state.app.cartItems);

    const selectAddress = useAction(actions.selectAddress);
    const setDeliveryEstimate = useAction(actions.setDeliveryEstimate);
    const setLoadingDeliveryEstimate = useAction(actions.setLoadingDeliveryEstimate);
    const setPage = useAction(actions.setPage);

    const openCartDialog = useAction(actions.openCartDialog);

    const restaurantTheme = useRestaurantTheme(restaurant);

    useEffect(() => {
        setPage(Pages.MENU_PAGE);
    }, []);

    useEffect(() => {
        if (!restaurantId || !address?.location) return;
        setLoadingDeliveryEstimate(true);
        async function getDeliveryEstimate() {
            if (!address.location) return;
            const response = await getDeliveryEstimateApi({ restaurantId, deliveryLocation: address.location });
            if (!response.ok) {
                selectAddress(undefined);
                setLoadingDeliveryEstimate(false);
                return;
            }
            setDeliveryEstimate(response.data);
            setLoadingDeliveryEstimate(false);
        }
        getDeliveryEstimate();
    }, [restaurantId, address?.location]);

    useEffect(() => {
        resetCartItemsInSessionStorage();
        setCartItems({
            cartItems: [],
            cartItemsByMenuItem: {},
        });
        setPromotionalBannerDialogState(true);
    }, [restaurantId]);

    useEffect(() => {
        setCartItemsInSessionStorage({ cartItems, cartItemsByMenuItem });
    }, [cartItems, cartItemsByMenuItem]);

    const handlePromotionalBannerDialogClose = () => {
        setPromotionalBannerDialogState(false);
    };

    if (!restaurant) return null;

    return (
        <ThemeProvider theme={restaurantTheme}>
            <Page title={'RestaurantMenuPage'}>
                <PromotionalBannerDialog open={promotionalBannerDialogState} onClose={handlePromotionalBannerDialogClose} />
                <RestaurantMenu />
                <WarningAlert />
                <SelectRestaurantDialog />
                <RestaurantOpeningHoursDialog />
                <AddMenuItemDialog />
                <OrderStatusScreen />
                <CheckoutDialog />
                <CartDialog />
                <SignInDialog onSuccess={openCartDialog} />
                <SignUpDialog onSuccess={openCartDialog} />
                <CreateCustomerAccountDialog />
                <EnterEmailDialog />
                <EnterNameDialog />
                <EnterVerificationCodeDialog />
                <EnterPasswordDialog />
                <SelectOrderTypeDialog />
                <SelectTableDialog />
                <SelectAddressDialog />
                <Stripe3dSecureDialog />
                <AddNewAddressDialog />
                <AddNewNonGeoLocatedAddressDialog />
                <EditAddressDialog />
                <EditNonGeoLocatedAddressDialog />
                <SelectPickupTimeDialog />
                <SelectPaymentMethodDialog />
                <RemoveAddressDialog />
                <AddNewCardDialog />
                <ValidateCardDialog />
                <AddPromoCodeDialog />
                <CartButton />
            </Page>
        </ThemeProvider>
    );
}
