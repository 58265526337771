import * as React from 'react';
import { DefaultCustomerCards } from 'src/components/cart/selectPaymentMethodDialog/DefaultCustomerCards';
import { PlexoCustomerCards } from 'src/components/cart/selectPaymentMethodDialog/PlexoCustomerCards';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomerCards({ selectedPaymentMethod, disabled, onSelectCard, onStartLoading, onEndLoading }: Props): React.ReactElement {
    const plexoStoreId = useSelector((state) => state.app.restaurant?.plexoStoreId);

    if (plexoStoreId) {
        return <PlexoCustomerCards selectedPaymentMethod={selectedPaymentMethod} onStartLoading={onStartLoading} onEndLoading={onEndLoading} />;
    }

    return <DefaultCustomerCards selectedPaymentMethod={selectedPaymentMethod} disabled={disabled} onSelectCard={onSelectCard} onStartRemoving={onStartLoading} onEndRemoving={onEndLoading} />;
}

type Props = {
    selectedPaymentMethod: PaymentMethod;
    disabled?: boolean;
    onSelectCard?: any;
    onStartLoading?: any;
    onEndLoading?: any;
};
