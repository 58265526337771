import * as React from 'react';
import { CartItem } from 'src/components/CartItem';
import { useSelector } from 'src/utils/react/useSelector';

export function CartItems(): React.ReactElement {
    const cartItems = useSelector((state) => state.app.cartItems);
    const hasAnyImage = cartItems.some((cartItem) => cartItem.imageUrl);

    return (
        <div>
            {cartItems.map((cartItem) => (
                <CartItem key={cartItem.key} cartItem={cartItem} leftPad={hasAnyImage} />
            ))}
        </div>
    );
}
