export const DeliveryStatuses = Object.freeze({
    REQUESTED: 'REQUESTED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    PICKED_UP: 'PICKED_UP',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
    WITHDRAWN: 'WITHDRAWN',
});

export type DeliveryStatus = (typeof DeliveryStatuses)[keyof typeof DeliveryStatuses];
