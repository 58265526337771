import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { getBackgroundPattern } from 'src/components/getBackgroundPattern';
import { TopBar } from 'src/components/TopBar';
import { PromoCodeAlert } from 'src/modules/promoCode/components/PromoCodeAlert';
import { AppTheme } from 'src/styles/AppTheme';
import { transformImageUrlRestaurant } from 'src/utils/image/transformImageUrlRestaurant';
import { useIsSmallScreen } from 'src/utils/react/useIsSmallScreen';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantBanner(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const restaurant = useSelector((state) => state.app.restaurant);

    const isSmallScreen = useIsSmallScreen();

    const getBackgroundImage = () => {
        if (restaurant.backgroundImageUrl) return '';
        return getBackgroundPattern();
    };

    const getBackgroundColor = () => {
        if (restaurant.backgroundImageUrl) return 'transparent';
        return theme.palette.primary.main;
    };

    const shouldRenderBannerImage = () => {
        if (isSmallScreen) return !!restaurant.imageUrlMobile || !!restaurant.imageUrl;
        return !!restaurant.imageUrl;
    };

    if (!restaurant) {
        return null;
    }

    return (
        <div className={classes.bannerContainer}>
            <PromoCodeAlert />
            <div className={classes.banner} style={{ backgroundImage: getBackgroundImage(), backgroundColor: getBackgroundColor() }}>
                <TopBar restaurant={restaurant} />
                {shouldRenderBannerImage() && (
                    <div className={classes.layer}>
                        <img
                            className={classes.image}
                            src={transformImageUrlRestaurant(isSmallScreen ? (restaurant.imageUrlMobile ?? restaurant.imageUrl) : restaurant.imageUrl)}
                            draggable='false'
                            alt='imageUrl'
                        />
                    </div>
                )}
                {!restaurant.imageUrl && <h1 className={classes.restaurantName}>{restaurant.name}</h1>}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        position: 'fixed',
        width: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
    },
    banner: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: '100%',
    },
    restaurantName: {
        fontFamily: AppTheme.typography.bold,
        fontSize: 40,
        color: 'white',
        margin: 0,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        height: 250,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    imageContainer: {
        width: '100%',
        position: 'fixed',
        zIndex: 97,
    },
    layer: {
        width: '100%',
        height: 250,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        [theme.breakpoints.down('sm')]: {
            height: 300,
        },
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            maxHeight: 300,
            objectFit: 'cover',
        },
    },
}));
