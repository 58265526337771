import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { PromoType } from 'src/constants/PromoType';
import type { RedeemablePaymentType } from 'src/constants/RedeemablePaymentType';
import type { CustomerId, DeliveryEstimateId, PromoCodeId, RestaurantId } from 'src/types/Id';
import { PaymentVm } from 'src/types/PaymentVm';

export async function createRedeemablePaymentApi(request: CreateRedeemablePaymentApiRequest): ApiSauceResponse<PaymentVm> {
    return pidedirectoApiMethod('payment/createRedeemablePaymentApi', request);
}

export type CreateRedeemablePaymentApiRequest = {
    restaurantId: RestaurantId;
    customerId: CustomerId;
    redeemablePaymentType: RedeemablePaymentType;
    deliveryEstimateId?: DeliveryEstimateId;
    orderItems: Array<OrderItemVm>;
    orderType: OrderType;
    pickupTimeType?: PickupTimeType;
    pickupTime?: Date;
    promoCode?: {
        promoCodeId: PromoCodeId;
        code: string;
        promoType: PromoType;
        discount: string;
        discountType: DiscountType;
        endsAt: Date;
    };
    useLetsEatCredits: boolean;
    driverTip?: string;
    mandatoryCashAmount?: string;
};
