import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { Form } from 'src/components/form/Form';
import { FormGoogleMapsAddressSearchAutocomplete } from 'src/components/form/FormGoogleMapsAddressSearchAutocomplete';
import { DeleteIcon } from 'src/icons/DeleteIcon';
import { PinFilledIcon } from 'src/icons/PinFilledIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { actions } from 'src/reducers';
import { theme } from 'src/styles/theme';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SearchRestaurantItem({ customerLocation }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const addressLocationMap = useSelector((state) => state.app.addressLocationMap);

    const [geocoder, setGeocoder] = useState<any>();
    const [searchOpen, setSearchOpen] = useState(false);
    const [addressFound, setAddressFound] = useState('');

    const googleMapsReady = useSelector((state) => state.app.googleMapsReady);

    const centerMapToCustomerLocation = useAction(actions.centerMapToCustomerLocation);
    const setAddressLocationMap = useAction(actions.setAddressLocationMap);

    const _centerMapToCustomerLocation = async () => {
        if (!customerLocation) return;
        centerMapToCustomerLocation();
        const response = await geocoder.geocode({ location: toLatLng(customerLocation) });
        if (response.results[0]) setAddressFound(response.results[0].formatted_address);
    };

    const openLocation = (form: any) => {
        setAddressLocationMap(form.mapsResult);
    };

    const handleAutocomplete = (value: any) => {
        setAddressLocationMap(value);
    };

    useEffect(() => {
        if (googleMapsReady) {
            const geocoder = new window.google.maps.Geocoder();
            setGeocoder(geocoder);
        }
    }, [googleMapsReady]);

    const showFormAddres = searchOpen || !smallScreen;
    return (
        <Form form={form} onSubmit={openLocation} className={classNames(classes.container, searchOpen && smallScreen ? classes.searchOpen : '')}>
            {showFormAddres && (
                <div onClick={_centerMapToCustomerLocation} style={{ margin: 0 }}>
                    <PinFilledIcon className={classNames(classes.iconPinAddress, classes.iconButton)} />
                </div>
            )}
            {showFormAddres && (
                <div className={classNames(classes.formInputContainer)}>
                    <FormGoogleMapsAddressSearchAutocomplete name='mapsResult' autocompleteChange={handleAutocomplete} addressFound={addressFound} classes={{ inputContainer: classes.autocomplete }} />
                </div>
            )}
            {searchOpen && (
                <div
                    className={classes.deleteIcon}
                    onClick={() => {
                        setSearchOpen(false);
                    }}
                >
                    <DeleteIcon color={'#97979c'} />
                </div>
            )}
            {smallScreen && !searchOpen && (
                <div className={classNames(classes.searchIcon, classes.iconButton)} onClick={() => setSearchOpen(!searchOpen)}>
                    <SearchIcon color={theme.palette.primary.main} />
                </div>
            )}
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        right: 24,
        top: 24,
        height: 42,
        width: 42,
        borderRadius: '50%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        transition: '200ms',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -250%)',
            width: 500,
            height: 75,
            padding: 24,
            border: '1px solid #DDDEE2',
            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.25)',
            borderRadius: 16,
            zIndex: 99,
        },
        [theme.breakpoints.up('md')]: {
            width: 600,
        },
    },
    formInputContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
    },
    searchOpen: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 42,
        width: '90%',
        borderRadius: 18,
        padding: '10px 12px',
        [theme.breakpoints.up('sm')]: {
            width: 450,
        },
    },
    iconButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconPinAddress: {
        color: theme.palette.primary.main,
    },
    searchIcon: {
        margin: '5px auto 0 auto',
    },
    deleteIcon: {
        margin: 0,
        display: 'grid',
        placeItems: 'center',
        marginRight: 12,
    },
    autocomplete: {
        border: 0,
    },
}));

type Props = {
    customerLocation?: GeoJsonPointVm;
};
