import * as React from 'react';

export function XCircleIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='101' height='101' viewBox='0 0 101 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M50.5 0.5C22.8846 0.5 0.5 22.8846 0.5 50.5C0.5 78.1154 22.8846 100.5 50.5 100.5C78.1154 100.5 100.5 78.1154 100.5 50.5C100.5 22.8846 78.1154 0.5 50.5 0.5ZM41.6795 36.2436C41.3274 35.8657 40.9028 35.5626 40.431 35.3524C39.9592 35.1422 39.4499 35.0292 38.9334 35.02C38.417 35.0109 37.9041 35.1059 37.4251 35.2994C36.9462 35.4928 36.5112 35.7807 36.146 36.146C35.7807 36.5112 35.4928 36.9462 35.2994 37.4251C35.1059 37.9041 35.0109 38.417 35.02 38.9334C35.0292 39.4499 35.1422 39.9592 35.3524 40.431C35.5626 40.9028 35.8657 41.3274 36.2436 41.6795L45.0641 50.5L36.2436 59.3205C35.8657 59.6726 35.5626 60.0972 35.3524 60.569C35.1422 61.0408 35.0292 61.5501 35.02 62.0666C35.0109 62.583 35.1059 63.0959 35.2994 63.5749C35.4928 64.0538 35.7807 64.4888 36.146 64.854C36.5112 65.2193 36.9462 65.5072 37.4251 65.7006C37.9041 65.8941 38.417 65.9891 38.9334 65.98C39.4499 65.9708 39.9592 65.8578 40.431 65.6476C40.9028 65.4374 41.3274 65.1343 41.6795 64.7564L50.5 55.9359L59.3205 64.7564C59.6726 65.1343 60.0972 65.4374 60.569 65.6476C61.0408 65.8578 61.5501 65.9708 62.0666 65.98C62.583 65.9891 63.0959 65.8941 63.5749 65.7006C64.0538 65.5072 64.4888 65.2193 64.854 64.854C65.2193 64.4888 65.5072 64.0538 65.7006 63.5749C65.8941 63.0959 65.9891 62.583 65.98 62.0666C65.9708 61.5501 65.8578 61.0408 65.6476 60.569C65.4374 60.0972 65.1343 59.6726 64.7564 59.3205L55.9359 50.5L64.7564 41.6795C65.1343 41.3274 65.4374 40.9028 65.6476 40.431C65.8578 39.9592 65.9708 39.4499 65.98 38.9334C65.9891 38.417 65.8941 37.9041 65.7006 37.4251C65.5072 36.9462 65.2193 36.5112 64.854 36.146C64.4888 35.7807 64.0538 35.4928 63.5749 35.2994C63.0959 35.1059 62.583 35.0109 62.0666 35.02C61.5501 35.0292 61.0408 35.1422 60.569 35.3524C60.0972 35.5626 59.6726 35.8657 59.3205 36.2436L50.5 45.0641L41.6795 36.2436Z'
                fill={color ?? '#F64F4F'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
