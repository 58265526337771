import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { OrderTypeSelect } from 'src/components/OrderTypeSelect';
import { Text } from 'src/components/Text';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { ClockIcon } from 'src/icons/ClockIcon';
import { StoreClosedIcon } from 'src/icons/StoreClosedIcon';
import { StoreOpenIcon } from 'src/icons/StoreOpenIcon';
import { formatTimeInMinutes } from 'src/utils/date/formatTimeInMinutes';
import { isWithinHours } from 'src/utils/hours/isWithinHours';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMenuInfo({ restaurant }: Props): React.ReactElement {
    const classes = useStyles();

    const closed = !isWithinHours(restaurant.hours, restaurant.timeZone);
    const temporarilyWithoutDelivery = restaurant.temporarilyWithoutDelivery;
    const temporarilyOffline = restaurant.temporarilyOffline;
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant?.plannedOrdersEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant?.externalDelivery);
    const externalDeliveryEstimatedTime = useSelector((state) => state.app.restaurant?.externalDeliveryEstimatedTime);
    const driverArrivesAtStoreTime = useSelector((state) => state.app.driverArrivesAtStoreTime);
    const preparationTime = useSelector((state) => state.app.restaurant?.preparationTime);
    const orderType = useSelector((state) => state.app.orderType);

    const estimatedTime = () => {
        if (orderType === OrderTypes.DELIVERY_ORDER) {
            if (!externalDelivery) {
                return translate(`DriverArrivesAtStoreTimes.${driverArrivesAtStoreTime ?? 'DEFAULT'}`) + ' min';
            }
            if (externalDelivery) {
                return `${externalDeliveryEstimatedTime} min`;
            }
        }
        if (preparationTime !== undefined) {
            return preparationTime > 50 ? formatTimeInMinutes(preparationTime) : `${preparationTime}-${preparationTime + 5} min`;
        }
        return '45-60 min';
    };

    if (temporarilyOffline) {
        return (
            <div className={classes.container}>
                <div className={classes.infoContainer}>
                    <StoreClosedIcon color={'#B40707'} />
                    <Text className={classes.textRed}>{translate('Temporarily offline')}</Text>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            {temporarilyWithoutDelivery && (
                <div className={classes.infoContainer}>
                    <StoreClosedIcon color={'#B40707'} />
                    <Text className={classes.textRed}>{translate('Temporarily without delivery')}</Text>
                </div>
            )}
            {closed && !temporarilyWithoutDelivery && (
                <div className={classes.infoContainer}>
                    <StoreClosedIcon color={plannedOrdersEnabled ? '#C1C3C8' : '#B40707'} />
                    <Text className={plannedOrdersEnabled ? classes.text : classes.textRed}>{plannedOrdersEnabled ? translate('Pre Orders Only') : translate('Closed')}</Text>
                </div>
            )}
            {!closed && !temporarilyWithoutDelivery && (
                <div className={classes.infoContainer}>
                    <StoreOpenIcon color={'#C1C3C8'} />
                    <Text className={classes.text}>{translate('Open')}</Text>
                </div>
            )}

            <div className={classes.infoContainer}>
                <ClockIcon color={'#C1C3C8'} />
                {<Text className={classes.text}>{estimatedTime()}</Text>}
            </div>
            <OrderTypeSelect />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 24,
        maxWidth: 420,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%',
            width: '100%',
            justifyContent: 'center',
            gap: 42,
            marginTop: 60,
        },
    },
    infoContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    text: {
        color: '#4F586E',
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
    },
    textRed: {
        color: '#B40707',
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
    },
}));

type Props = {
    restaurant: RestaurantVm;
};
