import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import type { SubModifierGroupVm } from 'src/api/pidedirecto/getAppContextApi';
import { translate } from 'src/i18n/translate';
import { AppTheme } from 'src/styles/AppTheme';

export function SubModifierGroupHeader({ subModifierGroup, missingSectionName }: Props): React.ReactElement | null {
    const classes = useStyles();
    const container = useRef<HTMLDivElement>(null);

    const isMissing = missingSectionName === subModifierGroup.name;

    useEffect(() => {
        if (missingSectionName === subModifierGroup.name) {
            container.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [subModifierGroup, missingSectionName]);

    const renderBadges = () => {
        if (!subModifierGroup.requiredMin && !subModifierGroup.requiredMax) {
            return null;
        }

        const required = subModifierGroup.requiredMin === subModifierGroup.requiredMax;
        const showMinBadge = !required && !!subModifierGroup.requiredMin && subModifierGroup.requiredMin >= 1;
        const showMaxBadge = !required && !!subModifierGroup.requiredMax && subModifierGroup.requiredMax >= 1;

        return (
            <>
                {required && (
                    <Chip
                        className={isMissing ? classes.missingTitle : ''}
                        size='small'
                        label={subModifierGroup.requiredMin === 1 ? translate('REQUIRED') : `${String(subModifierGroup.requiredMin)} ${translate('REQUIRED')}`}
                    />
                )}
                {showMinBadge && <Chip className={isMissing ? classes.missingTitle : ''} size='small' label={`${translate('MIN')} ${String(subModifierGroup.requiredMin)}`} />}
                {showMaxBadge && <Chip className={isMissing ? classes.missingTitle : ''} size='small' label={`${translate('MAX')} ${String(subModifierGroup.requiredMax)}`} />}
            </>
        );
    };

    return (
        <Grid container direction='row' justify='flex-start' alignItems='center' spacing={1} style={{ marginTop: 16 }} ref={container}>
            <Grid item>
                <FormLabel component='legend'>{subModifierGroup.name}</FormLabel>
            </Grid>
            <Grid item>{renderBadges()}</Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: '#222',
    },
    headerText: {
        alignSelf: 'center',
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    missingTitle: {
        animation: '$vibration 700ms ease-out, $color 700ms ease-out',
    },
    '@keyframes vibration': {
        '0%': {
            transform: 'translateX(0px)',
        },
        '25%': {
            transform: 'translateX(15px)',
        },
        '50%': {
            transform: 'translateX(0px)',
        },
        '75%': {
            transform: 'translateX(15px)',
        },
        '100%': {
            transform: 'translateX(0px)',
        },
    },
    '@keyframes color': {
        '0%': {
            backgroundColor: AppTheme.text.colorFailure,
        },
        '75%': {
            backgroundColor: AppTheme.text.colorFailure,
        },
    },
}));

type Props = {
    subModifierGroup: SubModifierGroupVm;
    missingSectionName: string;
};
