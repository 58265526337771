import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { SummaryRow } from 'src/components/SummaryRow';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { roundDigits } from 'src/utils/number/roundDigits';
import { useSelector } from 'src/utils/react/useSelector';
import { negateNumberString } from 'src/utils/string/negateNumberString';

export function CheckoutSummary(): React.ReactElement | null {
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const classes = useStyles();

    const order = useSelector((state) => state.app.order);
    const quickActionMessage = useSelector((state) => state.app.quickActionMessage);
    const hasNoPayment = useSelector((state) => !state.app.payment);
    const subtotal = useSelector((state) => state.app.payment?.subtotal);
    const productDiscount = useSelector((state) => state.app.payment?.productDiscount);
    const promoCodeDiscount = useSelector((state) => state.app.payment?.promoCodeDiscount);
    const promotionsDiscount = useSelector((state) => state.app.payment?.promotionsDiscount);
    const customerDeliveryCost = useSelector((state) => state.app.payment?.customerDeliveryCost);
    const pideDirectoProtectionCost = useSelector((state) => state.app.payment?.pideDirectoProtectionCost);
    const restaurantServiceFee = useSelector((state) => state.app.payment?.restaurantServiceFee);
    const restaurantServiceFeeEnabled = useSelector((state) => state.app.restaurant?.restaurantServiceFeeEnabled);
    const deliveryCashHandlingFee = useSelector((state) => state.app.payment?.deliveryCashHandlingFee);
    const restaurantServiceFeeRate = useSelector((state) => state.app.restaurant?.restaurantServiceFeeRate);
    const driverTip = useSelector((state) => state.app.driverTip);
    const tax = useSelector((state) => state.app.payment?.tax);
    const serviceFee = useSelector((state) => state.app.payment?.serviceFee);
    const companyCredits = useSelector((state) => state.app.payment?.companyCredits);
    const letsEatCredits = useSelector((state) => state.app.payment?.letsEatCredits);
    const total = useSelector((state) => state.app.payment?.total);
    const companyName = undefined; // const companyName = useSelector(state => state.app.company?.name);
    const invoiceRestaurantDeliveryCost = useSelector((state) => state.app.restaurant?.invoiceRestaurantDeliveryCost);

    if (hasNoPayment) {
        return null;
    }

    return (
        <div className={classes.container}>
            {subtotal !== total && <SummaryRow text={translate('Subtotal')} value={formatAsCurrencyNumber(subtotal)} />}
            {productDiscount && <SummaryRow text={translate('Product Discount')} value={formatAsCurrencyNumber(negateNumberString(productDiscount))} />}
            {promotionsDiscount && <SummaryRow text={translate('Promotions discount')} value={formatAsCurrencyNumber(negateNumberString(promotionsDiscount))} />}
            {customerDeliveryCost && !invoiceRestaurantDeliveryCost && !BigNumber(customerDeliveryCost).isZero() && (
                <SummaryRow text={translate('Delivery')} value={formatAsCurrencyNumber(customerDeliveryCost)} />
            )}
            {pideDirectoProtectionCost && <SummaryRow text={translate('Order protection')} value={formatAsCurrencyNumber(pideDirectoProtectionCost)} />}
            {driverTip && <SummaryRow text={translate('Driver Tip')} value={formatAsCurrencyNumber(driverTip)} />}
            {tax && <SummaryRow text={translate('Tax')} value={formatAsCurrencyNumber(tax)} />}
            {!!deliveryCashHandlingFee && <SummaryRow text={translate('Cash fee')} value={formatAsCurrencyNumber(deliveryCashHandlingFee)} />}
            {!!serviceFee && (
                <SummaryRow text={translate('Service fee (@serviceFee%)', { serviceFee: roundDigits(restaurantServiceFeeRate * 100) })} value={formatAsCurrencyNumber(restaurantServiceFee)} />
            )}
            {promoCodeDiscount && <SummaryRow text={translate('Promo Code')} value={formatAsCurrencyNumber(negateNumberString(promoCodeDiscount))} />}
            {companyCredits && (
                <SummaryRow
                    text={translate('Paid by @company', { company: companyName })}
                    value={formatAsCurrencyNumber(negateNumberString(companyCredits))}
                    className={{ color: theme.palette.primary }}
                />
            )}
            {letsEatCredits && <SummaryRow text={translate('Credits')} value={formatAsCurrencyNumber(negateNumberString(letsEatCredits))} />}
            {!!quickActionMessage && <p className={classes.quickActionMessage}>{quickActionMessage}</p>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    quickActionMessage: {
        textAlign: 'left',
        fontWeight: 'bold',
        color: '#444444',
    },
}));
