import { makeStyles, Theme } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'src/components/Button';
import { PageContext } from 'src/components/Page';
import { SECONDS } from 'src/constants/TimeUnit';
import { CrossIcon } from 'src/icons/CrossIcon';
import { createUserOpenedDialogLogEvent } from 'src/services/logEvent/createUserOpenedDialogLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onActionClick
 */

/**
 * @callback onClose
 */

/**
 *
 * @param {string} title - dialog title
 * @param {boolean} open - dialog open state
 * @param {boolean} [disabled] - disable dialog actions
 * @param {boolean} [actionButtonDisabled] - disable action button
 * @param {boolean} [loading] - loading indicator for the dialog
 * @param {string} [actionText] - text for the dialog main button, if this is passes the button will be rendered
 * @param {string} [actionButtonFormId] - in order to link a form and the dialog button you have to pass the form id here
 * @param {string} [position] - dialog screen position, by default it will be at the center coming up from the bottom of the screen
 * @param {Object} [classes] - classes object to override dialog styles
 * @param {string} [classes.dialog] - dialog class
 * @param {string} [classes.content] - dialog content container class
 * @param {onActionClick} [onActionClick] - callback for the dialog main button
 * @param {React.Node} [children] - content to be rendered inside the dialog
 * @param {onClose} onClose - callback to be called when user wants to close the dialog, if this is not passed means that the dialog is not closable by the user
 * @returns {React.Node}
 */
export function Dialog({
    title,
    open,
    disabled,
    actionButtonDisabled,
    loading,
    actionText,
    actionButtonFormId,
    position,
    classes: classesProp,
    onActionClick,
    children,
    onClose,
}: Props): React.ReactElement | null {
    const classes = useStyles({ position });
    const pageContext = useContext(PageContext);

    const [triggerAnimations, setTriggerAnimations] = useState(false);
    const [shouldKeepClosedDialog, setShouldKeepClosedDialog] = useState(true);

    useEffect(() => {
        if (open) {
            setShouldKeepClosedDialog(false);
            setTimeout(() => {
                setTriggerAnimations(true);
            }, 10);
            createUserOpenedDialogLogEvent({ title, pageContext });
        } else {
            setTriggerAnimations(false);
            setTimeout(() => {
                setShouldKeepClosedDialog(true);
            }, ANIMATION_SECONDS * SECONDS);
        }
    }, [open]);

    const handleClose = () => {
        if (disabled || loading || !onClose) return;
        setTriggerAnimations(false);
        setTimeout(() => {
            onClose?.();
        }, ANIMATION_SECONDS * SECONDS);
    };

    const handleButton = () => {
        if (!onActionClick) return;

        onActionClick?.();
        handleClose();
    };

    const addDialogToStackTrace = () => {
        pageContext.addElementToStackTrace(normalizeUiStackTrace(`dialog_${title ?? ''}`));
    };

    if (!open && shouldKeepClosedDialog) return null;

    return createPortal(
        <div className={classes.container}>
            <div className={classNames(classes.background, triggerAnimations ? classes.backgroundColored : '')} onClick={handleClose}></div>
            <div
                role='dialog'
                aria-labelledby='dialog-title'
                className={classNames(classes.dialog, classesProp?.dialog, triggerAnimations ? classes.dialogOpen : '')}
                onClickCapture={addDialogToStackTrace}
            >
                {title && (
                    <h1 id='dialog-title' className={classNames(classes.title, classesProp?.title)}>
                        {title}
                    </h1>
                )}
                {!!onClose && (
                    <Button onClick={handleClose} icon classes={{ button: classes.closeButton }} disabled={disabled}>
                        <CrossIcon />
                    </Button>
                )}
                <div className={classNames(classes.dialogContent, classesProp?.content)}>{children}</div>
                {!!actionText && (
                    <Button onClick={handleButton} classes={{ button: classes.button }} disabled={actionButtonDisabled} loading={loading} form={actionButtonFormId}>
                        {actionText}
                    </Button>
                )}
            </div>
        </div>,
        document.body,
    );
}

const ANIMATION_SECONDS = 0.3;

const useStyles = makeStyles<Theme, { position: 'right' | 'left' | 'bottom' | undefined }>((theme) => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 100,
        display: 'flex',
        alignItems: ({ position }) => (position === 'bottom' ? 'flex-end' : 'center'),
        justifyContent: ({ position }) => {
            if (position === 'left') return 'flex-start';
            if (position === 'right') return 'flex-end';
            return 'center';
        },
        flexDirection: 'row',
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        transition: `background-color ${ANIMATION_SECONDS}s ease`,
    },
    dialog: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderTopRightRadius: ({ position }) => (position === 'right' ? 0 : 18),
        borderBottomRightRadius: ({ position }) => (position === 'right' || position === 'bottom' ? 0 : 18),
        borderTopLeftRadius: ({ position }) => (position === 'left' ? 0 : 18),
        borderBottomLeftRadius: ({ position }) => (position === 'left' || position === 'bottom' ? 0 : 18),
        zIndex: 101,
        position: 'relative',
        bottom: ({ position }) => (position === 'bottom' ? '-100vh' : 'unset'),
        top: ({ position }) => (!position ? '100vh' : 'unset'),
        left: ({ position }) => (position === 'left' ? '100vw' : 'unset'),
        right: ({ position }) => (position === 'right' ? '-100vw' : 'unset'),
        transition: ({ position }) => {
            if (position === 'left') return `left ${ANIMATION_SECONDS}s ease`;
            if (position === 'right') return `right ${ANIMATION_SECONDS}s ease`;
            if (position === 'bottom') return `bottom ${ANIMATION_SECONDS}s ease`;
            return `top ${ANIMATION_SECONDS}s ease`;
        },
        width: '30vw',
        maxHeight: '90vh',
        [theme.breakpoints.down('md')]: {
            width: '40vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
    dialogContent: {
        height: '100%',
        width: '100%',
        padding: 24,
        maxHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        paddingTop: 0,
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '70svh',
        },
    },
    dialogOpen: {
        bottom: ({ position }) => (position === 'bottom' ? 0 : 'unset'),
        top: ({ position }) => (!position ? 0 : 'unset'),
        left: ({ position }) => (position === 'left' ? 0 : 'unset'),
        right: ({ position }) => (position === 'right' ? 0 : 'unset'),
    },
    title: {
        fontFamily: AppTheme.typography.semiBold,
        fontSize: 16,
        color: theme.palette.primary.main,
        textAlign: 'center',
        margin: '25px auto 30px auto',
        width: '100%',
        position: 'relative',
        maxWidth: '80%',
    },
    backgroundColored: {
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    button: {
        width: '100%',
        height: '100%',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18,
        textAlign: 'center',
        justifyContent: 'center',
        padding: '20px 0px',
    },
    closeButton: {
        position: 'absolute',
        right: 12,
        top: 12,
        transformOrigin: 'center',
        zIndex: 20,
    },
    underGroundOnClose: {
        position: 'absolute',
        right: 12,
        top: 12,
        transformOrigin: 'center',
        zIndex: 20,
        color: 'white',
    },
}));

type Props = {
    title?: string;
    open: boolean;
    disabled?: boolean;
    actionButtonDisabled?: boolean;
    loading?: boolean;
    actionText?: string;
    actionButtonFormId?: string;
    position?: 'right' | 'left' | 'bottom';
    classes?: {
        dialog?: string;
        content?: string;
        title?: string;
    };
    onActionClick?: any;
    children?: React.ReactNode;
    onClose?: any;
};
