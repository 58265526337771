import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { PhoneIcon } from 'src/icons/PhoneIcon';

export function ContactInfoIcon(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <PhoneIcon />
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        placeItems: 'center',
        minWidth: 32,
        minHeight: 32,
        borderRadius: '50%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
}));
