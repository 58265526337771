import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { buttonWidth } from 'src/components/BottomDialogHeader';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { CheckIcon } from 'src/icons/CheckIcon';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

export function BottomDialogCheckButton({ Icon, text, subtext, checked, disabled, onClickRow, children }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Button text classes={{ button: classes.container }} onClick={onClickRow} disabled={disabled}>
            {checked && (
                <Fade in={checked}>
                    <CheckIcon className={classNames(classes.checkIcon, disabled && classes.disabledSubtext)} />
                </Fade>
            )}
            {!children && !!Icon && (
                <>
                    <Icon className={classNames(classes.icon, disabled && classes.disabled)} />
                    <div className={classes.textContainer}>
                        <Text className={classNames(classes.text, disabled && classes.disabled)}>{text}</Text>
                        {!!subtext && <Text className={classNames(classes.subtext, disabled && classes.disabledSubtext)}>{subtext}</Text>}
                    </div>
                </>
            )}
            {children}
        </Button>
    );
}

export function runAfterCheckAnimation(callback: () => void) {
    setTimeout(() => {
        callback();
    }, 250);
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: 12,
        padding: '18px 0',
        width: '100%',
        '&:focus': {
            outline: 0,
        },
    },
    contentContainer: {},
    disabled: {
        color: `${AppTheme.text.colorDisabled} !important`,
    },
    disabledSubtext: {
        color: '#D0D0D0 !important',
    },
    icon: {
        color: '#4F586E',
        width: 24,
    },
    subtext: {
        fontSize: 10,
        color: AppTheme.listRowButton.subtext.color,
        textAlign: 'start',
        maxWidth: 200,
    },
    checkIconContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: buttonWidth,
    },
    checkIcon: {
        color: theme.palette.primary.main,
        width: 28,
        flexShrink: 0,
    },
    textContainer: {},
    text: {},
}));

type Props = {
    Icon?: React.ComponentType<any>;
    text?: string;
    subtext?: string;
    checked: boolean;
    disabled?: boolean;
    onClickRow: any;
    children?: React.ReactNode;
};
