import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect } from 'react';
import { CheckoutBottomDrawer } from 'src/components/bottomDrawers/CheckoutBottomDrawer';
import { Button } from 'src/components/Button';
import { CreateOrderButton } from 'src/components/checkout/CreateOrderButton';
import { GoogleMapsCheckoutDialog } from 'src/components/googlemaps/GoogleMapsCheckoutDialog';
import { LogEventTypes } from 'src/constants/LogEventType';
import { ChevronLeftIcon } from 'src/icons/ChevronLeftIcon';
import { CartProvider } from 'src/providers/CartProvider';
import { actions } from 'src/reducers';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CheckoutDialog(): React.ReactElement | null {
    const classes = useStyles();

    const open = useSelector((state) => state.app.checkoutDialog.open);
    const subtotal = useSelector((state) => state.app.payment?.subtotal);
    const productDiscount = useSelector((state) => state.app.payment?.productDiscount);
    const maximumCashOrderAmount = useSelector((state) => state.app.restaurant?.maximumCashOrderAmount);
    const closeCheckoutDialog = useAction(actions.closeCheckoutDialog);
    const selectPaymentMethod = useAction(actions.selectPaymentMethod);

    const cashAmountHigherThanPermitted = BigNumber(subtotal ?? 0)
        .minus(productDiscount ?? 0)
        .isGreaterThanOrEqualTo(maximumCashOrderAmount);

    useEffect(() => {
        if (open) {
            createUiInteractionLogEvent({
                logEventType: LogEventTypes.USER_OPENED_CART_DETAILS,
            });
        }
    }, [open]);

    useEffect(() => {
        if (cashAmountHigherThanPermitted) selectPaymentMethod({ paymentMethod: undefined });
    }, [cashAmountHigherThanPermitted]);

    if (!open) {
        return null;
    }

    return (
        <div className={classes.container}>
            <CartProvider>
                {open && <div className={classes.background} onClick={closeCheckoutDialog}></div>}
                <div className={classes.checkout}>
                    <div className={classes.iconContainer}>
                        <Button icon classes={{ button: classes.button }} onClick={closeCheckoutDialog}>
                            <ChevronLeftIcon color={'#4F586E'} />
                        </Button>
                    </div>
                    <GoogleMapsCheckoutDialog />
                    <CheckoutBottomDrawer />
                </div>
                <CreateOrderButton />
            </CartProvider>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    background: {
        [theme.breakpoints.up('sm')]: {
            position: 'fixed',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 98,
        },
    },
    checkout: {
        display: 'flex',
        flexDirection: 'column',
        bottom: 0,
        width: '100%',
        height: '100vh',
        zIndex: 99,
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            top: 0,
            right: 0,
            width: 420,
            maxHeight: '100vh',
            transition: 'width 450ms',
            borderRadius: '42px 0 0 42px !important',
        },
    },
    iconContainer: {
        position: 'fixed',
        left: 24,
        top: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 42,
        height: 42,
        padding: 8,
        borderRadius: '50%',
        background: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
        zIndex: 97,
        [theme.breakpoints.up('sm')]: {
            boxShadow: 'none',
            display: 'none',
        },
    },
    button: {
        width: 42,
        height: 42,
        borderRadius: 42,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 0,
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginTop: 24,
            },
        },
    },
    title: {
        fontWeight: 500,
        fontSize: 22,
        color: '#393B41',
        margin: 0,
    },
    checkoutDesktop: {
        background: 'white',
    },
}));
