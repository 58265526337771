import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

export function BottomDrawerTopBar(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.topBarContainer}>
            <div className={classes.topBar}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    topBarContainer: {
        position: 'sticky',
        top: 0,
        height: 'fit-content',
        width: '100%',
        display: 'grid',
        placeItems: 'center',
        marginTop: 4,
        paddingTop: 4,
        marginBottom: 18,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    topBar: {
        width: 70,
        height: 4,
        backgroundColor: '#E3E3E3',
        borderRadius: 4,
    },
}));
