import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function OkButton({ tooltip, onClick }: Props): React.ReactElement {
    return (
        <>
            <Tooltip title={tooltip || translate('Ok')}>
                <IconButton onClick={onClick}>
                    <CheckIcon />
                </IconButton>
            </Tooltip>
        </>
    );
}

type Props = {
    tooltip?: string;
    onClick?: any;
};
