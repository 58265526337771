import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import * as React from 'react';
import { findPaymentLinkApi } from 'src/api/pidedirecto/paymentLink/findPaymentLinkApi';
import { Footer } from 'src/components/Footer';
import { getBackgroundPattern } from 'src/components/getBackgroundPattern';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { translate } from 'src/i18n/translate';
import { PaymentLinkCheckoutSteps } from 'src/scenes/paymentLink/PaymentLinkCheckoutSteps';
import { AppTheme } from 'src/styles/AppTheme';
import type { PaymentLinkId } from 'src/types/Id';
import type { PaymentLinkVm } from 'src/types/PaymentLinkVm';
import { transformImageUrlRestaurant } from 'src/utils/image/transformImageUrlRestaurant';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentLinkCheckout({ paymentLinkId }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const restaurant = useSelector((state) => state.app.restaurant);

    const [loading, paymentLink] = useLoadApi<PaymentLinkVm | undefined>(findPaymentLinkApi, { paymentLinkId }, { requiredValues: [paymentLinkId], dependencies: [paymentLinkId], onError: () => {} });

    const restaurantImage = smallScreen ? restaurant?.imageUrlMobile || restaurant?.imageUrl : restaurant?.imageUrl;

    if (loading) {
        return (
            <div className={classes.container} style={{ backgroundImage: getBackgroundPattern('rgb(210, 210, 210)') }}>
                <LoadingIndicator loading={loading} />
                <Footer>
                    <Text className={classes.footerText}>{translate('With technology from')}</Text>
                    <a className={classes.footerText} href={'https://www.ambit.la/'}>
                        Ambit.la
                    </a>
                </Footer>
            </div>
        );
    }

    if (!paymentLink) {
        return (
            <div className={classes.container} style={{ backgroundImage: getBackgroundPattern('rgb(210, 210, 210)') }}>
                <Text variant='subtitle' size='large' className={classes.paymentLinkNotFound} style={{ color: '#0D3037' }}>
                    {translate('We could not found this payment link, check that the link is correct or ask the person who sent it to you to create a new one.')}
                </Text>
                <Footer>
                    <Text className={classes.footerText}>{translate('With technology from')}</Text>
                    <a className={classes.footerText} href={'https://www.ambit.la/'}>
                        Ambit.la
                    </a>
                </Footer>
            </div>
        );
    }

    return (
        <div className={classes.container} style={{ backgroundImage: getBackgroundPattern('rgb(210, 210, 210)') }}>
            <section className={classes.paymentLinkContainer}>
                <div className={classes.restaurantInfoContainer}>
                    {!!restaurantImage && <img className={classes.image} src={transformImageUrlRestaurant(restaurantImage)} draggable='false' alt={translate('restaurant logo image')} />}
                    <Text size='large' style={{ color: '#0D3037' }} variant='subtitle'>
                        {restaurant?.name}
                    </Text>
                </div>
                <PaymentLinkCheckoutSteps paymentLink={paymentLink} />
            </section>
            <Footer>
                <Text className={classes.footerText}>{translate('With technology from')}</Text>
                <a className={classes.footerText} href={'https://www.ambit.la/'}>
                    Ambit.la
                </a>
            </Footer>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        flexWrap: 'wrap',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            height: '100vh',
            minWidth: 500,
        },
    },
    restaurantInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
        gap: 10,
    },
    paymentLinkContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 500,
        width: '90%',
        gap: 10,
    },
    image: {
        width: '50%',
    },
    footerText: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 14,
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: 5,
    },
    total: {
        fontFamily: AppTheme.typography.bold,
        fontSize: 28,
        color: '#0D3037',
    },
    orderItemsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    orderItems: {},
    button: {
        width: '100%',
        marginTop: 30,
    },
    payingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
    },
    paymentLinkNotFound: {
        textAlign: 'center',
        margin: '0 auto',
        width: '40vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
    footerTextContent: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
    },
}));

type Props = {
    paymentLinkId: PaymentLinkId;
};
