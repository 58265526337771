import { BigNumber } from 'bignumber.js';
import type { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import { DiscountTypes } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { RewardTypes } from 'src/constants/RewardType';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemSubtotalPrice } from 'src/utils/order/calculateOrderItemSubtotalPrice';
import { isPromoCodeApplicable } from 'src/utils/promoCode/isPromoCodeApplicable';
import { requireValue } from 'src/utils/require/requireValue';

export function calculatePromoCodeDiscount(
    promoCode: PromoCodeVm | undefined,
    orderType: OrderType | undefined,
    paymentMethod: PaymentMethod | undefined,
    numberOfOrders: number,
    amount: string,
    customerDeliveryCost: string | undefined,
    orderItems: Array<CartItemVm>,
): string | undefined {
    if (!promoCode) {
        return;
    }

    const menuItemIds = orderItems?.map((orderItem: CartItemVm) => orderItem?.menuItemId);

    if (!isPromoCodeApplicable({ promoCode, orderType, paymentMethod, amount, customerDeliveryCost, menuItemIds, numberOfOrders })) {
        return;
    }

    if (promoCode.excludeDeliveryCost && customerDeliveryCost) {
        amount = BigNumber(amount).minus(customerDeliveryCost).toString();
    }

    if (promoCode.rewardType === RewardTypes.PRODUCTS) {
        const menuItems = orderItems.filter((orderItem) => promoCode.menuItemIds?.includes(orderItem?.menuItemId));
        let orderItemsSubTotal = BigNumber(0);
        menuItems.forEach((menuItem) => {
            const orderItemSubtotal = calculateOrderItemSubtotalPrice(menuItem);
            orderItemsSubTotal = BigNumber(orderItemsSubTotal).plus(orderItemSubtotal);
        });
        const applicableProductsAmount = BigNumber(orderItemsSubTotal).toString();
        amount = applicableProductsAmount;
    }

    if (promoCode.discountType === DiscountTypes.AMOUNT) {
        let discountAmount = BigNumber(promoCode.discount);

        if (promoCode.freeDelivery && customerDeliveryCost) {
            discountAmount = BigNumber(discountAmount).plus(BigNumber(customerDeliveryCost));
        }

        if (discountAmount.isGreaterThanOrEqualTo(amount)) {
            return amount.toString();
        }
        return discountAmount.toString();
    } else if (promoCode.discountType === DiscountTypes.PERCENT) {
        if (promoCode.freeDelivery && customerDeliveryCost) {
            amount = BigNumber(amount).minus(customerDeliveryCost).toString();
        }

        let discountAmount = BigNumber(BigNumber(amount).multipliedBy(promoCode.discount).dividedBy(100).toFixed(2));

        if (promoCode.freeDelivery && customerDeliveryCost) {
            discountAmount = BigNumber(discountAmount).plus(customerDeliveryCost);
        }
        if (!promoCode.freeDelivery && promoCode.maxDiscountAmount && discountAmount.isGreaterThanOrEqualTo(promoCode.maxDiscountAmount)) {
            return promoCode.maxDiscountAmount;
        }
        if (promoCode.freeDelivery && customerDeliveryCost && promoCode.maxDiscountAmount && discountAmount.isGreaterThanOrEqualTo(promoCode.maxDiscountAmount)) {
            return BigNumber(customerDeliveryCost).plus(requireValue(promoCode.maxDiscountAmount)).toString();
        }
        if (discountAmount.isGreaterThanOrEqualTo(amount)) {
            return amount.toString();
        }
        return discountAmount.toString();
    }

    // DiscountType not implemented, fallback to no discount, return nothing
}
