import { BigNumber } from 'bignumber.js';
import type { CardVm } from 'src/types/CardVm';
import { isMexico } from 'src/utils/country/isMexico';
import { useSelector } from 'src/utils/react/useSelector';

export function useDoesTotalNeedCardValidation(): (params: Params) => boolean {
    const country = useSelector((state) => state.app.restaurant?.country);
    const maximumOrderAmountWithoutCardValidation = useSelector((state) => state.app.restaurant?.maximumOrderAmountWithoutCardValidation);

    const doesTotalNeedCardValidation = ({ total, card }: Params) => {
        if (!isMexico(country) || card?.validated) return false;
        return BigNumber(total || 0).isGreaterThan(maximumOrderAmountWithoutCardValidation ?? MAXIMUM_AMOUNT_WITHOUT_CARD_VALIDATION);
    };

    return doesTotalNeedCardValidation;
}

const MAXIMUM_AMOUNT_WITHOUT_CARD_VALIDATION = 300;

type Params = {
    total: string | number | undefined;
    card?: CardVm;
};
