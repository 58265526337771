import { BigNumber } from 'bignumber.js';
import type { ModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';
import { calculateOrderItemSubModifierGroupsPrice } from 'src/utils/order/calculateOrderItemSubModifierGroupsPrice';
import { sum } from 'src/utils/reduce/sum';

export function calculateOrderItemModifierGroupsPrice(modifierGroups: Array<ModifierGroupVm>): BigNumber {
    const totalPrice = modifierGroups.reduce((accumulator, modifierGroup) => {
        const freeModifiersQuantity = BigNumber(modifierGroup.freeModifiersQuantity ?? 0);
        const selectedAndSortedModifiers = modifierGroup.modifiers
            .filter((modifier) => modifier.quantity > 0)
            .sort((previousModifier, afterModifier) => Number(previousModifier.price) - Number(afterModifier.price));

        const selectedModifiersQuantity = selectedAndSortedModifiers.map((modifier) => modifier.quantity).reduce(sum, BigNumber(0));

        if (selectedModifiersQuantity.comparedTo(freeModifiersQuantity)) {
            let nonFreeQuantities = selectedModifiersQuantity.minus(freeModifiersQuantity).toNumber();

            const groupTotalPrice = selectedAndSortedModifiers
                .slice()
                .reverse()
                .map((modifier) => {
                    if (nonFreeQuantities <= 0) return BigNumber(0);

                    const quantity = Math.min(modifier.quantity, nonFreeQuantities);
                    nonFreeQuantities -= quantity;

                    const subModifierGroupsPrice = calculateOrderItemSubModifierGroupsPrice(modifier.subModifierGroups);

                    return BigNumber(modifier.price).plus(subModifierGroupsPrice).multipliedBy(quantity);
                })
                .reduce(sum, BigNumber(0));

            return accumulator.plus(groupTotalPrice);
        } else {
            return accumulator;
        }
    }, BigNumber(0));

    return totalPrice;
}
