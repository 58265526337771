import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverArrivesAtStoreTime } from 'src/constants/DriverArrivesAtStoreTime';
import type { DeliveryEstimateVm } from 'src/types/DeliveryEstimateVm';
import type { RestaurantId } from 'src/types/Id';

export async function getDeliveryEstimateApi(request: GetDeliveryEstimateApiRequest): ApiSauceResponse<GetDeliveryEstimateApiResponse> {
    return pidedirectoApiMethod('order/getDeliveryEstimateApi', request);
}

type GetDeliveryEstimateApiResponse = DeliveryEstimateVm & {
    driverArrivesAtStoreTime?: DriverArrivesAtStoreTime;
};

export type GetDeliveryEstimateApiRequest = {
    restaurantId: RestaurantId;
    deliveryLocation: GeoJsonPointVm;
    appVersion?: string;
};
