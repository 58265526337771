export const PaymentMethods = {
    CREDIT_CARD: 'CREDIT_CARD',
    CARD_ON_DELIVERY: 'CARD_ON_DELIVERY',
    CASH: 'CASH',
    PAYROLL: 'PAYROLL',
    MULTIPLE: 'MULTIPLE',
    CREDITS: 'CREDITS',
    TRANSFER: 'TRANSFER',
} as const;

export type PaymentMethod = (typeof PaymentMethods)[keyof typeof PaymentMethods];
