import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import { Text } from 'src/components/Text';
import PhotoNotFoundImage from 'src/images/PhotoNotFoundImage.png';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { modifierGroupsAndNoteToString } from 'src/utils/cart/modifierGroupsAndNoteToString';
import { transformImageUrlMenuItem } from 'src/utils/image/transformImageUrlMenuItem';
import { calculateOrderItemProductDiscountPrice } from 'src/utils/order/calculateOrderItemProductDiscountPrice';
import { calculateOrderItemSubtotalPrice } from 'src/utils/order/calculateOrderItemSubtotalPrice';
import { classNames } from 'src/utils/react/classNames';

export function OrderItem({ orderItem }: Props): React.ReactElement {
    const { name, imageUrl, quantity } = orderItem;

    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const modifiersAndNote = modifierGroupsAndNoteToString(orderItem.modifierGroups, orderItem.note);

    return (
        <div className={classes.container}>
            {!!imageUrl && (
                <img
                    className={classNames(classes.image, {
                        [classes.imagePng]: imageUrl && imageUrl.endsWith('png'), // TODO: .endsWith('png') does not work with encoded image url
                    })}
                    src={transformImageUrlMenuItem(imageUrl)}
                    draggable='false'
                    alt='itemImage'
                />
            )}
            {!imageUrl && <img className={classes.image} src={PhotoNotFoundImage} alt='noIconPhoto' />}
            <div className={classes.nonImageContainer}>
                <div className={classes.nameAndButtonContainer}>
                    <Text className={classes.name}>{name}</Text>
                    {quantity > 1 && <Text className={classes.menuItemQuantity}>{quantity}x</Text>}
                    <div className={classes.priceContainer}>
                        {!!orderItem.promoUnitPrice && <Text className={classes.price}>{formatAsCurrencyNumber(calculateOrderItemProductDiscountPrice(orderItem).toString())}</Text>}
                        <Text className={classNames(classes.price, orderItem.promoUnitPrice ? classes.nonPromoPrice : undefined)}>
                            {formatAsCurrencyNumber(calculateOrderItemSubtotalPrice(orderItem).toString())}
                        </Text>
                        {!!orderItem.promoText && <Text className={classes.promoText}>{orderItem.promoText}</Text>}
                    </div>
                </div>
                <div>
                    <Text className={classes.modifierGroupsAndNote}>{modifiersAndNote}</Text>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 12,
    },
    image: {
        width: 40,
        height: 40,
        minWidth: 40,
        minHeight: 40,
        borderRadius: 8,
        objectFit: 'cover',
        marginRight: 12,
        boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.up('sm')]: {
            width: 60,
            height: 60,
            minWidth: 60,
            minHeight: 60,
        },
    },
    imagePng: {
        objectFit: 'contain',
    },
    nonImageContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        // backgroundColor: 'green',
    },
    nameAndButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        zIndex: 1,
    },
    name: {
        display: 'flex',
        flex: 1,
        fontSize: 15,
        fontFamily: AppTheme.typography.medium,
        textTransform: 'none',
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    promoText: {
        fontSize: 10,
        lineHeight: '20px',
        color: '#FF7F00',
        fontWeight: 600,
        marginLeft: 8,
    },
    price: {
        fontSize: 15,
        fontFamily: AppTheme.typography.medium,
    },
    nonPromoPrice: {
        color: '#CCCCCC',
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid',
        marginLeft: 8,
    },
    modifierGroupsAndNote: {
        fontSize: 13,
        color: '#B1B1B1',
        paddingTop: 2,
        fontFamily: AppTheme.typography.regular,
    },
    menuItemQuantity: {
        fontSize: 15,
        textAlign: 'center',
        fontFamily: AppTheme.typography.regular,
        marginRight: 5,
    },
}));

type Props = {
    orderItem: OrderItemVm;
};
