import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { BottomDrawerTopBar } from 'src/components/BottomDrawerTopBar';
import { CheckoutContent } from 'src/components/checkout/CheckoutContent';
import { OrderTypeSelect } from 'src/components/OrderTypeSelect';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';

export function CheckoutBottomDrawer(): React.ReactElement {
    const classes = useStyles();

    return (
        <BottomDrawer className={classes.bottomDrawer}>
            <div className={classes.stickyContainer}>
                <BottomDrawerTopBar />
                <div className={classes.header}>
                    <Text variant={'semiBold'} className={classes.title}>
                        {translate('Your order')}
                    </Text>
                    <OrderTypeSelect />
                </div>
            </div>
            <CheckoutContent />
        </BottomDrawer>
    );
}
const useStyles = makeStyles((theme) => ({
    stickyContainer: {
        position: 'sticky',
        top: 0,
        background: 'white',
    },
    bottomDrawer: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 0,
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            marginTop: 24,
            maxWidth: 372,
        },
    },
    title: {
        fontWeight: 500,
        fontSize: 22,
        color: '#393B41',
        margin: 0,
    },
}));
