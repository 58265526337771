import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SurveyAnswer } from 'src/constants/SurveyAnswer';
import type { RestaurantId } from 'src/types/Id';

export async function createAnsweredSurveyApi(request: CreateAnswerSurveyApiRequest): ApiSauceResponse<void> {
    return pidedirectoApiMethodPublic('survey/createAnsweredSurveyApi', request);
}

export type CreateAnswerSurveyApiRequest = {
    restaurantId: RestaurantId;
    restaurantName: string;
    rating: number;
    recommendationRating: number;
    surveyAnswers?: Array<SurveyAnswer>;
    otherAnswer?: string;
};
