import { CashIcon, CellPhoneIcon, ChainIcon, CreditCardIcon, DoorHangerIcon, ExchangeArrowsIcon, TerminalIcon, WalletIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { CustomPaymentMethodIcon, CustomPaymentMethodIcons } from 'src/constants/CustomPaymentMethodIcons';
import { translate } from 'src/i18n/translate';

export function PaymentMethodIcon({ icon }: Props): React.ReactElement {
    if (CustomPaymentMethodIcons.CHAIN === icon) return <ChainIcon title={translate('Chain')} />;
    if (CustomPaymentMethodIcons.CELL_PHONE === icon) return <CellPhoneIcon title={translate('Cellphone')} />;
    if (CustomPaymentMethodIcons.ARROW_RIGHT_LEFT === icon) return <ExchangeArrowsIcon title={translate('Arrows with opposite directions')} />;
    if (CustomPaymentMethodIcons.WALLET === icon) return <WalletIcon title={translate('Wallet')} />;
    if (CustomPaymentMethodIcons.DOOR_HANGER === icon) return <DoorHangerIcon title={translate('Door hanger')} />;
    if (CustomPaymentMethodIcons.SHEET === icon) return <TerminalIcon title={translate('Terminal icon')} />;
    if (CustomPaymentMethodIcons.CREDIT_CARD === icon) return <CreditCardIcon title={translate('Card')} />;
    return <CashIcon size={25} title={translate('Cash')} />;
}

type Props = {
    icon?: CustomPaymentMethodIcon;
};
