import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { memo } from 'react';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { MinusIcon } from 'src/icons/MinusIcon';
import { PlusIcon } from 'src/icons/PlusIcon';
import { classNames } from 'src/utils/react/classNames';

export const QuantitySpinner: React.ComponentType<Props> = memo<Props>(function QuantitySpinner({ quantity, onSubtract, onAdd, variant = 'primary', size = 'normal' }: Props): React.ReactElement {
    const classes = useStyles();

    const isSecondary = variant === 'secondary';

    return (
        <div className={classes.container}>
            <Button outlined classes={{ button: classNames(classes.subtract, isSecondary ? classes.secondarySubtract : '', size === 'small' ? classes.smallSubtract : '') }} onClick={onSubtract}>
                <MinusIcon width={18} height={18} />
            </Button>
            <Button outlined classes={{ button: classNames(classes.add, isSecondary ? classes.secondaryAdd : '', size === 'small' ? classes.smallAdd : '') }} onClick={onAdd}>
                <PlusIcon />
            </Button>
            <Text className={classNames(classes.quantity, isSecondary ? classes.secondaryQuantity : '')}>{quantity}</Text>
        </div>
    );
});

const HEIGHT = 40;
const BUTTON_BORDER_RADIUS = HEIGHT / 2;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontSize: 24,
        padding: '2px 0',
        position: 'relative',
    },
    subtract: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: BUTTON_BORDER_RADIUS,
        borderBottomLeftRadius: BUTTON_BORDER_RADIUS,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingLeft: 12,
        width: '100%',
        padding: '3px 0',
        borderRight: 0,
    },
    add: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: BUTTON_BORDER_RADIUS,
        borderBottomRightRadius: BUTTON_BORDER_RADIUS,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        paddingRight: 12,
        width: '100%',
        padding: '3px 0',
        borderLeft: 0,
    },
    secondaryAdd: {
        borderRight: `2px solid ${theme.palette.primary.main}`,
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'white',
        padding: '8px 0',
        paddingRight: 10,
    },
    secondarySubtract: {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'white',
        padding: '8px 0',
        paddingLeft: 10,
    },
    smallAdd: {
        padding: '4px 0',
        paddingRight: 10,
    },
    smallSubtract: {
        padding: '4px 0',
        paddingLeft: 10,
    },
    icon: {
        fontSize: 20,
        opacity: 0.54,
    },
    secondaryIcon: {
        color: theme.palette.primary.main,
        opacity: 1,
    },
    quantity: {
        position: 'absolute',
        // top: 0,
        fontSize: 18,
        // lineHeight: HEIGHT,
        color: '#FFFFFF',
    },
    secondaryQuantity: {
        color: theme.palette.primary.main,
    },
}));

type Props = {
    quantity: number;
    variant?: 'primary' | 'secondary';
    onSubtract: () => void;
    onAdd: () => void;
    size?: 'normal' | 'small';
};
