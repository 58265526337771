import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function getManagePlexoCardsUrlApi(request: GetManagePlexoCardsUrlApiRequest): ApiSauceResponse<string> {
    return pidedirectoApiMethod('card/getManagePlexoCardsUrlApi', request);
}

export type GetManagePlexoCardsUrlApiRequest = {
    restaurantId: RestaurantId;
    customerId: CustomerId;
};
