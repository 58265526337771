import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';

export function getDefaultPaymentMethod(restaurant: RestaurantVm, rememberedPaymentMethod?: PaymentMethod): PaymentMethod | undefined {
    if (rememberedPaymentMethod === PaymentMethods.CREDIT_CARD && restaurant.inAppPaymentsEnabled) {
        return PaymentMethods.CREDIT_CARD;
    }
    if (rememberedPaymentMethod === PaymentMethods.CASH && restaurant.cashPaymentsEnabled) {
        return PaymentMethods.CASH;
    }
    return;
}
