import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMenuContainer({ children }: Props): React.ReactElement {
    let timeout: NodeJS.Timeout;

    const restaurantMenuPage = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const isCartDialogOpen = useSelector((state) => state.app.cartDialog.open);
    const animationToCategory = useSelector((state) => state.app.openCategory.animationToCategory);
    const scrollContainerToPosition = useSelector((state) => state.app.scrollContainerToPosition);
    const clearScrollContainerToPosition = useAction(actions.clearScrollContainerToPosition);

    const endAnimationToCategory = useAction(actions.endAnimationToCategory);

    useEffect(() => {
        restaurantMenuPage.current?.addEventListener('scroll', isScrolling);
        return () => {
            restaurantMenuPage.current?.removeEventListener('scroll', isScrolling);
        };
    }, [animationToCategory]);

    useEffect(() => {
        if (scrollContainerToPosition !== undefined) scrollToPosition();
    }, [scrollContainerToPosition]);

    const scrollToPosition = () => {
        restaurantMenuPage.current?.scrollTo({
            top: scrollContainerToPosition,
            behavior: 'smooth',
        });
        clearScrollContainerToPosition();
    };

    const isScrolling = () => {
        if (!animationToCategory) return;

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => endAnimationToCategory(), 100);
    };

    return (
        <div className={isCartDialogOpen ? classes.scrollDisabled : classes.restaurantMenuPage} id='restaurantMenuPage' ref={restaurantMenuPage}>
            {children}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    scrollDisabled: {
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            margin: 0,
            height: '100%',
            overflow: 'hidden',
        },
    },
    restaurantMenuPage: {
        position: 'relative',
        display: 'block',
        height: '100vh',
        overflowY: 'auto',
    },
}));

type Props = {
    children: React.ReactNode;
};
