import { UiLogEventTracker } from '@pidedirecto/ui';
import { createContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { createUserOpenedPageLogEvent } from 'src/services/logEvent/createUserOpenedPageLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function Page({ title, children, className }: Props): React.ReactElement {
    const stackTraceRef = useRef(normalizeUiStackTrace(`ecommerce/page_${title}`));

    const [stackTrace, setStackTrace] = useState(normalizeUiStackTrace(`ecommerce/page_${title}`));

    useEffect(() => {
        createUserOpenedPageLogEvent({ title, stackTrace });
    }, []);

    const addElementToStackTrace = (element: any) => {
        let previousStackTrace = stackTrace;
        if (previousStackTrace !== stackTraceRef.current) previousStackTrace = stackTraceRef.current;

        if (previousStackTrace.includes(element)) return;

        setStackTrace(`${previousStackTrace}/${element}`);
        stackTraceRef.current = `${previousStackTrace}/${element}`;
    };

    const clearStackTrace = () => {
        stackTraceRef.current = normalizeUiStackTrace(`ecommerce/page_${title}`);
        setStackTrace(normalizeUiStackTrace(`ecommerce/page_${title}`));
    };

    return (
        <UiLogEventTracker id={normalizeUiStackTrace(`ecommerce/page_${title}`)} onInteract={createUiInteractionLogEvent}>
            <PageContext.Provider value={{ stackTrace: stackTrace, addElementToStackTrace, clearStackTrace }}>
                <div className={className} onClick={clearStackTrace}>
                    {children}
                </div>
            </PageContext.Provider>
        </UiLogEventTracker>
    );
}

export const PageContext: React.Context<PageContextType> = createContext({
    stackTrace: '',
    addElementToStackTrace: () => {},
    clearStackTrace: () => {},
});

type Props = {
    title: string;
    children: React.ReactNode;
    className?: string;
};

export type PageContextType = {
    stackTrace: string;
    addElementToStackTrace: any;
    clearStackTrace: any;
};
