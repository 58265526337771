import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';
import 'react-phone-number-input/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { Countries } from 'src/constants/Country';
import { AppTheme } from 'src/styles/AppTheme';
import { getCountryCallingCode } from 'src/utils/phoneNumber/getCountryCallingCode';
import { useSelector } from 'src/utils/react/useSelector';

export function FormPrefixPhoneNumberField({ name, label, placeholder, helperText, defaultValue = null, disabled, required }: Props): React.ReactElement {
    const classes = useStyles();
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    const country = useSelector((state) => state.app.restaurant?.country);

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const { ref, ...restProps } = props;
                return (
                    <div>
                        {label && (
                            <label htmlFor='phone-input' className={classes.label}>
                                {label}
                            </label>
                        )}
                        <PhoneInput
                            id='phone-input'
                            countries={Object.values(Countries)}
                            displayInitialValueAsLocalNumber
                            value={value}
                            onChange={onChange}
                            error={!!getError(errors, name)}
                            placeholder={placeholder}
                            disabled={isSubmitting || disabled}
                            defaultCountry={getCountryCallingCode(country)}
                            className={classes.input}
                            {...restProps}
                        />
                        {helperText && <div className={classes.helperText}>{helperText}</div>}
                    </div>
                );
            }}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        '& .PhoneInputInput': {
            borderRadius: 8,
            minHeight: 42,
            border: '1px solid #D9D9D9',
        },
    },
    helperText: {
        marginTop: 4,
        fontFamily: AppTheme.typography.regular,
        fontSize: 10,
        color: '#8E919C',
        textAlign: 'center',
    },
    label: {
        fontFamily: AppTheme.typography.regular,
        marginLeft: 38,
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
};
