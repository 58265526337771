import CryptoJS from 'crypto-js';

export function generatePassword(): string {
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const secretPassphrase = 'c826ce595d939c183b119c0ac4a71b66';
    const key512Bits1000Iterations = CryptoJS.PBKDF2(secretPassphrase, salt, {
        keySize: 128 / 32,
        iterations: 1234,
    });
    return key512Bits1000Iterations.toString();
}
