import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

export const ORDER_ITEM_HEIGHT = 48;

export function OrderDetailsItem({ icon, text, subtext, subtext2, srcImage, classes: classesProp, tag }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <div className={classNames(classes.container, classesProp?.container)}>
            <Text className={classes.text}>{text}</Text>
            <div className={classes.bodyContainer}>
                {icon && <div className={classes.icon}>{icon}</div>}
                <div>
                    {!!subtext && <Text className={classes.subtext}>{subtext}</Text>}
                    {!!subtext2 && <Text className={classes.subtext}>{subtext2}</Text>}
                </div>
            </div>
            {tag && <Text className={classes.tag}>{tag}</Text>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        margin: '12px 0',
    },
    bodyContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    iconContainer: {
        marginRight: 8,
    },
    imageContainer: {
        backgroundColor: '#fafbfb',
        height: 25,
        margin: '5px 10px 10px 0',
        objectFit: 'cover',
    },
    leftIcon: {
        color: theme.palette.primary.main,
    },
    text: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 14,
        color: '#818696',
        marginBottom: 4,
    },
    subtext: {
        fontSize: 12,
        fontWeight: 500,
        color: '#4F586E',
        [theme.breakpoints.up('sm')]: {
            fontSize: 13,
        },
    },
    tag: {
        fontFamily: AppTheme.typography.light,
        backgroundColor: '#F1F1F1',
        fontSize: 12,
        width: 'fit-content',
        padding: '5px 10px',
        borderRadius: 12,
        marginTop: 12,
    },
    icon: {
        display: 'grid',
        placeItems: 'center',
    },
}));

type Props = {
    icon?: any;
    text: string;
    subtext?: string;
    subtext2?: string;
    tag?: string;
    srcImage?: string;
    classes?: {
        container?: string;
    };
};
