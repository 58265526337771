import apisauce from 'apisauce';
import moment from 'moment';
import { dateReviver } from 'src/api/utils/dateReviver';
import { APP_VERSION } from 'src/config/appVersion';
import { store } from 'src/config/store';
import { Apps } from 'src/constants/App';
import { SECONDS } from 'src/constants/TimeUnit';
import { envPIDEDIRECTO_API_URL } from 'src/env/envPIDEDIRECTO_API_URL';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { getSessionId } from 'src/services/device/getSessionId';

export const pidedirectoApi: any = configPidedirectoApiSauce(); // TODO: return api sauce type

function configPidedirectoApiSauce() {
    // TODO: return api sauce type
    const baseURL = envPIDEDIRECTO_API_URL();
    const api = apisauce.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': 'application/vnd.api.autoparsedates+json',
            accept: 'application/vnd.api.autoparsedates+json',
        },
        transformResponse: [(data: any) => JSON.parse(data, dateReviver)],
        timeout: 60 * SECONDS,
    });

    api.addRequestTransform((request) => {
        const state = store.getState();
        request.data.app = Apps.PIDEDIRECTO; // Remove later when app is no longer used backend and start using _app instead
        request.data.appVersion = APP_VERSION; // Remove later when appVersion is no longer used backend and start using _appVersion instead
        request.data._deviceId = getDeviceId();
        request.data._restaurantId = state.app.restaurant?.restaurantId;
        request.data._sessionId = getSessionId();
        request.data._app = Apps.PIDEDIRECTO;
        request.data._pideDirectoVersion = APP_VERSION;
    });

    const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
    if (isDebuggingInChrome) {
        api.addRequestTransform((request) => {
            if (mutedApiPaths.includes(request.url as string)) return;
            console.log(`${request.method?.toUpperCase()} ${baseURL}/${request.url} @ ${moment().format('HH:mm:ss.SSS')}`, request.params ?? request.data);
        });
        api.addMonitor((response) => {
            if (mutedApiPaths.includes(response.config?.url as string)) return;
            console.log(`${response.status ?? ''} <- ${response.config?.url ?? ''} @ ${moment().format('HH:mm:ss.SSS')}`, response.data);
        });
    }

    return api;
}

const mutedApiPaths = ['logEvent/createUiInteractionLogEventApi', 'logEvent/createUiErrorLogEventApi'];
