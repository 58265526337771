import type { ModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';
import { subModifierGroupsToString } from 'src/utils/cart/subModifierGroupsToString';

export function modifierGroupsAndNoteToString(modifierGroups?: Array<ModifierGroupVm> | null, note?: string): string {
    const texts = modifierGroups
        ? modifierGroups
              .flatMap((modifierGroup) => modifierGroup.modifiers)
              .map((modifier) =>
                  modifier.subModifierGroups ? `${modifier.name} x ${modifier.quantity} ${subModifierGroupsToString(modifier.subModifierGroups)}` : `${modifier.name} x ${modifier.quantity}`,
              )
        : [];

    if (note) {
        texts.push(note);
    }

    return texts.join(', ');
}
