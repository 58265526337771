import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { getBackgroundPattern } from 'src/components/getBackgroundPattern';
import { GoogleMapsTrackDriverPosition } from 'src/components/googlemaps/GoogleMapsTrackDriverPosition';
import { TopBar } from 'src/components/TopBar';
import { OrderTypes } from 'src/constants/OrderType';
import { theme } from 'src/styles/theme';
import { transformImageUrlRestaurant } from 'src/utils/image/transformImageUrlRestaurant';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function OngoingOrderBanner(): React.ReactElement | null {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);
    const orderType = useSelector((state) => state.app.orderType);
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const orderIsTakeAwayAndDesktop = orderType === OrderTypes.TAKE_AWAY_ORDER && !smallScreen;
    const showImageBanner = !!restaurant?.imageUrl || !!restaurant?.imageUrlMobile;

    const getBackgroundImage = () => {
        if (restaurant.backgroundImageUrl) return '';
        return getBackgroundPattern();
    };

    const getBackgroundColor = () => {
        if (restaurant.backgroundImageUrl) return 'transparent';
        return theme.palette.primary.main;
    };

    if (!restaurant) return null;

    return (
        <div style={{ backgroundImage: getBackgroundImage(), backgroundColor: getBackgroundColor() }} className={classes.bannerContainer}>
            <div className={classes.imageContainer}>
                {showImageBanner && (
                    <div className={classes.layer}>
                        <img
                            className={classes.image}
                            src={transformImageUrlRestaurant(smallScreen ? (restaurant.imageUrlMobile ?? restaurant.imageUrl) : restaurant.imageUrl)}
                            draggable='false'
                            alt='imageUrl'
                        />
                    </div>
                )}
            </div>
            <TopBar restaurant={restaurant} hideTopBarIcons={true} />
            {!restaurant.imageUrl && <h1 className={classNames(classes.restaurantName, orderIsTakeAwayAndDesktop ? classes.show : '')}>{restaurant.name}</h1>}
            <div className={classes.mapContainer}>
                <GoogleMapsTrackDriverPosition />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        width: '100%',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    show: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    bannerContainer: {
        position: 'fixed',
        height: 250,
        width: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        overflow: 'auto',
    },
    restaurantName: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: theme.typography.bold,
        fontSize: 40,
        color: 'white',
        margin: 0,
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    imageContainer: {
        width: '100%',
        position: 'fixed',
        zIndex: 97,
    },
    layer: {
        position: 'fixed',
        top: 0,
        height: 300,
        zIndex: 95,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        [theme.breakpoints.up('sm')]: {
            height: 250,
        },
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            maxHeight: 300,
            objectFit: 'cover',
        },
    },
}));
