import { makeStyles } from '@material-ui/core/styles';
import { OutlinedDoorbellIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { OrderTypes } from 'src/constants/OrderType';
import { ServiceTypes } from 'src/constants/ServiceType';
import { translate } from 'src/i18n/translate';
import { ChevronDownIcon } from 'src/icons/ChevronDownIcon';
import { DeliveryIcon } from 'src/icons/DeliveryIcon';
import { FoodIcon } from 'src/icons/FoodIcon';
import { PickFoodIcon } from 'src/icons/PickFoodIcon';
import { actions } from 'src/reducers';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useAction } from 'src/utils/react/useAction';
import { useHasClickedOutside } from 'src/utils/react/useHasClickedOutside';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderTypeSelect(): React.ReactElement {
    const classes = useStyles();
    const selectRef = useRef<HTMLDivElement>(null);

    useHasClickedOutside({
        element: selectRef.current,
        onClick: (hasClickedOutside) => {
            if (hasClickedOutside) setOrderTypeOpen(false);
        },
    });

    const [orderTypeOpen, setOrderTypeOpen] = useState(false);

    const orderType = useSelector((state) => state.app.orderType);
    const temporarilyWithoutDelivery = useSelector((state) => state.app.restaurant?.temporarilyWithoutDelivery);
    const serviceType = useSelector((state) => state.app.restaurant?.serviceType);
    const deliveryEnabled = useSelector((state) => state.app.restaurant?.deliveryEnabled);
    const takeAwayEnabled = useSelector((state) => state.app.restaurant?.takeAwayEnabled);
    const eatHereEnabled = useSelector((state) => state.app.restaurant?.eatHereEnabled);
    const roomServiceEnabled = useSelector((state) => state.app.restaurant?.roomServiceEnabled);
    const roomServiceFixedDeliveryCost = useSelector((state) => state.app.restaurant?.roomServiceFixedDeliveryCost);

    const selectOrderType = useAction(actions.selectOrderType);
    const setRoomServiceDeliveryCost = useAction(actions.setRoomServiceDeliveryCost);

    useEffect(() => {
        if (temporarilyWithoutDelivery) {
            selectOrderType(OrderTypes.TAKE_AWAY_ORDER);
        }
    }, []);

    useEffect(() => {
        if (isRoomServiceOrder(orderType)) {
            setRoomServiceDeliveryCost(roomServiceFixedDeliveryCost);
        } else {
            setRoomServiceDeliveryCost(undefined);
        }
    }, [orderType]);

    const toggleOrderTypeOpen = () => {
        setOrderTypeOpen((current) => !current);
    };

    const selectedServiceType = serviceType === ServiceTypes.TABLE_SERVICE ? OrderTypes.TABLE_ORDER : OrderTypes.SELF_SERVICE_ORDER;

    return (
        <div className={classes.infoContainer} onClick={toggleOrderTypeOpen} ref={selectRef}>
            {isDeliveryOrder(orderType) && !temporarilyWithoutDelivery && (
                <div className={classes.infoContainer}>
                    <DeliveryIcon color={'#C1C3C8'} />
                    <Text className={classes.text}>{translate('Delivery')}</Text>
                </div>
            )}
            {isTakeAwayOrder(orderType) && (
                <div className={classes.infoContainer}>
                    <PickFoodIcon color={'#C1C3C8'} />
                    <Text className={classes.text}>{translate('Take Away')}</Text>
                </div>
            )}
            {isEatHereOrder(orderType) && (
                <div className={classes.infoContainer}>
                    <FoodIcon color={'#C1C3C8'} />
                    <Text className={classes.text}>{translate('Eat Here')}</Text>
                </div>
            )}
            {isRoomServiceOrder(orderType) && (
                <div className={classes.infoContainer}>
                    <OutlinedDoorbellIcon size={16} color={'#C1C3C8'} />
                    <Text className={classes.text}>{translate('Room service')}</Text>
                </div>
            )}
            <div className={orderTypeOpen ? classes.arrowIconUp : classes.arrowIconDown}>
                <ChevronDownIcon color={'#4F586E'} />
            </div>
            {orderTypeOpen && (
                <div className={classes.orderTypeSelect}>
                    {!isTakeAwayOrder(orderType) && takeAwayEnabled && (
                        <Button
                            classes={{ button: classes.orderTypeListItem }}
                            onClick={() => {
                                selectOrderType(OrderTypes.TAKE_AWAY_ORDER);
                            }}
                        >
                            <PickFoodIcon color={'white'} />
                            {translate('Take Away')}
                        </Button>
                    )}
                    {!isDeliveryOrder(orderType) && deliveryEnabled && !temporarilyWithoutDelivery && (
                        <Button
                            classes={{ button: classes.orderTypeListItem }}
                            onClick={() => {
                                selectOrderType(OrderTypes.DELIVERY_ORDER);
                            }}
                        >
                            <DeliveryIcon color={'white'} />
                            {translate('Delivery')}
                        </Button>
                    )}

                    {!isRoomServiceOrder(orderType) && roomServiceEnabled && (
                        <Button classes={{ button: classes.orderTypeListItem }} onClick={() => selectOrderType(OrderTypes.ROOM_SERVICE_ORDER)}>
                            <OutlinedDoorbellIcon color={'#ffffff'} />
                            {translate('Room service')}
                        </Button>
                    )}
                    {orderType !== selectedServiceType && eatHereEnabled && (
                        <Button
                            classes={{ button: classes.orderTypeListItem }}
                            onClick={() => {
                                selectOrderType(selectedServiceType);
                            }}
                        >
                            <FoodIcon color={'white'} />
                            {translate('Eat Here')}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    infoContainer: {
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    orderTypeSelect: {
        position: 'absolute',
        right: 0,
        top: '120%',
        backgroundColor: theme.palette.primary.main,
        paddingTop: 12,
        paddingBottom: 12,
        borderRadius: 12,
        width: 'fit-content',
    },
    orderTypeListItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: '6px 12px',
        color: 'white',
        whiteSpace: 'nowrap',
        fontSize: '0.875rem',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}aa`,
        },
    },
    arrowIconDown: {
        transform: 'rotate(0deg)',
        transition: '400ms',
    },
    arrowIconUp: {
        transform: 'rotate(-180deg)',
        transition: '400ms',
    },
    text: {
        [theme.breakpoints.down('xs')]: {
            width: 'min-content',
        },
    },
}));
