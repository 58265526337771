import { transformImageUrl } from 'src/utils/image/transformImageUrl';

export function transformImageUrlRestaurant(imageUrl?: string): string {
    return transformImageUrl(imageUrl, getImageWidthBasedOnScreenWidth());
}

export function getImageWidthBasedOnScreenWidth(): number {
    if (window.screen.width <= 1920) {
        return 1920;
    }
    return 2560;
}
