import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeAddressApi } from 'src/api/pidedirecto/address/changeAddressApi';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createSelectAddressLogEvent } from 'src/services/logEvent/createSelectAddressLogEvent';
import { alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport } from 'src/utils/alert/alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport';
import { logError } from 'src/utils/log/logError';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function EditNonGeoLocatedAddressDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const open = useSelector((state) => state.app.editNonGeoLocatedAddressDialog.open);
    const editAddress = useSelector((state) => state.app.editNonGeoLocatedAddressDialog.address);
    const customerId = useSelector((state) => state.app.customerId);

    const closeEditNonGeoLocatedAddressDialog = useAction(actions.closeEditNonGeoLocatedAddressDialog);
    const changeAddress = useAction(actions.changeAddress);
    const selectAddress = useAction(actions.selectAddress);

    const [loading, setLoading] = useState(false);

    const onSubmit = async (form: any) => {
        const request = {
            addressId: editAddress.addressId,
            customerId: customerId,
            street: form.street,
            other: form.other,
            instructions: form.instructions,
        } as const;
        setLoading(true);
        const response = await changeAddressApi(request);
        setLoading(false);
        if (!response.ok) {
            logError('Failed to change address', { request, response });
            alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport();
            return;
        }
        const address = response.data;
        changeAddress(address);
        selectAddress(address);
        createSelectAddressLogEvent({
            address: address[0].street,
        });
        closeEditNonGeoLocatedAddressDialog();
    };

    useEffect(() => {
        if (open) {
            form.reset({
                street: editAddress.street || '',
                other: editAddress.other || '',
                instructions: editAddress.instructions || '',
            });
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={closeEditNonGeoLocatedAddressDialog} loading={loading} title={translate('Edit Address')} actionButtonFormId={'form'} actionText={translate('Next')}>
            <Form form={form} onSubmit={onSubmit} className={classes.form} id={'form'}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='street' label={translate('Street address and building number')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='other' label={translate('Staircase / apartment / floor / other')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='instructions' label={translate('Instructions for carrier')} multiline rowsMax={3} />
                        </Grid>
                    </Grid>
                </div>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContent: {
        padding: theme.spacing(2),
    },
    inputContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,0.23)',
        padding: '12px 10px',
        '& > div': {
            width: '100%',
        },
    },
    inputContainerFocused: {
        border: `2px solid ${theme.palette.primary.main}`,
        padding: '11px 9px',
    },
    iconTarget: {
        cursor: 'pointer',
        marginLeft: 5,
    },
}));
