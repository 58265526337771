import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { BottomDialogCenteredButton } from 'src/components/BottomDialogCenteredButton';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormPercentNumberField } from 'src/components/form/FormPercentNumberField';
import { FormTipTypeSelect } from 'src/components/form/FormTipTypeSelect';
import { Input } from 'src/components/Input';
import { TipTypes } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { roundDigits } from 'src/utils/number/roundDigits';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isAmountTip } from 'src/utils/tip/isAmountTip';
import { isPercentTip } from 'src/utils/tip/isPercentTip';

export function OtherDriverTipDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [otherDriverTip, setOtherDriverTip] = useState<string>();

    const open = useSelector((state) => state.app.otherDriverTipDialog.open);
    const pideDirectoTipAsPercentageEnabled = useSelector((state) => state.app.restaurant?.pideDirectoTipAsPercentageEnabled);
    const subtotalAfterDiscount = useSelector((state) => state.app.payment?.subtotalAfterDiscount);

    const closeOtherDriverTipDialog = useAction(actions.closeOtherDriverTipDialog);
    const setDriverTip = useAction(actions.setDriverTip);

    const tipType = form.watch('tipType') ?? TipTypes.AMOUNT;

    useEffect(() => {
        if (open) {
            setOtherDriverTip(undefined);
        }
    }, [open]);

    const exitDialogWithoutSaving = () => {
        setDriverTip();
        closeOtherDriverTipDialog();
    };

    const addTip = (form: any) => {
        if (isAmountTip(form.tipType)) {
            setDriverTip(form.amount);
            closeOtherDriverTipDialog();
            return;
        }

        const driverTip = roundDigits(BigNumber(subtotalAfterDiscount).multipliedBy(form.percent));
        setDriverTip(driverTip);
        closeOtherDriverTipDialog();
    };

    if (pideDirectoTipAsPercentageEnabled) {
        return (
            <Dialog open={open} onClose={exitDialogWithoutSaving} title={translate('Add a Custom Driver Tip')} actionButtonFormId='form' actionText={translate('Confirm')}>
                <Form form={form} id='form' onSubmit={addTip}>
                    <div className={classes.formContainer}>
                        <FormTipTypeSelect name={'tipType'} label={translate('Tip type')} required defaultValue={TipTypes.AMOUNT} classes={{ button: classes.tipTypeSelect }} />
                        {isAmountTip(tipType) && <FormCurrencyNumberStringField name='amount' label={translate('Tip')} required />}
                        {isPercentTip(tipType) && <FormPercentNumberField name='percent' label={translate('Tip')} required />}
                    </div>
                </Form>
            </Dialog>
        );
    }

    return (
        <Dialog open={open} onClose={exitDialogWithoutSaving} title={translate('Add a Custom Driver Tip')}>
            <Input
                name={'driverTip'}
                label={translate('Type your custom driver tip')}
                autoFocus
                classes={{ input: classes.input, label: classes.label }}
                leftIcon={'$'}
                InputComponent={NumberFormatCustom}
                value={otherDriverTip}
                onChange={(value: string) => {
                    setOtherDriverTip(value.replace(/[^0-9]/g, ''));
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                    if ((e.key || e.code) === 'Enter') {
                        setDriverTip(otherDriverTip);
                        closeOtherDriverTipDialog();
                    }
                }}
                helperText={translate('Your tip will be fully given to the driver')}
            />
            <BottomDialogCenteredButton
                text={translate('Confirm')}
                onClick={() => {
                    setDriverTip(otherDriverTip);
                    closeOtherDriverTipDialog();
                }}
            />
        </Dialog>
    );
}

function NumberFormatCustom({ inputRef, onChange, ...other }: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={({ value }) => {
                onChange(value);
            }}
            // allowedDecimalSeparators={[',', '.']}
            thousandSeparator
            isNumericString
            decimalScale={2}
            // prefix="$"
        />
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'center',
        // fontFamily: '"Courier New", Courier, monospace',
        color: AppTheme.text.light,
        fontSize: 35,
        height: 40,
        margin: 0,
        textTransform: 'uppercase',
        '&[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    label: {
        fontSize: '1rem',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        paddingTop: 10,
    },
    tipTypeSelect: {
        width: '100%',
    },
}));
