export function formatTimeInMinutes(timeInMinutes: number): string {
    if (timeInMinutes < 60) return `${timeInMinutes}min`;

    let hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;

    if (hours < 24) {
        if (minutes) return `${hours}h ${minutes}min`;
        return `${hours}h`;
    }

    const days = Math.floor(hours / 24);
    hours = hours % 24;

    return `${days}d ${hours}h ${minutes}min`;
}
