export const NegativeSurveyAnswers = Object.freeze({
    FOOD_WAS_COLD: 'FOOD_WAS_COLD',
    MISSING_OR_INCORRECT_ITEMS: 'MISSING_OR_INCORRECT_ITEMS',
    FOOD_WAS_NOT_PREPARED: 'FOOD_WAS_NOT_PREPARED',
    FOOD_ARRIVED_LATE: 'FOOD_ARRIVED_LATE',
    CONFUSED_PICK_UP_PLACE: 'CONFUSED_PICK_UP_PLACE',
    BAD_SERVICE: 'BAD_SERVICE',
});

export type NegativeSurveyAnswer = (typeof NegativeSurveyAnswers)[keyof typeof NegativeSurveyAnswers];
