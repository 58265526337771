import { translate } from 'src/i18n/translate';

export function alertKnownErrorOrSomethingWentWrong(response: any): void {
    if (!response) {
        alert(translate('Something went wrong'));
        return;
    }
    if (response?.data?.message) {
        alert(response.data.message);
        return;
    }
    alert(`${translate('Something went wrong')} (${response.status ?? ''}: ${response.problem ?? ''})`);
}
