import * as React from 'react';

export function CancelIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0ZM9.88308 8.57846C9.79857 8.48777 9.69666 8.41503 9.58343 8.36458C9.4702 8.31413 9.34797 8.287 9.22403 8.28481C9.10009 8.28262 8.97697 8.30542 8.86203 8.35185C8.74709 8.39828 8.64268 8.46738 8.55503 8.55503C8.46738 8.64268 8.39828 8.74709 8.35185 8.86203C8.30542 8.97697 8.28262 9.10009 8.28481 9.22403C8.287 9.34797 8.31413 9.4702 8.36458 9.58343C8.41503 9.69666 8.48777 9.79857 8.57846 9.88308L10.6954 12L8.57846 14.1169C8.48777 14.2014 8.41503 14.3033 8.36458 14.4166C8.31413 14.5298 8.287 14.652 8.28481 14.776C8.28262 14.8999 8.30542 15.023 8.35185 15.138C8.39828 15.2529 8.46738 15.3573 8.55503 15.445C8.64268 15.5326 8.74709 15.6017 8.86203 15.6482C8.97697 15.6946 9.10009 15.7174 9.22403 15.7152C9.34797 15.713 9.4702 15.6859 9.58343 15.6354C9.69666 15.585 9.79857 15.5122 9.88308 15.4215L12 13.3046L14.1169 15.4215C14.2014 15.5122 14.3033 15.585 14.4166 15.6354C14.5298 15.6859 14.652 15.713 14.776 15.7152C14.8999 15.7174 15.023 15.6946 15.138 15.6482C15.2529 15.6017 15.3573 15.5326 15.445 15.445C15.5326 15.3573 15.6017 15.2529 15.6482 15.138C15.6946 15.023 15.7174 14.8999 15.7152 14.776C15.713 14.652 15.6859 14.5298 15.6354 14.4166C15.585 14.3033 15.5122 14.2014 15.4215 14.1169L13.3046 12L15.4215 9.88308C15.5122 9.79857 15.585 9.69666 15.6354 9.58343C15.6859 9.4702 15.713 9.34797 15.7152 9.22403C15.7174 9.10009 15.6946 8.97697 15.6482 8.86203C15.6017 8.74709 15.5326 8.64268 15.445 8.55503C15.3573 8.46738 15.2529 8.39828 15.138 8.35185C15.023 8.30542 14.8999 8.28262 14.776 8.28481C14.652 8.287 14.5298 8.31413 14.4166 8.36458C14.3033 8.41503 14.2014 8.48777 14.1169 8.57846L12 10.6954L9.88308 8.57846Z'
                fill={color}
            />
        </svg>
    );
}

type Props = {
    color: string;
};
