import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

/**
 *
 * @param {string} [children] - content to be rendered
 * @param {string} [className] - class for the container
 * @returns {React.Node}
 */
export function DialogActions({ children, className }: Props): React.ReactElement {
    const classes = useStyles();

    return <div className={classNames(classes.container, className)}>{children}</div>;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        marginTop: 10,
        width: '100%',
    },
}));

type Props = {
    children: React.ReactNode;
    className?: string;
};
