import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Apps } from 'src/constants/App';
import type { AuthenticationType } from 'src/constants/AuthenticationType';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { RestaurantId } from 'src/types/Id';
import { isPasswordAuthentication } from 'src/utils/authentication/isPasswordAuthentication';
import { generatePassword } from 'src/utils/crypto/generatePassword';
import { logError } from 'src/utils/log/logError';
import { logInfo } from 'src/utils/log/logInfo';
import { logWarning } from 'src/utils/log/logWarning';

// Enable aws debug logs by uncommenting line below
// window.LOG_LEVEL = 'DEBUG';
const debug = false;
export class AwsFacade {
    static memorizedCognitoUser: any;

    static async refreshJwtTokensAndCheckForNetworkError() {
        // const cognitoUser = await Auth.currentAuthenticatedUser();
        // TODO: Upgrade AWS Amplify and make sure below code is not needed,
        //  it is a workaround since currentAuthenticatedUser does not throw NetWorkError
        //  when one hour has passed and JWT token needs to be refreshed,
        //  can be tested by changing time on computer.
        //  Code below is taken from the Auth.currentAuthenticatedUser source code
        let cognitoUser: CognitoUser | undefined;
        try {
            cognitoUser = await Auth.currentUserPoolUser();
        } catch (e: any) {
            if (e && e.code === 'NetworkError') {
                throw e;
            }
            throw 'not authenticated';
        }
        const currentSession = await Auth.currentSession();
        await new Promise((res: (result: Promise<undefined> | undefined) => void, rej: (error?: any) => void) => {
            cognitoUser?.refreshSession(currentSession.getRefreshToken(), (err) => {
                if (err) rej(err);
                res(undefined);
            });
        });
    }

    static async isSignedIn(forceRefreshToken?: boolean): Promise<IsSignedInResult> {
        try {
            if (forceRefreshToken) {
                await AwsFacade.refreshJwtTokensAndCheckForNetworkError();
            }
            const cognitoUser = await Auth.currentUserPoolUser();
            return IsSignedInResults.SIGNED_IN;
            // const idTokenPayloadAttributes = cognitoUser?.signInUserSession?.idToken?.payload; // Use user attributes from idToken payload instead of attributes since they are up to date, attributes do not update, see https://github.com/aws-amplify/amplify-js/issues/2534
            // if (
            //     cognitoUser?.attributes &&
            //     idTokenPayloadAttributes &&
            //     cognitoUser.username &&
            //     idTokenPayloadAttributes.email &&
            //     idTokenPayloadAttributes.email.length > 0 &&
            //     idTokenPayloadAttributes.phone_number &&
            //     idTokenPayloadAttributes.phone_number.length > 0 &&
            //     idTokenPayloadAttributes.given_name &&
            //     idTokenPayloadAttributes.given_name.length > 0 &&
            //     idTokenPayloadAttributes.family_name &&
            //     idTokenPayloadAttributes.family_name.length > 0
            // ) {
            //     return IsSignedInResults.SIGNED_IN;
            // }
            // return IsSignedInResults.SIGNED_OUT;
        } catch (e: any) {
            if (e !== 'not authenticated' && e !== 'No current user' && e.code !== 'NetworkError') {
                logError('[Investigate]] Failed to sign in on app start', { e });
            }
            if ((e as any).code === 'NetworkError') {
                return IsSignedInResults.NETWORK_ERROR;
            }
            return IsSignedInResults.SIGNED_OUT;
        }
    }

    static async getSignedInCognitoCustomer(): Promise<CognitoCustomer> {
        try {
            const cognitoUser = await Auth.currentUserPoolUser();
            const idTokenPayloadAttributes = cognitoUser?.signInUserSession?.idToken?.payload; // Use user attributes from idToken payload instead of attributes since they are up to date, attributes do not update, see https://github.com/aws-amplify/amplify-js/issues/2534
            return {
                awsCognitoUsername: idTokenPayloadAttributes.sub,
                mobileNumber: idTokenPayloadAttributes?.phone_number,
                email: idTokenPayloadAttributes?.email,
                firstName: idTokenPayloadAttributes?.given_name,
                lastName: idTokenPayloadAttributes?.family_name,
            };
        } catch (e: any) {
            return {} as any;
        }
    }

    static async signOut() {
        await Auth.signOut();
    }

    static async requestSignUpSignIn({ username, authenticationType, restaurantId }: RequestSignInParams): Promise<Response> {
        logInfo('Requesting sign up/sign in', { username });
        try {
            if (debug) console.trace('Auth.signUp({ username: , password }');
            await Auth.signUp({ username, password: generatePassword() });
        } catch (e: any) {
            if (debug) console.trace('Auth.signUp({ username, password } error', { e });
            if (e.code !== 'UsernameExistsException') {
                if (e.code === 'LimitExceededException') {
                    logWarning('User tried too sign in/sign up too many times', { e });
                    return ATTEMPT_LIMIT_EXCEEDED_ERROR;
                }

                if (e.code === 'NotAuthorizedException') {
                    logInfo('User tried too sign in/sign up when user was disabled', { e });
                    return USER_DISABLED_ERROR;
                }

                if (e.code === 'NetworkError') {
                    logInfo('User has an issue with the network when user tried too sign in/sign up', { e });
                    return NETWORK_ERROR;
                }

                logError('Failed to request sign up', { e, username });
                return UNKNOWN_ERROR;
            }
        }

        try {
            if (debug) console.log(`Auth.signIn(${username}, null)`);
            const signInResponse = await Auth.signIn(username, null as any);
            if (debug) console.log(`Auth.signIn(${username}, null) signInResponse`, signInResponse);
            if (debug) console.log(`Auth.sendCustomChallengeAnswer(signInResponse, ${authenticationType}, { authenticationType: "${authenticationType}" }})`);
            const sendCustomChallengeAnswerResponse = await Auth.sendCustomChallengeAnswer(signInResponse, authenticationType, {
                authenticationType,
                restaurantId,
                app: Apps.PIDEDIRECTO,
                deviceId: getDeviceId(),
            });
            if (debug)
                console.log(
                    `Auth.sendCustomChallengeAnswer(signInResponse, ${authenticationType}, { authenticationType: "${authenticationType}" }}) sendCustomChallengeAnswerResponse`,
                    sendCustomChallengeAnswerResponse,
                );
            AwsFacade.memorizedCognitoUser = sendCustomChallengeAnswerResponse;
            return successResponse();
        } catch (e: any) {
            if (debug) console.trace('Auth.signIn(username) or Auth.sendCustomChallengeAnswer(signInResponse, ${authenticationType}, { authenticationType: "${authenticationType}" }}) error', { e });

            if (e.code === 'NotAuthorizedException') {
                logInfo('User tried too sign in/sign up too many times or tried too sign in/sign up when user was disabled', { e });
                return ATTEMPT_LIMIT_EXCEEDED_ERROR;
            }

            if (e.code === 'LimitExceededException') {
                logWarning('User tried too sign in/sign up too many times', { e });
                return ATTEMPT_LIMIT_EXCEEDED_ERROR;
            }

            if (e.code === 'NetworkError') {
                logInfo('User has an issue with the network when user tried too sign in/sign up', { e });
                return NETWORK_ERROR;
            }

            if (e.message.includes('User does not exists')) {
                return USER_DOES_NOT_EXISTS_ERROR;
            }

            logError('Failed to request sign in', { e, username });

            if (isPasswordAuthentication(authenticationType)) {
                return UNKNOWN_ERROR_PROBABLY_NOT_CONFIGURED_PASSWORD;
            }

            return UNKNOWN_ERROR;
        }
    }

    static async verifySignIn(username: string, verificationCode: string, authenticationType: AuthenticationType): Promise<Response> {
        logInfo('Verifying sign in');
        try {
            if (debug) console.trace(`Auth.sendCustomChallengeAnswer(AwsFacade.memorizedCognitoUser, ${verificationCode}, { ${authenticationType} })`);
            const response = await Auth.sendCustomChallengeAnswer(AwsFacade.memorizedCognitoUser, verificationCode, {
                authenticationType,
                app: Apps.PIDEDIRECTO,
                deviceId: getDeviceId(),
            });
            if (debug) console.trace(`Auth.sendCustomChallengeAnswer(AwsFacade.memorizedCognitoUser, ${verificationCode}, { ${authenticationType} }) response`, response);
            AwsFacade.memorizedCognitoUser = response;
            if (!response.signInUserSession) {
                if (isPasswordAuthentication(authenticationType)) return WRONG_PASSWORD_ERROR;
                return WRONG_CODE_ERROR;
            }
            return successResponse();
        } catch (e: any) {
            if (debug) console.trace('Auth.sendCustomChallengeAnswer(username, verificationCode, password) error', { e });
            if (e.code === 'CodeMismatchException') {
                if (isPasswordAuthentication(authenticationType)) {
                    logInfo('Wrong password was entered (verifySignIn)', { e });
                    return WRONG_PASSWORD_ERROR;
                }
                logInfo('Wrong verification code was entered (verifySignIn)', { e });
                return WRONG_CODE_ERROR;
            }

            if (e.code === 'NotAuthorizedException') {
                logInfo('User tried too sign in/sign up too many times or tried too sign in/sign up when user was disabled', { e });
                return ATTEMPT_LIMIT_EXCEEDED_ERROR;
            }

            if (e.code === 'LimitExceededException') {
                logWarning('User tried too sign in/sign up too many times (verifySignIn)', { e });
                return ATTEMPT_LIMIT_EXCEEDED_ERROR;
            }

            if (e.code === 'NetworkError') {
                logInfo('User has an issue with the network when user tried too verify sign in', { e });
                return NETWORK_ERROR;
            }

            logError('Failed to verify sign in (2)', { e });
            return UNKNOWN_ERROR_FAILED_TO_VERIFY_CODE;
        }
    }

    static async setMobileNumber(mobileNumber: string): Promise<Response> {
        logInfo('Setting mobile number', { mobileNumber });
        try {
            const user = await Auth.currentUserPoolUser();
            await Auth.updateUserAttributes(user, { phone_number: mobileNumber });
            await Auth.currentAuthenticatedUser({ bypassCache: true });
            return successResponse();
        } catch (e: any) {
            // if (e.code === 'UserNotFoundException') { // Could happen in test TODO: Investigate if needed
            //     yield signOut();
            //     return;
            // }

            if (e.code === 'NetworkError') {
                logInfo('User has an issue with the network when user tried too set mobile number', { e });
                return NETWORK_ERROR;
            }

            logError('Failed to set mobile number', { e, mobileNumber });
            return UNKNOWN_ERROR_FAILED_TO_SAVE;
        }
    }

    static async setEmail(email: string): Promise<Response> {
        logInfo('Setting email', { email });
        try {
            const user = await Auth.currentUserPoolUser();
            await Auth.updateUserAttributes(user, { email: email });
            await Auth.currentAuthenticatedUser({ bypassCache: true });
            return successResponse();
        } catch (e: any) {
            // if (e.code === 'UserNotFoundException') { // Could happen in test TODO: Investigate if needed
            //     yield signOut();
            //     return;
            // }

            if (e.code === 'NetworkError') {
                logInfo('User has an issue with the network when user tried too set email', { e });
                return NETWORK_ERROR;
            }

            logError('Failed to set email', { e, email });
            return UNKNOWN_ERROR_FAILED_TO_SAVE;
        }
    }

    static async setName(firstName: string, lastName: string): Promise<Response> {
        logInfo('Setting name', { firstName, lastName });
        try {
            const user = await Auth.currentUserPoolUser();
            await Auth.updateUserAttributes(user, {
                given_name: firstName,
                family_name: lastName,
            });
            await Auth.currentAuthenticatedUser({ bypassCache: true });
            return successResponse();
        } catch (e: any) {
            // if (e.code === 'UserNotFoundException') { // Could happen in test TODO: Investigate if needed
            //     yield signOut();
            //     return;
            // }

            if (e.code === 'NetworkError') {
                logInfo('User has an issue with the network when user tried too set name', { e });
                return NETWORK_ERROR;
            }

            logError('Failed to set name', { e, firstName, lastName });
            return UNKNOWN_ERROR_FAILED_TO_SAVE;
        }
    }
}

function successResponse(): Response {
    return {
        success: true,
    };
}

function errorResponse(errorType: string, message: string): Response {
    return {
        success: false,
        error: {
            errorType,
            message,
        },
    };
}

const NETWORK_ERROR = errorResponse('NETWORK_ERROR', 'Network error, please check your connection.');
const ATTEMPT_LIMIT_EXCEEDED_ERROR = errorResponse('ATTEMPT_LIMIT_EXCEEDED', 'You tried too many times! Wait some time before trying again.');
const USER_DISABLED_ERROR = errorResponse('USER_DISABLED', 'Failed to sign in/sign up, please contact support.');
const WRONG_CODE_ERROR = errorResponse('WRONG_VERIFICATION_CODE_ENTERED', 'Wrong verification code entered, please try again.');
const WRONG_PASSWORD_ERROR = errorResponse('WRONG_PASSWORD_ENTERED', 'Wrong password entered, please try again.');
const USER_DOES_NOT_EXISTS_ERROR = errorResponse('USER_DOES_NOT_EXISTS_ERROR', 'User does not exists, try to sign in with code.');
const UNKNOWN_ERROR = errorResponse('UNKNOWN', 'Failed to sign in/sign up, please try again.');
const UNKNOWN_ERROR_PROBABLY_NOT_CONFIGURED_PASSWORD = errorResponse('UNKNOWN', 'Failed to sign in/sign up, verify your account has a password. Otherwise, sign in with code and create one.');
const UNKNOWN_ERROR_FAILED_TO_VERIFY_CODE = errorResponse('UNKNOWN', 'Failed to verify code, please try again.');
const UNKNOWN_ERROR_FAILED_TO_SAVE = errorResponse('UNKNOWN', 'Failed to save, please try again.');

export type CognitoCustomer = {
    awsCognitoUsername?: string;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
};

type Response = SuccessResponse | ErrorResponse;

type SuccessResponse = {
    success: true;
    error?: undefined;
};

type ErrorResponse = {
    success: false;
    error: {
        errorType: string;
        message: string;
    };
};

type RequestSignInParams = {
    username: string;
    authenticationType: AuthenticationType;
    restaurantId: RestaurantId;
};

export const IsSignedInResults = Object.freeze({
    SIGNED_IN: 'SIGNED_IN',
    SIGNED_OUT: 'SIGNED_OUT',
    NETWORK_ERROR: 'NETWORK_ERROR',
});

export type IsSignedInResult = (typeof IsSignedInResults)[keyof typeof IsSignedInResults];
