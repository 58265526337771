import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectTableDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const open = useSelector((state) => state.app.selectTableDialog.open);
    const closeSelectTableDialog = useAction(actions.closeSelectTableDialog);
    const selectTable = useAction(actions.selectTable);

    const onSubmit = async (form: any) => {
        selectTable(form.table);
        closeSelectTableDialog();
    };

    return (
        <Dialog open={open} onClose={closeSelectTableDialog} title={translate('Enter the table number')} actionButtonFormId={'form'} actionText={translate('Next')}>
            <Form form={form} onSubmit={onSubmit} className={classes.form} id={'form'}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='table' label={translate('Table')} autoFocus capitalize />
                        </Grid>
                    </Grid>
                </div>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContent: {
        padding: theme.spacing(2),
    },
}));
