import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import type { RestaurantChainVm, RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { getRestaurantColorsInLocalStorage } from 'src/localStorage/getRestaurantColorsInLocalStorage';

export function createRestaurantMuiTheme(restaurant?: RestaurantVm | RestaurantChainVm): MuiTheme {
    let colors: Array<string> | undefined;
    console.log('running createRestaurantMuiTheme');
    if (getRestaurantColorsInLocalStorage()) {
        try {
            colors = getRestaurantColorsInLocalStorage();
        } catch (e: any) {
            // use default colors, this is here just for safety since we did save strange values in localstorage for a while
        }
    } else if ((restaurant as RestaurantVm)?.restaurantColors) {
        colors = (restaurant as RestaurantVm).restaurantColors;
    } else if ((restaurant as RestaurantChainVm)?.chainColors) {
        colors = (restaurant as RestaurantChainVm).chainColors;
    }
    return createMuiTheme({
        palette: {
            primary: {
                main: colors?.[0] ?? '#1a9aa0',
            },
            secondary: {
                main: colors?.[1] ?? '#68CCCA',
            },
        },
    } as ThemeOptions);
}

export type MuiTheme = {
    palette: {
        primary: {
            main: string;
            dark: string;
        };
        text: {
            disabled: string;
        };
    };
    spacing: (arg1: number) => number;
    breakpoints: {
        down: (arg1: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => string;
    };
};

// add TypeScript support for custom theme props by extending Mui types, see https://v4.mui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createTypography' {
    interface Typography {
        bold: string;
        semiBold: string;
        regular: string;
        medium: string;
        light: string;
    }

    interface TypographyOptions {
        bold: string;
        semiBold: string;
        regular: string;
        medium: string;
        light: string;
    }
}

// add TypeScript support for custom theme props by extending Mui types, see https://v4.mui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createPalette' {
    export interface Palette {
        outlined: Outlined;
        gray: Gray;
        green: Green;
        blue: Blue;
    }

    interface PaletteOptions {
        outlined: Outlined;
        gray: Gray;
        green: Green;
        blue: Blue;
    }

    interface PaletteColor {
        mainHover: string;
    }

    interface SimplePaletteColorOptions {
        mainHover: string;
    }

    type Outlined = {
        mainHover: string;
    };

    type Gray = {
        light: string;
        dark: string;
        one: string;
        two: string;
        three: string;
        four: string;
        five: string;
        six: string;
    };

    type Green = {
        one: string;
        two: string;
        three: string;
        four: string;
    };

    type Blue = {
        one: string;
        two: string;
        three: string;
        four: string;
    };
}
