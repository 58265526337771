import { Countries, Country } from 'src/constants/Country';

export function getCountryCallingCode(value: undefined): undefined;
export function getCountryCallingCode(value: Country): Country;
export function getCountryCallingCode(value: Country | undefined): string | undefined;

export function getCountryCallingCode(value: Country | undefined): string | undefined {
    if (value === undefined || value === null) return value as any;
    if (value === Countries.MX) return 'MX';
    if (value === Countries.US) return 'US';
    if (value === Countries.SE) return 'SE';
    if (value === Countries.UY) return 'UY';
    if (value === Countries.AR) return 'AR';
    if (value === Countries.BR) return 'BR';
    if (value === Countries.PE) return 'PE';
    if (value === Countries.CL) return 'CL';
    if (value === Countries.PY) return 'PY';
    if (value === Countries.CO) return 'CO';
    if (value === Countries.BO) return 'BO';
    if (value === Countries.CR) return 'CR';
    if (value === Countries.CU) return 'CU';
    if (value === Countries.EC) return 'EC';
    if (value === Countries.SV) return 'SV';
    if (value === Countries.GT) return 'GT';
    if (value === Countries.NI) return 'NI';
    if (value === Countries.PA) return 'PA';
    if (value === Countries.DO) return 'DO';
    if (value === Countries.HN) return 'HN';

    throw Error(`Unsupported country=${value}`);
}
