import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

export function BottomDialogCenteredButton({ Icon, text, subtext, disabled, loading, onClick, type, size = 'md' }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Button classes={{ button: classes.button }} onClick={onClick} disabled={disabled || loading} type={type} loading={loading}>
            <div className={classes.contentContainer}>
                {!!Icon && <Icon className={classNames(classes.iconColor, disabled || loading ? classes.disabled : undefined)} />}
                {!!text && !loading && (
                    <div className={classes.textContainer}>
                        <div className={classes.text}>{text}</div>
                        {!!subtext && <div className={classNames(classes.subtext, disabled || loading ? classes.disabledSubtext : undefined)}>{subtext}</div>}
                    </div>
                )}
            </div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        top: 24,
        left: -24,
        width: 'calc(100% + 48px)',
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 8,
        padding: 8,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 22,
    },
    disabled: {
        backgroundColor: `${AppTheme.text.colorDisabled} !important`,
    },
    disabledSubtext: {
        color: '#D0D0D0 !important',
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    subtext: {
        fontSize: 12,
    },
    iconColor: {
        color: 'white',
    },
}));

type Props = {
    Icon?: React.ComponentType<any>;
    text?: string;
    subtext?: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => any;
    type?: 'submit';
    size?: 'md' | 'lg';
};
