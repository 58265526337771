import { BigNumber } from 'bignumber.js';
import type { GiftVm } from 'src/types/GiftVm';
import { validGifts } from 'src/utils/gift/filter/validGifts';
import { sum } from 'src/utils/reduce/sum';

export function sumValidGifts(gifts?: Array<GiftVm>): BigNumber {
    if (!gifts) {
        return BigNumber(0);
    }
    return gifts
        .filter(validGifts)
        .map((gift) => BigNumber(gift.credits).minus(gift.usedCredits || 0))
        .reduce(sum, BigNumber(0));
}
