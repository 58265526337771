import CryptoJS from 'crypto-js';
import RSAKey from 'react-native-rsa';
import { envENCRYPTION_DATA_IN_TRANSIT_PUBLIC_KEY } from 'src/env/envENCRYPTION_DATA_IN_TRANSIT_PUBLIC_KEY';

// Uses crypto-js since node crypto module may not be defined on client
// Uses react-native-rsa since node crypto module may not be defined on client
export function encryptDataInTransit(plaintext: string | Record<any, any>): string {
    if (plaintext === undefined) {
        throw new Error('plaintext cannot be undefined');
    }
    if (plaintext === null) {
        throw new Error('plaintext cannot be null');
    }

    return encryptAsymmetric(plaintext, envENCRYPTION_DATA_IN_TRANSIT_PUBLIC_KEY());
}

function encryptAsymmetric(plaintext: string | Record<any, any>, publicKey: string): string {
    const aes = encryptAes(JSON.stringify(plaintext));
    const rsaEncryptedAesKey = encryptRsa(aes.key, publicKey);

    return [rsaEncryptedAesKey, aes.cipher, aes.iv].join('$');
}

function encryptAes(plaintext: string) {
    const key = CryptoJS.lib.WordArray.random(32);
    const iv = CryptoJS.lib.WordArray.random(12);
    const cipher = CryptoJS.AES.encrypt(plaintext, key, { iv: iv });

    return {
        key: CryptoJS.enc.Base64.stringify(key),
        iv: CryptoJS.enc.Base64.stringify(iv),
        cipher: cipher.toString(),
    };
}

function encryptRsa(plaintext: string, publicKey: string) {
    const key = new RSAKey();
    key.setPublicString(publicKey);
    return key.encrypt(plaintext);
}
