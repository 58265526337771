import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { GoogleMapsRestaurantsEmptyState } from 'src/components/emptyStates/GoogleMapsRestaurantsEmptyState';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { distanceInKm } from 'src/utils/geojson/distanceInKm';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';

// Google Map Static API https://developers.google.com/maps/documentation/maps-static/start?hl=es-419
export function GoogleMapsRestaurantLocations({ customerLocation, restaurantsSortedByDistance }: Props): React.ReactElement {
    const classes = useStyles();

    const formatUrlMap = () => {
        const url = `https://maps.googleapis.com/maps/api/staticmap`;
        const customerMapMarkersUrl = `?markers=${getCustomerMapMarkers()}`;
        const restaurantMapMarkersUrl = `&markers=${getRestaurantMapMarkers()}`;
        return url + customerMapMarkersUrl + restaurantMapMarkersUrl + `&size=700x400&key=${googleMapsConfig.googleMapsApiKey}`;
    };

    const getCustomerMapMarkers = () => {
        return `size:mid|icon:${CUSTOMER_PIN_ICON}|` + getCustomerLocation();
    };

    const getCustomerLocation = () => {
        const customerLocationCoordinates = toLatLng(customerLocation);
        if (!customerLocationCoordinates) return '';
        return `${customerLocationCoordinates.lat},${customerLocationCoordinates.lng}`;
    };

    const getRestaurantMapMarkers = () => {
        const restaurantsWithLocation = restaurantsSortedByDistance.filter((restaurant) => restaurant.location);
        const nearbyRestaurants = restaurantsWithLocation.filter((restaurant) => {
            const distanceToRestaurant = distanceInKm(restaurant.location, customerLocation);
            if (!distanceToRestaurant) return false;
            return distanceToRestaurant <= 50;
        });

        let restaurantMarkers = `size:mid|icon:${RESTAURANT_PIN_ICON}`;

        for (const restaurant of nearbyRestaurants.slice(0, 3)) {
            const restaurantLocation = toLatLng(restaurant.location);
            if (!restaurantLocation) continue;
            restaurantMarkers += `|${restaurantLocation.lat},${restaurantLocation.lng}`;
        }

        return restaurantMarkers;
    };

    if (!customerLocation) return <GoogleMapsRestaurantsEmptyState customerLocation={customerLocation} />;

    return (
        <div className={classes.container}>
            <img className={classes.image} src={formatUrlMap()} />
        </div>
    );
}

const CUSTOMER_PIN_ICON = 'https://images.letseat.mx/b98c0288d1afc02a450f6ddc7c660209.png';
const RESTAURANT_PIN_ICON = 'https://images.letseat.mx/877ea66ba44cc1f788ecb95ec98e11ea.png';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        background: 'black',
        borderRadius: 24,
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));

type Props = {
    customerLocation?: GeoJsonPointVm;
    restaurantsSortedByDistance: Array<RestaurantVm>;
};
