import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';
import { CheckoutFormStripe } from 'src/components/checkout/CheckoutFormStripe';
import { envSTRIPE_PUBLIC_KEY } from 'src/env/envSTRIPE_PUBLIC_KEY';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

const stripePromise = loadStripe(envSTRIPE_PUBLIC_KEY());

export function Stripe3dSecureDialog(): React.ReactElement | null {
    const open = useSelector((state) => state.app.stripe3dSecureDialog.open);
    const stripe3dsClientSecret = useSelector((state) => state.app.stripe3dsClientSecret);
    const closeStripe3dSecureDialog = useAction(actions.closeStripe3dSecureDialog);

    const appearance = {
        theme: 'stripe',
    } as const;

    const options = {
        clientSecret: stripe3dsClientSecret,
        appearance,
    } as const;
    if (!stripe3dsClientSecret) return null;
    return (
        <Elements options={options} stripe={stripePromise}>
            <CheckoutFormStripe />
        </Elements>
    );
}
