import type { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, MenuItemId, RestaurantId } from 'src/types/Id';

export async function findValidPromoCodeApi(request: FindValidPromoCodeApiRequest): ApiSauceResponse<PromoCodeVm> {
    return pidedirectoApiMethod('promoCode/findValidPromoCodeApi', request);
}

export type FindValidPromoCodeApiRequest = {
    appVersion?: string;
    restaurantId: RestaurantId;
    customerId: CustomerId;
    code: string;
    menuItemIds?: Array<MenuItemId>;
};
