import { useEffect, useState } from 'react';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isArray } from 'src/utils/array/isArray';

export function useLoadApi<T>(fn: any, request: any, options?: Options): [boolean, T, (params?: { request: any }) => Promise<void>] {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(options?.initialValue as any);

    useEffect(() => {
        shouldCallApi(load, options?.requiredValues);
    }, getDependencies(options));

    const load = async (params?: { request: any }) => {
        setLoading(true);
        const response = await fn(params?.request ?? request);
        if (!response.ok) {
            setLoading(false);
            if (options?.onError) return options?.onError?.(response, setData);
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        setData(response.data);
        setLoading(false);
    };

    return [loading, data, load];
}

function getDependencies(options?: Options): Array<any> {
    let dependencies: Array<any> | Array<any | undefined> = [];

    if (isArray(options?.dependencies)) {
        dependencies = dependencies.concat(options?.dependencies);
    }
    if (isArray(options?.requiredValues)) {
        dependencies = dependencies.concat(options?.requiredValues);
    }

    return dependencies;
}

function shouldCallApi(load: any, requiredValues?: Array<any>): void {
    if (requiredValues?.some((value: any) => value === undefined || value === null)) return;

    load();
}

type Options = {
    initialValue?: any;
    onError?: OnErrorFunction;
    dependencies?: Array<any>;
    requiredValues?: Array<any>;
};

type OnErrorFunction = (response: ApiSauceResponse<any>, setData: any) => void;
