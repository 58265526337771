import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { translate } from 'src/i18n/translate';
import { wait } from 'src/utils/wait';

/**
 * @deprecated
 */
export function DeprecatedFormSelect({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    options,
    disabled,
    required,
    variant = 'outlined',
    onChange: onChangeProp,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLSelectElement>();
    const classes = useStyles();

    const [isSubmittingWorkaround, setIsSubmittingWorkaround] = useState(false);

    useEffect(() => {
        // Workaround since only inputRef.current.disabled = false; does not work
        async function delay() {
            await wait(0);
            setIsSubmittingWorkaround(isSubmitting);
        }
        delay();
    }, [isSubmitting]);

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <FormControl className={classes.container}>
                    <InputLabel error={!!getError(errors, name)} required={(required as any)?.value ?? required} classes={{ root: classes.label, error: classes.labelError }}>
                        {label}
                    </InputLabel>
                    <Select
                        {...props}
                        value={transformStringInput(value)}
                        disabled={disabled}
                        label={label}
                        onChange={(e: React.ChangeEvent<any>) => {
                            const value = transformStringOutput(e.target.value);
                            onChangeProp && onChangeProp(value);
                            onChange(value);
                        }}
                        placeholder={placeholder}
                        variant={variant}
                        error={!!getError(errors, name)}
                        inputProps={{
                            inputRef: inputRef,
                        }}
                        classes={{ root: classes.select, icon: classes.selectIcon, selectMenu: classes.selectMenu }}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        {options?.map((modifierGroup: { label: string; value: any }) => (
                            <MenuItem key={`${modifierGroup.value}-${modifierGroup.label}`} value={modifierGroup.value}>
                                {modifierGroup.label}&nbsp;
                            </MenuItem>
                        ))}
                    </Select>
                    {(helperText ?? getError(errors, name)) && <FormHelperText error={!!getError(errors, name)}>{errors?.[name]?.message ?? helperText}</FormHelperText>}
                </FormControl>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        '& .MuiInputBase-root': {
            width: '100%',
            borderRadius: 12,
        },
    },
    select: {
        width: '100%',
        fontFamily: theme.typography.regular,
        fontSize: 15,
        borderRadius: 15,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '.Mui-error > &': {
            border: '2px solid red',
        },
    },
    selectIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    selectMenu: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    label: {
        fontFamily: theme.typography.medium,
        marginTop: -5,
        marginLeft: 10,
    },
    labelError: {
        color: 'red',
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    options: Array<{
        label: string;
        value: any;
    }>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: (arg1?: any) => any;
};
