import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import { useTheme } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { CartQuantitySpinner } from 'src/components/cart/CartQuantitySpinner';
import { translate } from 'src/i18n/translate';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import PhotoNotFoundImage from 'src/images/PhotoNotFoundImage.png';
import { useRemoveItem } from 'src/services/order/useRemoveItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CartItemVm } from 'src/types/CartItemVm';
import { transformImageUrlMenuItem } from 'src/utils/image/transformImageUrlMenuItem';
import { isItemSoldByWeight } from 'src/utils/order/isItemSoldByWeight';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function CartItem({ cartItem }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { removeItem } = useRemoveItem();

    const usedPromotions = useSelector((state) => state.app.payment?.usedPromotions) ?? [];
    const promotions = useSelector((state) => state.app.promotions);
    const menuItemCurrency = useSelector((state) => state.app.addMenuItemDialog.menuItem?.currency);

    const { name, imageUrl, quantity, promoUnitPrice, unitPrice } = cartItem;
    const usedOrderItemPromotion = usedPromotions.find((usedPromotion) => usedPromotion.cartItemKey === cartItem.key);
    const promotion = promotions.find((promotion) => usedOrderItemPromotion?.promotionId === promotion.promotionId);

    const getQuantityLabel = () => {
        if (cartItem.salesUnit === 'CURRENCY') return `${menuItemCurrency}`;
        return translate(`UnitsOfMeasurement.${cartItem.salesUnit}`);
    };

    return (
        <div className={classes.container}>
            {imageUrl ? (
                <img
                    className={classNames(classes.image, {
                        [classes.imagePng]: imageUrl && imageUrl.endsWith('png'), // TODO: .endsWith('png') does not work with encoded image url
                    })}
                    src={transformImageUrlMenuItem(imageUrl)}
                    draggable='false'
                    alt='itemImage'
                />
            ) : (
                <img className={classes.image} src={PhotoNotFoundImage} alt='PhotoNotFoundImage' />
            )}
            <div className={classes.cartItemInfo}>
                <div className={classes.nameContainer}>
                    <div className={classes.itemName}>{name}</div>
                    {!!promotion && <Text style={{ color: theme.palette?.text?.brand }}>{promotion?.name}</Text>}
                </div>
                <div className={classes.bottomSection}>
                    <div className={classes.pricesContainer}>
                        <div className={classNames(classes.price, { [classes.nonPromoPrice]: promoUnitPrice })}>{formatAsCurrencyNumber(unitPrice)}</div>
                        {!!promoUnitPrice && <div className={classes.price}>{formatAsCurrencyNumber(promoUnitPrice)}</div>}
                    </div>
                    {isItemSoldByWeight(cartItem) && (
                        <div className={classes.separatedLineText}>
                            <Button onClick={() => removeItem(cartItem)} text>
                                <TrashcanIcon />
                            </Button>
                            <div className={classes.quantityItemContainer}>
                                <span>{getQuantityLabel()}</span>
                            </div>
                        </div>
                    )}
                    {!isItemSoldByWeight(cartItem) && <CartQuantitySpinner cartItem={cartItem} quantity={quantity} />}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    image: {
        minHeight: 64,
        minWidth: 64,
        height: 64,
        width: 64,
        borderRadius: 8,
        marginRight: 12,
        objectFit: 'cover',
    },
    itemName: {
        fontSize: 20,
        color: 'white',
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
        },
    },
    cartItemInfo: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    pricesContainer: {
        display: 'flex',
        gap: 12,
    },
    price: {
        fontSize: 18,
        color: 'white',
        fontWeight: 500,
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
        },
    },
    nonPromoPrice: {
        color: 'rgba(0,0,0,0.5)',
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid',
    },
    bottomSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    separatedLineText: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    quantityItemContainer: {
        display: 'flex',
        width: 56,
        height: 28,
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#2E3748',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        border: '1px solid #D9D9D9',
        borderRadius: 4,
    },
    imagePng: {},
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

type Props = {
    cartItem: CartItemVm;
};
