import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailAddress } from 'src/types/Id';

export async function signUpApi(request: SignUpApiRequest): ApiSauceResponse<void> {
    return pidedirectoApiMethod('customer/signUpApi', request);
}

export type SignUpApiRequest = {
    mobileNumber: string;
    firstName: string;
    lastName: string;
    email: EmailAddress;
    encryptedPassword: string;
    urlSubdomain?: string;
};
