import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { CashIcon, ExchangeArrowsIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { PaymentMethodIcon } from 'src/components/cart/PaymentMethodIcon';
import { CustomerCards } from 'src/components/cart/selectPaymentMethodDialog/CustomerCards';
import { Dialog } from 'src/components/Dialog';
import { Text } from 'src/components/Text';
import { CustomPaymentMethodIcons } from 'src/constants/CustomPaymentMethodIcons';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUserSelectedPaymentMethodLogEvent } from 'src/services/logEvent/createUserSelectedPaymentMethodLogEvent';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { isTableOrder } from 'src/utils/order/isTableOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { isCardOnDeliveryPayment } from 'src/utils/paymentMethod/isCardOnDeliveryPayment';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isTransferPayment } from 'src/utils/paymentMethod/isTransferPayment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectPaymentMethodDialogDeprecated({ isPaymentLink }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const open = useSelector((state) => state.app.selectPaymentMethodDialog.open);
    const orderType = useSelector((state) => state.app.orderType);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const cashPaymentsEnabled = useSelector((state) => state.app.restaurant?.cashPaymentsEnabled);
    const ecommerceCashPaymentsToEatHereEnabled = useSelector((state) => state.app.restaurant?.ecommerceCashPaymentsToEatHereEnabled);
    const ecommerceCashPaymentsToTakeAwayEnabled = useSelector((state) => state.app.restaurant?.ecommerceCashPaymentsToTakeAwayEnabled);
    const inAppPaymentsEnabled = useSelector((state) => state.app.restaurant?.inAppPaymentsEnabled);
    const cardOnDeliveryPaymentsEnabled = useSelector((state) => state.app.restaurant?.cardOnDeliveryPaymentsEnabled);
    const maximumCashOrderAmount = useSelector((state) => state.app.restaurant?.maximumCashOrderAmount);
    const subtotal = useSelector((state) => state.app.payment?.subtotal);
    const productDiscount = useSelector((state) => state.app.payment?.productDiscount);
    const currencyFormat = useSelector((state) => state.app.restaurantMenu?.currencyFormat);
    const customPaymentMethods = useSelector((state) => state.app.restaurant?.paymentMethods);
    const customPaymentMethod = useSelector((state) => state.app.customPaymentMethod);
    const plexoStoreId = useSelector((state) => state.app.restaurant?.plexoStoreId);

    const closeSelectPaymentMethodDialog = useAction(actions.closeSelectPaymentMethodDialog);
    const selectPaymentMethod = useAction(actions.selectPaymentMethod);
    const openAddNewCardDialog = useAction(actions.openAddNewCardDialog);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethod);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCustomPaymentMethod, setIsCustomPaymentMethod] = useState(false);

    const cashAmountHigherThanPermitted = BigNumber(subtotal ?? 0)
        .minus(productDiscount ?? 0)
        .isGreaterThanOrEqualTo(maximumCashOrderAmount);
    const cashPaymentsDisabled =
        (!cashPaymentsEnabled && orderType !== OrderTypes.TABLE_ORDER) ||
        cashAmountHigherThanPermitted ||
        (!ecommerceCashPaymentsToEatHereEnabled && isTableOrder(orderType)) ||
        (!ecommerceCashPaymentsToTakeAwayEnabled && isTakeAwayOrder(orderType));

    const isPaymentOnDelivery = !!isPaymentLink || !cashPaymentsDisabled || cardOnDeliveryPaymentsEnabled;

    useEffect(() => {
        if (open) {
            createUserSelectedPaymentMethodLogEvent({
                paymentMethodChangedFrom: selectedPaymentMethod,
                paymentMethodChangedTo: paymentMethod,
            });
            setSelectedPaymentMethod(paymentMethod);
        }
    }, [open]);

    const getCashPaymentSubText = () => {
        if (cashAmountHigherThanPermitted) return translate(`The limit of cash payments is @amount`, { amount: formatAsCurrencyNumber(maximumCashOrderAmount, currencyFormat) });
        return '';
    };

    const handleSelectCard = () => {
        setSelectedPaymentMethod(PaymentMethods.CREDIT_CARD);
    };

    const handleAddNewCard = () => {
        closeSelectPaymentMethodDialog();
        openAddNewCardDialog();
    };

    if (!isPaymentOnDelivery && !inAppPaymentsEnabled) {
        return (
            <Dialog title={translate('There are no payment methods configured')} open={open}>
                <Button onClick={closeSelectPaymentMethodDialog}>{translate('Continue')}</Button>
            </Dialog>
        );
    }

    return (
        <Dialog classes={{ content: classes.dialogContent }} loading={isSubmitting} open={open} title={translate('How do you want to pay?')} onClose={closeSelectPaymentMethodDialog}>
            {isPaymentOnDelivery && (
                <div>
                    <h3 className={classes.titleSection}>{translate('Payment on delivery')}</h3>
                    <div className={classes.paymentContainer}>
                        {(!cashPaymentsDisabled || cashPaymentsEnabled) && (
                            <Button
                                variant={'outline'}
                                color={!isCustomPaymentMethod && isCashPayment(paymentMethod) ? '#06B7A2' : '#8C9196'}
                                classes={{ button: cashPaymentsDisabled ? classes.containerDisabled : classes.buttonContainer }}
                                onClick={() => {
                                    setSelectedPaymentMethod(PaymentMethods.CASH);
                                    selectPaymentMethod({ paymentMethod: PaymentMethods.CASH });
                                    runAfterCheckAnimation(closeSelectPaymentMethodDialog);
                                    setIsCustomPaymentMethod(false);
                                }}
                                disabled={cashPaymentsDisabled || isSubmitting}
                            >
                                <div className={classes.buttonContent}>
                                    <CashIcon title={translate('Cash icon')} size={25} />
                                    <Text className={classNames(classes.text)}>{translate('Cash')}</Text>
                                </div>
                                <Text className={classNames(classes.subtextButton)}>{getCashPaymentSubText()}</Text>
                            </Button>
                        )}
                        {!!plexoStoreId && (
                            <Button
                                variant={'outline'}
                                color={!isCustomPaymentMethod && isTransferPayment(paymentMethod) ? '#06B7A2' : '#8C9196'}
                                classes={{ button: classes.buttonContainer }}
                                onClick={() => {
                                    setSelectedPaymentMethod(PaymentMethods.TRANSFER);
                                    selectPaymentMethod({ paymentMethod: PaymentMethods.TRANSFER });
                                    runAfterCheckAnimation(closeSelectPaymentMethodDialog);
                                    setIsCustomPaymentMethod(false);
                                }}
                                disabled={cashPaymentsDisabled || isSubmitting}
                            >
                                <div className={classes.buttonContent}>
                                    <div className={classes.roundedIcon}>
                                        <ExchangeArrowsIcon title={translate('Transfer Icon')} />
                                    </div>
                                    <Text className={classNames(classes.text)}>{translate('Transfer')}</Text>
                                </div>
                            </Button>
                        )}
                        {cardOnDeliveryPaymentsEnabled && (
                            <Button
                                variant={'outline'}
                                color={!isCustomPaymentMethod && isCardOnDeliveryPayment(paymentMethod) ? '#06B7A2' : '#8C9196'}
                                classes={{ button: cardOnDeliveryPaymentsEnabled ? classes.buttonContainer : classes.containerDisabled }}
                                onClick={() => {
                                    setSelectedPaymentMethod(PaymentMethods.CARD_ON_DELIVERY);
                                    selectPaymentMethod({ paymentMethod: PaymentMethods.CARD_ON_DELIVERY });
                                    runAfterCheckAnimation(closeSelectPaymentMethodDialog);
                                    setIsCustomPaymentMethod(false);
                                }}
                                disabled={!cardOnDeliveryPaymentsEnabled}
                            >
                                <div className={classes.buttonContent}>
                                    <PaymentMethodIcon icon={CustomPaymentMethodIcons.SHEET} />
                                    <Text className={classes.text}>{translate('Terminal')}</Text>
                                </div>
                            </Button>
                        )}
                        {customPaymentMethods?.map((paymentMethod) => (
                            <Button
                                variant={'outline'}
                                color={isCustomPaymentMethod && paymentMethod?.name === customPaymentMethod?.customPaymentMethod ? '#06B7A2' : '#8C9196'}
                                classes={{
                                    button: isCardPayment(paymentMethod.paymentMethod)
                                        ? cardOnDeliveryPaymentsEnabled || isCardPayment(paymentMethod.paymentMethod)
                                            ? classes.buttonContainer
                                            : classes.containerDisabled
                                        : cashPaymentsDisabled && !cashPaymentsEnabled
                                          ? classes.containerDisabled
                                          : classes.container,
                                }}
                                onClick={() => {
                                    setSelectedPaymentMethod(isCardPayment(paymentMethod.paymentMethod) ? PaymentMethods.CREDIT_CARD : PaymentMethods.CASH);
                                    const customPaymentMethodObject = {
                                        amount: paymentMethod.commission ?? '',
                                        customPaymentMethod: paymentMethod.name,
                                        paymentMethod: paymentMethod.paymentMethod,
                                    } as const;
                                    selectPaymentMethod({ paymentMethod: paymentMethod.paymentMethod, customPaymentMethod: customPaymentMethodObject });
                                    runAfterCheckAnimation(closeSelectPaymentMethodDialog);
                                    setIsCustomPaymentMethod(true);
                                }}
                                disabled={isCardPayment(paymentMethod.paymentMethod) ? !isCardPayment(paymentMethod.paymentMethod) : cashPaymentsDisabled || !cashPaymentsEnabled}
                            >
                                <div className={classes.buttonContent}>
                                    {paymentMethod.icon && <PaymentMethodIcon icon={paymentMethod.icon} />}
                                    {!paymentMethod.icon && (
                                        <PaymentMethodIcon icon={isCardPayment(paymentMethod.paymentMethod) ? CustomPaymentMethodIcons.CREDIT_CARD : CustomPaymentMethodIcons.CASH} />
                                    )}
                                    <Text className={classNames(classes.text)}>{paymentMethod.name}</Text>
                                </div>
                            </Button>
                        ))}
                    </div>
                </div>
            )}
            {inAppPaymentsEnabled && (
                <CustomerCards
                    selectedPaymentMethod={selectedPaymentMethod}
                    disabled={isSubmitting}
                    onSelectCard={handleSelectCard}
                    onStartLoading={() => setIsSubmitting(true)}
                    onEndLoading={() => setIsSubmitting(false)}
                />
            )}

            {inAppPaymentsEnabled && (
                <Button onClick={handleAddNewCard} disabled={isSubmitting || !inAppPaymentsEnabled}>
                    {translate('+ New Card')}
                </Button>
            )}
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {},
    card: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    titleSection: {
        color: '#2E3748',
        fontSize: 14,
        fontFamily: AppTheme.typography.light,
    },
    paymentContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 8,
        rowGap: 7,
    },
    icon: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 22,
        minWidth: 22,
    },
    buttonContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        margin: 0,
        gap: 12,
    },
    subtextButton: {
        fontSize: 10,
        color: AppTheme.listRowButton.subtext.color,
        maxWidth: 100,
    },
    dialogContent: {
        gap: 24,
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
    text: {
        textAlign: 'left',
    },
    containerDisabled: {
        display: 'none',
        backgroundColor: '#e8e8e8',
        height: '100%',
        width: '100%',
        '&:hover': {
            backgroundColor: '#e8e8e8',
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
    },
    roundedIcon: {
        backgroundColor: '#F1F1F1',
        width: 36,
        height: 36,
        borderRadius: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#616B79',
    },
}));

type Props = {
    isPaymentLink?: boolean;
};
