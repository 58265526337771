import { isString } from 'src/utils/string/isString';
import { upperCaseFirst } from 'src/utils/string/upperCaseFirst';

export function capitalize<T extends string | null | undefined>(value: T): T {
    if (value === undefined || value === null) {
        return value;
    }
    if (!isString(value)) {
        return value;
    }
    return value.split(' ').map(upperCaseFirst).join(' ') as any;
}
