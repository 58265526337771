import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import type { CartItemVm } from 'src/types/CartItemVm';

export function createUserRemovedItemFromCartLogEvent(params: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_REMOVED_ITEM_FROM_CART,
        message: `${params.cartItem.name} removed from cart`,
        data: { cartItem: params },
    });
}

type Params = {
    cartItem: CartItemVm;
    quantity: number;
};
