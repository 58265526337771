import { findOrderApi } from 'src/api/pidedirecto/order/findOrderApi';
import { actions } from 'src/reducers';
import type { OrderId } from 'src/types/Id';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { orderIsInternalDeliveryOrder } from 'src/utils/order/orderIsInternalDeliveryOrder';
import { useAction } from 'src/utils/react/useAction';

export function useFetchOrder(): FetchOrder {
    const setOrder = useAction(actions.setOrder);
    const setDriver = useAction(actions.setDriver);
    const setCustomer = useAction(actions.setCustomer);
    const setShowMap = useAction(actions.setShowMap);
    const setRestaurant = useAction(actions.setRestaurant);
    const setDriverArrivesAtStoreTime = useAction(actions.setDriverArrivesAtStoreTime);
    const setTrackInApp = useAction(actions.setTrackInApp);
    const setMandatoryCashAmount = useAction(actions.setMandatoryCashAmount);
    const setLoadingAppContext = useAction(actions.setLoadingAppContext);
    const setContentNotFound = useAction(actions.setContentNotFound);

    const fetchOrder = async (orderId: OrderId) => {
        const response = await findOrderApi({ orderId });
        if (!response.ok) {
            return;
        }

        if (!response.data) {
            setContentNotFound(true);
            return;
        }

        const { order, restaurant, driverArrivesAtStoreTime, trackInAppBannerBrandName, trackInAppBannerColor, trackInAppBannerImageUrl } = response.data;
        setOrder(order);
        setMandatoryCashAmount(order.mandatoryCashAmount);
        setDriver(order);
        setCustomer(order);
        setShowMap(orderIsInternalDeliveryOrder(order) && (isNewOrder(order.orderStatus) || isAcceptedOrder(order.orderStatus) || isCompletedOrder(order.orderStatus)));

        setRestaurant(restaurant);

        setDriverArrivesAtStoreTime(driverArrivesAtStoreTime);
        setTrackInApp({ trackInAppBannerBrandName, trackInAppBannerColor, trackInAppBannerImageUrl });
        setLoadingAppContext(false);
    };

    return fetchOrder;
}

type FetchOrder = (arg1: OrderId) => Promise<void>;
