import { LogEventTypes } from 'src/constants/LogEventType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function createUserSelectedPaymentMethodLogEvent(params: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_SELECTED_PAYMENT_METHOD,
        message: `Current paymentMethod: ${params.paymentMethodChangedTo}`,
        data: params,
    });
}

type Params = {
    paymentMethodChangedFrom: PaymentMethod;
    paymentMethodChangedTo: PaymentMethod;
};
