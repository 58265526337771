import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { CartIcon } from 'src/icons/CartIcon';
import { actions } from 'src/reducers';
import { isItemSoldByWeight } from 'src/utils/order/isItemSoldByWeight';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function CartButton(): React.ReactElement {
    const classes = useStyles();

    const cartItems = useSelector((state) => state.app.cartItems);
    const openCartDialog = useAction(actions.openCartDialog);

    const itemsQuantity = cartItems
        .map((cartItem) => (isItemSoldByWeight(cartItem) ? 1 : cartItem.quantity))
        .reduce(sum, BigNumber(0))
        .toString();

    const handleClick = () => {
        if (!cartItems.length) return;
        openCartDialog();
    };

    return (
        <Button classes={{ button: classes.button }} onClick={handleClick}>
            <div className={classes.itemsCounter}>
                <CartIcon color={'white'} />
                <div className={classes.itemsQuantity}>{itemsQuantity}</div>
            </div>
        </Button>
    );
}
const useStyles = makeStyles((theme) => ({
    button: {
        position: 'fixed',
        width: 60,
        height: 60,
        transform: 'translate(-50%,-50%)',
        bottom: 0,
        right: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 8,
        color: 'black',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '50%',
        zIndex: 10,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    itemsQuantity: {
        position: 'absolute',
        display: 'grid',
        placeItems: 'center',
        fontSize: 14,
        width: 24,
        height: 24,
        backgroundColor: '#333333',
        borderRadius: '50%',
        right: -22,
        top: -20,
        color: 'white',
    },
    itemsCounter: {
        position: 'relative',
        height: 20,
        width: 20,
    },
    totalText: {
        fontSize: 14,
        color: 'white',
    },
    total: {
        fontSize: 18,
        color: 'white',
    },
}));
