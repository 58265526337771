import * as React from 'react';
import { BOTTOM_DIALOG_TIMEOUT } from 'src/components/bottomDialog/BottomDialogDesktop';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';

// Inspired by https://dev.to/dmtrkovalenko/the-neatest-way-to-handle-alert-dialogs-in-react-1aoe
// and https://github.com/jonatanklosko/material-ui-confirm/
// recommended from https://mui.com/material-ui/react-dialog/#complementary-projects
export function ConfirmDialog({ open, title, content, buttonText, onAccept, onClose }: Props): React.ReactElement {
    return (
        <Dialog open={open} title={title || ''} onClose={onClose}>
            {!!content && <Text>{content}</Text>}
            <DialogActions>
                <Button onClick={onAccept}>{buttonText ?? translate('Understood')}</Button>
            </DialogActions>
        </Dialog>
    );
}

const ConfirmDialogServiceContext = React.createContext(Promise.reject<boolean | undefined>);

export const useConfirmDialog: any = () => React.useContext(ConfirmDialogServiceContext);

export function ConfirmDialogProvider({ children }: any): React.ReactElement {
    const [confirmDialogState, setConfirmDialogState] = React.useState<ConfirmDialogOptions>();

    const awaitingPromiseRef = React.useRef<
        | {
              resolve: (result: Promise<boolean | undefined> | boolean | undefined) => void;
          }
        | undefined
    >();

    const openConfirmDialog = (options: ConfirmDialogOptions) => {
        setConfirmDialogState(options);
        return new Promise<boolean | undefined>((resolve: (result: Promise<boolean | undefined> | boolean | undefined) => void, reject: (error?: any) => void) => {
            awaitingPromiseRef.current = { resolve };
        });
    };

    const handleAccept = () => {
        awaitingPromiseRef.current?.resolve(true);
        setTimeout(() => {
            setConfirmDialogState(undefined);
        }, BOTTOM_DIALOG_TIMEOUT);
    };

    const handleClose = () => {
        awaitingPromiseRef.current?.resolve(false);
        setTimeout(() => {
            setConfirmDialogState(undefined);
        }, BOTTOM_DIALOG_TIMEOUT);
    };

    return (
        <>
            <ConfirmDialogServiceContext.Provider value={openConfirmDialog} children={children} />
            <ConfirmDialog
                open={Boolean(confirmDialogState)}
                title={confirmDialogState?.title}
                content={confirmDialogState?.content}
                buttonText={confirmDialogState?.buttonText}
                onAccept={handleAccept}
                onClose={handleClose}
            />
        </>
    );
}

type Props = {
    open: boolean;
    onAccept?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    title?: string;
    content?: string;
    buttonText?: string;
    cancelButtonText?: string;
};

type ConfirmDialogOptions = {
    title?: string;
    content?: string;
    buttonText?: string;
    cancelButtonText?: string;
};
