import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { CircleInfoIcon } from 'src/icons/CircleInfoIcon';
import { AppTheme } from 'src/styles/AppTheme';

export const listRowHeight = 48;

export function ListInfo({ text }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <CircleInfoIcon className={classes.icon} />
            <div className={classes.textContainer}>
                <div className={classes.text}>{text}</div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 4,
        paddingRight: 4,
        marginLeft: 4,
        marginRight: 4,
        // backgroundColor: AppTheme.button.backgroundColor,
        // borderRadius: 16,
    },
    icon: {
        color: theme.palette.primary.main,
    },
    textContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        paddingLeft: 8,
    },
    text: {
        fontSize: AppTheme.listRowButton.fontSize,
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
}));

type Props = {
    text: string;
};
