import * as React from 'react';
import { FormProvider } from 'react-hook-form';

export function Form({ form, onSubmit, disableSubmitOnEnterKey, style, className, id, children }: Props): React.ReactElement {
    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                onKeyDown={
                    disableSubmitOnEnterKey
                        ? (e: any) => {
                              if ((e.key || e.code) === 'Enter') e.preventDefault();
                          }
                        : undefined
                }
                style={style}
                className={className}
                noValidate
                id={id}
            >
                {children}
            </form>
        </FormProvider>
    );
}

type Props = {
    form: any;
    onSubmit?: any;
    disableSubmitOnEnterKey?: boolean;
    style?: any;
    className?: any;
    id?: string;
    children?: any;
};
