import * as React from 'react';

export function ClosedMenuIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='37' height='40' viewBox='0 0 37 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.76218 9.99982V8.57125C9.76218 6.29796 10.6652 4.11778
12.2727 2.51033C13.8801 0.902875 16.0603 -0.000183105 18.3336 -0.000183105C20.6069 -0.000183105 22.7871
0.902875 24.3945 2.51033C26.002 4.11778 26.905 6.29796 26.905 8.57125V9.99982H30.6898C31.5725 9.99969
32.4239 10.3264 33.0799 10.917C33.7359 11.5075 34.1499 12.3201 34.2422 13.1979L36.6479 36.0551C36.7002
 36.5529 36.6473 37.0561 36.4926 37.5321C36.3379 38.0082 36.0849 38.4464 35.75 38.8183C35.415 39.1903
 35.0056 39.4877 34.5483 39.6912C34.091 39.8947 33.5961 39.9999 33.0955 39.9998H3.5717C3.07116 39.9999
  2.57619 39.8947 2.11891 39.6912C1.66162 39.4877 1.25222 39.1903 0.917264 38.8183C0.58231 38.4464 0.329282
   38.0082 0.17459 37.5321C0.019899 37.0561 -0.0330006 36.5529 0.0193227 36.0551L2.42504 13.1979C2.61742
    11.3789 4.14885 9.99982 5.97742 9.99982H9.76218ZM14.293 4.53064C15.3646 3.459 16.8181 2.85696 18.3336
     2.85696C19.8491 2.85696 21.3026 3.459 22.3742 4.53064C23.4459 5.60227 24.0479 7.05572 24.0479 8.57125V9.99982H12.6193V8.57125C12.6193
      7.05572 13.2214 5.60227 14.293 4.53064ZM15 20C15 21.1046 14.1046 22 13 22C11.8954 22 11 21.1046 11 20C11 18.8954 11.8954 18 13 18C14.1046
      18 15 18.8954 15 20ZM25 20C25 21.1046 24.1046 22 23 22C21.8954 22 21 21.1046 21 20C21 18.8954 21.8954 18 23 18C24.1046 18 25 18.8954 25
       20ZM10.395 29.7991C9.86519 29.9549 9.562 30.5108 9.71784 31.0406C9.87367 31.5705 10.4295 31.8737 10.9594 31.7178L26.0406 27.2822C26.5705
       27.1263 26.8737 26.5705 26.7178 26.0406C26.562 25.5108 26.0061 25.2076 25.4763 25.3634L10.395 29.7991Z'
                fill={color}
                stroke={color}
            />
        </svg>
    );
}

type Props = {
    color: string;
};
