import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { ModifierGroupVm, ModifierVm } from 'src/api/pidedirecto/getAppContextApi';
import { type ModifierGroupVm as OrderModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';
import { QuantitySpinner } from 'src/components/QuantitySpinner';
import { Text } from 'src/components/Text';
import { ModifierTypes } from 'src/constants/ModifierType';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { clipString } from 'src/utils/clipString';
import { deepClone } from 'src/utils/object/deepClone';
import { useAction } from 'src/utils/react/useAction';
import { sum } from 'src/utils/reduce/sum';
import { requireValue } from 'src/utils/require/requireValue';

export function Modifier({ modifierGroup, modifier, selectedModifierGroups, onMissingSectionName, onChangeSelectedModifierGroups }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const classes = useStyles();

    const openAddMenuItemSubModifierGroupDialog = useAction(actions.openAddMenuItemSubModifierGroupDialog);

    const selectedModifiers = selectedModifierGroups.find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)?.modifiers ?? [];
    const selectedModifiersQuantity =
        selectedModifiers
            .map((selectedModifier) => selectedModifier.quantity)
            .reduce(sum, BigNumber(0))
            .toNumber() ?? 0;
    const quantity = selectedModifiers.find((selectedModifier) => selectedModifier.name === modifier.name)?.quantity ?? 0;
    const hasSubModifierGroups = modifier.subModifierGroups && modifier.subModifierGroups?.length > 0;

    const handleChangeQuantity = (newQuantity: number) => {
        if (newQuantity < 0) {
            return;
        }
        if (modifierGroup.requiredMax !== undefined) {
            const max = modifierGroup.requiredMax - (selectedModifiersQuantity - quantity);
            if (max < newQuantity) {
                onMissingSectionName();
                return;
            }
        }
        const updatedSelectedModifierGroups = deepClone(selectedModifierGroups); // Deep clone since we should not modify selectedModifierGroups state passes as prop
        const _modifier = requireValue(
            updatedSelectedModifierGroups
                .find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)
                ?.modifiers?.find((selectedModifier) => selectedModifier.name === modifier.name),
        );
        _modifier.quantity = newQuantity;
        onChangeSelectedModifierGroups(updatedSelectedModifierGroups);
    };

    const openSubModifierGroups = () => {
        openAddMenuItemSubModifierGroupDialog({ modifierGroup: modifierGroup, modifier: modifier, selectedModifierGroups: selectedModifierGroups });
    };

    return (
        <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={1}>
            <Grid item xs={10}>
                {modifier.type === ModifierTypes.MULTIPLE && (
                    <div className={classes.modifierMultipleContainer}>
                        <div className={classes.spinnerContainer}>
                            <QuantitySpinner
                                quantity={quantity}
                                onSubtract={() => handleChangeQuantity(quantity - 1)}
                                onAdd={() => handleChangeQuantity(quantity + 1)}
                                variant='secondary'
                                size='small'
                            />
                        </div>
                        <div className={classes.modifierNameContainer}>{modifier.name}</div>
                    </div>
                )}
                {modifier.type === ModifierTypes.SINGLE && (
                    <div className={classes.labelItemContainer}>
                        <FormControlLabel
                            control={<Checkbox checked={quantity >= 1} disabled={modifierGroup.requiredMax === selectedModifiersQuantity && quantity === 0} color='primary' />}
                            label={modifier.name}
                            className={classes.checkbox}
                            onChange={(event: React.ChangeEvent<any>) => handleChangeQuantity(event.target.checked ? 1 : 0)}
                        />
                        {!!modifier.description && <Text className={classes.description}>{clipString({ string: modifier.description ?? '', max: 70 })}</Text>}
                    </div>
                )}
                {quantity >= 1 && hasSubModifierGroups && <Button onClick={openSubModifierGroups}>{translate('Sub modifier groups')}</Button>}
            </Grid>
            <Grid item className={classes.priceContainer} xs={2}>
                {BigNumber(modifier.price ?? 0).isGreaterThan(0) && <Text className={classes.price}>+{formatAsCurrencyNumber(modifier.price)}</Text>}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    description: {
        color: AppTheme.listRowButton.subtext.color,
        fontSize: AppTheme.listRowButton.subtext.fontSize,
        fontFamily: AppTheme.typography.regular,
        marginLeft: 30,
    },
    labelItemContainer: {
        marginBottom: 12,
        fontFamily: AppTheme.typography.regular,
    },
    modifierMultipleContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12 },
    spinnerContainer: { minWidth: 95 },
    priceContainer: { flex: 0, paddingTop: 12 },
    price: { color: '#222' },
    checkbox: {
        width: '100%',
        '& span': {
            fontFamily: AppTheme.typography.regular,
            fontSize: 14,
        },
    },
    modifierNameContainer: { fontSize: 14, paddingLeft: 8, fontFamily: AppTheme.typography.regular },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
    modifier: ModifierVm;
    selectedModifierGroups: Array<OrderModifierGroupVm>;
    onMissingSectionName: () => any;
    onChangeSelectedModifierGroups: (modifierGroups: Array<OrderModifierGroupVm>) => any;
};
