import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { BannerId, RestaurantId } from 'src/types/Id';

export async function findRestaurantBannersApi(request: FindRestaurantBannersApiRequest): ApiSauceResponse<Array<BannerVm>> {
    return pidedirectoApiMethodPublic('banner/findRestaurantBannersApi', request);
}

type FindRestaurantBannersApiRequest = {
    restaurantId: RestaurantId;
};

export type BannerVm = {
    bannerId: BannerId;
    enabled: boolean;
    hours?: string;
    imageUrl: string;
    urlSubdomain?: string;
    promoCode?: string;
    pinned?: boolean;
    channels?: Array<App>;
};
