import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

/**
 * @param {boolean} loading - Whether the loading indicator should be displayed or not.
 * @param {number} [size] - The size of the loading indicator. If not provided, a default size is used.
 * @param {React.Node} [children] - Optional children to be rendered inside the loading indicator.
 * @param {Object} [classes] - Optional classes to customize the styling of the loading indicator and its children.
 * @param {string} [classesProp.container] - Custom class for the container element.
 * @param {string} [classesProp.loader] - Custom class for the loader element.
 * @param {string} [classesProp.children] - Custom class for the children container element.
 * @returns {React.Node} - The LoadingIndicator component.
 */

export function LoadingIndicator({ loading, size, children, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const DEFAULT_SIZE = 150;

    return (
        <div className={classNames(classes.container, classesProp?.container)} style={{ width: size ?? DEFAULT_SIZE, height: size ?? DEFAULT_SIZE }}>
            <div className={classNames(classes.loader, classesProp?.loader)}></div>
            {children && <div className={classNames(classes.children, classesProp?.children)}>{children}</div>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
    },
    loader: {
        zIndex: 97,
        position: 'absolute',
        display: 'grid',
        placeItems: 'center',
        width: '95%',
        height: '95%',
        borderRadius: '50%',
        border: '4px solid #d9d9d9',
        borderTop: `4px solid ${theme.palette.primary.main}`,
        animation: '$spin 1s linear infinite',
    },
    children: {
        zIndex: 99,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 0,
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}));

type Props = {
    loading: boolean;
    size?: number;
    children?: React.ReactNode;
    classes?: {
        container?: string;
        loader?: string;
        children?: string;
    };
};
