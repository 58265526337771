import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { MenuCategoryVm } from 'src/api/pidedirecto/getAppContextApi';
import { Button } from 'src/components/Button';
import { actions } from 'src/reducers';
import { theme } from 'src/styles/theme';
import { MenuCategoryId } from 'src/types/Id';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuSectionList(): React.ReactElement {
    const classes = useStyles();
    const sectionElements = useRef<Array<HTMLDivElement | null>>([]);
    const sectionsContainerRef = useRef<HTMLDivElement | null>(null);
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const categoryOpened = useSelector((state) => state.app.openCategory.category);
    const restaurantMenu = useSelector((state) => state.app.restaurantMenu);

    const openCategory = useAction(actions.openCategory);

    const sections = restaurantMenu?.menus[0]?.categories;

    useEffect(() => {
        scrollToSection();
    }, [categoryOpened, smallScreen]);

    const scrollToSection = () => {
        if (!categoryOpened) return;

        const sectionSelectedIndex = sectionElements.current.findIndex((element) => element?.id === categoryOpened);

        if (sectionSelectedIndex < 0) return;
        if (!sectionsContainerRef) return;

        sectionsContainerRef.current?.scrollTo({
            left: getScrollToLeft(sectionSelectedIndex),
            behavior: 'smooth',
        });
    };

    const getScrollToLeft = (idx: number) => {
        if (idx === 0) return 0;

        let scrollToLeft = 0;
        const elementsBefore = sectionElements.current.slice(0, idx);
        for (const element of elementsBefore) {
            scrollToLeft += (element?.clientWidth ?? 0) + 20;
        }

        if (scrollToLeft <= (sectionsContainerRef.current?.clientWidth ?? 0)) return 0;

        return scrollToLeft;
    };

    const handleClickCategory = (menuCategoryId: MenuCategoryId) => {
        openCategory(menuCategoryId);
    };

    return (
        <div className={classes.sectionsContainer} ref={sectionsContainerRef}>
            {sections?.map((section: MenuCategoryVm, idx: number) => {
                const isSelected = categoryOpened === section.menuCategoryId;
                return (
                    <Button
                        text
                        buttonRef={(ref: HTMLDivElement) => (sectionElements.current[idx] = ref)}
                        id={section.menuCategoryId}
                        key={section.menuCategoryId}
                        classes={{ button: isSelected ? classNames(classes.sectionName, classes.sectionNameSelected) : classes.sectionName }}
                        onClick={() => handleClickCategory(section.menuCategoryId)}
                    >
                        {section.name}
                        <div className={classNames(isSelected ? classes.underline : classes.underlineTransparent)}></div>
                    </Button>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    sectionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        height: 'fit-content',
        overflow: 'scroll',
        gap: 20,
        alignItems: 'center',
        paddingBottom: 24,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            paddingBottom: 0,
        },
    },
    sectionName: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        fontSize: 16,
        color: '#868C9F',
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 'max-content',
        gap: 0,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 24,
        },
        '&:focus': {
            outline: 0,
        },
    },
    sectionNameSelected: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    underline: {
        height: 2,
        width: '70%',
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main,
        marginTop: 6,
        transition: '400ms',
    },
    underlineTransparent: {
        transition: '400ms',
        height: 2,
        width: '0%',
        borderRadius: 4,
        backgroundColor: 'transparent',
        marginTop: 6,
    },
}));
