import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { ModifierGroupVm } from 'src/api/pidedirecto/getAppContextApi';
import { OrderItemVm, type ModifierGroupVm as OrderModifierGroupVm, type ModifierVm as OrderModifierVm } from 'src/api/pidedirecto/types/OrderVm';
import { Modifier } from 'src/components/addMenuItemDialog/Modifier';
import { ModifierGroupHeader } from 'src/components/addMenuItemDialog/ModifierGroupHeader';
import { AddMenuItemSubModifierGroupDialog } from 'src/components/AddMenuItemSubModifierGroupDialog';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Input } from 'src/components/Input';
import { QuantitySpinner } from 'src/components/QuantitySpinner';
import { SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { SubscriptionStatuses } from 'src/constants/SubscriptionStatus';
import { translate } from 'src/i18n/translate';
import { NoteIcon } from 'src/icons/NoteIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import PhotoNotFoundImage from 'src/images/PhotoNotFoundImage.png';
import { actions } from 'src/reducers';
import { createUserAddedItemToCartLogEvent } from 'src/services/logEvent/createUserAddedItemToCartLogEvent';
import { useAddItem } from 'src/services/order/useAddItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { CartItemVm } from 'src/types/CartItemVm';
import { checkCategoryLimit } from 'src/utils/cart/checkCategoryLimit';
import { checkMenuLimit } from 'src/utils/cart/checkMenuLimit';
import { checkProductLimit } from 'src/utils/cart/checkProductLimit';
import { isWithinHours } from 'src/utils/hours/isWithinHours';
import { transformImageUrlMenuItem } from 'src/utils/image/transformImageUrlMenuItem';
import { calculateOrderItemProductDiscountPrice } from 'src/utils/order/calculateOrderItemProductDiscountPrice';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isItemSoldByWeight } from 'src/utils/order/isItemSoldByWeight';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function AddMenuItemDialog(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { addItem } = useAddItem();

    const [note, setNote] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [orderItemsMaximumQuantity, setOrderItemsMaximumQuantity] = useState<string | undefined>(undefined);
    const [selectedModifierGroups, setSelectedModifierGroups] = useState<Array<OrderModifierGroupVm>>([]);
    const [missingSectionName, setMissingSectionName] = useState('');

    const restaurant = useSelector((state) => state.app.restaurant);
    const temporarilyOffline = useSelector((state) => state.app.restaurant?.temporarilyOffline);
    const hideOrderItemNotesEnabled = useSelector((state) => state.app.restaurant?.hideOrderItemNotesEnabled);
    const open = useSelector((state) => state.app.addMenuItemDialog.open);
    const orderType = useSelector((state) => state.app.orderType);
    const hideCartEnabled = useSelector((state) => state.app.restaurant?.hideCartEnabled);
    const subscriptionPlan = useSelector((state) => state.app.restaurant?.subscriptionPlan);
    const remainingDays = useSelector((state) => state.app.restaurant?.remainingDays);
    const subscriptionStatus = useSelector((state) => state.app.restaurant?.subscriptionStatus);
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant?.plannedOrdersEnabled);
    const limitProductsInOrder = useSelector((state) => state.app.restaurant?.limitProductsInOrder);
    const categories = useSelector((state) => state.app.restaurantMenu?.menus[0]?.categories);
    const orderItemsMaximumQuantityMenu = useSelector((state) => state.app.restaurantMenu?.menus[0]?.orderItemsMaximumQuantity);
    const cartItems = useSelector((state) => state.app.cartItems);
    const menuCategoryIdOpened = useSelector((state) => state.app.openCategory.category);
    const menuItem = useSelector((state) => state.app.addMenuItemDialog.menuItem);
    const menuItemCurrency = useSelector((state) => state.app.addMenuItemDialog.menuItem?.currency);

    const closeAddMenuItemDialog = useAction(actions.closeAddMenuItemDialog);
    const openAddMenuItemSubModifierGroupDialog = useAction(actions.openAddMenuItemSubModifierGroupDialog);
    const openWarningAlert = useAction(actions.openWarningAlert);

    const isServiceCutOffAndFreeTrial = remainingDays <= 0 && subscriptionPlan === SubscriptionPlans.FREE_TRIAL && subscriptionPlan;
    const isServiceCutOffAndNotPayPerUse = remainingDays <= 0 && subscriptionPlan !== SubscriptionPlans.PAY_PER_USE && subscriptionPlan;

    const closed = !isWithinHours(restaurant.hours, restaurant.timeZone);
    const isDeliveryLimited = limitProductsInOrder && isDeliveryOrder(orderType);
    const category = menuItem?.menuCategoryId ? categories.find((category) => category.menuCategoryId === menuItem.menuCategoryId) : undefined;
    const cartItemsFromCategory = category ? cartItems.filter((item) => item.menuCategoryId === category.menuCategoryId) : [];
    const minimumSalesQuantity = menuItem?.minimumSalesQuantity ? BigNumber(menuItem?.minimumSalesQuantity).toNumber() : 0;

    useEffect(() => {
        if (minimumSalesQuantity && open) setQuantity(minimumSalesQuantity);
    }, [minimumSalesQuantity, open]);

    useEffect(() => {
        if (open) {
            setSelectedModifierGroups(
                menuItem.modifierGroups.map((modifierGroup) => ({
                    name: modifierGroup.name,
                    freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
                    modifiers: modifierGroup.modifiers.map((modifier) => ({
                        modifierId: modifier.modifierId,
                        name: modifier.name,
                        price: modifier.price,
                        quantity: modifier.preselected ? 1 : 0,
                        description: modifier.description,
                        subModifierGroups: modifier.subModifierGroups?.map((subModifierGroup) => ({
                            name: subModifierGroup.name,
                            subModifiers: subModifierGroup.subModifiers.map((subModifier) => ({
                                name: subModifier.name,
                                price: subModifier.price,
                                quantity: 0,
                            })),
                        })),
                    })),
                })),
            );
            setNote('');
        } else {
            setQuantity(1);
            setMissingSectionName('');
        }
    }, [open]);

    useEffect(() => {
        if (missingSectionName) {
            setTimeout(() => setMissingSectionName(''), 700);
        }
    }, [missingSectionName]);

    useEffect(() => {
        if (orderItemsMaximumQuantity !== undefined) handleCartLimitExceeded();
    }, [orderItemsMaximumQuantity]);

    const addOneCartItem = () => {
        setQuantity(quantity + 1);
    };

    const getOrderItemPrice = () => {
        return calculateOrderItemProductDiscountPrice({
            menuItemId: menuItem?.menuItemId,
            menuItemType: menuItem.menuItemType,
            name: menuItem.name,
            unitPrice: menuItem.price,
            promoUnitPrice: menuItem.promoPrice,
            promoText: menuItem.promoText,
            imageUrl: menuItem.imageUrl,
            quantity: BigNumber(quantity || 0).toNumber(),
            note,
            modifierGroups: selectedModifierGroups,
            pickupTime: menuItem.pickupTime,
        } as OrderItemVm).toString();
    };

    const removeOneCartItem = () => {
        if (quantity !== 1) setQuantity(quantity - 1);
    };

    const hasMissingRequiredModifiers = !!menuItem?.modifierGroups.some((modifierGroup) => {
        const selectedModifiers = selectedModifierGroups.find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)?.modifiers ?? [];
        const selectedModifiersQuantity =
            selectedModifiers
                .map((selectedModifier) => selectedModifier.quantity)
                .reduce(sum, BigNumber(0))
                .toNumber() ?? 0;

        if (selectedModifiersQuantity >= 1) {
            for (const modifier of modifierGroup.modifiers) {
                const openSubModifierGroupMenu =
                    modifier.subModifierGroups?.some((subModifierGroup) => {
                        if (subModifierGroup.requiredMin === undefined && subModifierGroup.requiredMax === undefined) {
                            return false;
                        }
                        const selectedModifiersSubModifierGroups = selectedModifiers.find((selectedModifier) => selectedModifier.name === modifier.name)?.subModifierGroups ?? [];
                        const selectedSubModifiersQuantity =
                            selectedModifiersSubModifierGroups
                                .flatMap((subModifierGroup) => subModifierGroup.subModifiers)
                                .map((selectedSubModifier) => selectedSubModifier.quantity)
                                .reduce(sum, BigNumber(0))
                                .toNumber() ?? 0;
                        if (subModifierGroup.requiredMin !== undefined && selectedSubModifiersQuantity < subModifierGroup.requiredMin) {
                            return true;
                        }
                        if (subModifierGroup.requiredMax !== undefined && selectedSubModifiersQuantity > subModifierGroup.requiredMax) {
                            return true;
                        }
                        return false;
                    }) ?? false;
                if (openSubModifierGroupMenu) {
                    return true;
                }
            }
        }

        if (modifierGroup.requiredMin !== undefined && selectedModifiersQuantity < modifierGroup.requiredMin) {
            return true;
        }
        if (modifierGroup.requiredMax !== undefined && selectedModifiersQuantity > modifierGroup.requiredMax) {
            return true;
        }

        return false;
    });

    const disabled = !!(
        hasMissingRequiredModifiers ||
        isServiceCutOffAndFreeTrial ||
        isServiceCutOffAndNotPayPerUse ||
        subscriptionStatus === SubscriptionStatuses.CANCELLED ||
        (closed && !plannedOrdersEnabled)
    );

    function checkIsCartLimitedReached() {
        if (menuItem.orderItemsMaximumQuantity) {
            const existingProductCart = cartItems.find((item) => item.menuItemId === menuItem.menuItemId);
            const productQuantity = existingProductCart ? existingProductCart.quantity : 0;
            const isProductOnLimit = quantity + productQuantity === parseInt(menuItem.orderItemsMaximumQuantity);
            if (isProductOnLimit) {
                setOrderItemsMaximumQuantity(menuItem.orderItemsMaximumQuantity);
                return;
            }
        }

        if (category?.orderItemsMaximumQuantity) {
            const cartItemsQuantityFromCategory = cartItemsFromCategory?.reduce((total: number, cartItem) => total + cartItem.quantity, 0);
            const isCategoryOnLimit = quantity + cartItemsQuantityFromCategory === parseInt(category?.orderItemsMaximumQuantity);
            if (isCategoryOnLimit) {
                setOrderItemsMaximumQuantity(category?.orderItemsMaximumQuantity);
                return;
            }
        }
        if (orderItemsMaximumQuantityMenu) {
            const cartItemsQuantity = cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
            const isMenuOnLimit = quantity + cartItemsQuantity === parseInt(orderItemsMaximumQuantityMenu);
            if (isMenuOnLimit) {
                setOrderItemsMaximumQuantity(orderItemsMaximumQuantityMenu);
                return;
            }
        }
    }

    const handleAddCartItem = () => {
        if (disabled) {
            showMissingSection();
            return;
        }
        if (isDeliveryLimited) {
            const isCartItemLimitExceeded = menuItem.orderItemsMaximumQuantity ? checkProductLimit(quantity, cartItems, menuItem) : false;
            const isCategoryLimitExceeded = menuItem.orderItemsMaximumQuantity ? checkCategoryLimit(quantity, cartItems, menuItem, categories) : false;
            const isMenuLimitExceeded = orderItemsMaximumQuantityMenu ? checkMenuLimit(quantity, cartItems, orderItemsMaximumQuantityMenu) : false;

            if (isCartItemLimitExceeded) {
                setOrderItemsMaximumQuantity(menuItem.orderItemsMaximumQuantity);
                handleCartLimitExceeded();
                checkIsCartLimitedReached();
                return;
            }

            if (isCategoryLimitExceeded) {
                setOrderItemsMaximumQuantity(menuItem.orderItemsMaximumQuantity);
                handleCartLimitExceeded();
                checkIsCartLimitedReached();
                return;
            }
            if (isMenuLimitExceeded) {
                setOrderItemsMaximumQuantity(orderItemsMaximumQuantityMenu);
                handleCartLimitExceeded();
                checkIsCartLimitedReached();
                return;
            }
        }

        if (isItemSoldByWeight(menuItem) && quantity < minimumSalesQuantity) {
            openWarningAlert({
                title: translate('Minimum sales quantity'),
                message: translate('The minimum sales quantity is @minimumSalesQuantity', {
                    minimumSalesQuantity: `${minimumSalesQuantity}${translate(`UnitsOfMeasurement.${menuItem?.salesUnit ?? ''}`)}`,
                }),
            });
            return;
        }

        addItem({
            menuItemId: menuItem.menuItemId,
            menuItemType: menuItem.menuItemType,
            name: menuItem.name,
            unitPrice: menuItem.price,
            promoUnitPrice: menuItem.promoPrice,
            promoText: menuItem.promoText,
            size: menuItem.size,
            imageUrl: menuItem.imageUrl,
            isSoldByWeight: menuItem?.isSoldByWeight,
            salesUnit: menuItem?.salesUnit,
            currency: menuItem?.currency,
            quantity: BigNumber(quantity).toNumber(),
            note,
            modifierGroups: removeZeroQuantityItems(selectedModifierGroups),
            pickupTime: menuItem.pickupTime,
            orderItemsMaximumQuantity: menuItem.orderItemsMaximumQuantity, // TODO: hack only used for validation in front end, should not be on this type
            menuCategoryId: menuItem.menuCategoryId ?? menuCategoryIdOpened,
        } as CartItemVm);

        createUserAddedItemToCartLogEvent({
            cartItem: menuItem,
            quantity: BigNumber(quantity).toNumber(),
            modifierGroups: removeZeroQuantityItems(selectedModifierGroups),
        });

        if (restaurant.facebookPixelId) {
            ReactPixel.trackCustom('AddItemToCart', {
                content_id: menuItem.menuItemId,
                content_name: menuItem.name,
                content_category: menuItem.menuItemType,
                value: menuItem.price,
                currency: restaurant.country,
            });
        }
        if (restaurant.googleAnalyticsId) {
            const dataLayer = {
                event: 'AddItemToCart',
                product: {
                    id: menuItem.menuItemId,
                    name: menuItem.name,
                    price: menuItem.price,
                    quantity: quantity,
                },
            } as const;
            const tagManagerData = {
                gtmId: restaurant.googleAnalyticsId,
                dataLayer,
            } as const;
            TagManager.initialize(tagManagerData);
        }
        closeAddMenuItemDialog();
    };

    const handleCartLimitExceeded = () => {
        closeAddMenuItemDialog();
        openWarningAlert({
            title: translate('Maximum of delivery reached'),
            message: translate(
                'To guarantee the quality of your shipment and that the product arrives without problem, we limit the maximum that the delivery person can carry. If you want you can continue creating another order when finished',
            ),
        });
        return;
    };

    const showMissingSection = () => {
        for (const modifierGroup of menuItem.modifierGroups) {
            const selectedModifiers = selectedModifierGroups.find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)?.modifiers ?? [];
            const selectedModifiersQuantity =
                selectedModifiers
                    .map((selectedModifier) => selectedModifier.quantity)
                    .reduce(sum, BigNumber(0))
                    .toNumber() ?? 0;

            if (modifierGroup.requiredMin !== undefined && selectedModifiersQuantity < modifierGroup.requiredMin) {
                setMissingSectionName(modifierGroup.name);
                break;
            }
            if (modifierGroup.requiredMax !== undefined && selectedModifiersQuantity > modifierGroup.requiredMax) {
                setMissingSectionName(modifierGroup.name);
                break;
            }

            showMissingSubModifierGroups(selectedModifiers, modifierGroup);
        }
    };

    const showMissingSubModifierGroups = (selectedModifiers: Array<OrderModifierVm>, modifierGroup: ModifierGroupVm) => {
        const selectedModifiersQuantity =
            selectedModifiers
                .map((selectedModifier) => selectedModifier.quantity)
                .reduce(sum, BigNumber(0))
                .toNumber() ?? 0;

        if (selectedModifiersQuantity >= 1) {
            for (const modifier of modifierGroup.modifiers) {
                const openSubModifierGroupMenu =
                    modifier.subModifierGroups?.some((subModifierGroup) => {
                        if (subModifierGroup.requiredMin === undefined && subModifierGroup.requiredMax === undefined) {
                            return false;
                        }
                        const selectedModifiersSubModifierGroups = selectedModifiers.find((selectedModifier) => selectedModifier.name === modifier.name)?.subModifierGroups ?? [];
                        const selectedSubModifiersQuantity =
                            selectedModifiersSubModifierGroups
                                .flatMap((subModifierGroup) => subModifierGroup.subModifiers)
                                .map((selectedSubModifier) => selectedSubModifier.quantity)
                                .reduce(sum, BigNumber(0))
                                .toNumber() ?? 0;
                        if (subModifierGroup.requiredMin !== undefined && selectedSubModifiersQuantity < subModifierGroup.requiredMin) {
                            return true;
                        }
                        if (subModifierGroup.requiredMax !== undefined && selectedSubModifiersQuantity > subModifierGroup.requiredMax) {
                            return true;
                        }
                        return false;
                    }) ?? false;
                if (openSubModifierGroupMenu) {
                    setMissingSectionName(modifierGroup.name);
                    openAddMenuItemSubModifierGroupDialog({ modifierGroup: modifierGroup, modifier: modifier, selectedModifierGroups: selectedModifierGroups });
                    return true;
                }
            }
        }
    };

    const orderItemPrice = !!menuItem ? formatAsCurrencyNumber(getOrderItemPrice()) : '';

    if (!menuItem) {
        return null;
    }

    const getFormattedUnitText = (addSlash: boolean) => {
        if (menuItemCurrency) {
            return addSlash ? `${menuItemCurrency}` : `/${menuItemCurrency}`;
        }
        return isItemSoldByWeight(menuItem) ? ` ${translate(`UnitsOfMeasurement.${menuItem?.salesUnit ?? ''}`)}` : '';
    };

    return (
        <div>
            <Dialog classes={{ dialog: classes.dialog }} open={open} title={menuItem.name} onClose={closeAddMenuItemDialog}>
                <div className={classes.imageContainer}>
                    {menuItem.imageUrl ? <img src={transformImageUrlMenuItem(menuItem.imageUrl)} className={classes.image} /> : <img className={classes.image} src={PhotoNotFoundImage} />}
                </div>
                <div className={classes.mainTextContainer}>
                    {!!menuItem.name && <p className={classes.name}>{menuItem.name} </p>}
                    <p className={classes.name}>{`${orderItemPrice}${getFormattedUnitText(false)}`}</p>
                </div>
                {!!menuItem.description && <p className={classes.description}>{menuItem.description}</p>}
                {menuItem.modifierGroups.map((modifierGroup) => (
                    <div key={modifierGroup.name}>
                        <ModifierGroupHeader modifierGroup={modifierGroup} missingSectionName={missingSectionName} />
                        <FormControl required error={false} component='fieldset' style={{ width: '100%', paddingTop: 8 }}>
                            <FormGroup>
                                {modifierGroup.modifiers.map((modifier) => (
                                    <Modifier
                                        key={modifier.name}
                                        modifierGroup={modifierGroup}
                                        modifier={modifier}
                                        selectedModifierGroups={selectedModifierGroups}
                                        onMissingSectionName={() => setMissingSectionName(modifierGroup.name)}
                                        onChangeSelectedModifierGroups={(selectedModifierGroups) => {
                                            setSelectedModifierGroups(selectedModifierGroups);
                                        }}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </div>
                ))}
                {!hideOrderItemNotesEnabled && (
                    <>
                        <div className={classes.instruction}>
                            <NoteIcon />
                            <p>{translate('Add an instruction')}</p>
                        </div>
                        <TextField
                            fullWidth
                            multiline
                            variant='outlined'
                            value={note}
                            onChange={({ target: { value } }) => setNote(value)}
                            helperText={translate('Any instruction that may increase the check value will be ignored')}
                        />
                    </>
                )}
                {!hideCartEnabled && (
                    <div className={classes.bottomContainer}>
                        {!isItemSoldByWeight(menuItem) && (
                            <div className={classes.spinnerContainer}>
                                <QuantitySpinner quantity={quantity} onSubtract={removeOneCartItem} onAdd={addOneCartItem} variant='secondary' />
                            </div>
                        )}
                        {isItemSoldByWeight(menuItem) && (
                            <div className={classes.quantityContainer}>
                                <Button onClick={closeAddMenuItemDialog} text>
                                    <TrashcanIcon />
                                </Button>
                                <Input
                                    minValue={minimumSalesQuantity || 0}
                                    classes={{ inputContainer: classes.input }}
                                    name={'quantity'}
                                    type={'number'}
                                    onChange={(value: number) => setQuantity(value)}
                                    value={quantity}
                                    step={0.5}
                                />
                                {menuItem?.salesUnit && <span className={classes.unit}>{getFormattedUnitText(true)}</span>}
                            </div>
                        )}

                        <Button classes={{ button: classNames(classes.button, disabled ? classes.buttonDisabled : '') }} onClick={handleAddCartItem} disabled={temporarilyOffline}>
                            {translate('Add')}
                        </Button>
                    </div>
                )}
            </Dialog>
            <AddMenuItemSubModifierGroupDialog
                selectedModifierGroups={selectedModifierGroups}
                onChangeSelectedModifierGroups={(selectedModifierGroups) => {
                    setSelectedModifierGroups(selectedModifierGroups);
                }}
            />
        </div>
    );
}

function removeZeroQuantityItems(selectedModifierGroups: Array<OrderModifierGroupVm>): Array<OrderModifierGroupVm> {
    return selectedModifierGroups
        .map((modifierGroup) => ({
            ...modifierGroup,
            modifiers: modifierGroup.modifiers
                .filter((modifier) => modifier.quantity >= 1)
                .map((modifier) => ({
                    ...modifier,
                    subModifierGroups: modifier.subModifierGroups
                        ?.map((subModifierGroup) => ({
                            ...subModifierGroup,
                            subModifiers: subModifierGroup.subModifiers.filter((subModifier) => subModifier.quantity >= 1),
                        }))
                        .filter((subModifierGroup) => subModifierGroup.subModifiers.length >= 1),
                })),
        }))
        .filter((modifierGroup) => modifierGroup.modifiers.length >= 1);
}

const useStyles = makeStyles((theme) => ({
    image: {
        height: '40vh',
        objectFit: 'cover',
        width: '100%',
        borderRadius: 6,
    },
    name: {
        color: '#2E3748',
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
    },
    mainTextContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    description: {
        color: '#818696',
        fontSize: 16,
        lineHeight: 1.9,
        marginTop: 0,
    },
    instruction: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        color: '#818696',
        fontSize: 12,
        lineHeight: 1.9,
        marginTop: 0,
    },
    imageContainer: {
        position: 'relative',
    },
    bottomContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 12px 12px 12px',
        [theme.breakpoints.up('sm')]: {
            padding: '12px 12px 12px 12px',
        },
    },
    spinnerContainer: {
        width: '40%',
    },
    button: {
        minWidth: '50%',
    },
    buttonDisabled: {
        backgroundColor: '#F1F1F1',
        color: '#8C9196',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#F1F1F1',
        },
    },
    dialog: {
        fontFamily: AppTheme.typography.regular,
        overflowY: 'scroll',
        maxHeight: '80%',
        width: '95%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
            width: '65%',
            maxHeight: '85%',
            borderRadius: '12px',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            width: '45%',
            maxHeight: '85%',
            borderRadius: '12px',
        },
    },
    formContainer: {
        width: '100%',
        paddingTop: 8,
    },
    quantityContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    input: {
        [theme.breakpoints.up('sm')]: {
            width: 100,
        },
        width: 60,
        height: 42,
        padding: 10,
        border: '1px solid #D9D9D9',
    },
    unit: {},
}));
