import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import * as React from 'react';
import { ListRowCheckbox } from 'src/components/ListRowCheckbox';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CreditsCheckbox(): React.ReactElement | null {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const usableLetsEatCredits = useSelector((state) => state.app.payment?.usableLetsEatCredits);
    const useLetsEatCredits = useSelector((state) => state.app.useLetsEatCredits);

    const setUseLetsEatCredits = useAction(actions.setUseLetsEatCredits);

    if (!usableLetsEatCredits) {
        return null;
    }
    return (
        <ListRowCheckbox
            Icon={MonetizationOnIcon}
            text={translate('Credits')}
            rightText={formatAsCurrencyNumber(usableLetsEatCredits)}
            checked={useLetsEatCredits}
            onChange={() => setUseLetsEatCredits(!useLetsEatCredits)}
        />
    );
}
