import * as React from 'react';

export function NoteIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.66666 5.33333C4.66666 5.15652 4.7369 4.98695 4.86193 4.86193C4.98695 4.7369 5.15652 4.66666 5.33333 4.66666H10.6667C10.8435 4.66666 11.013 4.7369 11.1381 4.86193C11.2631 4.98695 11.3333 5.15652 11.3333 5.33333C11.3333 5.51014 11.2631 5.67971 11.1381 5.80474C11.013 5.92976 10.8435 6 10.6667 6H5.33333C5.15652 6 4.98695 5.92976 4.86193 5.80474C4.7369 5.67971 4.66666 5.51014 4.66666 5.33333ZM5.33333 8.66666H10.6667C10.8435 8.66666 11.013 8.59643 11.1381 8.4714C11.2631 8.34638 11.3333 8.17681 11.3333 8C11.3333 7.82319 11.2631 7.65362 11.1381 7.52859C11.013 7.40357 10.8435 7.33333 10.6667 7.33333H5.33333C5.15652 7.33333 4.98695 7.40357 4.86193 7.52859C4.7369 7.65362 4.66666 7.82319 4.66666 8C4.66666 8.17681 4.7369 8.34638 4.86193 8.4714C4.98695 8.59643 5.15652 8.66666 5.33333 8.66666ZM8 10H5.33333C5.15652 10 4.98695 10.0702 4.86193 10.1953C4.7369 10.3203 4.66666 10.4899 4.66666 10.6667C4.66666 10.8435 4.7369 11.013 4.86193 11.1381C4.98695 11.2631 5.15652 11.3333 5.33333 11.3333H8C8.17681 11.3333 8.34638 11.2631 8.4714 11.1381C8.59643 11.013 8.66666 10.8435 8.66666 10.6667C8.66666 10.4899 8.59643 10.3203 8.4714 10.1953C8.34638 10.0702 8.17681 10 8 10ZM16 1.33333V10.3908C16.0006 10.566 15.9663 10.7395 15.8992 10.9013C15.8321 11.0631 15.7335 11.21 15.6092 11.3333L11.3333 15.6092C11.21 15.7335 11.0631 15.8321 10.9013 15.8992C10.7395 15.9663 10.566 16.0006 10.3908 16H1.33333C0.979711 16 0.640572 15.8595 0.390524 15.6095C0.140476 15.3594 0 15.0203 0 14.6667V1.33333C0 0.979711 0.140476 0.640572 0.390524 0.390524C0.640572 0.140476 0.979711 0 1.33333 0H14.6667C15.0203 0 15.3594 0.140476 15.6095 0.390524C15.8595 0.640572 16 0.979711 16 1.33333ZM1.33333 14.6667H10V10.6667C10 10.4899 10.0702 10.3203 10.1953 10.1953C10.3203 10.0702 10.4899 10 10.6667 10H14.6667V1.33333H1.33333V14.6667ZM11.3333 11.3333V13.725L13.7242 11.3333H11.3333Z'
                fill={color ?? '#06B7A2'}
            />
        </svg>
    );
}
type Props = {
    color?: string;
};
