export const OrderStatuses = Object.freeze({
    NEW: 'NEW',
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    COMPLETE: 'COMPLETE', // TODO: Remove OrderStatus.COMPLETE when changed to COMPLETED on server and db,
    COMPLETED: 'COMPLETED',
});

export type OrderStatus = (typeof OrderStatuses)[keyof typeof OrderStatuses];
