import type { AddressVm } from 'src/api/pidedirecto/types/AddressVm';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { AddressId, CustomerId, GooglePlaceId } from 'src/types/Id';

export async function changeAddressApi(request: ChangeAddressApiRequest): ApiSauceResponse<Array<AddressVm>> {
    return pidedirectoApiMethod('address/changeAddressApi', request);
}

export type ChangeAddressApiRequest = {
    customerId: CustomerId;
    addressId: AddressId;
    location?: GeoJsonPointVm;
    street: string;
    other?: string;
    instructions?: string;
    googlePlaceId?: GooglePlaceId;
    name?: string; // backup data from google place details,
    formattedAddress?: string; // backup data from google place details,
    text?: string; // backup data from google autocomplete or nearby suggestion,
    subtext?: string; // backup data from google autocomplete or nearby suggestion
};
