import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardVm } from 'src/types/CardVm';
import type { CardId } from 'src/types/Id';

export async function startCardValidationApi(request: StartCardValidationApiRequest): ApiSauceResponse<CardVm> {
    return pidedirectoApiMethod('card/startCardValidationApi', request);
}

export type StartCardValidationApiRequest = {
    cardId: CardId;
};
