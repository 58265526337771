import { Collapse } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as React from 'react';
import { useEffect } from 'react';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function WarningAlert(): React.ReactElement {
    const classes = useStyles();

    const open = useSelector((state) => state.app.warningAlert.open);
    const title = useSelector((state) => state.app.warningAlert.title);
    const message = useSelector((state) => state.app.warningAlert.message);

    const closeWarningAlert = useAction(actions.closeWarningAlert);

    useEffect(() => {
        if (open) {
            const timeout = setTimeout(() => {
                closeWarningAlert();
            }, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [open, closeWarningAlert]);

    return (
        <Collapse in={open}>
            <Alert
                variant='filled'
                severity='info'
                action={
                    <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={() => {
                            closeWarningAlert();
                        }}
                    >
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                }
                className={classes.root}
            >
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Collapse>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: 100,
        right: 0,
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        maxWidth: '400px',
    },
}));
