import { makeStyles } from '@material-ui/core/styles';
import { useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { getBackgroundPattern } from 'src/components/getBackgroundPattern';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { translate } from 'src/i18n/translate';
import { MapPinIcon } from 'src/icons/MapPinIcon';

export function GoogleMapsCheckoutEmptyState({ customerLocation }: Props): React.ReactElement | null {
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);
    const classes = useStyles();

    if (!isLoaded) return null;

    return (
        <div className={classes.container} style={{ backgroundImage: getBackgroundPattern() }}>
            <div className={classes.emptyState}>
                <MapPinIcon color={'white'} />
                {loadError && <p className={classes.text}>{translate('Failed to load google maps, please refresh page.')}</p>}
                {!customerLocation && <p className={classes.text}>{translate('Failed to load google maps, please accept get your location.')}</p>}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '40vh',
        position: 'fixed',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 42,
        top: 0,
        [theme.breakpoints.up('sm')]: {
            height: 250,
            position: 'relative',
        },
    },
    emptyState: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        color: 'white',
        fontSize: 18,
        fontWeight: 500,
        textAlign: 'center',
    },
}));

type Props = {
    customerLocation?: GeoJsonPointVm;
};
