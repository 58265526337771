export const LogEventTypes = Object.freeze({
    ORDER_CREATED: 'ORDER_CREATED',
    ORDER_ACCEPTED: 'ORDER_ACCEPTED',
    ORDER_COMPLETED: 'ORDER_COMPLETED',
    ORDER_REJECTED: 'ORDER_REJECTED',
    ORDER_RETURNED: 'ORDER_RETURNED',
    ORDER_CANCELLED: 'ORDER_CANCELLED',
    RAPPI_INTEGRATED: 'RAPPI_INTEGRATED',
    RAPPI_INTEGRATION_FAILED: 'RAPPI_INTEGRATION_FAILED',
    RAPPI_DISINTEGRATED: 'RAPPI_DISINTEGRATED',
    RAPPI_DISINTEGRATION_FAILED: 'RAPPI_DISINTEGRATION_FAILED',
    RAPPI_STORE_OPENED: 'RAPPI_STORE_OPENED',
    RAPPI_STORE_CLOSED: 'RAPPI_STORE_CLOSED',
    DELIVER_ORDERS_FROM_RAPPI_ENABLED: 'DELIVER_ORDERS_FROM_RAPPI_ENABLED',
    DELIVER_ORDERS_FROM_RAPPI_DISABLED: 'DELIVER_ORDERS_FROM_RAPPI_DISABLED',
    DIDI_FOOD_INTEGRATED: 'DIDI_FOOD_INTEGRATED',
    DIDI_FOOD_INTEGRATION_FAILED: 'DIDI_FOOD_INTEGRATION_FAILED',
    DIDI_FOOD_DISINTEGRATED: 'DIDI_FOOD_DISINTEGRATED',
    DIDI_FOOD_DISINTEGRATION_FAILED: 'DIDI_FOOD_DISINTEGRATION_FAILED',
    DIDI_FOOD_STORE_OPENED: 'DIDI_FOOD_STORE_OPENED',
    DIDI_FOOD_STORE_CLOSED: 'DIDI_FOOD_STORE_CLOSED',
    DELIVER_ORDERS_FROM_DIDI_FOOD_ENABLED: 'DELIVER_ORDERS_FROM_DIDI_FOOD_ENABLED',
    DELIVER_ORDERS_FROM_DIDI_FOOD_DISABLED: 'DELIVER_ORDERS_FROM_DIDI_FOOD_DISABLED',
    UBER_EATS_INTEGRATED: 'UBER_EATS_INTEGRATED',
    UBER_EATS_INTEGRATION_FAILED: 'UBER_EATS_INTEGRATION_FAILED',
    UBER_EATS_DISINTEGRATED: 'UBER_EATS_DISINTEGRATED',
    UBER_EATS_DISINTEGRATION_FAILED: 'UBER_EATS_DISINTEGRATION_FAILED',
    UBER_EATS_INTEGRATION_ENABLED: 'UBER_EATS_INTEGRATION_ENABLED',
    UBER_EATS_INTEGRATION_DISABLED: 'UBER_EATS_INTEGRATION_DISABLED',
    UBER_EATS_STORE_OPENED: 'UBER_EATS_STORE_OPENED',
    UBER_EATS_STORE_CLOSED: 'UBER_EATS_STORE_CLOSED',
    DELIVER_ORDERS_FROM_UBER_EATS_ENABLED: 'DELIVER_ORDERS_FROM_UBER_EATS_ENABLED',
    DELIVER_ORDERS_FROM_UBER_EATS_DISABLED: 'DELIVER_ORDERS_FROM_UBER_EATS_DISABLED',
    CARD_PAYMENT_PRE_AUTHORIZED: 'CARD_PAYMENT_PRE_AUTHORIZED',
    CARD_PAYMENT_PAID: 'CARD_PAYMENT_PAID',
    CARD_PAYMENT_FAILED: 'CARD_PAYMENT_FAILED',
    CARD_PAYMENT_REFUNDED: 'CARD_PAYMENT_REFUNDED',
    CARD_ADDED: 'CARD_ADDED',
    ADDING_CARD_FAILED: 'ADDING_CARD_FAILED',
    CARD_REMOVED: 'CARD_REMOVED',
    CARD_VALIDATED: 'CARD_VALIDATED',
    CARD_VALIDATION_FAILED: 'CARD_VALIDATION_FAILED',
    CARD_BLOCKED: 'CARD_BLOCKED',
    SUBSCRIPTION_CREATED: 'SUBSCRIPTION_CREATED',
    SUBSCRIPTION_CHANGED: 'SUBSCRIPTION_CHANGED',
    SUBSCRIPTION_REMOVED: 'SUBSCRIPTION_REMOVED',
    SUBSCRIPTION_CARD_ADDED: 'SUBSCRIPTION_CARD_ADDED',
    SUBSCRIPTION_CARD_REMOVED: 'SUBSCRIPTION_CARD_REMOVED',
    RESTAURANT_SETTINGS_CHANGED: 'RESTAURANT_SETTINGS_CHANGED',
    RESTAURANT_OPENING_HOURS_CHANGED: 'RESTAURANT_OPENING_HOURS_CHANGED',
    MENU_CHANGED: 'MENU_CHANGED',
    AWS_SMS_SENT: 'AWS_SMS_SENT',
    VOICES_SMS_SENT: 'VOICES_SMS_SENT',
    WHATSAPP_MESSAGE_SENT: 'WHATSAPP_MESSAGE_SENT',
    DELIVERY_MANUALLY_COMPLETED_BY_SUPPORT: 'DELIVERY_MANUALLY_COMPLETED_BY_SUPPORT',
    DELIVERY_REQUEST_SENT_TO_DRIVER: 'DELIVERY_REQUEST_SENT_TO_DRIVER',
    RESTAURANT_MANUAL_EARNING_CREATED: 'RESTAURANT_MANUAL_EARNING_CREATED',
    RESTAURANT_MANUAL_EARNING_CHANGED: 'RESTAURANT_MANUAL_EARNING_CHANGED',
    RESTAURANT_MANUAL_EARNING_FOR_SUBSCRIPTION_PAY_PER_USE_CREATED: 'RESTAURANT_MANUAL_EARNING_FOR_SUBSCRIPTION_PAY_PER_USE_CREATED',
    DRIVER_MANUAL_EARNING_CREATED: 'DRIVER_MANUAL_EARNING_CREATED',
    DRIVER_MANUAL_EARNING_CHANGED: 'DRIVER_MANUAL_EARNING_CHANGED',
    DRIVER_REQUESTED: 'DRIVER_REQUESTED',
    DRIVER_MANUALLY_ASSIGNED_TO_ORDER: 'DRIVER_MANUALLY_ASSIGNED_TO_ORDER',
    DELIVERY_PROVIDER_SWITCHED_TO_UBER_DIRECT: 'DELIVERY_PROVIDER_SWITCHED_TO_UBER_DIRECT',
    DELIVERY_PROVIDER_SWITCHED_TO_UBER_DAAS: 'DELIVERY_PROVIDER_SWITCHED_TO_UBER_DAAS',
    DELIVERY_PROVIDER_SWITCHED_TO_PIDE_DIRECTO: 'DELIVERY_PROVIDER_SWITCHED_TO_PIDE_DIRECTO',
    DELIVERY_PROVIDER_SWITCHED_TO_RAPPI_CARGO: 'DELIVERY_PROVIDER_SWITCHED_TO_RAPPI_CARGO',
    QUICK_ACTION_ACTIVATED: 'QUICK_ACTION_ACTIVATED',
    QUICK_ACTION_DEACTIVATED: 'QUICK_ACTION_DEACTIVATED',
    QUICK_ACTION_CREATED: 'QUICK_ACTION_CREATED',
    QUICK_ACTION_CHANGED: 'QUICK_ACTION_CHANGED',
    QUICK_ACTION_REMOVED: 'QUICK_ACTION_REMOVED',
    CUSTOMER_CREDITS_ADDED: 'CUSTOMER_CREDITS_ADDED',
    CUSTOMER_CREDITS_REMOVED: 'CUSTOMER_CREDITS_REMOVED',
    PROMO_CODE_CREATED: 'PROMO_CODE_CREATED',
    PROMO_CODE_CHANGED: 'PROMO_CODE_CHANGED',
    SCRIPT_RUN_STARTED: 'SCRIPT_RUN_STARTED',
    SCRIPT_RUN_ENDED: 'SCRIPT_RUN_ENDED',
    PATCH_RUN_STARTED: 'PATCH_RUN_STARTED',
    PATCH_RUN_ENDED: 'PATCH_RUN_ENDED',
    FAILED_TO_RUN_SCRIPT: 'FAILED_TO_RUN_SCRIPT',
    FAILED_TO_RUN_PATCH: 'FAILED_TO_RUN_PATCH',
    USER_SIGNED_IN_TO_PIDE_DIRECTO_ADMIN: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_ADMIN',
    USER_SIGNED_IN_TO_PIDE_DIRECTO: 'USER_SIGNED_IN_TO_PIDE_DIRECTO',
    USER_SIGNED_UP_TO_PIDE_DIRECTO: 'USER_SIGNED_UP_TO_PIDE_DIRECTO',
    USER_SIGNED_IN_TO_PIDE_DIRECTO_APP: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_APP',
    USER_SIGNED_UP_TO_PIDE_DIRECTO_APP: 'USER_SIGNED_UP_TO_PIDE_DIRECTO_APP',
    USER_OPENED_REQUEST_DRIVER_DIALOG: 'USER_OPENED_REQUEST_DRIVER_DIALOG',
    USER_SELECTED_RESTAURANT: 'USER_SELECTED_RESTAURANT',
    USER_OPENED_CONFIRM_DIRECTION_DIALOG: 'USER_OPENED_CONFIRM_DIRECTION_DIALOG',
    USER_OPENED_PAGE: 'USER_OPENED_PAGE',
    USER_CLICKED_BUTTON: 'USER_CLICKED_BUTTON',
    USER_CLICKED_TAB: 'USER_CLICKED_TAB',
    USER_OPENED_DIALOG: 'USER_OPENED_DIALOG',
    USER_OPENED_CONTEXTUAL_MENU: 'USER_OPENED_CONTEXTUAL_MENU',
    USER_CLICKED_CONTEXTUAL_MENU_ITEM: 'USER_CLICKED_CONTEXTUAL_MENU_ITEM',
    USER_TOGGLED_ACCORDION: 'USER_TOGGLED_ACCORDION',
    USER_OPENED_ALERT_DIALOG: 'USER_OPENED_ALERT_DIALOG',
    USER_TYPED_INPUT: 'USER_TYPED_INPUT',
    USER_CLICKED_SWITCH: 'USER_CLICKED_SWITCH',
    USER_CLICKED_SELECT_OPTION: 'USER_CLICKED_SELECT_OPTION',
    USER_OPENED_PIDEDIRECTO: 'USER_OPENED_PIDEDIRECTO',
    USER_SELECTED_ORDER_TYPE: 'USER_SELECTED_ORDER_TYPE',
    USER_OPENED_RESTAURANT_OPENING_HOURS_DIALOG: 'USER_OPENED_RESTAURANT_OPENING_HOURS_DIALOG',
    USER_SELECTED_RESTAURANT_FROM_SELECT_RESTAURANT_DIALOG: 'USER_SELECTED_RESTAURANT_FROM_SELECT_RESTAURANT_DIALOG',
    USER_SELECTED_RESTAURANT_FROM_CHAIN_RESTAURANTS: 'USER_SELECTED_RESTAURANT_FROM_CHAIN_RESTAURANTS',
    USER_OPENED_CART_DIALOG: 'USER_OPENED_CART_DIALOG',
    USER_OPENED_CART_DETAILS: 'USER_OPENED_CART_DETAILS',
    USER_ADDED_ITEM_TO_CART: 'USER_ADDED_ITEM_TO_CART',
    USER_REMOVED_ITEM_FROM_CART: 'USER_REMOVED_ITEM_FROM_CART',
    USER_SELECTED_ADDRESS: 'USER_SELECTED_ADDRESS',
    USER_ADDED_INVALID_PROMO_CODE: 'USER_ADDED_INVALID_PROMO_CODE',
    USER_ADDED_VALID_PROMO_CODE: 'USER_ADDED_VALID_PROMO_CODE',
    USER_SELECTED_PAYMENT_METHOD: 'USER_SELECTED_PAYMENT_METHOD',
});

export type LogEventType = (typeof LogEventTypes)[keyof typeof LogEventTypes];
