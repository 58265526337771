import type { TimeZone } from 'src/constants/TimeZone';
import { duplicates } from 'src/utils/reduce/duplicates';
import { datesAscending } from 'src/utils/sort/datesAscending';
import { formatPickupTime } from 'src/utils/transform/formatPickupTime';

export function formatPickupStationTimes(pickupTimes: Array<string | Date | undefined>, timeZone: TimeZone): Array<string> {
    return pickupTimes
        .reduce<Array<any>>(duplicates, [])
        .sort(datesAscending)
        .map((pickupTime) => formatPickupTime(pickupTime, timeZone));
}
