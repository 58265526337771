import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { Country } from 'src/constants/Country';
import { DiscountTypes } from 'src/constants/DiscountType';
import { PromoTypes } from 'src/constants/PromoType';
import { RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AddPromoCodeButton(): React.ReactElement {
    const promoCode = useSelector((state) => state.app.promoCode);
    const promoCodeDiscount = useSelector((state) => state.app.payment?.promoCodeDiscount);
    const promoCodeCredits = useSelector((state) => state.app.payment?.promoCodeCredits);
    const country = useSelector((state) => state.app.restaurant?.country);

    const openAddPromoCodeDialog = useAction(actions.openAddPromoCodeDialog);
    const removePromoCode = useAction(actions.removePromoCode);

    function translatePromoCode() {
        if (promoCode) {
            return promoCode.code;
        } else return translate('No Promo Code');
    }

    return (
        <CheckoutElement
            title={translate('Promo Code')}
            content={translatePromoCode()}
            action={!promoCode ? openAddPromoCodeDialog : removePromoCode}
            actionTitle={promoCode ? translate('Remove') : translate('Add')}
            description={promoCode && translatePromoCodeDescription(promoCode, promoCodeDiscount, promoCodeCredits, country)}
        />
    );
}

function translatePromoCodeDescription(promoCode: PromoCodeVm, promoCodeDiscount: string, promoCodeCredits: string, country: Country | undefined) {
    const discount =
        promoCode.discountType === DiscountTypes.AMOUNT
            ? formatAsCurrencyNumber(promoCode.discount, country as any)
            : `${promoCode.discount}% (${formatAsCurrencyNumber(promoCode.rewardType === RewardTypes.DISCOUNT ? promoCodeDiscount || '0' : promoCodeCredits || '0', country as any)})`;
    const freeDelivery = promoCode.freeDelivery ? ' ' + translate('Free Delivery.') : '';
    const minOrderAmountDescription = BigNumber(promoCode.minOrderAmount || '0').isGreaterThan(0)
        ? ' ' + translate('Minimum order @minorderamount.').replace('@minorderamount', formatAsCurrencyNumber(promoCode.minOrderAmount, country as any))
        : '';
    const maxDiscountAmountDescription = BigNumber(promoCode.maxDiscountAmount || '0').isGreaterThan(0)
        ? ' ' + translate('Maximum discount @maxdiscountamount.').replace('@maxdiscountamount', formatAsCurrencyNumber(promoCode.maxDiscountAmount, country as any))
        : '';
    if (promoCode.promoType === PromoTypes.FIRST_ORDER_PROMO) {
        if (promoCode.rewardType === RewardTypes.DISCOUNT) {
            return promoCode.description ?? translate('Save @discount on first order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription;
        }
        if (promoCode.rewardType === RewardTypes.CREDITS) {
            return (
                promoCode.description ?? translate('Receive @discount credits on first order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription
            );
        }
    }
    if (promoCode.promoType === PromoTypes.ONE_TIME_PROMO) {
        if (promoCode.rewardType === RewardTypes.DISCOUNT) {
            return promoCode.description ?? translate('Save @discount on this order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription;
        }
        if (promoCode.rewardType === RewardTypes.CREDITS) {
            return (
                promoCode.description ?? translate('Receive @discount credits on this order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription
            );
        }
    }
    if (promoCode.promoType === PromoTypes.REUSABLE_PROMO) {
        if (promoCode.rewardType === RewardTypes.DISCOUNT) {
            return promoCode.description ?? translate('Save @discount on every order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription;
        }
        if (promoCode.rewardType === RewardTypes.CREDITS) {
            return (
                promoCode.description ?? translate('Receive @discount credits on every order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription
            );
        }
    }

    if (promoCode.rewardType === RewardTypes.DISCOUNT) {
        return promoCode.description ?? translate('Save @discount on this order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription;
    }
    if (promoCode.rewardType === RewardTypes.CREDITS) {
        return promoCode.description ?? translate('Receive @discount credits on this order.').replace('@discount', discount) + freeDelivery + minOrderAmountDescription + maxDiscountAmountDescription;
    }

    return '';
}
