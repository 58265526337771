import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { EnvironmentLabel } from 'src/components/EnvironmentLabel';
import { translate } from 'src/i18n/translate';

export function UrlHasChangedPage({ redirectUrl }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <main className={classes.layout}>
            <div className={classes.notFoundContainer}>
                <Typography variant='h4' align='center' color='textSecondary'>
                    {translate('Url has moved!')}
                </Typography>
                <Typography variant='h5' align='center' color='textSecondary'>
                    {translate('Please use following url instead')}
                </Typography>
                <br />
                <Typography variant='h5' align='center' color='primary'>
                    <Link href={redirectUrl}>{redirectUrl}</Link>
                </Typography>
            </div>
            <EnvironmentLabel />
        </main>
    );
}

const useStyles = makeStyles((theme) => ({
    layout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notFoundContainer: {},
}));

type Props = {
    redirectUrl: string;
};
