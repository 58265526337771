import { BigNumber } from 'bignumber.js';
import { useSelector } from 'src/utils/react/useSelector';

export function useAmountToPay(): string {
    const total = useSelector((state) => state.app.payment?.total);
    const usedCredits = useSelector((state) => state.app.payment?.usedCredits);

    return BigNumber(total || 0)
        .minus(usedCredits || 0)
        .toString();
}
