import { getLanguage } from 'src/i18n/language';

export function formatAsPercentage(value: string | number | void | null): string {
    if (value === undefined || value === null) {
        return '';
    }

    return Intl.NumberFormat(getLanguage(), {
        style: 'percent',
        maximumFractionDigits: 8,
    }).format(value as any);
}
