import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import {
    isPromoCodeApplicableForMinOrderAmount,
    isPromoCodeApplicableForOrderItems,
    isPromoCodeApplicableForOrderType,
    isPromoCodeApplicableForPaymentMethod,
} from 'src/utils/promoCode/isPromoCodeApplicable';
import { isPromoCodeApplicableForRequireAtLeastOneCard } from 'src/utils/promoCode/isPromoCodeApplicableForRequireAtLeastOneCard';
import { useSelector } from 'src/utils/react/useSelector';
import { translateOrderType } from 'src/utils/translate/translateOrderType';

export function useGetInvalidPromoCodeMessage(): any {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const orderType = useSelector((state) => state.app.orderType);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const numberOfCards = useSelector((state) => state.app.cards?.length ?? 0);
    const promoCodeSubtotal = useSelector((state) => state.app.payment?.promoCodeSubtotal);
    const customerDeliveryCost = useSelector((state) => state.app.payment?.customerDeliveryCost);

    const getInvalidPromoCodeMessage = (promoCode: any, menuItemIds: any) => {
        if (!isPromoCodeApplicableForOrderType(promoCode, orderType)) {
            if (promoCode.orderTypes && !promoCode.orderTypes.includes(OrderTypes.PICKUP_STATION_ORDER)) {
                return translate('The promo code cannot be used for company orders.');
            }
            if (
                promoCode.orderTypes &&
                !promoCode.orderTypes.includes(OrderTypes.TAKE_AWAY_ORDER) &&
                !promoCode.orderTypes.includes(OrderTypes.SELF_SERVICE_ORDER) &&
                !promoCode.orderTypes.includes(OrderTypes.TABLE_ORDER)
            ) {
                return translate('The promo code cannot be used for pick up orders.');
            }
            if (promoCode.orderTypes && !promoCode.orderTypes.includes(OrderTypes.DELIVERY_ORDER)) {
                return translate('The promo code cannot be used for delivery orders.');
            }
            return translate('The promo code cannot be used when @ordertype is selected.').replace('@ordertype', translateOrderType(orderType));
        }
        if (!isPromoCodeApplicableForPaymentMethod(promoCode, paymentMethod)) {
            return translate('The promo code cannot be used with the selected payment method.');
        }
        if (!isPromoCodeApplicableForRequireAtLeastOneCard({ promoCode, numberOfCards })) {
            return translate('You must add a credit card to use this promo code.');
        }
        if (!isPromoCodeApplicableForMinOrderAmount(promoCode, promoCodeSubtotal, customerDeliveryCost)) {
            return translate('You must order for at least @amount to use this promo code.').replace('@amount', formatAsCurrencyNumber(promoCode.minOrderAmount));
        }
        if (!isPromoCodeApplicableForOrderItems(promoCode, menuItemIds)) {
            return translate('No valid promotion for your cartItems');
        }

        return '';
    };

    return getInvalidPromoCodeMessage;
}
