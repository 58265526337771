import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormEmailField, FormPasswordField } from '@pidedirecto/ui/form';
import { useForm, useWatch } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { GetAppContextSignedInApiResponse } from 'src/api/pidedirecto/getAppContextSignedInApi';
import { Text } from 'src/components/Text';
import { AuthenticationTypes } from 'src/constants/AuthenticationType';
import { LogEventTypes } from 'src/constants/LogEventType';
import { AwsFacade } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

export function SignInForDiningModeDialog({ onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();

    const form = useForm();
    const { getAppContextSignedIn } = useGetAppContext();

    const [error, setError] = useState<{ errorType: string; message: string }>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const open = useSelector((state) => state.app.signInDialog.open);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const diningModeEnabled = useSelector((state) => state.app.restaurant?.diningModeEnabled);
    const restaurant = useSelector((state) => state.app.restaurant);

    const closeSignInDialog = useAction(actions.closeSignInDialog);

    const formEmail: string = useWatch({ name: 'email', control: form.control });

    useEffect(() => {
        if (open) setError(undefined);
    }, [open]);

    const signInDiningMode = async (form: FormValues) => {
        const username = form?.email || formEmail;
        setIsSubmitting(true);
        const response = await AwsFacade.requestSignUpSignIn({ username, authenticationType: AuthenticationTypes.PASSWORD, restaurantId });
        setIsSubmitting(false);
        if (response.error) {
            setError(response.error);
            return;
        }
        setIsSubmitting(true);
        const responseVerifySignIn = await AwsFacade.verifySignIn(username, form.password, AuthenticationTypes.PASSWORD);
        setIsSubmitting(false);
        if (responseVerifySignIn.error) {
            setError(responseVerifySignIn.error);
            setIsSubmitting(false);
            return;
        }
        fetchAppContextRecursive();
    };

    const fetchAppContextRecursive = async () => {
        const appContextResponse = await getAppContextSignedIn({ urlSubdomain: getUrlSubdomain(), urlPathname: restaurant.urlPathname ?? '', preventAskForUserInfo: true });
        if (!appContextResponse.ok) {
            alert(translate('Failed to sign in, check you internet connection and press ok to retry'));
            fetchAppContextRecursive();
            return;
        }

        if (!appContextResponse.data) return;

        const appContext: GetAppContextSignedInApiResponse = appContextResponse.data as any;

        onSuccess?.(appContext);
        closeSignInDialog();
        setIsSubmitting(false);
        createUiInteractionLogEvent({
            logEventType: LogEventTypes.USER_SIGNED_IN_TO_PIDE_DIRECTO,
        });
    };

    if (!diningModeEnabled) return null;

    return (
        <Dialog classes={{ dialog: classes.dialog, title: classes.title }} open={open} loading={isSubmitting} title={translate('Welcome')} onClose={closeSignInDialog}>
            <Form form={form} onSubmit={signInDiningMode} id={'form'}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Text style={{ textAlign: 'center' }}>{translate('Enter your email address and password to continue with order')}</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <FormEmailField label={translate('Email')} name={'email'} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPasswordField name='password' label={translate('Password')} inputProps={{ autoComplete: 'off' } as any} required disabled={isSubmitting} />
                    </Grid>
                    <Grid item xs={12}>
                        {!!error && <Text>{translate(error.message)}</Text>}
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button type='submit' classes={{ button: classes.button }} disabled={isSubmitting || !formEmail}>
                        {translate('Verify')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 8,
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
        maxWidth: '90%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 420,
        },
        overflow: 'hidden',
    },
    button: {
        flexGrow: 1,
    },
    title: {
        color: theme.palette.text.primary,
    },
}));

type FormValues = {
    email: string;
    password: string;
};

type Props = {
    onSuccess?: Function;
};
