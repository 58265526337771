import short from 'short-uuid';
import type { Uuid } from 'src/types/Id';

const translator = short();

export function uuidFromShortUuid<T extends Uuid | undefined>(value: T): T {
    if (!value) {
        return value;
    }
    return translator.toUUID(value) as T;
}
