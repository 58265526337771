import * as React from 'react';

export function CardIcon({ color, className }: Props): React.ReactElement {
    return (
        <svg width='19' height='15' viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
            <path
                d='M3.42045 0.1875C2.74536 0.1875 2.09791 0.455681 1.62055 0.933046C1.14318 1.41041 0.875 2.05786 0.875 2.73295V3.36932H18.6932V2.73295C18.6932 2.05786 18.425 1.41041 17.9476 0.933046C17.4703 0.455681 16.8228 0.1875 16.1477 0.1875H3.42045Z'
                fill={color ?? '#4F586E'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.6932 5.27841H0.875V11.642C0.875 12.3171 1.14318 12.9646 1.62055 13.442C2.09791 13.9193 2.74536 14.1875 3.42045 14.1875H16.1477C16.8228 14.1875 17.4703 13.9193 17.9476 13.442C18.425 12.9646 18.6932 12.3171 18.6932 11.642V5.27841ZM3.42045 8.46023C3.42045 8.29145 3.4875 8.12959 3.60684 8.01025C3.72618 7.89091 3.88804 7.82386 4.05682 7.82386H9.14773C9.3165 7.82386 9.47836 7.89091 9.5977 8.01025C9.71705 8.12959 9.78409 8.29145 9.78409 8.46023C9.78409 8.629 9.71705 8.79086 9.5977 8.9102C9.47836 9.02955 9.3165 9.09659 9.14773 9.09659H4.05682C3.88804 9.09659 3.72618 9.02955 3.60684 8.9102C3.4875 8.79086 3.42045 8.629 3.42045 8.46023ZM4.05682 10.3693C3.88804 10.3693 3.72618 10.4364 3.60684 10.5557C3.4875 10.675 3.42045 10.8369 3.42045 11.0057C3.42045 11.1745 3.4875 11.3363 3.60684 11.4557C3.72618 11.575 3.88804 11.642 4.05682 11.642H6.60227C6.77105 11.642 6.93291 11.575 7.05225 11.4557C7.17159 11.3363 7.23864 11.1745 7.23864 11.0057C7.23864 10.8369 7.17159 10.675 7.05225 10.5557C6.93291 10.4364 6.77105 10.3693 6.60227 10.3693H4.05682Z'
                fill={color ?? '#4F586E'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    className?: string;
};
