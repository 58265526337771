import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';

export function FormCurrencyNumberStringField({ name, label, placeholder, helperText, defaultValue = null, disabled, required, min, max }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <Input
                    name={name}
                    label={label}
                    leftIcon={'$'}
                    InputComponent={NumberFormatCustom}
                    value={transformStringInput(value)}
                    onChange={(value: any) => onChange(transformStringOutput(value))}
                    helperText={helperText}
                    placeholder={placeholder}
                    disabled={isSubmitting || disabled}
                    error={!!getError(errors, name)}
                    type='number'
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                min: {
                    value: (min as any)?.value ?? min,
                    message: (min as any)?.message ?? translate('Must be number equal to or larger than @number', { number: (min as any)?.value ?? min }),
                },
                max: {
                    value: (max as any)?.value ?? max,
                    message: (max as any)?.message ?? translate('Must be number equal to or less than @number', { number: (max as any)?.value ?? max }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom({ inputRef, onChange, ...other }: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => onChange(values.value)}
            // allowedDecimalSeparators={[',', '.']}
            thousandSeparator
            isNumericString
            decimalScale={2}
            // prefix="$"
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null;
    autoComplete?: AutoCompleteInputProp;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    min?:
        | number
        | {
              message: string;
              value: number;
          };
    max?:
        | number
        | {
              message: string;
              value: number;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
};
