import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { isUruguay } from 'src/utils/country/isUruguay';

export function canRestaurantInvoice(restaurant?: RestaurantVm): boolean {
    if (!restaurant) return false;

    if (isUruguay(restaurant.country)) {
        return !!restaurant.uruwareClave;
    }
    return restaurant.csdCertificateUploaded || !!restaurant.csdCertificateRestaurantId;
}
