import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function isToday(date?: string | Date | moment.Moment, timeZone?: TimeZone): boolean {
    if (!date) return false;
    if (!timeZone) {
        return moment(date, timeZone).isSame(moment(), 'day');
    }
    return moment.tz(date, timeZone).isSame(moment.tz(timeZone), 'day');
}
