import moment from 'moment-timezone';
import OpeningHours from 'opening_hours';
import type { TimeZone } from 'src/constants/TimeZone';
import { momentToLocalDate } from 'src/utils/date/momentToLocalDate';

export function isDateWithinHours(hours: string | null | undefined, date: Date | string, timeZone: TimeZone): boolean {
    if (!hours || hours.trim() === '' || hours.trim() === '24/7' || hours.trim() === '00:00-24:00') {
        return true;
    }
    const oh = new OpeningHours(hours);
    const localDate = momentToLocalDate(moment.tz(date, timeZone));
    return oh.getState(localDate);
}
