import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addCardApi } from 'src/api/pidedirecto/card/addCardApi';
import { useConfirmDialog } from 'src/components/cart/ConfirmDialog';
import { LoadConektaScript } from 'src/components/conekta/LoadConektaScript';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormCardDetailsField } from 'src/components/form/FormCardDetailsField';
import { LoadStripeScript } from 'src/components/stripe/LoadStripeScript';
import { Text } from 'src/components/Text';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { createConektaCardToken } from 'src/facade/conekta/createConektaCardToken';
import { createMercadoPagoCardToken } from 'src/facade/mercadoPago/createMercadoPagoCardToken';
import { createStripeCardToken } from 'src/facade/stripe/createStripeCardToken';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useDoesTotalNeedCardValidation } from 'src/services/card/useDoesTotalNeedCardValidation';
import { useAmountToPay } from 'src/services/order/useAmountToPay';
import { isMexico } from 'src/utils/country/isMexico';
import { encryptDataInTransit } from 'src/utils/crypto/encryptDataInTransit';
import { fullName } from 'src/utils/customer/fullName';
import { logError } from 'src/utils/log/logError';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function DefaultAddNewCardDialog(): React.ReactElement {
    const classes = useStyles();
    const confirm = useConfirmDialog();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const doesTotalNeedCardValidation = useDoesTotalNeedCardValidation();

    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.app.addNewCardDialog.open);
    const customerId = useSelector((state) => state.app.customerId);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const firstName = useSelector((state) => state.app.firstName);
    const lastName = useSelector((state) => state.app.lastName);
    const country = useSelector((state) => state.app.restaurant?.country);

    const closeAddNewCardDialog = useAction(actions.closeAddNewCardDialog);
    const openValidateCardDialog = useAction(actions.openValidateCardDialog);
    const addCard = useAction(actions.addCard);
    const selectPaymentMethod = useAction(actions.selectPaymentMethod);

    const amountToPay = useAmountToPay();

    useEffect(() => {
        if (!customerId) return;
        const script = document.createElement('script');
        script.src = 'https://www.mercadopago.com/v2/security.js';
        script.setAttribute('view', 'checkout');
        script.async = true;
        document?.body?.appendChild(script);
    }, [open]);

    const onSubmit = async (form: any) => {
        if (isSubmitting) return;
        setLoading(true);

        let conektaResponse;
        let stripeResponse;
        if (isMexico(country)) {
            conektaResponse = await createConektaCardToken({
                ...form.card,
                name: fullName(firstName, lastName),
            });
            stripeResponse = await createStripeCardToken(form.card);
        }

        const mercadoPagoResponse = await createMercadoPagoCardToken({ ...form.card, name: firstName, country, deviceId: window.MP_DEVICE_SESSION_ID });

        if (!conektaResponse?.ok && !stripeResponse?.ok && !mercadoPagoResponse?.mercadoPagoCardToken) {
            console.log('Failed to add card', { conektaResponse, stripeResponse, mercadoPagoResponse });
            await confirm({
                title: `${translate('Failed to add card')}`,
                content: `${(conektaResponse?.data.errorMessage || stripeResponse?.data.errorMessage || mercadoPagoResponse?.errorMessage) ?? ''}`,
            });
            return;
        }

        const response = await addCardApi({
            restaurantId,
            customerId: requireValue(customerId),
            cardToken: encryptDataInTransit(form.card),
            stripeCardToken: stripeResponse?.data.stripeCardToken,
            conektaCardToken: conektaResponse?.data.conektaCardToken,
            mercadoPagoCardToken: mercadoPagoResponse?.mercadoPagoCardToken,
            amount: amountToPay,
            country,
        });

        setLoading(false);
        if (!response.ok) {
            logError('Failed to add card', {
                request: { customerId, cardToken: '****', stripeCardToken: '****', conektaCardToken: '****', mercadoPagoCardToken: '****' },
                response,
            });
            await confirm({
                title: `${translate('Failed to add card')}`,
                content: `${
                    isMexico(country)
                        ? translate('Failed to add card. Make sure you have at least $20 on your cards. Otherwise, you can try to contact your bank to solve this problem or try another card.')
                        : translate('Failed to add card. You can try to contact your bank to solve this problem or try another card.')
                }`,
            });
            return;
        }

        const card = response.data;
        addCard(card);
        selectPaymentMethod({ paymentMethod: PaymentMethods.CREDIT_CARD, card });
        closeAddNewCardDialog();
        if (doesTotalNeedCardValidation({ total: amountToPay, card })) openValidateCardDialog({ card });
    };

    return (
        <>
            <LoadStripeScript />
            <LoadConektaScript />
            <Dialog
                title={translate('Add New Card')}
                open={open}
                onClose={closeAddNewCardDialog}
                actionText={translate('Next')}
                actionButtonFormId='form'
                disabled={isSubmitting}
                loading={isSubmitting}
                actionButtonDisabled={loading}
            >
                <Form form={form} onSubmit={onSubmit} className={classes.form} id='form'>
                    <div className={classes.formContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormCardDetailsField name='card' label='' required />
                            </Grid>
                            {doesTotalNeedCardValidation({ total: amountToPay }) && (
                                <Text error style={{ textAlign: 'center', fontSize: 12 }}>
                                    {translate(
                                        'We will charge a random amount less than $20 on your card that you will need to send to us to verify that you are the owner of this card. The amount will be refunded and only used for verification.',
                                    )}
                                </Text>
                            )}
                        </Grid>
                    </div>
                </Form>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContent: {
        padding: theme.spacing(2),
    },
    dialog: {
        width: '30vw',
        [theme.breakpoints.down('md')]: {
            width: '40vw',
        },
    },
}));
