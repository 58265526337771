import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { AddressVm } from 'src/api/pidedirecto/types/AddressVm';
import { BottomDialogCenteredButton } from 'src/components/BottomDialogCenteredButton';
import { BottomDialogCheckButton, runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { translate } from 'src/i18n/translate';
import { PinIcon } from 'src/icons/PinIcon';
import { PlusIcon } from 'src/icons/PlusIcon';
import { actions } from 'src/reducers';
import { createSelectAddressLogEvent } from 'src/services/logEvent/createSelectAddressLogEvent';
import { AddressId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectAddressDialog(): React.ReactElement {
    const classes = useStyles();

    const open = useSelector((state) => state.app.selectAddressDialog.open);
    const address = useSelector((state) => state.app.address);
    const addresses = useSelector((state) => state.app.addresses);
    const useNonGeoLocatedAddress = useSelector((state) => state.app.restaurant?.useNonGeoLocatedDeliveryAddressesEnabled);

    const closeSelectAddressDialog = useAction(actions.closeSelectAddressDialog);
    const selectAddress = useAction(actions.selectAddress);
    const openAddNewAddressDialog = useAction(actions.openAddNewAddressDialog);
    const openAddNewNonGeoLocatedAddressDialog = useAction(actions.openAddNewNonGeoLocatedAddressDialog);
    const openEditAddressDialog = useAction(actions.openEditAddressDialog);
    const openEditNonGeoLocatedAddressDialog = useAction(actions.openEditNonGeoLocatedAddressDialog);
    const openRemoveAddressDialog = useAction(actions.openRemoveAddressDialog);

    const [selected, setSelected] = useState(address);
    const editAddress = (address: AddressVm) => {
        if (useNonGeoLocatedAddress) {
            openEditNonGeoLocatedAddressDialog(address);
            return;
        }
        openEditAddressDialog(address);
    };
    const confirmRemove = (addressId: AddressId) => {
        openRemoveAddressDialog(addressId);
    };

    const handleNewAddress = () => {
        closeSelectAddressDialog();
        if (useNonGeoLocatedAddress) {
            openAddNewNonGeoLocatedAddressDialog();
            return;
        }
        openAddNewAddressDialog();
    };

    useEffect(() => {
        if (open) setSelected(address);
    }, [open]);

    const availableAddresses = (useNonGeoLocatedAddress ? addresses : filterAddressesWithLocation(addresses)) ?? [];

    return (
        <Dialog classes={{ content: classes.contentDialog }} open={open} onClose={closeSelectAddressDialog} title={translate('Where should we deliver?')}>
            <div className={classes.addressesContainer}>
                {availableAddresses?.map((address) => (
                    <div className={classes.addressItem} key={address.addressId}>
                        <BottomDialogCheckButton
                            key={address.addressId}
                            checked={address.addressId === selected?.addressId}
                            Icon={PinIcon}
                            text={address.street}
                            subtext={address.other}
                            onClickRow={() => {
                                setSelected(address);
                                selectAddress(address);
                                createSelectAddressLogEvent({
                                    address: address.street,
                                });
                                runAfterCheckAnimation(closeSelectAddressDialog);
                            }}
                        />
                        <Button icon onClick={() => editAddress(address)}>
                            <EditIcon />
                        </Button>
                        <Button icon onClick={() => confirmRemove(address.addressId)}>
                            <DeleteIcon />
                        </Button>
                    </div>
                ))}
            </div>
            <BottomDialogCenteredButton
                Icon={PlusIcon}
                text={translate('Add New Address')}
                onClick={() => {
                    handleNewAddress();
                }}
            />
        </Dialog>
    );
}

function filterAddressesWithLocation(addresses: Array<AddressVm> | undefined) {
    return addresses?.filter((address) => address.location);
}

const useStyles = makeStyles((theme) => ({
    addressesContainer: {
        width: '100%',
        maxHeight: '80vh',
        flexDirection: 'column',
        display: 'flex',
        overflowY: 'auto',
    },
    addressItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    contentDialog: {
        overflowY: 'hidden',
    },
}));
