import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import TagManager from 'react-gtm-module';
import { Button } from 'src/components/Button';
import { LogEventTypes } from 'src/constants/LogEventType';
import { SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { SubscriptionStatuses } from 'src/constants/SubscriptionStatus';
import { AwsFacade, IsSignedInResults } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { checkYourInternetConnectionAndTryAgain } from 'src/utils/alert/checkYourInternetConnectionAndTryAgain';
import { isWithinHours } from 'src/utils/hours/isWithinHours';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CartContinueButton(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const minimumOrderAmount = useSelector((state) => state.app.restaurant?.minimumOrderAmount);
    const maximumOrderAmount = useSelector((state) => state.app.restaurant?.maximumOrderAmount);
    const total = useSelector((state) => state.app.payment?.total);
    const remainingDays = useSelector((state) => state.app.restaurant?.remainingDays);
    const subscriptionPlan = useSelector((state) => state.app.restaurant?.subscriptionPlan);
    const subscriptionStatus = useSelector((state) => state.app.restaurant?.subscriptionStatus);
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant?.plannedOrdersEnabled);
    const restaurant = useSelector((state) => state.app.restaurant);
    const signedIn = useSelector((state) => state.app.signedIn);
    const cartItems = useSelector((state) => state.app.cartItems);

    const closeCartDialog = useAction(actions.closeCartDialog);
    const openWarningAlert = useAction(actions.openWarningAlert);
    const openCheckoutDialog = useAction(actions.openCheckoutDialog);
    const openSignInDialog = useAction(actions.openSignInDialog);

    const closed = !isWithinHours(restaurant.hours, restaurant.timeZone);
    const isServiceCutOffAndFreeTrial = remainingDays <= 0 && subscriptionPlan === SubscriptionPlans.FREE_TRIAL && !!subscriptionPlan;
    const isServiceCutOffAndNotPayPerUse = remainingDays <= 0 && subscriptionPlan !== SubscriptionPlans.PAY_PER_USE && !!subscriptionPlan;
    const disabled = isServiceCutOffAndFreeTrial || isServiceCutOffAndNotPayPerUse || subscriptionStatus === SubscriptionStatuses.CANCELLED || (closed && !plannedOrdersEnabled);
    const isMinimumOrderAmountNotMet = minimumOrderAmount && BigNumber(minimumOrderAmount).isGreaterThan(total);
    const isMaximumOrderAmountMet = maximumOrderAmount && BigNumber(total).isGreaterThan(maximumOrderAmount);

    const signInAndOpenCheckout = async () => {
        if (disabled) return;

        if (isMinimumOrderAmountNotMet) {
            openWarningAlert({
                title: translate('Minimum order amount not met'),
                message: translate('Minimum order amount: @minimumOrderAmount', { minimumOrderAmount: formatAsCurrencyNumber(minimumOrderAmount) }),
            });
            return;
        }

        if (isMaximumOrderAmountMet) {
            openWarningAlert({
                title: translate('Maximum order amount reached'),
                message: translate('Maximum order amount: @maximumOrderAmount', { maximumOrderAmount: formatAsCurrencyNumber(maximumOrderAmount) }),
            });
            return;
        }

        if (signedIn) {
            handleOpenCheckout();
            return;
        }

        const result = await AwsFacade.isSignedIn(true);
        if (result === IsSignedInResults.NETWORK_ERROR) {
            checkYourInternetConnectionAndTryAgain();
        }
        if (result === IsSignedInResults.SIGNED_IN) {
            handleOpenCheckout();
        } else {
            openSignInDialog({ onSuccess: () => handleOpenCheckout() });
        }
    };

    const handleOpenCheckout = () => {
        if (restaurant.googleAnalyticsId) {
            const dataLayer = {
                event: 'Checkout',
                products: cartItems,
                total: total,
            } as const;
            const tagManagerData = {
                gtmId: restaurant.googleAnalyticsId,
                dataLayer,
            } as const;
            TagManager.initialize(tagManagerData);
        }
        createUiInteractionLogEvent({
            logEventType: LogEventTypes.USER_OPENED_CART_DIALOG,
        });
        openCheckoutDialog();
    };

    return (
        <Button
            classes={{ button: classes.button }}
            onClick={() => {
                closeCartDialog();
                signInAndOpenCheckout();
            }}
            disabled={disabled}
        >
            {translate('Continue')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: 60,
        fontSize: 20,
        fontWeight: 600,
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
