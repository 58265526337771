import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import type { CardVm } from 'src/types/CardVm';
import { translateCard } from 'src/utils/translate/translateCard';

export function translatePaymentMethod(paymentMethod: PaymentMethod, card?: CardVm, orderType?: OrderType): string {
    switch (paymentMethod) {
        case PaymentMethods.PAYROLL: {
            return translate('Pay with payroll');
        }
        case PaymentMethods.CREDIT_CARD: {
            if (!card) {
                return translate('Pay with card');
            }
            return translateCard(card);
        }
        case PaymentMethods.CARD_ON_DELIVERY: {
            return translate('Pay with card on delivery');
        }
        case PaymentMethods.CASH: {
            if (orderType === OrderTypes.PICKUP_STATION_ORDER) {
                return translate('Pay at delivery');
            }
            if (orderType === OrderTypes.DELIVERY_ORDER) {
                return translate('Pay with cash');
            }
            return translate('Pay in store');
        }
        case PaymentMethods.TRANSFER: {
            return translate('Transfer');
        }
        default: {
            return '';
        }
    }
}
