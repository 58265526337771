import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { PaymentMethodIcon } from 'src/components/PaymentMethodIcon';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { isCardPayment } from 'src/utils/order/isCardPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function SelectPaymentMethodButton(): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const card = useSelector((state) => state.app.card);
    const orderType = useSelector((state) => state.app.orderType);
    const customPaymentMethod = useSelector((state) => state.app.customPaymentMethod);
    const ecommerceOrdersCashbackEnabled = useSelector((state) => state.app.restaurant?.ecommerceOrdersCashbackEnabled);
    const ecommerceOrdersCashbackPercentage = useSelector((state) => state.app.restaurant?.ecommerceOrdersCashbackPercentage);
    const total = useSelector((state) => state.app.payment?.total);

    const openSelectPaymentMethodDialog = useAction(actions.openSelectPaymentMethodDialog);

    const paymentMethodText = translatePaymentMethod(paymentMethod, card, orderType) || translate('No payment method');
    const cashbackOrder = BigNumber(total)
        .multipliedBy(ecommerceOrdersCashbackPercentage ?? 0)
        .dividedBy(100)
        .toString();

    return (
        <CheckoutElement
            title={translate('Payment Method')}
            content={customPaymentMethod ? translate(customPaymentMethod?.customPaymentMethod) : paymentMethodText}
            action={openSelectPaymentMethodDialog}
            actionTitle={paymentMethod ? translate('Change') : translate('Select payment method')}
            icon={<PaymentMethodIcon />}
            tag={
                ecommerceOrdersCashbackEnabled && isCardPayment(paymentMethod)
                    ? translate('You will receive @totalCashback in cashback', { totalCashback: formatAsCurrencyNumber(cashbackOrder) })
                    : undefined
            }
        />
    );
}
