import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { CreditsCheckbox } from 'src/components/cart/CreditsCheckbox';
import { DriverTip } from 'src/components/cart/DriverTip';
import { CartItems } from 'src/components/CartItems';
import { AddPromoCodeButton } from 'src/components/checkout/AddPromoCodeButton';
import { CheckoutBottom } from 'src/components/checkout/CheckoutBottom';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { EstimatedDeliverySection } from 'src/components/checkout/EstimatedDeliverySection';
import { SelectAddressButton } from 'src/components/checkout/SelectAddressButton';
import { SelectPaymentMethodButton } from 'src/components/checkout/SelectPaymentMethodButton';
import { SelectPickupTimeButton } from 'src/components/checkout/SelectPickupTimeButton';
import { SelectTableButton } from 'src/components/checkout/SelectTableButton';
import { Input } from 'src/components/Input';
import { OtherDriverTipDialog } from 'src/components/OtherDriverTipDialog';
import { translate } from 'src/i18n/translate';
import { CartContext } from 'src/providers/CartProvider';
import { actions } from 'src/reducers';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTableOrder } from 'src/utils/order/isTableOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CheckoutContent(): React.ReactElement {
    const classes = useStyles();
    const cartContext = useContext(CartContext);

    const [mandatoryCash, setMandatoryCash] = useState('0');

    const orderType = useSelector((state) => state.app.orderType);
    const usableLetsEatCredits = useSelector((state) => state.app.payment?.usableLetsEatCredits);
    const mandatoryCashAmountEnabled = useSelector((state) => state.app.restaurant?.mandatoryCashAmountEnabled);
    const ownFleetEnabled = useSelector<boolean | undefined>((state) => state.app.restaurant?.ownFleetEnabled);
    const externalOwnFleetEnabled = useSelector<boolean | undefined>((state) => state.app.restaurant?.externalOwnFleetEnabled);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const total = useSelector((state) => state.app.payment?.total);
    const roomServiceCustomLabel = useSelector((state) => state.app.restaurant?.roomServiceCustomLabel);

    const addNotes = useAction(actions.addNotes);
    const addRoomServiceNumber = useAction(actions.addRoomServiceNumber);
    const setMandatoryCashAmount = useAction(actions.setMandatoryCashAmount);

    useEffect(() => {
        onBlurMandatoryCashAmount(mandatoryCash);
    }, [paymentMethod, orderType, total]);

    const setNote = (value: any) => {
        const normalizedNote = value.trim();
        if (normalizedNote === '') {
            addNotes(undefined);
        }
        addNotes(normalizedNote);
    };

    const onBlurMandatoryCashAmount = (value: string) => {
        setMandatoryCash(value);
        if (!value || BigNumber(value).isLessThan(total)) {
            cartContext.handleDisableOrderButton(true);
            return;
        }
        const normalizedCashAmount = value.trim();
        cartContext.handleDisableOrderButton(false);
        if (normalizedCashAmount === '') {
            setMandatoryCashAmount(undefined);
        }
        setMandatoryCashAmount(normalizedCashAmount);
    };

    return (
        <div className={classes.elements}>
            {isTableOrder(orderType) && <SelectTableButton />}
            {isDeliveryOrder(orderType) && <SelectAddressButton />}
            {isDeliveryOrder(orderType) && <EstimatedDeliverySection />}
            {isTakeAwayOrder(orderType) && <SelectPickupTimeButton />}
            {isRoomServiceOrder(orderType) && (
                <CheckoutElement
                    title={roomServiceCustomLabel || translate('Room service')}
                    content={<Input name={'roomServiceNumber'} placeholder={'1-423'} type={'text'} onChange={addRoomServiceNumber} required />}
                />
            )}
            <SelectPaymentMethodButton />
            {mandatoryCashAmountEnabled && isDeliveryOrder(orderType) && isCashPayment(paymentMethod) && (
                <CheckoutElement
                    title={translate('How much are you going to pay?')}
                    content={
                        <Input
                            name={'mandatoryCashAmount'}
                            value={mandatoryCash}
                            helperText={cartContext.cartHasError ? translate('The entered value is less than the total value.') : ''}
                            onChange={onBlurMandatoryCashAmount}
                            error={cartContext.cartHasError}
                        />
                    }
                />
            )}
            <AddPromoCodeButton />

            {!ownFleetEnabled && !externalOwnFleetEnabled && isDeliveryOrder(orderType) && (
                <>
                    <DriverTip />
                    <OtherDriverTipDialog />
                </>
            )}

            <CheckoutElement title={translate('My Order')} content={<CartItems />} />
            <CheckoutElement
                title={translate('Note')}
                content={<TextField fullWidth multiline variant='outlined' label={translate('Add a Note')} onChange={({ target: { value } }) => setNote(value)} />}
            />
            {usableLetsEatCredits && <CreditsCheckbox />}
            <CheckoutElement title={translate('Summary')} content={<CheckoutBottom />} />
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    elements: {
        paddingBottom: 130,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: 120,
            maxWidth: 372,
        },
    },
}));
