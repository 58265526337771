import { translate } from 'src/i18n/translate';

export function formatAsDetailedDuration(value?: number): string {
    // TODO: Return maybe type
    if (!value) {
        return '';
    }

    let seconds = Math.floor(value / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const milliseconds = value % 1000;

    seconds %= 60;
    minutes %= 60;
    hours %= 24;

    const durationParts = [];

    if (days) {
        durationParts.push(`${days} ${days === 1 ? translate('day') : translate('days')}`);
    }
    if (hours) {
        durationParts.push(`${hours} ${hours === 1 ? translate('hour') : translate('hours')}`);
    }
    if (minutes) {
        durationParts.push(`${minutes} ${minutes === 1 ? translate('minute') : translate('minutes')}`);
    }
    if (seconds) {
        durationParts.push(`${seconds} ${seconds === 1 ? translate('second') : translate('seconds')}`);
    }
    if (milliseconds) {
        durationParts.push(`${milliseconds} ${milliseconds === 1 ? translate('millisecond') : translate('milliseconds')}`);
    }

    if (!durationParts.length) {
        return `0 ${translate('seconds')}`;
    }

    return durationParts.join(' ');
}
