export function addRestaurantGoogleAnalytics(googleAnalyticsId: string): void {
    const body = document.getElementsByTagName('body')[0];

    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleAnalyticsId}`;
    iframe.width = '0';
    iframe.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const script1 = document.createElement('noscript');
    script1.appendChild(iframe);

    body.prepend(script1);

    const head = document.getElementsByTagName('head')[0];
    const script2 = document.createElement('script');
    script2.text = `
        try {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleAnalyticsId}')
        }catch(e){ console.log(e) }
    `;
    head.prepend(script2);
}
