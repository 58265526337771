import moment from 'moment-timezone';
import type { GiftVm } from 'src/types/GiftVm';
import { getDeviceTimeZone } from 'src/utils/getDeviceTimeZone';
import { nextDateTimeWithinHoursAfterDate } from 'src/utils/hours/nextDateTimeWithinHoursAfterDate';

export function giftIsValid(gift: GiftVm): boolean {
    const now = new Date();
    if (gift.validFrom && moment(gift.validFrom).isAfter(now)) {
        return false;
    }
    if (gift.validTo && moment(gift.validTo).isBefore(now)) {
        return false;
    }
    const nextValidHourDateTine = nextDateTimeWithinHoursAfterDate(gift.hours, new Date(), getDeviceTimeZone());
    if (!nextValidHourDateTine) {
        return false;
    }
    if (gift.validTo) {
        return moment.tz(nextValidHourDateTine, getDeviceTimeZone()).isBefore(gift.validTo);
    }
    return true;
}
