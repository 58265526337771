import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { getBackgroundPattern } from 'src/components/getBackgroundPattern';
import { GoogleMapsRestaurantLocations } from 'src/components/googlemaps/GoogleMapsRestaurantLocations';
import { SearchRestaurantItem } from 'src/components/SearchRestaurantItem';
import { distanceInKm } from 'src/utils/geojson/distanceInKm';
import { useCurrentLocation } from 'src/utils/react/useCurrentLocation';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantSelectStoreBanner(): React.ReactElement | null {
    const classes = useStyles();
    const { location } = useCurrentLocation();

    const restaurants = useSelector((state) => state.app.restaurants);
    const restaurantChain = useSelector((state) => state.app.restaurantChain);
    const addressLocationMap = useSelector((state) => state.app.addressLocationMap);

    const locationToMeasure = addressLocationMap?.location || location;
    const restaurantsSortedByDistance = [...restaurants]
        ?.sort((a, b) => (distanceInKm(a.location, locationToMeasure) ?? 0) - (distanceInKm(b.location, locationToMeasure) ?? 0))
        .filter((restaurant) => restaurant.urlPathname && restaurant.webOrdersEnabled);

    if (!restaurantChain) return null;

    return (
        <div style={{ backgroundImage: getBackgroundPattern() }} className={classes.bannerContainer}>
            <div className={classes.mapContainer}>
                <GoogleMapsRestaurantLocations customerLocation={locationToMeasure} restaurantsSortedByDistance={restaurantsSortedByDistance} />
            </div>
            <div className={classes.searchBar} style={{ height: 150 }}>
                <SearchRestaurantItem customerLocation={location} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        position: 'relative',
        height: 250,
        width: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    mapContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    searchBar: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));
