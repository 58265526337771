import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { ClockIcon } from 'src/icons/ClockIcon';
import { StoreOpenIcon } from 'src/icons/StoreOpenIcon';
import { createUserSelectedRestaurantFromChainRestaurantsLogEvent } from 'src/services/logEvent/createUserSelectedRestaurantFromChainRestaurantsLogEvent';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { distanceInKm } from 'src/utils/geojson/distanceInKm';
import { getNextOpeningHoursChange } from 'src/utils/hours/getNextOpeningHoursChange';
import { isWithinHours } from 'src/utils/hours/isWithinHours';
import { classNames } from 'src/utils/react/classNames';
import { isRestaurantWithinDeliveryDistance } from 'src/utils/restaurant/isRestaurantWithinDeliveryDistance';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

export function RestaurantItemsList({ restaurantsSortedByDistance, locationToMeasure }: Props): React.ReactElement {
    const classes = useStyles();

    const { getAppContext } = useGetAppContext();

    const selectRestaurant = (restaurant: RestaurantVm) => {
        getAppContext({ urlSubdomain: getUrlSubdomain(), urlPathname: restaurant.urlPathname ?? '' });
        createUserSelectedRestaurantFromChainRestaurantsLogEvent({
            restaurant,
        });
    };

    return (
        <ul className={classes.list}>
            {restaurantsSortedByDistance.map((restaurant) => {
                const tooFarAway = !isRestaurantWithinDeliveryDistance(restaurant, locationToMeasure);
                const distanceMessage = tooFarAway ? 'Further than the delivery radius' : 'Approximate distance, within the delivery range';
                const closed = !isWithinHours(restaurant.hours, restaurant.timeZone);
                const nextOpeningHoursChange = getNextOpeningHoursChange(restaurant.hours);

                const temporarilyWithoutDelivery = restaurant.temporarilyWithoutDelivery;
                const temporarilyOffline = restaurant.temporarilyOffline;

                return (
                    <li key={restaurant.restaurantId}>
                        <Button classes={{ button: classes.listItem }} onClick={() => selectRestaurant(restaurant)}>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.iconContainer}>
                                    <StoreOpenIcon color={'#4F525E'} />
                                </div>
                                <div className={classes.restaurantInfo}>
                                    <div className={classes.itemTextContainer}>
                                        <Text variant={'semiBold'} className={classes.restaurantName}>
                                            {restaurant.name}
                                        </Text>
                                        <h5 className={classes.locationText}>{restaurant.street} </h5>
                                    </div>
                                    <section className={classes.timeInfo}>
                                        {temporarilyWithoutDelivery && !temporarilyOffline && (
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 12 }}>
                                                <ClockIcon color={'#B40707'} />
                                                <h4 style={{ color: '#B40707' }} className={classes.timeText}>
                                                    {translate('Temporarily without delivery')}
                                                </h4>
                                            </div>
                                        )}
                                        {temporarilyOffline && (
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 12 }}>
                                                <ClockIcon color={'#B40707'} />
                                                <h4 style={{ color: '#B40707' }} className={classes.timeText}>
                                                    {translate('Temporarily offline')}
                                                </h4>
                                            </div>
                                        )}
                                        {closed && !temporarilyOffline && (
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <ClockIcon color={'#B40707'} />
                                                <h4 style={{ color: '#B40707' }} className={classes.timeText}>
                                                    {translate('Closed')}
                                                </h4>
                                            </div>
                                        )}
                                        {!closed && !temporarilyOffline && (
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <ClockIcon color={'#0CAB4C'} />
                                                <h4 style={{ color: '#0CAB4C' }} className={classes.timeText}>
                                                    {translate('Open')}
                                                </h4>
                                            </div>
                                        )}

                                        {!temporarilyOffline && (
                                            <h4 className={classes.timeText}>{nextOpeningHoursChange ? `${closed ? translate('Opens at') : translate('Closes at')} ${nextOpeningHoursChange}` : ''}</h4>
                                        )}
                                    </section>
                                </div>
                            </div>
                            {locationToMeasure && (
                                <Tooltip arrow className={classNames(classes.arrow, classes.tooltip)} title={translate(distanceMessage)} placement='right'>
                                    <h5 className={classes.distanceText} style={{ color: tooFarAway ? '#f84b4b' : '#848484' }}>
                                        {distanceInKm(restaurant.location, locationToMeasure)?.toFixed(1)} km
                                    </h5>
                                </Tooltip>
                            )}
                        </Button>
                    </li>
                );
            })}
        </ul>
    );
}

const useStyles = makeStyles((theme) => ({
    list: {
        overflow: 'hidden',
        height: 'fit-content',
        paddingBottom: '1vh',
        minWidth: '40%',
        listStyle: 'none',
        paddingLeft: 0,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    listItem: {
        borderRadius: 12,
        marginBottom: 12,
        display: 'flex',
        height: 'fit-content',
        background: '#EEEFF0',
        padding: '18px 12px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        color: '#0D3037',
        '&:hover': {
            background: '#EEEFF088',
        },
    },
    iconContainer: {
        marginTop: 3,
    },
    restaurantInfo: {
        marginLeft: 8,
    },
    itemTextContainer: {},
    distanceText: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '14px',
        textAlign: 'right',
        margin: 0,
        whiteSpace: 'nowrap',
    },
    restaurantName: {
        fontFamily: theme.typography.bold,
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        textAlign: 'left',
        color: '#0D3037',
    },
    locationText: {
        fontWeight: 400,
        lineHeight: '14px',
        fontSize: 12,
        margin: 0,
        textAlign: 'left',
    },
    timeInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '25%',
        marginTop: 12,
    },
    timeText: {
        fontSize: 12,
        fontWeight: 400,
        margin: 0,
        marginLeft: 8,
        color: '#4F525E',
    },
    //toltip Classes
    arrow: {
        color: theme.palette.secondary.main,
    },
    tooltip: {
        color: theme.palette.common.white,
    },
}));

type Props = {
    restaurantsSortedByDistance: Array<RestaurantVm>;
    locationToMeasure?: GeoJsonPointVm;
};
