import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'src/components/Button';
import type { Variant } from 'src/components/form/FormTypes';
import { Input } from 'src/components/Input';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import type { Country } from 'src/constants/Country';
import { translate } from 'src/i18n/translate';
import { DeleteIcon } from 'src/icons/DeleteIcon';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { trim } from 'src/utils/string/trim';

export function FormGoogleMapsAddressSearchAutocomplete({
    name,
    label,
    required,
    variant = 'outlined',
    country,
    autocompleteChange,
    addressFound,
    rightIcon,
    classes,
    onFocus,
    onBlur,
    helperText,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>(null);

    // const { location, error } = useCurrentLocation();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <SearchAutoComplete
                    label={label}
                    controllerProps={props}
                    variant={variant}
                    addressFound={addressFound}
                    inputRef={inputRef}
                    country={country}
                    autocompleteChange={autocompleteChange}
                    rightIcon={rightIcon}
                    classes={classes}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    helperText={helperText}
                />
            )}
            control={control}
            name={name}
            defaultValue={null}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

function SearchAutoComplete({
    label,
    controllerProps,
    variant,
    addressFound,
    inputRef,
    country,
    autocompleteChange,
    rightIcon,
    classes: classesProp,
    onChange,
    onFocus,
    onBlur,
    helperText,
}: PropsSearchAutoComplete) {
    const classes = useStyles();

    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);
    const mapRef = useRef<HTMLDivElement>(null);

    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
    const [value, setValue] = useState<any>();
    const [inputValue, setInputValue] = useState<string>('');
    const [lastInputValue, setLastInputValue] = useState<string>();
    const [hideLoad, setHideLoad] = useState(true); // Hide loading for x seconds to make it look smother
    const setAddressLocationMap = useAction(actions.setAddressLocationMap);

    const findPlaceFromQuery = (service: google.maps.places.PlacesService) => {
        return new Promise((resolve: (result: google.maps.places.PlaceResult[] | null) => void, reject: (error?: any) => void) => {
            service.findPlaceFromQuery(
                {
                    query: addressFound as any,
                    fields: ['formatted_address', 'place_id', 'name', 'geometry'],
                },
                function (results, status) {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) return resolve(results);
                    reject();
                },
            );
        });
    };

    const setAutocompleteValue = async () => {
        if (!mapRef.current) return;
        const service = new window.google.maps.places.PlacesService(mapRef.current);
        const results = await findPlaceFromQuery(service);
        if (!results?.[0]?.place_id || !results?.[0]?.geometry?.location) {
            onChange(null);
            setValue(null);
            if (inputRef.current) inputRef.current.value = '';
            setLastInputValue('');
            return;
        }
        const addressLocation = {
            type: 'Point',
            coordinates: [results[0].geometry.location.lng(), results[0].geometry.location.lat()],
        } as const;
        const newValue = {
            street: lastInputValue ?? results[0].formatted_address,
            googlePlaceId: results[0].place_id,
            name: results[0].name,
            formattedAddress: results[0].formatted_address,
            location: addressLocation,
        } as const;
        onChange(newValue);
        setValue(newValue);
        setInputValue(newValue.formattedAddress ?? '');
        //autocompleteChange(newValue);
        setLastInputValue(inputRef.current?.value);
    };

    useEffect(() => {
        autocompleteChange?.(value);
    }, [value]);

    useEffect(() => {
        if (addressFound && isLoaded) {
            setInputValue(addressFound);
            setAutocompleteValue();
        }
    }, [addressFound, isLoaded]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setHideLoad(false); // x seconds passed show loading now
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!isLoaded) {
        return (
            <TextField
                {...controllerProps}
                type='text'
                label={label}
                helperText={helperText}
                placeholder={!hideLoad ? translate('Loading google maps...') : ''}
                variant={variant}
                fullWidth
                onKeyPress={(e) => {
                    (e.key || e.code) === 'Enter' && e.preventDefault();
                }}
            />
        );
    }

    if (loadError) {
        return (
            <TextField
                {...controllerProps}
                type='text'
                label={label}
                variant={variant}
                fullWidth
                error={true}
                helperText={translate('Failed to load google maps, please check your connection and try again.')}
                onKeyPress={(e) => {
                    (e.key || e.code) === 'Enter' && e.preventDefault();
                }}
            />
        );
    }

    return (
        <div className={classes.inputContainer}>
            <Autocomplete
                fields={['name', 'geometry.location', 'place_id', 'formatted_address']}
                className={classes.autoCompleteInput}
                restrictions={country ? { country } : undefined}
                onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                onUnmount={() => setAutocomplete(undefined)}
                onPlaceChanged={() => {
                    if (autocomplete) {
                        const result = autocomplete.getPlace();
                        if (!result?.place_id || !result?.geometry?.location) {
                            onChange(null);
                            setValue(null);
                            if (inputRef.current) inputRef.current.value = '';
                            setLastInputValue('');
                            return;
                        }
                        const addressLocation = {
                            type: 'Point',
                            coordinates: [result.geometry.location.lng(), result.geometry.location.lat()],
                        } as const;
                        const newValue = {
                            street: result.formatted_address,
                            googlePlaceId: result.place_id,
                            name: result.name,
                            formattedAddress: result.formatted_address,
                            location: addressLocation,
                        } as const;
                        onChange(newValue);
                        setValue(newValue);
                        setInputValue(newValue.formattedAddress ?? '');
                        setAddressLocationMap(newValue);
                        //autocompleteChange(newValue);
                        setLastInputValue(inputRef.current?.value);
                    } else {
                        console.log('Autocomplete is not loaded yet!');
                    }
                }}
                // bounds={location ? new window.google.maps.Circle({ center: toLatLng(location), radius: 50000 }).getBounds() : undefined}
                // options={{ strictBounds: location ? true : false }}
            >
                <Input
                    name={'address'}
                    label={label}
                    placeholder={translate('Select a location')}
                    inputRef={inputRef}
                    value={inputValue}
                    onChange={(value: any) => setInputValue(value)}
                    classes={classesProp}
                    helperText={helperText}
                    rightIcon={
                        <>
                            {rightIcon}
                            {inputValue && (
                                <Button icon classes={{ button: classes.deleteIcon }} onClick={() => setInputValue('')}>
                                    <DeleteIcon color={'#97979c'} />
                                </Button>
                            )}
                        </>
                    }
                    onKeyDown={(e: React.KeyboardEvent) => {
                        (e.key || e.code) === 'Enter' && e.preventDefault();
                    }}
                    onFocus={() => {
                        onFocus?.();
                    }}
                    onBlur={() => {
                        onBlur?.();
                        if (!trim(inputRef?.current?.value)) {
                            autocomplete?.set('place', {});
                            return;
                        }
                    }}
                />
            </Autocomplete>
            <div style={{ display: 'none' }} ref={mapRef}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    autoCompleteInput: {
        width: '100%',
    },
    deleteIcon: {
        width: 30,
        height: 30,
        minHeight: 30,
        borderRadius: 30,
        flexShrink: 0,
        '& svg': {
            flexShrink: 0,
        },
    },
}));

type Props = {
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    variant?: Variant;
    country?: Country;
    rightIcon?: React.ReactNode;
    classes?: any;
    addressFound?: string;
    autocompleteChange?: any;
    onFocus?: any;
    onBlur?: any;
};

type PropsSearchAutoComplete = {
    label?: string;
    controllerProps: any;
    variant?: Variant;
    addressFound?: string;
    helperText?: string;
    inputRef: any;
    country?: Country;
    rightIcon?: React.ReactNode;
    classes?: any;
    autocompleteChange?: any;
    onChange: any;
    onFocus?: any;
    onBlur?: any;
};
