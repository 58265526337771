import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import * as React from 'react';
import { CartItem } from 'src/components/cart/CartItem';
import { CartSummary } from 'src/components/cart/CartSummary';
import { Dialog } from 'src/components/Dialog';
import { translate } from 'src/i18n/translate';
import { PromoCodeRadioGroup } from 'src/modules/promoCode/components/PromoCodeRadioGroup';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CartDialog(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const isScreenBiggerThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const open = useSelector((state) => state.app.cartDialog.open);
    const checkoutOpen = useSelector((state) => state.app.checkoutDialog.open);

    const cartItems = useSelector((state) => state.app.cartItems);
    const total = useSelector((state) => state.app.payment?.total);

    const closeCartDialog = useAction(actions.closeCartDialog);

    const dialogShouldBeClosed = cartItems.length === 0 || checkoutOpen;

    useEffect(() => {
        if (dialogShouldBeClosed) closeCartDialog();
    }, [dialogShouldBeClosed]);

    if (dialogShouldBeClosed) return null;

    return (
        <Dialog
            classes={{ dialog: classes.dialog, content: classes.dialogContent }}
            title={translate('My Order')}
            open={open}
            onClose={closeCartDialog}
            position={isScreenBiggerThanMobile ? 'right' : 'bottom'}
        >
            <div className={classes.cartContent}>
                <div className={classes.cartItemsContainer}>
                    {cartItems.map((cartItem) => {
                        return <CartItem key={cartItem.menuItemId} cartItem={cartItem} />;
                    })}
                    <PromoCodeRadioGroup />
                </div>
                <CartSummary />
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '100%',
        height: '88vh',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: 420,
            height: '100vh',
            backgroundColor: 'white',
            maxHeight: '100vh',
        },
    },
    dialogContent: {
        maxHeight: '100vh',
    },
    totalText: {
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
        },
    },
    total: {
        fontSize: 18,
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
        },
    },
    itemsCounter: {
        position: 'relative',
        display: 'flex',
        padding: '5px',
    },
    itemsQuantity: {
        position: 'absolute',
        display: 'grid',
        placeItems: 'center',
        fontSize: 14,
        width: 24,
        height: 24,
        backgroundColor: 'white',
        borderRadius: '50%',
        right: -10,
        top: -10,
        color: theme.palette.primary.main,
    },
    cartContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '60vh',
        },
    },
    topBar: {
        position: 'relative',
        width: 70,
        height: 4,
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: 4,
        marginTop: 8,
        marginBottom: 18,
        alignSelf: 'center',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    headerText: {
        fontSize: 24,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 500,
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
        },
    },
    cartItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
        marginTop: 24,
        height: '50vh',
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            height: '100%',
        },
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: 'rgba(0,0,0,0.2)',
        marginTop: 12,
    },
    amountContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        marginTop: 12,
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
        },
    },
    button: {
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: 60,
        fontSize: 20,
        fontWeight: 600,
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    amountText: {
        fontSize: 20,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            color: '#0D3037',
        },
    },
    amount: {
        fontSize: 26,
        fontWeight: 700,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            color: '#0D3037',
        },
    },
    cartBottom: {},
    minimumOrderAmount: {},
    maximumOrderAmount: {},
}));
