import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { signUpApi } from 'src/api/pidedirecto/customer/signUpApi';
import { GetAppContextSignedInApiResponse } from 'src/api/pidedirecto/getAppContextSignedInApi';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { FormTextField } from 'src/components/form/FormTextField';
import { PasswordValidatorIndicator } from 'src/components/input/password/PasswordValidatorIndicator';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { validatePassword } from 'src/services/customer/validatePassword';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { encryptDataInTransit } from 'src/utils/crypto/encryptDataInTransit';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

export function CreateCustomerAccountDialog(): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const { setError } = form;

    const { getAppContextSignedIn } = useGetAppContext();

    const [loading, setLoading] = useState(false);

    const restaurant = useSelector((state) => state.app.restaurant);
    const open = useSelector((state) => state.app.createCustomerAccountDialog.open);
    const mobileNumber = useSelector((state) => state.app.createCustomerAccountDialog.mobileNumber);
    const firstName = useSelector((state) => state.app.createCustomerAccountDialog.firstName);
    const lastName = useSelector((state) => state.app.createCustomerAccountDialog.lastName);
    const email = useSelector((state) => state.app.createCustomerAccountDialog.email);
    const onSuccess = useSelector((state) => state.app.createCustomerAccountDialog.onSuccess);

    const closeCreateCustomerAccountDialog = useAction(actions.closeCreateCustomerAccountDialog);
    const openEnterNameDialog = useAction(actions.openEnterNameDialog);
    const openEnterEmailDialog = useAction(actions.openEnterEmailDialog);

    const password = form.watch('password');

    const onSubmit = async (form: any) => {
        if (form.password !== form.confirmPassword) {
            setError('confirmPassword', { message: translate('Password does not match') });
            return;
        }
        setLoading(true);

        const response = await signUpApi({
            mobileNumber,
            email: form.email,
            firstName: form.firstName,
            lastName: form.lastName,
            encryptedPassword: encryptDataInTransit(form.password),
            urlSubdomain: getUrlSubdomain(),
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await fetchAppContextRecursive();
    };

    const fetchAppContextRecursive = async () => {
        const appContextResponse = await getAppContextSignedIn({ urlSubdomain: getUrlSubdomain(), urlPathname: restaurant.urlPathname ?? '' });

        if (!appContextResponse.ok) {
            alert(translate('Failed to sign in, check you internet connection and press ok to retry'));
            fetchAppContextRecursive();
            return;
        }

        setLoading(false);
        const appContext: GetAppContextSignedInApiResponse = appContextResponse.data as any;

        if (!appContext.firstName || !appContext.lastName) {
            closeCreateCustomerAccountDialog();
            openEnterNameDialog({
                onSuccess: () => {
                    if (!appContext.email) {
                        return openEnterEmailDialog({ onSuccess });
                    }
                    onSuccess?.(appContext);
                },
            });
            return;
        }

        if (!appContext.email) {
            closeCreateCustomerAccountDialog();
            openEnterEmailDialog({ onSuccess });
            return;
        }

        closeCreateCustomerAccountDialog();
        onSuccess?.(appContext);
    };

    const isPasswordValidated = () => Object.values(validatePassword(password)).every(Boolean);

    return (
        <Dialog classes={{ dialog: classes.dialog, title: classes.title }} open={open} title={translate('Create an account')} loading={loading}>
            <Text className={classes.text}>{translate('Your next visit will be simpler and faster')}</Text>
            <Form form={form} className={classes.form} onSubmit={onSubmit} id='form'>
                <FormTextField name='firstName' defaultValue={firstName} label={translate('First Name')} disabled={loading} required />
                <FormTextField name='lastName' defaultValue={lastName} label={translate('Last Name')} disabled={loading} required />
                <FormTextField name='username' defaultValue={mobileNumber} label={translate('Phone')} disabled />
                <FormEmailField name='email' defaultValue={email} label={translate('Email')} disabled={loading} required />
                <div>
                    <FormPasswordField name='password' label={translate('Password')} required disabled={loading} />
                    <PasswordValidatorIndicator password={password} />
                </div>
                <FormPasswordField name='confirmPassword' label={translate('Confirm password')} required disabled={loading} />
                <Button disabled={!isPasswordValidated()} type={'submit'}>
                    {translate('Create account')}
                </Button>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    dialog: {
        maxWidth: '90%',
        height: '100%',
        overflowY: 'scroll',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 440,
        },
    },
    text: {
        textAlign: 'center',
        marginBottom: 10,
    },
    title: {
        color: theme.palette.text.primary,
    },
}));
