import { makeStyles } from '@material-ui/core/styles';
import { useRef, useState } from 'react';
import * as React from 'react';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onChange
 */

/**
 *
 * @param {string} [label] - label for the input
 * @param {string} name - name for the input
 * @param {string} [placeholder] - placeholder for the input
 * @param {string} [inputMode] - input mode, for references see [docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode)
 * @param {string} [defaultValue] - default value for the input, this only works when the Input is not being controlled
 * @param {string} [value] - value for the input when it is going to be controlled
 * @param {boolean} [disabled] - input disabled state
 * @param {string} [type] - define type of input
 * @param {number} [minValue] - when type is numeric define min value
 * @param {number} [step] - when type is numeric define step value to increment or decrement
 * @param {boolean} [required] - input disabled state
 * @param {boolean} [error] - indicates the input error state
 * @param {boolean} [autoFocus] - focus the input on rendering
 * @param {string} [autoComplete] - let the browser to auto complete the input from past values entered, for references see [docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
 * @param {string} [helperText] - state describing the input
 * @param {React.Node} [rightIcon] - element to be shown to the right of the input
 * @param {React.Node} [leftIcon] - element to be shown to the left of the input
 * @param {React.Node} [inputRef] - input element React ref
 * @param {Object} [classes] - classes object to customize the component
 * @param {string} [classes.input] - className that is going to be applied to the input element
 * @param {string} [classes.label] - className that is going to be applied to the label element
 * @param {React.Node} [InputComponent] - component used to replace input component
 * @param {onChange} [onChange] - callback function that is going to be called each time the input changes
 * @returns {React.Node}
 */
export function Input({
    label,
    name,
    placeholder,
    defaultValue,
    disabled,
    required,
    error,
    autoFocus,
    autoComplete,
    helperText,
    inputMode,
    value: valueProp,
    rightIcon,
    leftIcon,
    inputRef,
    classes: classesProp,
    InputComponent,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    type,
    step,
    minValue,
    ...props
}: Props): React.ReactElement {
    const classes = useStyles();
    const inputElementRef = useRef<HTMLElement>(null);

    const [value, setValue] = useState(defaultValue ?? '');

    const handleInputChange = (e: any) => {
        onChange?.(e.target.value);
        setValue(e.target.value);
    };

    const handleInputContainerClick = () => {
        const ref = inputRef || inputElementRef;
        ref.current?.focus();
    };

    const handleBlur = (e: any) => {
        onBlur?.(e);
    };

    return (
        <div className={classes.container}>
            {!!label && (
                <label htmlFor={`${name}-input`} className={classNames(classes.label, error && classes.errorColor, classesProp?.label)}>
                    {label}
                </label>
            )}
            <div className={classNames(classes.inputContainer, error && classes.inputContainerError, classesProp?.inputContainer)} onClick={handleInputContainerClick}>
                {!!leftIcon && leftIcon}
                {InputComponent && (
                    <InputComponent
                        {...props}
                        className={classNames(classes.input, classesProp?.input)}
                        id={`${name}-input`}
                        aria-describedby={`${name}-helperText`}
                        name={name}
                        value={valueProp ?? value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        placeholder={placeholder}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                        required={required}
                        inputMode={inputMode}
                        inputRef={inputRef || inputElementRef}
                    />
                )}
                {!InputComponent && (
                    <input
                        {...props}
                        className={classNames(classes.input, classesProp?.input)}
                        id={`${name}-input`}
                        aria-describedby={`${name}-helperText`}
                        name={name}
                        value={valueProp ?? value}
                        placeholder={placeholder}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                        required={required}
                        type={type}
                        step={step}
                        inputMode={inputMode}
                        ref={inputRef || inputElementRef}
                        onChange={handleInputChange}
                        onKeyDown={handleInputChange}
                        onFocus={onFocus}
                        onBlur={handleBlur}
                        min={minValue}
                    />
                )}
                {!!rightIcon && rightIcon}
            </div>
            {!!helperText && (
                <span id={`${name}-helperText`} className={classNames(classes.helperText, error && classes.errorColor)}>
                    {helperText}
                </span>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    label: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 12,
        color: '#2E3748',
    },
    inputContainer: {
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        padding: '4px 12px',
        minHeight: 42,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        '& svg': {
            color: '#4F586E',
        },
    },
    inputContainerError: {
        border: '1px solid #f44336',
    },
    input: {
        border: 0,
        color: '#2E3748',
        fontSize: 14,
        fontFamily: AppTheme.typography.regular,
        backgroundColor: 'transparent',
        outline: 0,
        width: '100%',
    },
    helperText: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 10,
        color: '#8E919C',
    },
    errorColor: {
        color: '#f44336',
    },
}));

type Props = {
    label?: string;
    helperText?: string;
    name: string;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    placeholder?: string;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
    defaultValue?: string;
    value?: string | number;
    type?: string;
    minValue?: number;
    step?: number;
    rightIcon?: React.ReactNode;
    leftIcon?: React.ReactNode;
    inputRef?: React.RefObject<any>;
    classes?: {
        label?: string;
        input?: string;
        inputContainer?: string;
    };
    InputComponent?: any;
    onChange?: any;
    onKeyDown?: any;
    onFocus?: any;
    onBlur?: any;
};
