import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { listRowHeight } from 'src/components/ListRowButton';
import { AppTheme } from 'src/styles/AppTheme';

export function ListRowCheckbox({ Icon, text, rightText, checked, onChange }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Button text classes={{ button: classes.buttonContainer }} onClick={() => onChange(!checked)}>
                <Icon className={classes.leftIcon} />
                <div className={classes.text}>{text}</div>
                {rightText && <div className={classes.rightText}>{rightText}</div>}
                <Checkbox checked={checked} color='primary' />
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: listRowHeight,
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: listRowHeight,
        paddingLeft: 8,
        paddingRight: 11,
    },
    leftIcon: {
        color: theme.palette.primary.main,
    },
    text: {
        display: 'flex',
        flex: 1,
        fontSize: AppTheme.listRowButton.fontSize,
        fontWeight: 600,
        color: '#000000',
        paddingLeft: 8,
        paddingRight: 8,
    },
    rightText: {
        fontSize: AppTheme.listRowButton.fontSize,
        fontWeight: 600,
        color: AppTheme.listRowButton.color,
    },
}));

type Props = {
    Icon: React.ComponentType<any>;
    text: string;
    rightText?: string;
    checked: boolean;
    onChange: (arg1: boolean) => void;
};
