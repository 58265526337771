import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { setNameApi } from 'src/api/pidedirecto/customer/setNameApi';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

export function EnterNameDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const [error, setError] = useState<{ message: string }>();

    const open = useSelector((state) => state.app.enterNameDialog.open);
    const onSuccess = useSelector((state) => state.app.enterNameDialog.onSuccess);
    const customerId = useSelector((state) => state.app.customerId);

    const closeEnterNameDialog = useAction(actions.closeEnterNameDialog);
    const setFirstName = useAction(actions.setFirstName);
    const setLastName = useAction(actions.setLastName);

    useEffect(() => {
        if (open) {
            setError(undefined);
        }
    }, [open]);

    const onSubmit = async (form: any) => {
        setError(undefined);
        const response = await setNameApi({ customerId, firstName: form.firstName, lastName: form.lastName, urlSubdomain: getUrlSubdomain() });
        if (!response.ok) {
            setError({ message: translate('Failed to save, please try again.') });
            return;
        }
        setFirstName(form.firstName);
        setLastName(form.lastName);
        closeEnterNameDialog();
        onSuccess?.();
    };

    return (
        <Dialog open={open} title={translate('Enter your name')} actionButtonFormId={'form'} actionText={translate('Next')}>
            <Form form={form} onSubmit={onSubmit} className={classes.form} id={'form'}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormTextField name='firstName' label={translate('First Name')} autoFocus required capitalize autoComplete='given-name' />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextField name='lastName' label={translate('Last Name')} required capitalize autoComplete='family-name' />
                        </Grid>
                        <Grid item xs={12}>
                            {!error && (
                                <Text variant='medium' style={{ textAlign: 'center' }}>
                                    {translate('We need your name to be able to hand out your orders')}
                                </Text>
                            )}
                            {!!error && (
                                <Text error variant='medium' style={{ textAlign: 'center' }}>
                                    {translate(error.message)}
                                </Text>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContent: {
        padding: theme.spacing(2),
    },
}));
