import { pidedirectoApi } from 'src/api/pidedirectoApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { dateReviver } from 'src/api/utils/dateReviver';

export async function pidedirectoApiMethodPublic(url: string, request: any): ApiSauceResponse<any> {
    return pidedirectoApi.post(`public/${url}`, request ?? {}, {
        headers: {
            'Content-Type': 'application/vnd.api.autoparsedates+json',
            accept: 'application/vnd.api.autoparsedates+json',
        },
        transformResponse: [(data: any) => (data ? JSON.parse(data, dateReviver) : null)],
    });
}
