export function removeNulls<T extends Record<any, any> | Array<any> | undefined | null>(obj: T): T {
    if (Array.isArray(obj)) {
        return obj.filter((i) => i !== null && i !== undefined).map(removeNulls) as any;
    }
    if (obj?.constructor?.name === 'Object') {
        const copy: Record<string, any> = {};
        Object.keys(obj).forEach((key) => {
            if (obj[key] === null) return;
            if (obj[key] === undefined) return;
            copy[key] = removeNulls(obj[key]);
        });
        return copy as any;
    }
    return obj;
}
