import * as React from 'react';
import { useEffect } from 'react';

export function LoadConektaScript(): React.ReactElement | null {
    useEffect(() => {
        if (document.getElementById('conektajs')) {
            return;
        }
        const script = document.createElement('script');
        script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
        script.async = true;
        script.id = 'conektajs';
        /*eslint no-unused-expressions:0*/
        document?.body?.appendChild(script);
    }, []);

    return null;
}
