import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormPasswordField, FormTextField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import type { GetAppContextSignedInApiResponse } from 'src/api/pidedirecto/getAppContextSignedInApi';
import { Text } from 'src/components/Text';
import { AuthenticationTypes } from 'src/constants/AuthenticationType';
import { LogEventTypes } from 'src/constants/LogEventType';
import { AwsFacade } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

const timeout: any = null;

export function EnterPasswordDialog(): React.ReactElement {
    const classes = useStyles();

    const form = useForm();

    const input: any = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<{ message: string }>();

    const restaurant = useSelector((state) => state.app.restaurant);
    const open = useSelector((state) => state.app.enterPasswordDialog.open);
    const mobileNumber = useSelector((state) => state.app.enterPasswordDialog.mobileNumber);
    const onForgotPassword = useSelector((state) => state.app.enterPasswordDialog.onForgotPassword);
    const onSuccess = useSelector((state) => state.app.enterPasswordDialog.onSuccess);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);

    const closeEnterPasswordDialog = useAction(actions.closeEnterPasswordDialog);
    const openEnterVerificationCodeDialog = useAction(actions.openEnterVerificationCodeDialog);

    const { getAppContextSignedIn } = useGetAppContext();

    useEffect(() => {
        if (open) {
            clearTimeout(timeout);
            setIsSubmitting(false);
            setError(undefined);
            setTimeout(() => {
                input?.current?.focus();
            }, 300);
            form.setValue('mobileNumber', mobileNumber);
        }
    }, [open, mobileNumber]);

    const onPasswordEntered = async (form: any) => {
        setIsSubmitting(true);
        setError(undefined);
        const response = await AwsFacade.verifySignIn(mobileNumber, form.password, AuthenticationTypes.PASSWORD);
        if (response.error) {
            setError(response.error);
            setIsSubmitting(false);
            input.current?.focus();
            return;
        }
        fetchAppContextRecursive();
    };

    const fetchAppContextRecursive = async () => {
        const appContextResponse = await getAppContextSignedIn({ urlSubdomain: getUrlSubdomain(), urlPathname: restaurant.urlPathname ?? '' });
        if (!appContextResponse.ok) {
            alert(translate('Failed to sign in, check you internet connection and press ok to retry'));
            fetchAppContextRecursive();
            return;
        }

        if (!appContextResponse.data) return;

        const appContext: GetAppContextSignedInApiResponse = appContextResponse.data as any;

        closeEnterPasswordDialog();
        onSuccess?.(appContext);
        setIsSubmitting(false);
        createUiInteractionLogEvent({
            logEventType: LogEventTypes.USER_SIGNED_IN_TO_PIDE_DIRECTO,
        });
    };

    const handleForgotPassword = () => {
        onForgotPassword(mobileNumber);
    };

    const handleSignInWithCode = async () => {
        setIsSubmitting(true);
        const response = await AwsFacade.requestSignUpSignIn({ username: mobileNumber, authenticationType: AuthenticationTypes.SMS, restaurantId });
        setIsSubmitting(false);
        if (response.error) {
            setError(response.error);
            return;
        }
        closeEnterPasswordDialog();
        openEnterVerificationCodeDialog({
            mobileNumber: mobileNumber,
            authenticationType: AuthenticationTypes.SMS,
            isSignUp: false,
            onSuccess,
        });
    };

    return (
        <Dialog classes={{ dialog: classes.dialog, title: classes.title }} open={open} loading={isSubmitting} title={translate('Login')} onClose={closeEnterPasswordDialog}>
            <Form form={form} onSubmit={onPasswordEntered} id={'form'}>
                <FormTextField name='mobileNumber' label={translate('Phone Number')} disabled defaultValue={mobileNumber} />
                <FormPasswordField name='password' label={translate('Password')} inputProps={{ autoFocus: true, autoComplete: 'off' } as any} required disabled={isSubmitting} />
                <Button variant='text' classes={{ button: classes.forgotPasswordButton }} onClick={handleForgotPassword}>
                    {translate('Forgot your password?')}
                </Button>

                {!isProductionEnvironment() && (
                    <Text style={{ color: 'red', textAlign: 'center', fontSize: 12 }}>{translate('Your in a dev environment. Use password 111111 to verify sign in/sing up.')}</Text>
                )}
                {!!error && <Text style={{ color: 'red', textAlign: 'center', fontSize: 14 }}>{translate(error?.message)}</Text>}
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleSignInWithCode} variant={'secondary'}>
                        {translate('Use SMS')}
                    </Button>
                    <Button type={'submit'}>{translate('Verify')}</Button>
                </DialogActions>
                <Text style={{ textAlign: 'center', fontSize: 10 }}>
                    {translate('By continuing, your accepting the ')}
                    <a className={classes.textTermsAndPrivacy} href='privacy' target='_blank'>
                        {translate('Privacy Policy')}
                    </a>
                    {translate(' and ')}
                    <a className={classes.textTermsAndPrivacy} href='terms-and-conditions' target='_blank'>
                        {translate('Terms and Conditions')}
                    </a>
                </Text>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    textTermsAndPrivacy: {
        color: 'black',
        textDecoration: 'none',
        fontWeight: 'bold',
    },
    textButton: {
        border: 'none',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        gap: 4,
        padding: 0,
        margin: 0,
        minHeight: 'fit-content',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    forgotPasswordButton: {
        textDecoration: 'underline',
        color: 'black',
        margin: '0 auto',
        '&:disabled': {
            backgroundColor: 'transparent',
        },
    },
    buttons: {
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
        },
    },
    title: {
        color: theme.palette.text.primary,
    },
    dialog: {
        overflow: 'hidden',
        maxWidth: '90%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 460,
        },
    },
}));
