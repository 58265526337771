import { makeStyles } from '@material-ui/core/styles';
import Apple from '@material-ui/icons/Apple';
import PlayStore from '@material-ui/icons/Shop';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function TrackInAppLink(): React.ReactElement | null {
    const classes = useStyles();

    const order = useSelector((state) => state.app.order);
    const trackInAppBannerImageUrl = useSelector((state) => state.app.trackInAppBannerImageUrl);
    const trackInAppBannerColor = useSelector((state) => state.app.trackInAppBannerColor);
    const trackInAppBannerBrandName = useSelector((state) => state.app.trackInAppBannerBrandName);

    if (!(order?.appTrackingUrl && order?.askToTrackInApp)) return null;

    return (
        <div className={classes.trackInAppLink} style={{ backgroundColor: trackInAppBannerColor ?? 'rgb(26, 154, 160)' }}>
            <div className={classes.imageContainer}>
                <img className={classes.trackInAppBannerImageUrl} src={trackInAppBannerImageUrl ?? 'https://images.letseat.mx/12bf36a588578a6fd5a2201baaeb5121.png'} alt='trackInAppBannerImageUrl' />
            </div>
            <div className={classes.column}>
                <h2 className={classes.title}>{translate('Download the @trackInAppBannerBrandName app to receive notifications.', { trackInAppBannerBrandName })}</h2>
                <div className={classes.buttons}>
                    <a className={classes.button} href={order.appTrackingUrl ?? '#'}>
                        <Apple className={classes.downloadLogo} style={{ color: trackInAppBannerColor ?? 'rgb(26, 154, 160)' }} />
                        <div className={classes.textContainer} style={{ color: trackInAppBannerColor ?? 'rgb(26, 154, 160)' }}>
                            <p className={classes.smallText}>{translate('Free download')}</p>
                            <p className={classes.bigText}>apple store</p>
                        </div>
                    </a>
                    <a className={classes.button} href={order.appTrackingUrl ?? '#'}>
                        <PlayStore className={classes.downloadLogo} style={{ color: trackInAppBannerColor ?? 'rgb(26, 154, 160)' }} />
                        <div className={classes.textContainer} style={{ color: trackInAppBannerColor ?? 'rgb(26, 154, 160)' }}>
                            <p className={classes.smallText}>{translate('Free download')}</p>
                            <p className={classes.bigText}>play store</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    trackInAppLink: {
        cursor: 'default',
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        width: '100%',
        height: 'fit-content',
        borderRadius: 12,
        padding: '8px 12px',
        marginTop: 18,
        marginBottom: 18,
        transition: '300ms',
        minHeight: 100,
    },
    imageContainer: {
        position: 'relative',
        width: '50%',
        maxWidth: 150,
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
    },
    trackInAppBannerImageUrl: {
        position: 'absolute',
        height: 110,
        transform: 'translate(-55%, -50%)',
        top: '50%',
        left: '50%',
        [theme.breakpoints.up('sm')]: {
            height: 130,
            transform: 'translate(-50%, -50%)',
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    buttons: {
        display: 'flex',
        width: '100%',
        gap: 4,
        [theme.breakpoints.up('sm')]: {
            gap: 12,
        },
    },
    button: {
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        background: 'white',
        border: 'none',
        padding: '4px 8px',
        textDecoration: 0,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: '#1A9AA0',
        padding: '0px',
    },
    downloadLogo: {
        width: 16,
        marginRight: 4,
        [theme.breakpoints.up('sm')]: {
            width: 24,
            marginRight: 8,
        },
    },
    smallText: {
        margin: '0',
        fontSize: 6,
        textTransform: 'uppercase',
        padding: '0',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            fontSize: 8,
        },
    },
    bigText: {
        fontSize: 10,
        margin: '0',
        padding: '0',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    title: {
        fontSize: 12,
        color: 'white',
        margin: 0,
        marginBottom: 8,
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
}));
