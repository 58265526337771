import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

export function LoadingContentProgress({ loading, text }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!loading) {
        return null;
    }

    return (
        <div className={classes.container}>
            <CircularProgress size={60} style={{ color: 'white' }} />
            {/*<img src={logo}*/}
            {/*     style={{ height: 20, position: 'absolute', top: 36, left: 35 }}*/}
            {/*     alt="image"*/}
            {/*     color="white"*/}
            {/*/>*/}
            {!!text && (
                <Typography variant='subtitle1' align='center' style={{ paddingTop: 20, color: 'white' }}>
                    {text}
                </Typography>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        // paddingTop: 64,
        textAlign: 'center',
        // backgroundColor: 'rgba(26, 154, 160, 0.98)',
        // borderRadius: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));

type Props = {
    loading?: boolean;
    text?: string;
};
