import * as React from 'react';
import { AddNewMercadoPagoCardDialog } from 'src/components/cart/addNewCardDialog/AddNewMercadoPagoCardDialog';
import { AddNewPlexoCardDialog } from 'src/components/cart/addNewCardDialog/AddNewPlexoCardDialog';
import { DefaultAddNewCardDialog } from 'src/components/cart/addNewCardDialog/DefaultAddNewCardDialog';
import { useSelector } from 'src/utils/react/useSelector';
import { isMercadoPagoRestaurantPsp } from 'src/utils/restaurantPsp/isMercadoPagoRestaurantPsp';
import { isPlexoRestaurantPsp } from 'src/utils/restaurantPsp/isPlexoRestaurantPsp';

export function AddNewCardDialog(): React.ReactElement {
    const restaurantPsp = useSelector((state) => state.app.restaurant?.restaurantPsp);

    if (isPlexoRestaurantPsp(restaurantPsp)) {
        return <AddNewPlexoCardDialog />;
    }

    if (isMercadoPagoRestaurantPsp(restaurantPsp)) {
        return <AddNewMercadoPagoCardDialog />;
    }

    return <DefaultAddNewCardDialog />;
}
