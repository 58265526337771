import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function findOrderForInvoiceApi(request: FindOrderForInvoiceApiRequest): ApiSauceResponse<OrderVm> {
    return pidedirectoApiMethodPublic('invoice/findOrderForInvoiceApi', request);
}

export type FindOrderForInvoiceApiRequest = {
    restaurantId: RestaurantId;
    shortOrderId: string;
};
