import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserOpenedDialogLogEvent({ title, pageContext }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_OPENED_DIALOG,
        message: `dialog ${title ?? ''} opened`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`dialog_${title ?? ''}`)}`,
        data: {
            title,
            dialogId: normalizeUiStackTrace(`dialog_${title ?? ''}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`dialog_${title ?? ''}`)}`,
            stackTrace: pageContext.stackTrace,
        },
    });
}

type Params = {
    title?: string;
    pageContext: PageContextType;
};
