import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function PromotionEndedDialog({ open, message, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <Dialog open={open} title={translate('Oops!')} onClose={onClose}>
            <Text>{message}</Text>
            <DialogActions>
                <Button onClick={onClose} classes={{ button: classes.button }}>
                    {translate('Accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100% !important',
    },
}));

type Props = {
    open: boolean;
    message: string;
    onClose: () => void;
};
