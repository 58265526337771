import * as React from 'react';

export function MapPinIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='21' height='25' viewBox='0 0 21 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.98329 24.0752L10.0663 24.1226L10.0994 24.1416C10.231 24.2127 10.3783 24.25 10.5279 24.25C10.6775 24.25 10.8248 24.2127 10.9564 24.1416L10.9896 24.1238L11.0737 24.0752C11.5373 23.8003 11.9897 23.5069 12.4297 23.1957C13.5688 22.3915 14.6332 21.4862 15.6098 20.4909C17.914 18.1322 20.307 14.5883 20.307 10.0285C20.307 7.43509 19.2768 4.94789 17.443 3.11406C15.6091 1.28023 13.1219 0.25 10.5285 0.25C7.93509 0.25 5.44789 1.28023 3.61406 3.11406C1.78023 4.94789 0.75 7.43509 0.75 10.0285C0.75 14.5871 3.14425 18.1322 5.44724 20.4909C6.42348 21.4862 7.48742 22.3914 8.62615 23.1957C9.06656 23.5069 9.5193 23.8003 9.98329 24.0752V24.0752ZM10.5285 13.5843C11.4716 13.5843 12.376 13.2097 13.0429 12.5429C13.7097 11.876 14.0843 10.9716 14.0843 10.0285C14.0843 9.08545 13.7097 8.18101 13.0429 7.51416C12.376 6.84732 11.4716 6.47269 10.5285 6.47269C9.58545 6.47269 8.68101 6.84732 8.01416 7.51416C7.34732 8.18101 6.97269 9.08545 6.97269 10.0285C6.97269 10.9716 7.34732 11.876 8.01416 12.5429C8.68101 13.2097 9.58545 13.5843 10.5285 13.5843V13.5843Z'
                fill={color}
            />
        </svg>
    );
}
type Props = {
    color: string;
};
