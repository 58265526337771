import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Environments } from 'src/constants/Environment';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { classNames } from 'src/utils/react/classNames';

export function EnvironmentLabel(): React.ReactElement | null {
    const classes = useStyles();
    if (envENVIRONMENT() === Environments.PROD) {
        return null;
    }
    return <div className={classNames(classes.container, getClassForEnvironment(classes))}>{envENVIRONMENT()}</div>;
}

function getClassForEnvironment(classes: any) {
    if (envENVIRONMENT() === Environments.TEST) return classes.red;
    if (envENVIRONMENT() === Environments.DEV) return classes.yellow;
    if (envENVIRONMENT() === Environments.LOCALHOST) return classes.green;
    return classes.yellow;
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        paddingLeft: 2,
        paddingRight: 2,
        borderTopRightRadius: 4,
    },
    red: {
        backgroundColor: '#f44336',
        color: 'white',
    },
    yellow: {
        backgroundColor: '#d9c300',
        color: 'white',
    },
    green: {
        backgroundColor: '#4caf50',
        color: 'white',
    },
}));
