import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';

export function envAWS_AUTH_USER_POOL_ID(): string {
    try {
        return requireStringNotBlank(process.env.REACT_APP_AWS_AUTH_USER_POOL_ID);
    } catch (e: any) {
        throw Error(`REACT_APP_AWS_AUTH_USER_POOL_ID is not configured correctly in .env.${envENVIRONMENT().toLowerCase()}`);
    }
}
