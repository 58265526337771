import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Country } from 'src/constants/Country';
import { RestaurantPsp } from 'src/constants/RestaurantPsp';
import type { CardVm } from 'src/types/CardVm';
import { RestaurantId, type CustomerId } from 'src/types/Id';

export async function addRestaurantPspCardApi(request: AddRestaurantPspCardApiRequest): ApiSauceResponse<CardVm> {
    return pidedirectoApiMethod('card/addRestaurantPspCardApi', request);
}

export type AddRestaurantPspCardApiRequest = {
    customerId: CustomerId;
    restaurantId: RestaurantId;
    restaurantPsp: RestaurantPsp;
    cardToken: string;
    mercadoPagoCardToken?: string;
    cvv?: string;
    country: Country;
};
