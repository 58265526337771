import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { createUruguayanOrderInvoiceApi } from 'src/api/pidedirecto/invoice/createUruguayanOrderInvoiceApi';
import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { translate } from 'src/i18n/translate';
import { InvoiceLoader } from 'src/scenes/invoicePage/InvoiceLoader';
import { AppTheme } from 'src/styles/AppTheme';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function UruguayanInvoiceForm({ order }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = async (form: any) => {
        setLoading(true);
        setEmail(form.email);
        const response = await createUruguayanOrderInvoiceApi({
            orderId: requireValue(order?.orderId),
            email: form.email,
        });
        setLoading(false);

        if (!response.ok) {
            setSuccess(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setSuccess(true);
    };

    if (!order) return null;

    return (
        <div className={classes.container}>
            {!success && (
                <Form form={form} className={classes.form} onSubmit={handleSubmit}>
                    <p className={classes.title}>{translate('Tax Info')}</p>
                    <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={12} md={12}>
                            <FormEmailField name='email' label={translate('Email')} required />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={12} md={12}>
                            <Button type='submit' disabled={isSubmitting} classes={{ button: classes.button }}>
                                <div className={classes.button}>{translate('Generate')}</div>
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
            {success && (
                <div className={classes.loaderContainer}>
                    <InvoiceLoader isSuccessful={success} loading={loading} isCompleted={!loading}>
                        <span className={classes.successfulText}>{translate('Successful billing')}</span>
                        <div className={classes.messageContainer}>
                            {translate('The invoice was sent to')} <span className={classes.textBold}>{email}</span>
                        </div>
                    </InvoiceLoader>
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius: 15,
        padding: '30px 40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexShrink: 0,
        height: 'fit-content',
        position: 'relative',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 20,
        margin: 0,
        textAlign: 'center',
        fontFamily: AppTheme.typography.regular,
        color: '#0D3037',
    },
    form: {
        marginTop: 40,
        width: '100%',
        maxWidth: 600,
    },
    buttonContainer: {
        width: '80%',
    },
    button: {
        width: '100%',
    },
    loaderContainer: {
        width: '100%',
        height: '80vh',
        display: 'flex',
    },
    text: {
        color: '#0D3037',
        fontSize: '16px',
        fontFamily: theme.typography.regular,
    },
    successfulText: {
        fontWeight: 500,
        fontSize: '16px',
    },
    messageContainer: {
        width: '100%',
        color: '#818696',
        textAlign: 'center',
        fontFamily: theme.typography.regular,
    },
    textBold: {
        fontWeight: 600,
    },
}));

type Props = {
    order?: OrderVm;
};
