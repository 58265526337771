import * as React from 'react';
import { useEffect } from 'react';
import { actions } from 'src/reducers';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function GeneratePlexoPaymentSecurityId(): React.ReactElement | null {
    const plexoStoreId = useSelector((state) => state.app.restaurant?.plexoStoreId);

    const setPlexoPaymentSecurityId = useAction(actions.setPlexoPaymentSecurityId);

    useEffect(() => {
        initializePlexoPaymentSecurity();
    }, [plexoStoreId]);

    const initializePlexoPaymentSecurity = () => {
        if (!plexoStoreId) return;

        const plexoPaymentSecurityId = newUuid();
        const scriptElement = document.createElement('script');
        const url = `https://h.online-metrix.net/fp/tags.js?org_id=${isProductionEnvironment() ? '9ozphlqx' : '45ssiuz3'}&session_id=${plexoStoreId}${plexoPaymentSecurityId}`;
        scriptElement.src = url;
        scriptElement.type = 'text/javascript';
        const headElements = document.getElementsByTagName('head');
        headElements[0].append(scriptElement);

        setPlexoPaymentSecurityId(plexoPaymentSecurityId);
    };

    return null;
}
