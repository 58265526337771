import moment from 'moment-timezone';
import OpeningHours from 'opening_hours';
import type { TimeZone } from 'src/constants/TimeZone';
import { localDateToMoment } from 'src/utils/date/localDateToMoment';
import { momentToLocalDate } from 'src/utils/date/momentToLocalDate';

export function nextDateTimeWithinHoursAfterDate(hours: string | null | undefined, date: Date | string, timeZone: TimeZone): string | undefined {
    if (!hours || hours.trim() === '' || hours.trim() === '24/7' || hours.trim() === '00:00-24:00') {
        return moment.tz(date, timeZone).toISOString();
    }
    const oh = new OpeningHours(hours);
    const localDate = momentToLocalDate(moment.tz(date, timeZone));
    if (oh.getState(localDate)) {
        return moment(date).toISOString();
    }

    const nextChange = oh.getNextChange(localDate);
    if (!nextChange) {
        return;
    }
    return localDateToMoment(nextChange, timeZone).toISOString();
}
