import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { BottomDrawerTopBar } from 'src/components/BottomDrawerTopBar';
import { MenuItems } from 'src/components/MenuItems';
import { MenuSectionList } from 'src/components/MenuSectionList';
import { RestaurantMenuInfo } from 'src/components/RestaurantMenuInfo';
import { SearchBar } from 'src/components/SearchBar';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { MenuItemList } from 'src/scenes/restaurantMenuPage/MenuItemList';
import { selectMenus } from 'src/selectors/selectMenus';
import { AppTheme } from 'src/styles/AppTheme';
import { theme } from 'src/styles/theme';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMenuBottomDrawer(): React.ReactElement | null {
    const classes = useStyles();

    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const restaurant = useSelector((state) => state.app.restaurant);
    const menus = useSelector(selectMenus);
    const isCartDialogOpen = useSelector((state) => state.app.cartDialog.open);
    const isCheckoutDialogOpen = useSelector((state) => state.app.checkoutDialog.open);
    const orderId = useSelector((state) => state.app.orderId);

    const isFixed = isCartDialogOpen || isCheckoutDialogOpen;

    const renderMenu = (menus: Array<any>) => {
        if (menus.length === 0) {
            return null;
        }
        if (menus[0].type === 'WITHOUT_CATEGORIES') {
            return <MenuItemList items={menus[0].items} />;
        }
        if (menus[0].type === 'WITH_CATEGORIES') return <MenuItems />;

        return null;
    };

    const renderMenuSections = (menus: Array<any>) => {
        if (menus.length === 0) return <Text className={classes.emptyMenuText}>{translate('Unfortunately, there are no products available on our menu at this time')}</Text>;
        return <MenuSectionList />;
    };

    if ((isCartDialogOpen && smallScreen) || (isCheckoutDialogOpen && smallScreen)) {
        return null;
    }

    return (
        <BottomDrawer className={classNames(classes.bottomDrawer, isFixed ? classes.positionFixed : '')}>
            <div className={classes.stickyContainer}>
                {!orderId && <SearchBar />}
                <BottomDrawerTopBar />
                <div className={classes.titleContainer}>
                    {restaurant.logoImageUrl && <img className={classes.logo} src={restaurant.logoImageUrl} alt='logo' />}
                    <h1 className={classes.title}>{restaurant.name}</h1>
                </div>
                <RestaurantMenuInfo restaurant={restaurant} />
                <div>{renderMenuSections(menus)}</div>
            </div>
            <div className={classes.menuContainer}>{renderMenu(menus)}</div>
        </BottomDrawer>
    );
}

const useStyles = makeStyles((theme) => ({
    bottomDrawer: {
        position: 'relative',
        zIndex: 10,
    },
    positionFixed: {
        position: 'fixed',
    },
    stickyContainer: {
        position: 'sticky',
        top: 0,
        background: 'white',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    title: {
        fontFamily: AppTheme.typography.bold,
        fontSize: 20,
        color: '#2E3338',
        margin: 0,
    },
    logo: {
        position: 'relative',
        width: 35,
        height: 35,
        marginRight: 12,
        borderRadius: '50%',
        backgroundColor: 'gray',
        objectFit: 'cover',
    },
    menus: {
        position: 'relative',
    },
    menuContainer: {
        overflow: 'hidden',
        height: 'fit-content',
        paddingBottom: '15vh',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    emptyMenuText: {
        textAlign: 'center',
        color: '#4F586E',
        fontSize: 16,
        fontWeight: 400,
        marginTop: '40px',
    },
}));
