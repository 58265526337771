import securitycode from 'payment-icons/min/outline/security-code.svg';
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { theme } from 'src/styles/theme';
import { isBlankString } from 'src/utils/string/isBlankString';

export function FormCvcField({ name, label, required, disabled, rules, defaultValue = null }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
        watch,
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();
    const cardId = watch(`${name}.cardId`);
    const cardNumber = watch(`${name}.cardNumber`);
    const expiry = watch(`${name}.expiry`);

    const minLength = 3;
    const maxLength = 4;

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const { ref, ...restProps } = props;
                return (
                    <Input
                        {...restProps}
                        value={transformStringInput(value)}
                        onChange={(value: string) => onChange(transformStringOutput(value))}
                        label={label}
                        placeholder={translate('CVC')}
                        error={!!getError(errors, name)?.['cvc']}
                        helperText={getError(errors, name)?.['cvc']?.message}
                        inputRef={inputRef}
                        rightIcon={<img src={securitycode} alt='' height={theme.spacing(2.5)} style={{ opacity: 0.7 }} />}
                        autoComplete='cc-csc'
                        required={required}
                        disabled={isSubmitting || disabled}
                        InputComponent={NumberFormatCustom}
                    />
                );
            }}
            control={control}
            name={`${name}.cvc`}
            defaultValue={cardId ? '***' : defaultValue}
            rules={{
                required: {
                    value: rules?.required || !isBlankString(cardNumber) || !isBlankString(expiry),
                    message: translate('CVC is required'),
                },
                minLength: {
                    value: minLength,
                    message: translate('CVC must be at least @number characters', { number: minLength }),
                },
                maxLength: {
                    value: maxLength,
                    message: translate('CVC must be at most @number characters', { number: maxLength }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat {...other} getInputRef={inputRef} onValueChange={(values) => onChange(values.value)} isNumericString format='####' decimalScale={0} />;
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    value?: string;
    onChange?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    required?: boolean;
    disabled?: boolean;
    rules?: {
        required?: boolean;
    };
    InputProps?: any;
    defaultValue?: string | null;
};
