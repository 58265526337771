import * as React from 'react';
import { SignInCustomerDialog } from 'src/components/dialog/customer/SignInCustomerDialog';
import { SignInForDiningModeDialog } from 'src/components/dialog/customer/SignInForDiningModeDialog';
import { useSelector } from 'src/utils/react/useSelector';

export function SignInDialog({ onSuccess }: Props): React.ReactElement {
    const diningModeEnabled = useSelector((state) => state.app.restaurant?.diningModeEnabled);

    if (diningModeEnabled) return <SignInForDiningModeDialog onSuccess={onSuccess} />;

    return <SignInCustomerDialog onSuccess={onSuccess} />;
}

type Props = {
    onSuccess?: Function;
};
