import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { isWithinHours } from 'src/utils/hours/isWithinHours';

export function createUserSelectedRestaurantFromChainRestaurantsLogEvent(params: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_SELECTED_RESTAURANT_FROM_CHAIN_RESTAURANTS,
        data: {
            restaurantIsOpen: isWithinHours(params.restaurant.hours, params.restaurant.timeZone),
        },
    });
}

type Params = {
    restaurant: RestaurantVm;
};
