import { isString } from 'src/utils/string/isString';

export function isBlankString(value: string): false;
export function isBlankString(value?: any): true;

export function isBlankString(value: string): boolean {
    if (!value) {
        return true;
    }
    if (!isString(value)) {
        return false;
    }
    return value.trim() === '';
}
