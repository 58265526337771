import * as React from 'react';

export function CircleCheckIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='101' height='101' viewBox='0 0 101 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M50.5 0.5C22.8846 0.5 0.5 22.8846 0.5 50.5C0.5 78.1154 22.8846 100.5 50.5 100.5C78.1154 100.5 100.5 78.1154 100.5 50.5C100.5 22.8846 78.1154 0.5 50.5 0.5ZM66.1872 41.8436C66.5749 41.5119 66.8928 41.1064 67.1223 40.6507C67.3518 40.1949 67.4883 39.6981 67.5239 39.189C67.5595 38.68 67.4934 38.169 67.3296 37.6858C67.1657 37.2026 66.9074 36.7568 66.5695 36.3744C66.2317 35.992 65.8211 35.6806 65.3618 35.4584C64.9024 35.2362 64.4035 35.1077 63.894 35.0802C63.3844 35.0528 62.8745 35.127 62.394 35.2986C61.9134 35.4701 61.4718 35.7356 61.0949 36.0795C54.0774 42.2694 48.4663 49.8897 44.6385 58.4282L37.8333 51.6282C37.1042 50.9488 36.1399 50.579 35.1435 50.5965C34.1471 50.6141 33.1964 51.0178 32.4917 51.7225C31.787 52.4271 31.3833 53.3778 31.3658 54.3742C31.3482 55.3707 31.7181 56.335 32.3974 57.0641L43.3461 68.018C43.8084 68.4796 44.3807 68.816 45.009 68.9952C45.6372 69.1744 46.3008 69.1907 46.9371 69.0424C47.5734 68.8941 48.1614 68.5861 48.6457 68.1476C49.13 67.7091 49.4946 67.1544 49.7051 66.5359C52.9541 57.0098 58.6358 48.4979 66.1872 41.8436Z'
                fill={color ?? '#13D48F'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
