import * as React from 'react';
import { CheckoutInfo } from 'src/components/cart/CheckoutInfo';
import { CheckoutSummary } from 'src/components/CheckoutSummary';

export function CheckoutBottom(): React.ReactElement {
    return (
        <div style={{ width: '100%' }}>
            <CheckoutSummary />
            <CheckoutInfo />
        </div>
    );
}
